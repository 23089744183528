import React, { useState, useRef } from 'react';
import { useAuth } from '../contexts/AuthContext';
import useFirebaseStorageUpload from '../hooks/useFirebaseStorageUpload';
import { storage } from '../firebase/config';
import { ref, getDownloadURL } from 'firebase/storage';

const FileUploadTest: React.FC = () => {
  const { currentUser } = useAuth();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [uploadResult, setUploadResult] = useState<any>(null);
  const [downloadTest, setDownloadTest] = useState<any>(null);
  const [corsResult, setCorsResult] = useState<any>(null);
  const [fileUrl, setFileUrl] = useState<string | null>(null);
  const [testLogs, setTestLogs] = useState<string[]>([]);
  
  const {
    uploadFile,
    isUploading,
    progress,
    error,
    url: uploadedUrl,
    fileName,
    debugInfo
  } = useFirebaseStorageUpload();
  
  const addLog = (message: string) => {
    setTestLogs(prev => [...prev, `[${new Date().toISOString()}] ${message}`]);
  };
  
  const handleFileUpload = async (file: File) => {
    if (!file) {
      addLog('Error: No file selected');
      return;
    }
    
    try {
      addLog(`Starting upload test for ${file.name} (${file.size} bytes, type: ${file.type})`);
      
      // Create a unique test path
      const testPath = `test-uploads/${Date.now()}`;
      addLog(`Upload path: ${testPath}`);
      
      // Attempt the upload
      const result = await uploadFile(file, testPath);
      setUploadResult(result);
      
      if (result.success && result.url) {
        addLog(`✅ Upload successful! URL: ${result.url}`);
        setFileUrl(result.url);
        
        // Test accessing the file immediately after upload
        try {
          const response = await fetch(result.url);
          const status = response.status;
          
          addLog(`📋 Fetch test status: ${status}`);
          if (status === 200) {
            addLog('✅ File is accessible via fetch after upload');
          } else {
            addLog(`❌ File fetch failed with status: ${status}`);
          }
          
          // Safely get headers as object
          const headers: Record<string, string> = {};
          response.headers.forEach((value, key) => {
            headers[key] = value;
          });
          
          setDownloadTest({
            success: status === 200,
            status,
            headers
          });
        } catch (error) {
          addLog(`❌ Error testing file access: ${error instanceof Error ? error.message : 'Unknown error'}`);
          setDownloadTest({
            success: false,
            error: error instanceof Error ? error.message : 'Unknown error',
          });
        }
      } else {
        addLog(`❌ Upload failed: ${result.error?.message || 'Unknown error'}`);
      }
    } catch (error) {
      addLog(`❌ Exception during upload test: ${error instanceof Error ? error.message : 'Unknown error'}`);
      setUploadResult({
        success: false,
        error: error instanceof Error ? error.message : 'Unknown error',
      });
    }
  };
  
  const testCorsConfiguration = async () => {
    addLog('🔍 Testing CORS configuration...');
    
    try {
      // Create a test URL to check CORS headers
      const testRef = ref(storage, 'test-cors-config.txt');
      let testUrl = '';
      
      try {
        testUrl = await getDownloadURL(testRef);
      } catch {
        // If file doesn't exist, we'll use a fallback URL
        testUrl = `https://firebasestorage.googleapis.com/v0/b/huduma-portal-5425b.appspot.com/o/test-cors-config.txt?alt=media`;
      }
      
      addLog(`Testing CORS with URL: ${testUrl}`);
      
      // First, try with fetch to check CORS headers
      const fetchOptions = {
        method: 'OPTIONS',
        headers: {
          'Origin': 'https://hudumacenter.com',
          'Access-Control-Request-Method': 'GET',
          'Access-Control-Request-Headers': 'Content-Type'
        }
      };
      
      // This will likely fail due to CORS if not configured correctly,
      // but we catch the error to provide diagnostics
      try {
        const response = await fetch(testUrl, fetchOptions);
        const corsHeaders = {
          'access-control-allow-origin': response.headers.get('access-control-allow-origin'),
          'access-control-allow-methods': response.headers.get('access-control-allow-methods'),
          'access-control-allow-headers': response.headers.get('access-control-allow-headers'),
          'access-control-max-age': response.headers.get('access-control-max-age')
        };
        
        addLog(`✅ CORS preflight success! Status: ${response.status}`);
        addLog(`CORS Headers: ${JSON.stringify(corsHeaders, null, 2)}`);
        
        setCorsResult({
          success: true,
          status: response.status,
          headers: corsHeaders
        });
      } catch (error) {
        addLog(`❌ CORS preflight test failed: ${error instanceof Error ? error.message : 'Unknown error'}`);
        
        // Since direct fetch likely failed, try XHR for more details
        const xhr = new XMLHttpRequest();
        xhr.open('OPTIONS', testUrl);
        xhr.setRequestHeader('Origin', 'https://hudumacenter.com');
        xhr.setRequestHeader('Access-Control-Request-Method', 'GET');
        xhr.setRequestHeader('Access-Control-Request-Headers', 'Content-Type');
        
        xhr.onload = () => {
          addLog(`XHR test status: ${xhr.status}`);
          const headers = {
            'access-control-allow-origin': xhr.getResponseHeader('access-control-allow-origin'),
            'access-control-allow-methods': xhr.getResponseHeader('access-control-allow-methods'),
            'access-control-allow-headers': xhr.getResponseHeader('access-control-allow-headers')
          };
          addLog(`XHR CORS Headers: ${JSON.stringify(headers, null, 2)}`);
          
          setCorsResult({
            success: xhr.status >= 200 && xhr.status < 300,
            status: xhr.status,
            headers,
            method: 'xhr'
          });
        };
        
        xhr.onerror = (e) => {
          addLog(`❌ XHR CORS test failed`);
          setCorsResult({
            success: false,
            error: 'Network error during XHR test',
            method: 'xhr'
          });
        };
        
        try {
          xhr.send();
        } catch (xhrError) {
          addLog(`❌ Error sending XHR request: ${xhrError instanceof Error ? xhrError.message : 'Unknown error'}`);
        }
      }
    } catch (error) {
      addLog(`❌ Error in CORS test: ${error instanceof Error ? error.message : 'Unknown error'}`);
      setCorsResult({
        success: false,
        error: error instanceof Error ? error.message : 'Unknown error'
      });
    }
  };
  
  return (
    <div className="container mx-auto py-8 px-4">
      <div className="max-w-4xl mx-auto">
        <h1 className="text-3xl font-bold mb-6">Firebase Storage Upload Test</h1>
        
        {!currentUser && (
          <div className="bg-yellow-100 border-l-4 border-yellow-500 text-yellow-700 p-4 mb-6">
            <p>⚠️ You are not logged in. Some operations might fail due to authentication requirements.</p>
          </div>
        )}
        
        <div className="bg-white rounded-lg shadow-md p-6 mb-6">
          <h2 className="text-xl font-semibold mb-4">1. Test File Upload</h2>
          
          <div className="mb-4">
            <input
              type="file"
              ref={fileInputRef}
              className="hidden"
              onChange={(e) => e.target.files?.[0] && handleFileUpload(e.target.files[0])}
            />
            <button
              onClick={() => fileInputRef.current?.click()}
              disabled={isUploading}
              className="bg-blue-600 hover:bg-blue-700 text-white font-medium py-2 px-4 rounded disabled:opacity-50"
            >
              {isUploading ? (
                <span className="flex items-center">
                  <svg className="animate-spin -ml-1 mr-2 h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                  </svg>
                  Uploading... {Math.round(progress)}%
                </span>
              ) : (
                'Select File to Test Upload'
              )}
            </button>
          </div>
          
          {fileUrl && (
            <div className="mt-4 p-3 bg-gray-50 rounded border">
              <p className="font-medium">File URL:</p>
              <div className="flex items-center mt-1">
                <input 
                  type="text" 
                  value={fileUrl} 
                  readOnly 
                  className="w-full px-3 py-2 border rounded text-sm font-mono bg-white"
                />
                <button
                  onClick={() => window.open(fileUrl, '_blank')}
                  className="ml-2 px-3 py-2 bg-gray-200 rounded hover:bg-gray-300"
                >
                  Open
                </button>
              </div>
            </div>
          )}
          
          {uploadResult && (
            <div className={`mt-4 p-4 rounded border ${uploadResult.success ? 'bg-green-50 border-green-200' : 'bg-red-50 border-red-200'}`}>
              <h3 className="font-medium">
                {uploadResult.success ? '✅ Upload Result: Success' : '❌ Upload Result: Failed'}
              </h3>
              <pre className="mt-2 p-3 bg-gray-800 text-gray-100 rounded text-xs overflow-auto max-h-32">
                {JSON.stringify(uploadResult, null, 2)}
              </pre>
            </div>
          )}
        </div>
        
        <div className="bg-white rounded-lg shadow-md p-6 mb-6">
          <h2 className="text-xl font-semibold mb-4">2. Test CORS Configuration</h2>
          <button
            onClick={testCorsConfiguration}
            className="bg-green-600 hover:bg-green-700 text-white font-medium py-2 px-4 rounded"
          >
            Test CORS Settings
          </button>
          
          {corsResult && (
            <div className={`mt-4 p-4 rounded border ${corsResult.success ? 'bg-green-50 border-green-200' : 'bg-red-50 border-red-200'}`}>
              <h3 className="font-medium">
                {corsResult.success ? '✅ CORS Test: Success' : '❌ CORS Test: Failed'}
              </h3>
              <pre className="mt-2 p-3 bg-gray-800 text-gray-100 rounded text-xs overflow-auto max-h-32">
                {JSON.stringify(corsResult, null, 2)}
              </pre>
            </div>
          )}
        </div>
        
        <div className="bg-white rounded-lg shadow-md p-6">
          <h2 className="text-xl font-semibold mb-4">Debug Logs</h2>
          <div className="bg-gray-900 rounded p-4 max-h-96 overflow-auto">
            {testLogs.map((log, index) => (
              <div key={index} className="text-xs font-mono mb-1">
                <span className={
                  log.includes('❌') ? 'text-red-400' : 
                  log.includes('✅') ? 'text-green-400' : 
                  log.includes('🔍') ? 'text-blue-400' : 
                  log.includes('⚠️') ? 'text-yellow-400' : 'text-gray-300'
                }>
                  {log}
                </span>
              </div>
            ))}
            {testLogs.length === 0 && (
              <p className="text-gray-500 text-sm">No logs yet. Run a test to see debug information.</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FileUploadTest; 