import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { getUserById, updateUser, User, UserRole, UserStatus } from '../services/userService';
import Card from '../components/ui/Card';
import Button from '../components/ui/Button';
import FormInput from '../components/ui/FormInput';
import FormSelect from '../components/ui/FormSelect';

const ROLES: UserRole[] = ['admin', 'manager', 'agent', 'receptionist', 'bank_agent'];
const DEPARTMENTS = ['Management', 'Finance', 'Customer Service', 'Reception', 'Banking'];
const STATUS_OPTIONS: UserStatus[] = ['active', 'inactive', 'suspended', 'pending'];

// Define available permissions
const AVAILABLE_PERMISSIONS = {
  users: ['view_users', 'create_users', 'edit_users', 'delete_users'],
  appointments: ['view_appointments', 'create_appointments', 'edit_appointments', 'delete_appointments'],
  reports: ['view_reports', 'create_reports', 'edit_reports', 'delete_reports'],
  settings: ['view_settings', 'edit_settings'],
  audit_logs: ['view_audit_logs'],
  banking: ['process_transactions', 'view_accounts', 'manage_accounts'],
} as const;

interface UserDetails extends User {
  id: string;
}

export default function UserDetailsPage() {
  const { userId } = useParams<{ userId: string }>();
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const [user, setUser] = useState<UserDetails | null>(null);
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [activeTab, setActiveTab] = useState('general');

  useEffect(() => {
    const fetchUser = async () => {
      if (!userId) return;
      try {
        const userData = await getUserById(userId);
        if (userData) {
          setUser({
            ...userData,
            permissions: userData.permissions || [],
            id: userId,
          } as UserDetails);
        }
      } catch (error) {
        console.error('Error fetching user:', error);
        // Show error toast
      } finally {
        setLoading(false);
      }
    };

    fetchUser();
  }, [userId]);

  // Check if current user is admin
  if (currentUser?.role !== 'admin') {
    navigate('/dashboard');
    return null;
  }

  if (loading) {
    return <div className="flex items-center justify-center min-h-screen">Loading...</div>;
  }

  if (!user) {
    return <div className="flex items-center justify-center min-h-screen">User not found</div>;
  }

  const handleSave = async () => {
    if (!user) return;
    setSaving(true);
    try {
      await updateUser(userId!, {
        displayName: user.displayName,
        role: user.role as UserRole,
        department: user.department,
        status: user.status as UserStatus,
        permissions: user.permissions,
        notes: user.notes,
        phoneNumber: user.phoneNumber,
      });
      // Show success toast
    } catch (error) {
      console.error('Error updating user:', error);
      // Show error toast
    } finally {
      setSaving(false);
    }
  };

  const handlePermissionChange = (permission: string, checked: boolean) => {
    if (!user) return;
    setUser(prev => {
      if (!prev) return prev;
      const newPermissions = checked
        ? [...(prev.permissions || []), permission]
        : (prev.permissions || []).filter(p => p !== permission);
      return { ...prev, permissions: newPermissions };
    });
  };

  return (
    <div className="container mx-auto py-8 px-4">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-bold">User Details: {user.displayName}</h1>
        <div className="space-x-4">
          <Button
            variant="outline"
            onClick={() => navigate('/users')}
          >
            Back to Users
          </Button>
          <Button
            onClick={handleSave}
            isDisabled={saving}
            isLoading={saving}
          >
            Save Changes
          </Button>
        </div>
      </div>

      <div className="space-y-6">
        <Card>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div className="space-y-4">
              <FormInput
                id="displayName"
                name="displayName"
                label="Display Name"
                value={user.displayName || ''}
                onChange={(e) => setUser(prev => ({ ...prev!, displayName: e.target.value }))}
              />
              <FormInput
                id="email"
                name="email"
                label="Email"
                value={user.email}
                onChange={() => {}}
                disabled
              />
              <FormInput
                id="phoneNumber"
                name="phoneNumber"
                label="Phone Number"
                value={user.phoneNumber || ''}
                onChange={(e) => setUser(prev => ({ ...prev!, phoneNumber: e.target.value }))}
              />
            </div>
            <div className="space-y-4">
              <FormSelect
                id="role"
                name="role"
                label="Role"
                value={user.role}
                onChange={(e) => setUser(prev => ({ ...prev!, role: e.target.value as UserRole }))}
                options={ROLES.map(role => ({
                  value: role,
                  label: role.charAt(0).toUpperCase() + role.slice(1).replace('_', ' ')
                }))}
              />
              <FormSelect
                id="department"
                name="department"
                label="Department"
                value={user.department || ''}
                onChange={(e) => setUser(prev => ({ ...prev!, department: e.target.value }))}
                options={DEPARTMENTS.map(dept => ({
                  value: dept,
                  label: dept
                }))}
              />
              <FormSelect
                id="status"
                name="status"
                label="Status"
                value={user.status || 'active'}
                onChange={(e) => setUser(prev => ({ ...prev!, status: e.target.value as UserStatus }))}
                options={STATUS_OPTIONS.map(status => ({
                  value: status,
                  label: status.charAt(0).toUpperCase() + status.slice(1)
                }))}
              />
            </div>
          </div>
        </Card>

        <Card title="Permissions">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {Object.entries(AVAILABLE_PERMISSIONS).map(([category, permissions]) => (
              <div key={category} className="space-y-4">
                <h3 className="font-semibold text-lg capitalize">{category.replace('_', ' ')}</h3>
                {permissions.map(permission => (
                  <div key={permission} className="flex items-center space-x-2">
                    <input
                      type="checkbox"
                      id={permission}
                      checked={user.permissions?.includes(permission)}
                      onChange={(e) => handlePermissionChange(permission, e.target.checked)}
                      className="rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                    />
                    <label
                      htmlFor={permission}
                      className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                    >
                      {permission.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}
                    </label>
                  </div>
                ))}
              </div>
            ))}
          </div>
        </Card>

        <Card title="Notes">
          <div className="space-y-4">
            <textarea
              className="w-full min-h-[200px] p-2 border rounded-md"
              value={user.notes || ''}
              onChange={(e) => setUser(prev => ({ ...prev!, notes: e.target.value }))}
              placeholder="Add notes about this user..."
            />
          </div>
        </Card>
      </div>
    </div>
  );
} 