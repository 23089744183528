import React from 'react';
import { shadows, borderRadius, spacing } from '../../styles/designTokens';

interface CardProps {
  children: React.ReactNode;
  title?: string;
  subtitle?: string;
  headerAction?: React.ReactNode;
  footerAction?: React.ReactNode;
  className?: string;
  isHoverable?: boolean;
}

const Card: React.FC<CardProps> = ({
  children,
  title,
  subtitle,
  headerAction,
  footerAction,
  className = '',
  isHoverable = false,
}) => {
  return (
    <div 
      className={`
        bg-white rounded-lg shadow-${shadows.md} overflow-hidden
        ${isHoverable ? 'transition hover:shadow-lg transform hover:-translate-y-1' : ''}
        ${className}
      `}
    >
      {(title || subtitle || headerAction) && (
        <div className="px-6 py-4 border-b border-gray-200 flex justify-between items-center">
          <div>
            {title && <h3 className="text-lg font-medium text-gray-900">{title}</h3>}
            {subtitle && <p className="mt-1 text-sm text-gray-500">{subtitle}</p>}
          </div>
          {headerAction && <div>{headerAction}</div>}
        </div>
      )}
      
      <div className="p-6">{children}</div>
      
      {footerAction && (
        <div className="px-6 py-4 border-t border-gray-200 bg-gray-50">
          {footerAction}
        </div>
      )}
    </div>
  );
};

export default Card; 