import React, { ReactNode, useState, useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import Header from './Header';
import Sidebar from './Sidebar';
import Footer from './Footer';

const Layout: React.FC<{ children?: ReactNode }> = ({ children }) => {
  const { currentUser } = useAuth();
  const location = useLocation();
  const isHomePage = location.pathname === '/';
  const isMessagesPage = location.pathname === '/messages';
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);
  
  // Handle sidebar toggle
  const toggleSidebar = () => {
    setIsSidebarCollapsed(!isSidebarCollapsed);
  };

  // Don't show the sidebar on the home page
  const showSidebar = currentUser && !isHomePage;

  // Calculate sidebar width for layout adjustments
  const sidebarWidth = showSidebar ? (isSidebarCollapsed ? '3.5rem' : '16rem') : '0px';

  // If it's the home page, use a different layout
  if (isHomePage) {
    return (
      <div className="flex flex-col min-h-screen bg-gray-50">
        <Header />
        <main className="flex-1">
          {children || <Outlet />}
        </main>
        <Footer />
      </div>
    );
  }

  // For messages page, use fixed viewport layout
  if (isMessagesPage) {
    return (
      <div className="flex flex-col h-screen bg-gray-50 overflow-hidden">
        <Header className="fixed top-0 left-0 right-0 z-50 h-16" />
        <div className="flex flex-1">
          {showSidebar && (
            <Sidebar 
              className="fixed left-0 top-16 bottom-0 z-40" 
              isCollapsed={isSidebarCollapsed}
              onToggle={toggleSidebar}
            />
          )}
          <main 
            className={`
              flex-1
              fixed
              top-16
              right-0
              bottom-0
              transition-all
              duration-300
              ${showSidebar ? `left-[${sidebarWidth}]` : 'left-0'}
              flex
              flex-col
            `}
            style={{ left: showSidebar ? sidebarWidth : '0px' }}
          >
            <div className="flex-1 flex flex-col min-h-0">
              {children || <Outlet />}
            </div>
          </main>
        </div>
      </div>
    );
  }

  // For all other pages, use regular scrolling layout
  return (
    <div className="flex flex-col min-h-screen bg-gray-50">
      <Header />
      <div className="flex flex-1 pt-16">
        {showSidebar && (
          <Sidebar 
            className="fixed left-0 top-16 bottom-0 bg-white border-r border-gray-200" 
            isCollapsed={isSidebarCollapsed}
            onToggle={toggleSidebar}
          />
        )}
        <main 
          className={`
            flex-1
            transition-all
            duration-300
            p-6
          `}
          style={{ marginLeft: showSidebar ? sidebarWidth : '0px' }}
        >
          {children || <Outlet />}
        </main>
      </div>
      <Footer />
    </div>
  );
};

export default Layout; 