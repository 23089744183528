import React from 'react';
import { cn } from '@/lib/utils';

type StepStatus = 'active' | 'completed' | 'upcoming';

interface Step {
  id: string;
  label: string;
  status: StepStatus;
}

interface StepIndicatorProps {
  steps: Step[];
  currentStep: string;
  className?: string;
}

const StepIndicator: React.FC<StepIndicatorProps> = ({
  steps,
  currentStep,
  className
}) => {
  return (
    <div className={cn("mb-6", className)}>
      <ol className="flex items-center w-full text-sm font-medium text-center text-gray-500 sm:text-base">
        {steps.map((step, index) => {
          const isActive = step.id === currentStep;
          const isCompleted = step.status === 'completed';
          
          return (
            <li key={step.id} className={cn(
              "flex md:w-full items-center",
              index < steps.length - 1 ? "after:content-[''] after:w-full after:h-1 after:border-b after:border-gray-200 after:border-1 after:hidden sm:after:inline-block after:mx-6 xl:after:mx-10" : ""
            )}>
              <span className={cn(
                "flex items-center justify-center w-8 h-8 rounded-full shrink-0",
                isActive ? "bg-blue-600 text-white" : 
                isCompleted ? "bg-green-600 text-white" : "bg-gray-200"
              )}>
                {isCompleted ? (
                  <svg className="w-3.5 h-3.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12">
                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 5.917 5.724 10.5 15 1.5"/>
                  </svg>
                ) : (
                  index + 1
                )}
              </span>
              <span className="hidden sm:inline-flex sm:ml-2">{step.label}</span>
            </li>
          );
        })}
      </ol>
    </div>
  );
};

export default StepIndicator; 