import React, { useState, useRef } from 'react';
import useFirebaseStorageUpload from '../hooks/useFirebaseStorageUpload';
import { useAuth } from '../contexts/AuthContext';
import { addDocumentToApplication } from '../services/applicationService';
import type { ApplicationDocument } from '../services/applicationService';

interface DocumentUploaderProps {
  applicationId: string;
  onUploadComplete?: () => void;
}

const DocumentUploader: React.FC<DocumentUploaderProps> = ({ 
  applicationId, 
  onUploadComplete 
}) => {
  const { currentUser } = useAuth();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [uploadDetails, setUploadDetails] = useState<string | null>(null);
  const { uploadFile, isUploading, progress, error, resetUploadState } = useFirebaseStorageUpload();

  const handleFileUpload = async (file: File) => {
    if (!applicationId || !currentUser) {
      setErrorMessage("Missing application ID or user is not logged in");
      return;
    }
    
    try {
      setErrorMessage(null);
      setUploadDetails(`Starting upload of ${file.name} (${file.size} bytes) to path: applications/${applicationId}/documents`);
      
      console.log('Starting file upload:', { 
        fileName: file.name,
        fileSize: file.size,
        fileType: file.type,
        path: `applications/${applicationId}/documents`
      });
      
      const result = await uploadFile(file, `applications/${applicationId}/documents`);
      
      console.log('Upload result:', result);
      
      if (result.success && result.url) {
        setUploadDetails(`File uploaded successfully. URL: ${result.url}`);
        
        const document: ApplicationDocument = {
          fileName: result.fileName || file.name,
          fileURL: result.url,
          uploadedAt: new Date(),
          uploadedBy: currentUser.displayName || currentUser.email || 'Unknown Staff',
          uploadedByEmail: currentUser.email || undefined,
          fileType: result.fileType || file.type,
          description: ''
        };
        
        console.log('Adding document to application:', document);
        
        await addDocumentToApplication(applicationId, document);
        console.log('Document added to application successfully');
        
        if (onUploadComplete) {
          onUploadComplete();
        }
      } else if (result.error) {
        throw result.error;
      }
    } catch (err) {
      console.error('Error uploading file:', err);
      setErrorMessage(err instanceof Error 
        ? `Upload error: ${err.message}` 
        : 'Failed to upload file. Please try again.'
      );
      setUploadDetails(null);
    }
  };

  return (
    <div className="p-4 border rounded-lg bg-white shadow-sm">
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-lg font-semibold">Documents</h2>
        <button
          onClick={() => fileInputRef.current?.click()}
          disabled={isUploading}
          className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50"
          title={isUploading ? "Upload in progress" : "Upload a new document"}
        >
          {isUploading ? (
            <>
              <svg className="animate-spin w-5 h-5 mr-2" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
              </svg>
              Uploading...
            </>
          ) : (
            <>
              <svg className="w-5 h-5 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 4v16m8-8H4" />
              </svg>
              Upload Document
            </>
          )}
        </button>
        <input
          type="file"
          ref={fileInputRef}
          className="hidden"
          onChange={(e) => {
            if (e.target.files?.[0]) {
              handleFileUpload(e.target.files[0]);
              // Reset the input value so the same file can be selected again if needed
              e.target.value = '';
            }
          }}
          accept=".pdf,.doc,.docx,.jpg,.jpeg,.png"
          aria-label="Upload document"
        />
      </div>
      
      {isUploading && (
        <div className="mb-4">
          <div className="bg-blue-50 rounded-lg p-4">
            <div className="flex items-center">
              <div className="mr-3">
                <svg className="animate-spin h-5 w-5 text-blue-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
              </div>
              <div className="w-full">
                <p className="text-sm font-medium text-blue-800">Uploading document...</p>
                <div className="w-full bg-gray-200 rounded-full h-2.5 mt-2">
                  <div className="bg-blue-600 h-2.5 rounded-full" style={{ width: `${progress}%` }}></div>
                </div>
                <p className="text-xs text-blue-600 mt-1">{Math.round(progress)}% complete</p>
              </div>
            </div>
          </div>
        </div>
      )}
      
      {uploadDetails && !errorMessage && !isUploading && (
        <div className="mb-4">
          <div className="bg-green-50 rounded-lg p-4">
            <div className="flex">
              <div className="flex-shrink-0">
                <svg className="h-5 w-5 text-green-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                  <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
                </svg>
              </div>
              <div className="ml-3">
                <h3 className="text-sm font-medium text-green-800">Upload Status</h3>
                <div className="mt-2 text-sm text-green-700">
                  <p>{uploadDetails}</p>
                </div>
                <div className="mt-2">
                  <button
                    type="button"
                    onClick={() => setUploadDetails(null)}
                    className="inline-flex items-center px-2 py-1 border border-transparent text-xs font-medium rounded-md text-green-700 bg-green-100 hover:bg-green-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                  >
                    Dismiss
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      
      {errorMessage && (
        <div className="mb-4">
          <div className="bg-red-50 rounded-lg p-4">
            <div className="flex">
              <div className="flex-shrink-0">
                <svg className="h-5 w-5 text-red-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clipRule="evenodd" />
                </svg>
              </div>
              <div className="ml-3">
                <h3 className="text-sm font-medium text-red-800">Upload Error</h3>
                <div className="mt-2 text-sm text-red-700">
                  <p>{errorMessage}</p>
                </div>
                <div className="mt-2">
                  <button
                    type="button"
                    onClick={() => {
                      setErrorMessage(null);
                      resetUploadState();
                    }}
                    className="inline-flex items-center px-2 py-1 border border-transparent text-xs font-medium rounded-md text-red-700 bg-red-100 hover:bg-red-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                  >
                    Dismiss
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default DocumentUploader; 