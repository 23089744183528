import { collection, doc, getDoc, getDocs, query, setDoc, updateDoc, where, serverTimestamp } from 'firebase/firestore';
import { getAuth, createUserWithEmailAndPassword, onAuthStateChanged } from 'firebase/auth';
import { db } from '../firebase/config';

const auth = getAuth();

export type UserRole = 'admin' | 'manager' | 'agent' | 'receptionist' | 'bank_agent';
export type UserStatus = 'active' | 'inactive' | 'suspended' | 'pending';

export interface User {
  uid: string;
  email: string;
  displayName: string;
  role: UserRole;
  permissions: string[];
  status: UserStatus;
  department?: string;
  isActive: boolean;
  createdAt: Date;
  lastSignIn?: Date;
  photoURL?: string;
  phoneNumber?: string;
  lastUpdated?: Date;
  lastUpdatedBy?: string;
  notes?: string;
}

// Simple list of all available permissions based on pages/features
export const ALL_PERMISSIONS = {
  // Dashboard
  view_dashboard: 'view_dashboard',
  
  // Visitor Registration
  view_visitor_registration: 'view_visitor_registration',
  manage_visitor_registration: 'manage_visitor_registration',
  
  // Visit Management
  view_visit_management: 'view_visit_management',
  manage_visits: 'manage_visits',
  
  // HCA Applications
  view_applications: 'view_applications',
  manage_applications: 'manage_applications',
  
  // Appointments
  view_appointments: 'view_appointments',
  manage_appointments: 'manage_appointments',
  
  // Jobs
  view_jobs: 'view_jobs',
  manage_jobs: 'manage_jobs',
  
  // Messages
  view_messages: 'view_messages',
  send_messages: 'send_messages',
  
  // Reports
  view_reports: 'view_reports',
  manage_reports: 'manage_reports',
  
  // Users
  view_users: 'view_users',
  manage_users: 'manage_users',
  
  // Bank Services
  view_bank_services: 'view_bank_services',
  manage_bank_services: 'manage_bank_services',
};

// Default permissions for each role
export const ROLE_PERMISSIONS = {
  admin: Object.values(ALL_PERMISSIONS),
  manager: [
    ALL_PERMISSIONS.view_dashboard,
    ALL_PERMISSIONS.view_visitor_registration,
    ALL_PERMISSIONS.manage_visitor_registration,
    ALL_PERMISSIONS.view_visit_management,
    ALL_PERMISSIONS.manage_visits,
    ALL_PERMISSIONS.view_applications,
    ALL_PERMISSIONS.manage_applications,
    ALL_PERMISSIONS.view_appointments,
    ALL_PERMISSIONS.manage_appointments,
    ALL_PERMISSIONS.view_jobs,
    ALL_PERMISSIONS.manage_jobs,
    ALL_PERMISSIONS.view_messages,
    ALL_PERMISSIONS.send_messages,
    ALL_PERMISSIONS.view_reports,
  ],
  agent: [
    // Dashboard
    ALL_PERMISSIONS.view_dashboard,
    
    // Visitor Registration
    ALL_PERMISSIONS.view_visitor_registration,
    ALL_PERMISSIONS.manage_visitor_registration,
    
    // Visit Management
    ALL_PERMISSIONS.view_visit_management,
    ALL_PERMISSIONS.manage_visits,
    
    // HCA Applications
    ALL_PERMISSIONS.view_applications,
    ALL_PERMISSIONS.manage_applications,
    
    // Appointments
    ALL_PERMISSIONS.view_appointments,
    ALL_PERMISSIONS.manage_appointments,
    
    // Jobs
    ALL_PERMISSIONS.view_jobs,
    ALL_PERMISSIONS.manage_jobs,

    // Bank Services
    ALL_PERMISSIONS.view_bank_services,
    ALL_PERMISSIONS.manage_bank_services,

    // Messages
    ALL_PERMISSIONS.view_messages,
    ALL_PERMISSIONS.send_messages,
  ],
  receptionist: [
    ALL_PERMISSIONS.view_dashboard,
    ALL_PERMISSIONS.view_visitor_registration,
    ALL_PERMISSIONS.manage_visitor_registration,
    ALL_PERMISSIONS.view_visit_management,
    ALL_PERMISSIONS.view_appointments,
  ],
  bank_agent: [
    ALL_PERMISSIONS.view_dashboard,
    ALL_PERMISSIONS.view_bank_services,
    ALL_PERMISSIONS.manage_bank_services,
  ],
};

export const DEPARTMENTS = [
  'Management',
  'Customer Service',
  'Passport Services',
  'ID Services',
  'Banking Services',
  'Reception',
  'IT Support'
];

/**
 * Get a user by their UID
 */
export const getUserById = async (uid: string): Promise<User | null> => {
  try {
    const userRef = doc(db, 'users', uid);
    const userSnap = await getDoc(userRef);
    
    if (userSnap.exists()) {
      return userSnap.data() as User;
    }
    
    return null;
  } catch (error) {
    console.error('Error getting user:', error);
    throw error;
  }
};

/**
 * Get all users
 */
export const getAllUsers = async (): Promise<User[]> => {
  try {
    const usersRef = collection(db, 'users');
    const usersSnap = await getDocs(usersRef);
    
    const users: User[] = [];
    usersSnap.forEach((doc) => {
      users.push(doc.data() as User);
    });
    
    return users;
  } catch (error) {
    console.error('Error getting all users:', error);
    throw error;
  }
};

/**
 * Get users by role
 */
export const getUsersByRole = async (role: string): Promise<User[]> => {
  try {
    const usersRef = collection(db, 'users');
    const q = query(usersRef, where('role', '==', role), where('isActive', '==', true));
    const usersSnap = await getDocs(q);
    
    const users: User[] = [];
    usersSnap.forEach((doc) => {
      users.push(doc.data() as User);
    });
    
    return users;
  } catch (error) {
    console.error(`Error getting users with role ${role}:`, error);
    throw error;
  }
};

/**
 * Get all staff members (users who can handle appointments)
 */
export const getAllStaffMembers = async (): Promise<User[]> => {
  try {
    console.log('Getting all staff members from database...');
    const usersRef = collection(db, 'users');
    
    console.log('Creating query for staff roles (admin, manager, agent)');
    const q = query(
      usersRef, 
      where('role', 'in', ['admin', 'manager', 'agent']), 
      where('isActive', '==', true)
    );
    
    console.log('Executing query to get staff members');
    const usersSnap = await getDocs(q);
    
    console.log(`Query returned ${usersSnap.size} staff members`);
    
    const users: User[] = [];
    usersSnap.forEach((doc) => {
      const userData = doc.data() as User;
      console.log(`Processing staff member: ${userData.displayName} (${userData.role})`);
      users.push(userData);
    });
    
    console.log(`Returning ${users.length} staff members`);
    return users;
  } catch (error) {
    console.error('Error getting staff members:', error);
    if (error instanceof Error) {
      console.error('Error details:', {
        message: error.message,
        stack: error.stack
      });
    }
    throw error;
  }
};

/**
 * Create or update a user
 */
export const saveUser = async (user: User): Promise<void> => {
  try {
    console.log(`Attempting to save user: ${user.displayName} (${user.uid}) with role: ${user.role}`);
    const userRef = doc(db, 'users', user.uid);
    
    // Check if user already exists
    const userDoc = await getDoc(userRef);
    if (userDoc.exists()) {
      console.log(`User ${user.displayName} already exists, updating...`);
    } else {
      console.log(`Creating new user: ${user.displayName}`);
    }
    
    await setDoc(userRef, user, { merge: true });
    console.log(`Successfully saved user: ${user.displayName}`);
  } catch (error) {
    console.error('Error saving user:', error);
    throw error;
  }
};

/**
 * Update a user's role
 */
export const updateUserRole = async (uid: string, role: string): Promise<void> => {
  try {
    const userRef = doc(db, 'users', uid);
    await updateDoc(userRef, { role });
  } catch (error) {
    console.error('Error updating user role:', error);
    throw error;
  }
};

/**
 * Update user status
 */
export const updateUserStatus = async (uid: string, status: 'active' | 'inactive' | 'suspended' | 'pending', updatedBy: string): Promise<void> => {
  try {
    const userRef = doc(db, 'users', uid);
    await updateDoc(userRef, { 
      status, 
      lastUpdated: new Date(),
      lastUpdatedBy: updatedBy
    });
  } catch (error) {
    console.error('Error updating user status:', error);
    throw error;
  }
};

/**
 * Update user department
 */
export const updateUserDepartment = async (uid: string, department: string, updatedBy: string): Promise<void> => {
  try {
    const userRef = doc(db, 'users', uid);
    await updateDoc(userRef, { 
      department,
      lastUpdated: new Date(),
      lastUpdatedBy: updatedBy
    });
  } catch (error) {
    console.error('Error updating user department:', error);
    throw error;
  }
};

/**
 * Add custom permissions to user
 */
export const updateUserPermissions = async (userId: string, permissions: string[], updatedBy: string): Promise<void> => {
  try {
    console.log('Updating permissions for user:', userId, {
      newPermissions: permissions
    });
    
    const userRef = doc(db, 'users', userId);
    
    // Get current user data
    const userDoc = await getDoc(userRef);
    if (!userDoc.exists()) {
      throw new Error('User not found');
    }
    
    const userData = userDoc.data() as User;
    console.log('Current user data:', {
      role: userData.role,
      currentPermissions: userData.permissions || []
    });
    
    // Update the user document with new permissions
    await updateDoc(userRef, {
      permissions,
      lastUpdated: new Date(),
      lastUpdatedBy: updatedBy
    });
    
    console.log('Successfully updated permissions');
  } catch (error) {
    console.error('Error updating user permissions:', error);
    throw error;
  }
};

/**
 * Add notes to user
 */
export const updateUserNotes = async (uid: string, notes: string, updatedBy: string): Promise<void> => {
  try {
    const userRef = doc(db, 'users', uid);
    await updateDoc(userRef, { 
      notes,
      lastUpdated: new Date(),
      lastUpdatedBy: updatedBy
    });
  } catch (error) {
    console.error('Error updating user notes:', error);
    throw error;
  }
};

/**
 * Get users by department
 */
export const getUsersByDepartment = async (department: string): Promise<User[]> => {
  try {
    const usersRef = collection(db, 'users');
    const q = query(usersRef, where('department', '==', department));
    const usersSnap = await getDocs(q);
    
    const users: User[] = [];
    usersSnap.forEach((doc) => {
      users.push(doc.data() as User);
    });
    
    return users;
  } catch (error) {
    console.error('Error getting users by department:', error);
    throw error;
  }
};

/**
 * Check if user has specific permissions
 */
export const hasPermission = (user: User | null, permission: string): boolean => {
  if (!user) {
    console.log('No user provided to hasPermission');
    return false;
  }
  
  // Debug log with more detailed information
  console.log('Checking permission:', {
    permission,
    userRole: user.role,
    userPermissions: user.permissions || []
  });

  // Admin has all permissions
  if (user.role === 'admin') {
    console.log('User is admin, granting permission');
    return true;
  }

  // Check role-based permissions first
  const rolePermissions = ROLE_PERMISSIONS[user.role] || [];
  if (rolePermissions.includes(permission)) {
    console.log(`Permission ${permission} granted by role ${user.role}`);
    return true;
  }

  // Check user's custom permissions
  const userPermissions = Array.isArray(user.permissions) ? user.permissions : [];
  if (userPermissions.includes(permission)) {
    console.log(`Permission ${permission} granted by custom permissions`);
    return true;
  }
  
  console.log(`Permission ${permission} denied - not found in role ${user.role} or custom permissions`);
  return false;
};

/**
 * Create authentication users
 */
const createAuthUsers = async (users: { email: string; password: string }[]): Promise<void> => {
  const auth = getAuth();
  
  for (const user of users) {
    try {
      await createUserWithEmailAndPassword(auth, user.email, user.password);
      console.log(`Successfully created auth user: ${user.email}`);
    } catch (error) {
      if (error && typeof error === 'object' && 'code' in error && error.code === 'auth/email-already-in-use') {
        console.log(`User ${user.email} already exists`);
      } else {
        console.error(`Error creating user ${user.email}:`, error);
      }
    }
  }
};

/**
 * Ensure Firestore record exists for authenticated user
 */
export const ensureFirestoreRecord = async (uid: string, email: string): Promise<void> => {
  try {
    const userRef = doc(db, 'users', uid);
    const userDoc = await getDoc(userRef);
    
    if (!userDoc.exists()) {
      const defaultRole: UserRole = 'agent';
      // Get the default permissions for the role
      const rolePermissions = ROLE_PERMISSIONS[defaultRole] || [];
      
      console.log(`Creating new user record for ${email}:`, {
        role: defaultRole,
        rolePermissions
      });
      
      const userData: User = {
        uid,
        email,
        displayName: email.split('@')[0],
        role: defaultRole,
        isActive: true,
        createdAt: new Date(),
        status: 'active',
        department: 'Customer Service',
        permissions: rolePermissions // Initialize with role-based permissions
      };
      
      await setDoc(userRef, userData);
      console.log(`Created Firestore record for user: ${email} with role: ${defaultRole} and permissions:`, rolePermissions);
    }
  } catch (error) {
    console.error('Error ensuring Firestore record:', error);
    throw error;
  }
};

/**
 * Create Firestore record for an existing auth user
 */
export const createFirestoreUserRecord = async (uid: string, email: string, role: UserRole = 'bank_agent'): Promise<void> => {
  try {
    const userRef = doc(db, 'users', uid);
    // Get the default permissions for the role
    const defaultPermissions = ROLE_PERMISSIONS[role] || [];
    
    const userData: User = {
      uid,
      email,
      displayName: email.split('@')[0],
      role,
      isActive: true,
      createdAt: new Date(),
      status: 'active',
      department: 'General',
      permissions: defaultPermissions // Initialize with role-based permissions
    };
    await setDoc(userRef, userData);
  } catch (error) {
    console.error('Error creating Firestore user record:', error);
    throw error;
  }
};

export const createDefaultUser = async (email: string, role: UserRole): Promise<void> => {
  try {
    const userCredential = await createUserWithEmailAndPassword(auth, email, 'defaultPassword123');
    const { uid } = userCredential.user;
    await createFirestoreUserRecord(uid, email, role);
  } catch (error) {
    console.error('Error creating default user:', error);
    throw error;
  }
};

export const initializeDefaultUsers = async (): Promise<void> => {
  try {
    const defaultUsers = [
      { email: 'admin@huduma.com', role: 'admin' as UserRole },
      { email: 'manager@huduma.com', role: 'manager' as UserRole },
      { email: 'agent@huduma.com', role: 'agent' as UserRole },
      { email: 'bank@huduma.com', role: 'bank_agent' as UserRole }
    ];

    for (const user of defaultUsers) {
      const userDoc = await getDoc(doc(db, 'users', user.email));
      if (!userDoc.exists()) {
        await createDefaultUser(user.email, user.role);
      }
    }
  } catch (error) {
    console.error('Error initializing default users:', error);
    throw error;
  }
};

export const updateUser = async (userId: string, userData: Partial<User>): Promise<void> => {
  try {
    const userRef = doc(db, 'users', userId);
    await updateDoc(userRef, {
      ...userData,
      updatedAt: serverTimestamp()
    });
  } catch (error) {
    console.error('Error updating user:', error);
    throw error;
  }
}; 