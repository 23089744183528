import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import LoginModal from './LoginModal'; // Import the modal component
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../firebase/config';

interface HeaderProps {
  className?: string;
}

const Header: React.FC<HeaderProps> = ({ className = '' }) => {
  const { currentUser, signOut } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false); // State for login modal
  const [userInfo, setUserInfo] = useState<any>(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  
  // Fetch user info when currentUser changes
  useEffect(() => {
    const fetchUserInfo = async () => {
      if (!currentUser) return;
      
      try {
        const userDoc = await getDoc(doc(db, 'users', currentUser.uid));
        if (userDoc.exists()) {
          setUserInfo(userDoc.data());
        }
      } catch (error) {
        console.error('Error fetching user info:', error);
      }
    };
    
    fetchUserInfo();
  }, [currentUser]);
  
  const handleSignOut = async () => {
    try {
      await signOut();
      navigate('/');
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };
  
  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };
  
  // Function to check if a path is active
  const isActive = (path: string) => {
    return location.pathname === path;
  };
  
  // Function to open login modal
  const openLoginModal = () => {
    setIsLoginModalOpen(true);
  };
  
  // Function to close login modal
  const closeLoginModal = () => {
    setIsLoginModalOpen(false);
  };
  
  return (
    <>
      <header className={`bg-white shadow-sm sticky top-0 z-50 ${className}`}>
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between h-16">
            {/* Logo - Always shown and positioned to the left */}
            <div className="flex-shrink-0 flex items-center">
              <Link to="/" className="text-blue-900 font-bold text-xl">
                Huduma Center USA
              </Link>
            </div>
            
            {/* Desktop Navigation - Only shown when not logged in */}
            {!currentUser && (
              <nav className="hidden sm:ml-6 sm:flex sm:space-x-10 sm:items-center">
                {/* Enhanced menu items with more prominent styling and top margin */}
                <Link 
                  to="/services" 
                  className={`px-3 py-2 mt-1 text-base font-medium rounded-md transition-all duration-200 hover:bg-blue-50 hover:text-blue-800 group ${
                    isActive('/services') 
                      ? 'text-blue-800 font-semibold' 
                      : 'text-gray-700'
                  }`}
                >
                  <span className="relative">
                    Services
                    <span className={`absolute bottom-0 left-0 h-0.5 bg-blue-600 transform transition-all duration-200 
                      ${isActive('/services') ? 'w-full' : 'w-0 group-hover:w-full'}`}></span>
                  </span>
                </Link>
                
                <Link 
                  to="/resources" 
                  className={`px-3 py-2 mt-1 text-base font-medium rounded-md transition-all duration-200 hover:bg-blue-50 hover:text-blue-800 group ${
                    isActive('/resources') 
                      ? 'text-blue-800 font-semibold' 
                      : 'text-gray-700'
                  }`}
                >
                  <span className="relative">
                    Resources
                    <span className={`absolute bottom-0 left-0 h-0.5 bg-blue-600 transform transition-all duration-200 
                      ${isActive('/resources') ? 'w-full' : 'w-0 group-hover:w-full'}`}></span>
                  </span>
                </Link>
                
                <Link 
                  to="/about-us" 
                  className={`px-3 py-2 mt-1 text-base font-medium rounded-md transition-all duration-200 hover:bg-blue-50 hover:text-blue-800 group ${
                    isActive('/about-us') 
                      ? 'text-blue-800 font-semibold' 
                      : 'text-gray-700'
                  }`}
                >
                  <span className="relative">
                    About Us
                    <span className={`absolute bottom-0 left-0 h-0.5 bg-blue-600 transform transition-all duration-200 
                      ${isActive('/about-us') ? 'w-full' : 'w-0 group-hover:w-full'}`}></span>
                  </span>
                </Link>
                
                <Link 
                  to="/contact" 
                  className={`px-3 py-2 mt-1 text-base font-medium rounded-md transition-all duration-200 hover:bg-blue-50 hover:text-blue-800 group ${
                    isActive('/contact') 
                      ? 'text-blue-800 font-semibold' 
                      : 'text-gray-700'
                  }`}
                >
                  <span className="relative">
                    Contact Us
                    <span className={`absolute bottom-0 left-0 h-0.5 bg-blue-600 transform transition-all duration-200 
                      ${isActive('/contact') ? 'w-full' : 'w-0 group-hover:w-full'}`}></span>
                  </span>
                </Link>
              </nav>
            )}
            
            {/* User actions - right side */}
            <div className="hidden sm:ml-6 sm:flex sm:items-center">
              {currentUser ? (
                <div className="flex items-center space-x-4">
                  {userInfo && (
                    <div className="bg-blue-50 px-3 py-1 rounded-lg">
                      <p className="text-sm text-blue-800">
                        Welcome, <span className="font-semibold">{userInfo.displayName}</span>
                        {userInfo.role && (
                          <span className="ml-2 px-2 py-0.5 text-xs rounded-full bg-blue-100">
                            {userInfo.role.charAt(0).toUpperCase() + userInfo.role.slice(1)}
                          </span>
                        )}
                      </p>
                    </div>
                  )}
                  <div className="relative">
                    <button
                      onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                      className="flex items-center space-x-2 bg-white text-gray-800 hover:bg-gray-50 px-3 py-2 rounded-md border border-gray-200 focus:outline-none focus:ring-2 focus:ring-blue-500"
                    >
                      <span>Account</span>
                      <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                      </svg>
                    </button>
                    
                    {isDropdownOpen && (
                      <div className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg py-1 z-10 border border-gray-200">
                        <button
                          onClick={() => {
                            setIsDropdownOpen(false);
                            handleSignOut();
                          }}
                          className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                        >
                          Sign Out
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              ) : (
                <button 
                  onClick={openLoginModal}
                  className="bg-blue-900 px-4 py-2 text-white text-sm font-medium rounded-md hover:bg-blue-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                >
                  LOGIN
                </button>
              )}
            </div>
            
            {/* Mobile menu button */}
            <div className="flex items-center sm:hidden">
              <button
                onClick={toggleMobileMenu}
                className="inline-flex items-center justify-center p-2 rounded-md text-gray-700 hover:text-gray-900 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-500"
              >
                <span className="sr-only">Open main menu</span>
                {isMobileMenuOpen ? (
                  <svg className="block h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                  </svg>
                ) : (
                  <svg className="block h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
                  </svg>
                )}
              </button>
            </div>
          </div>
        </div>
        
        {/* Mobile menu, show/hide based on menu state */}
        {isMobileMenuOpen && (
          <div className="sm:hidden">
            {/* Show navigation links only when not logged in */}
            {!currentUser ? (
              <div className="pt-2 pb-3 space-y-1">
                <Link 
                  to="/services" 
                  className={`block pl-3 pr-4 py-3 text-base font-medium border-l-4 transition-all duration-200 ${
                    isActive('/services')
                      ? 'bg-blue-50 border-blue-600 text-blue-800 font-semibold'
                      : 'border-transparent text-gray-700 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-900'
                  }`}
                >
                  Services
                </Link>
                <Link 
                  to="/resources" 
                  className={`block pl-3 pr-4 py-3 text-base font-medium border-l-4 transition-all duration-200 ${
                    isActive('/resources')
                      ? 'bg-blue-50 border-blue-600 text-blue-800 font-semibold'
                      : 'border-transparent text-gray-700 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-900'
                  }`}
                >
                  Resources
                </Link>
                <Link 
                  to="/about-us" 
                  className={`block pl-3 pr-4 py-3 text-base font-medium border-l-4 transition-all duration-200 ${
                    isActive('/about-us')
                      ? 'bg-blue-50 border-blue-600 text-blue-800 font-semibold'
                      : 'border-transparent text-gray-700 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-900'
                  }`}
                >
                  About Us
                </Link>
                <Link 
                  to="/contact" 
                  className={`block pl-3 pr-4 py-3 text-base font-medium border-l-4 transition-all duration-200 ${
                    isActive('/contact')
                      ? 'bg-blue-50 border-blue-600 text-blue-800 font-semibold'
                      : 'border-transparent text-gray-700 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-900'
                  }`}
                >
                  Contact Us
                </Link>
              </div>
            ) : (
              <div className="pt-2 pb-3 space-y-1">
                {/* For logged in users, show nothing in the mobile menu */}
              </div>
            )}
            
            <div className="pt-4 pb-3 border-t border-gray-200">
              {currentUser ? (
                <div className="space-y-2">
                  {userInfo && (
                    <div className="px-4">
                      <div className="bg-blue-50 px-3 py-1 rounded-lg">
                        <p className="text-sm text-blue-800">
                          Welcome, <span className="font-semibold">{userInfo.displayName}</span>
                          {userInfo.role && (
                            <span className="ml-2 px-2 py-0.5 text-xs rounded-full bg-blue-100">
                              {userInfo.role.charAt(0).toUpperCase() + userInfo.role.slice(1)}
                            </span>
                          )}
                        </p>
                      </div>
                    </div>
                  )}
                  <div className="px-4">
                    <button
                      onClick={handleSignOut}
                      className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-900 hover:bg-blue-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                    >
                      Sign Out
                    </button>
                  </div>
                </div>
              ) : (
                <div className="px-4">
                  <button 
                    onClick={openLoginModal}
                    className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-900 hover:bg-blue-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                  >
                    LOGIN
                  </button>
                </div>
              )}
            </div>
          </div>
        )}
      </header>
      
      {/* Login Modal */}
      <LoginModal isOpen={isLoginModalOpen} onClose={closeLoginModal} />
    </>
  );
};

export default Header; 