import React, { useEffect, useState } from 'react';
import { db } from '../firebase/config';
import { collection, getDocs, addDoc, serverTimestamp, Timestamp } from 'firebase/firestore';

const InitializeDatabase: React.FC = () => {
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    // Only initialize once
    if (initialized) return;

    const initializeCollections = async () => {
      try {
        // Initialize staff collection
        await initializeStaffCollection();
        
        // Initialize visits collection
        await initializeVisitsCollection();
        
        console.log('Database initialization completed');
        setInitialized(true);
      } catch (error) {
        console.error('Error initializing database:', error);
      }
    };

    initializeCollections();
  }, [initialized]);

  // Initialize staff collection
  const initializeStaffCollection = async () => {
    const staffCollectionRef = collection(db, 'staff');
    const staffSnapshot = await getDocs(staffCollectionRef);
    
    // Only add seed data if the collection is empty
    if (staffSnapshot.empty) {
      console.log('Initializing staff collection with seed data');
      
      const staffData = [
        {
          name: 'Mike Johnson',
          role: 'Agent',
          available: true,
          email: 'mike.johnson@hudumacenter.com'
        },
        {
          name: 'Sarah Davis',
          role: 'Agent',
          available: true,
          email: 'sarah.davis@hudumacenter.com'
        },
        {
          name: 'David Lee',
          role: 'Agent',
          available: true,
          email: 'david.lee@hudumacenter.com'
        },
        {
          name: 'Emma Wilson',
          role: 'Agent',
          available: false,
          email: 'emma.wilson@hudumacenter.com'
        }
      ];
      
      // Add staff members
      for (const staff of staffData) {
        await addDoc(staffCollectionRef, staff);
      }
      
      console.log('Staff seed data added successfully');
    }
  };
  
  // Initialize visits collection
  const initializeVisitsCollection = async () => {
    const visitsCollectionRef = collection(db, 'visits');
    const visitsSnapshot = await getDocs(visitsCollectionRef);
    
    // Only add seed data if the collection is empty
    if (visitsSnapshot.empty) {
      console.log('Initializing visits collection with seed data');
      
      // Create timestamps for different times
      const now = new Date();
      const fiveMinutesAgo = new Date(now.getTime() - 5 * 60 * 1000);
      const twelveMinutesAgo = new Date(now.getTime() - 12 * 60 * 1000);
      const twentyMinutesAgo = new Date(now.getTime() - 20 * 60 * 1000);
      const twoHoursAgo = new Date(now.getTime() - 2 * 60 * 60 * 1000);
      const threeHoursAgo = new Date(now.getTime() - 3 * 60 * 60 * 1000);
      
      const visitsData = [
        {
          visitorName: 'John Doe',
          phoneNumber: '555-1234',
          service: 'ID Renewal',
          status: 'waiting',
          assignedStaff: null,
          checkInTime: Timestamp.fromDate(fiveMinutesAgo),
          waitingTime: 5
        },
        {
          visitorName: 'Jane Smith',
          phoneNumber: '555-5678',
          service: 'Business Permit',
          status: 'being-served',
          assignedStaff: 'Mike Johnson',
          checkInTime: Timestamp.fromDate(twelveMinutesAgo),
          waitingTime: 12
        },
        {
          visitorName: 'Michael Lee',
          phoneNumber: '555-9012',
          service: 'ID Card Application',
          status: 'on-hold',
          assignedStaff: 'Sarah Davis',
          checkInTime: Timestamp.fromDate(twentyMinutesAgo),
          waitingTime: 20
        },
        {
          visitorName: 'Robert Johnson',
          phoneNumber: '555-3456',
          service: 'Passport Application',
          status: 'completed',
          assignedStaff: 'David Lee',
          checkInTime: Timestamp.fromDate(twoHoursAgo),
          waitingTime: 25
        },
        {
          visitorName: 'Lisa Williams',
          phoneNumber: '555-7890',
          service: 'Business Registration',
          status: 'completed',
          assignedStaff: 'Emma Wilson',
          checkInTime: Timestamp.fromDate(threeHoursAgo),
          waitingTime: 30
        }
      ];
      
      // Add visits
      for (const visit of visitsData) {
        await addDoc(visitsCollectionRef, visit);
      }
      
      console.log('Visits seed data added successfully');
    }
  };

  return null; // This component doesn't render anything
};

export default InitializeDatabase; 