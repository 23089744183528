import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, useSearchParams } from 'react-router-dom';
import { doc, getDoc, updateDoc, Timestamp, collection, query, where, getDocs, orderBy } from 'firebase/firestore';
import { db } from '../firebase/config';
import { verifyAppointmentToken } from '../services/appointmentTokenService';
import Card from '../components/ui/Card';
import Button from '../components/ui/Button';
import FormSelect from '../components/ui/FormSelect';

interface Appointment {
  id: string;
  fullName: string;
  email: string;
  phoneNumber: string;
  service: string;
  appointmentDate: string;
  appointmentTime: string;
  additionalNotes?: string;
  status: 'scheduled' | 'completed' | 'cancelled' | 'no-show';
  createdAt: Timestamp;
  dateTime: Timestamp;
}

interface TimeSlot {
  value: string;
  label: string;
  available: boolean;
}

const PublicAppointmentPage: React.FC = () => {
  const { appointmentId } = useParams<{ appointmentId: string }>();
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const navigate = useNavigate();
  
  const [appointment, setAppointment] = useState<Appointment | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [isVerified, setIsVerified] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [message, setMessage] = useState<{ type: 'success' | 'error'; text: string } | null>(null);
  
  // Reschedule state
  const [isRescheduling, setIsRescheduling] = useState(false);
  const [newDate, setNewDate] = useState<string>('');
  const [newTime, setNewTime] = useState<string>('');
  const [availableDates, setAvailableDates] = useState<string[]>([]);
  const [timeSlots, setTimeSlots] = useState<TimeSlot[]>([]);
  const [loadingTimeSlots, setLoadingTimeSlots] = useState(false);
  
  // Define available time slots
  const allTimeSlots: TimeSlot[] = [
    { value: '9:00 AM', label: '9:00 AM', available: true },
    { value: '9:30 AM', label: '9:30 AM', available: true },
    { value: '10:00 AM', label: '10:00 AM', available: true },
    { value: '10:30 AM', label: '10:30 AM', available: true },
    { value: '11:00 AM', label: '11:00 AM', available: true },
    { value: '11:30 AM', label: '11:30 AM', available: true },
    { value: '12:00 PM', label: '12:00 PM', available: true },
    { value: '12:30 PM', label: '12:30 PM', available: true },
    { value: '1:00 PM', label: '1:00 PM', available: true },
    { value: '1:30 PM', label: '1:30 PM', available: true },
    { value: '2:00 PM', label: '2:00 PM', available: true },
    { value: '2:30 PM', label: '2:30 PM', available: true },
    { value: '3:00 PM', label: '3:00 PM', available: true },
    { value: '3:30 PM', label: '3:30 PM', available: true },
    { value: '4:00 PM', label: '4:00 PM', available: true },
    { value: '4:30 PM', label: '4:30 PM', available: true }
  ];

  // Verify token and fetch appointment data
  useEffect(() => {
    const verifyAndFetchAppointment = async () => {
      if (!appointmentId || !token) {
        setError('Missing appointment ID or verification token');
        setLoading(false);
        return;
      }
      
      try {
        // Verify the token
        const verifiedAppointmentId = await verifyAppointmentToken(token);
        
        if (!verifiedAppointmentId || verifiedAppointmentId !== appointmentId) {
          setError('Invalid verification token');
          setLoading(false);
          return;
        }
        
        setIsVerified(true);
        
        // Fetch appointment data
        const appointmentRef = doc(db, 'appointments', appointmentId);
        const appointmentDoc = await getDoc(appointmentRef);
        
        if (!appointmentDoc.exists()) {
          setError('Appointment not found');
          setLoading(false);
          return;
        }
        
        const appointmentData = appointmentDoc.data();
        
        // Convert from Firestore
        setAppointment({
          id: appointmentDoc.id,
          fullName: appointmentData.fullName,
          email: appointmentData.email,
          phoneNumber: appointmentData.phoneNumber,
          service: appointmentData.service,
          appointmentDate: appointmentData.appointmentDate,
          appointmentTime: appointmentData.appointmentTime,
          additionalNotes: appointmentData.additionalNotes,
          status: appointmentData.status,
          createdAt: appointmentData.createdAt,
          dateTime: appointmentData.dateTime
        });
        
        // Generate available dates (next 14 days)
        const dates = [];
        const today = new Date();
        
        for (let i = 1; i <= 14; i++) {
          const date = new Date();
          date.setDate(today.getDate() + i);
          
          // Skip weekends
          if (date.getDay() === 0 || date.getDay() === 6) {
            continue;
          }
          
          const formattedDate = date.toISOString().split('T')[0];
          dates.push(formattedDate);
        }
        
        setAvailableDates(dates);
      } catch (err) {
        console.error('Error verifying token or fetching appointment:', err);
        setError('Failed to verify your access or load appointment details');
      } finally {
        setLoading(false);
      }
    };
    
    verifyAndFetchAppointment();
  }, [appointmentId, token]);
  
  // Fetch available time slots when date changes
  useEffect(() => {
    async function fetchAvailableTimeSlots() {
      if (!newDate) return;
      
      setLoadingTimeSlots(true);
      try {
        // Get all appointments for the selected date
        const appointmentsRef = collection(db, 'appointments');
        const q = query(
          appointmentsRef,
          where('appointmentDate', '==', newDate),
          where('status', '==', 'scheduled'),
          orderBy('appointmentTime')
        );
        
        const querySnapshot = await getDocs(q);
        
        // Create a map of booked time slots
        const bookedTimeSlots = new Set<string>();
        querySnapshot.forEach((doc) => {
          // Skip the current appointment
          if (doc.id === appointmentId) return;
          
          const appointmentData = doc.data();
          bookedTimeSlots.add(appointmentData.appointmentTime);
        });
        
        // Update available time slots
        const updatedTimeSlots = allTimeSlots.map(slot => ({
          ...slot,
          available: !bookedTimeSlots.has(slot.value)
        }));
        
        setTimeSlots(updatedTimeSlots);
      } catch (error) {
        console.error('Error fetching available time slots:', error);
      } finally {
        setLoadingTimeSlots(false);
      }
    }
    
    fetchAvailableTimeSlots();
  }, [newDate, appointmentId]);
  
  // Handle rescheduling
  const handleReschedule = async () => {
    if (!isVerified || !appointment) {
      setError('Verification failed. Please try again.');
      return;
    }
    
    if (!newDate || !newTime) {
      setError('Please select both a new date and time.');
      return;
    }
    
    setIsUpdating(true);
    try {
      // Parse time and create new date/time Timestamp
      const timeRegex = /^(\d+):(\d+)\s+(AM|PM)$/i;
      const match = newTime.match(timeRegex);
      
      if (!match) {
        throw new Error('Invalid time format');
      }
      
      let [_, hours, minutes, period] = match;
      let hour = parseInt(hours, 10);
      
      // Convert 12-hour to 24-hour
      if (period.toUpperCase() === 'PM' && hour < 12) {
        hour += 12;
      } else if (period.toUpperCase() === 'AM' && hour === 12) {
        hour = 0;
      }
      
      // Create date object from new date and set the time
      const appointmentDate = new Date(newDate);
      appointmentDate.setHours(hour, parseInt(minutes, 10), 0, 0);
      
      // Update appointment in Firestore
      const appointmentRef = doc(db, 'appointments', appointment.id);
      await updateDoc(appointmentRef, {
        appointmentDate: newDate,
        appointmentTime: newTime,
        dateTime: Timestamp.fromDate(appointmentDate),
        updatedAt: Timestamp.now(),
        lastModifiedBy: 'customer',
        lastModifiedReason: 'customer_reschedule'
      });
      
      // Update local state
      setAppointment({
        ...appointment,
        appointmentDate: newDate,
        appointmentTime: newTime,
        dateTime: Timestamp.fromDate(appointmentDate)
      });
      
      setMessage({
        type: 'success',
        text: 'Your appointment has been rescheduled successfully.'
      });
      
      // Reset rescheduling state
      setIsRescheduling(false);
      setNewDate('');
      setNewTime('');
    } catch (err) {
      console.error('Error rescheduling appointment:', err);
      setMessage({
        type: 'error',
        text: 'Failed to reschedule your appointment. Please try again.'
      });
    } finally {
      setIsUpdating(false);
    }
  };
  
  // Handle cancellation
  const handleCancel = async () => {
    if (!isVerified || !appointment) {
      setError('Verification failed. Please try again.');
      return;
    }
    
    if (!window.confirm('Are you sure you want to cancel this appointment? This action cannot be undone.')) {
      return;
    }
    
    setIsUpdating(true);
    try {
      // Update appointment in Firestore
      const appointmentRef = doc(db, 'appointments', appointment.id);
      await updateDoc(appointmentRef, {
        status: 'cancelled',
        updatedAt: Timestamp.now(),
        lastModifiedBy: 'customer',
        lastModifiedReason: 'customer_cancel'
      });
      
      // Update local state
      setAppointment({
        ...appointment,
        status: 'cancelled'
      });
      
      setMessage({
        type: 'success',
        text: 'Your appointment has been cancelled successfully.'
      });
    } catch (err) {
      console.error('Error cancelling appointment:', err);
      setMessage({
        type: 'error',
        text: 'Failed to cancel your appointment. Please try again.'
      });
    } finally {
      setIsUpdating(false);
    }
  };
  
  // Format date for display
  const formatDate = (dateString: string) => {
    if (!dateString) return 'N/A';
    
    try {
      // Create date object without timezone conversion
      // Parse the date parts explicitly to avoid timezone issues
      const [year, month, day] = dateString.split('-').map(part => parseInt(part, 10));
      
      // Month is 0-indexed in JavaScript Date object
      const date = new Date(year, month - 1, day);
      
      return new Intl.DateTimeFormat('en-US', {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        timeZone: 'UTC' // Use UTC to avoid timezone offset issues
      }).format(date);
    } catch (error) {
      console.error('Error formatting date:', error, dateString);
      return dateString; // Return original string if parsing fails
    }
  };
  
  // Display loading state
  if (loading) {
    return (
      <div className="max-w-4xl mx-auto px-4 py-8">
        <Card>
          <div className="p-6 text-center">
            <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-900 mx-auto"></div>
            <p className="mt-2 text-gray-600">Loading appointment details...</p>
          </div>
        </Card>
      </div>
    );
  }
  
  // Display error state
  if (error || !appointment) {
    return (
      <div className="max-w-4xl mx-auto px-4 py-8">
        <Card>
          <div className="p-6 text-center">
            <svg xmlns="http://www.w3.org/2000/svg" className="mx-auto h-12 w-12 text-red-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
            <h2 className="mt-2 text-xl font-semibold text-gray-900">Verification Failed</h2>
            <p className="mt-1 text-gray-600">{error || 'Unable to access appointment details.'}</p>
            <p className="mt-4 text-sm text-gray-500">
              If you believe this is an error, please contact our support team at{' '}
              <a href="mailto:booking@hudumacenter.com" className="text-blue-600 hover:text-blue-800">
                booking@hudumacenter.com
              </a>
            </p>
          </div>
        </Card>
      </div>
    );
  }
  
  return (
    <div className="max-w-4xl mx-auto px-4 py-8">
      <div className="mb-6">
        <h1 className="text-3xl font-bold text-gray-900 mb-2">Your Appointment</h1>
        <p className="text-gray-600">
          View, reschedule, or cancel your appointment below.
        </p>
      </div>
      
      {message && (
        <div className={`mb-6 p-4 rounded-md ${message.type === 'success' ? 'bg-green-50 text-green-800' : 'bg-red-50 text-red-800'}`}>
          <p>{message.text}</p>
        </div>
      )}
      
      <Card>
        <div className="p-6">
          <div className={`inline-flex items-center px-3 py-1 rounded-full text-sm font-medium ${
            appointment.status === 'scheduled' ? 'bg-blue-100 text-blue-800' :
            appointment.status === 'completed' ? 'bg-green-100 text-green-800' :
            appointment.status === 'cancelled' ? 'bg-gray-100 text-gray-800' :
            'bg-red-100 text-red-800'
          } mb-4`}>
            {appointment.status === 'scheduled' ? 'Confirmed' :
             appointment.status === 'completed' ? 'Completed' :
             appointment.status === 'cancelled' ? 'Cancelled' :
             'No Show'}
          </div>
          
          <h2 className="text-2xl font-semibold text-gray-900 mb-6">Appointment Details</h2>
          
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
            <div>
              <h3 className="text-lg font-medium text-gray-900 mb-2">Service</h3>
              <p className="text-gray-700">{appointment.service}</p>
            </div>
            
            <div>
              <h3 className="text-lg font-medium text-gray-900 mb-2">Contact Information</h3>
              <p className="text-gray-700">{appointment.fullName}</p>
              <p className="text-gray-700">{appointment.email}</p>
              <p className="text-gray-700">{appointment.phoneNumber}</p>
            </div>
            
            <div>
              <h3 className="text-lg font-medium text-gray-900 mb-2">Date & Time</h3>
              <p className="text-gray-700">{formatDate(appointment.appointmentDate)}</p>
              <p className="text-gray-700">{appointment.appointmentTime}</p>
            </div>
            
            {appointment.additionalNotes && (
              <div>
                <h3 className="text-lg font-medium text-gray-900 mb-2">Additional Notes</h3>
                <p className="text-gray-700">{appointment.additionalNotes}</p>
              </div>
            )}
          </div>
          
          {appointment.status === 'scheduled' && !isRescheduling && (
            <div className="flex flex-col sm:flex-row gap-3 mt-6">
              <Button 
                onClick={() => setIsRescheduling(true)}
                className="bg-blue-600 hover:bg-blue-700 text-white"
                isDisabled={isUpdating}
              >
                Reschedule Appointment
              </Button>
              <Button 
                onClick={handleCancel}
                className="bg-red-600 hover:bg-red-700 text-white"
                isDisabled={isUpdating}
              >
                Cancel Appointment
              </Button>
            </div>
          )}
          
          {isRescheduling && (
            <div className="mt-6 border-t border-gray-200 pt-6">
              <h3 className="text-lg font-medium text-gray-900 mb-4">Reschedule Appointment</h3>
              
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
                <div>
                  <label htmlFor="newDate" className="block text-sm font-medium text-gray-700 mb-1">
                    New Date
                  </label>
                  <select
                    id="newDate"
                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                    value={newDate}
                    onChange={(e) => setNewDate(e.target.value)}
                  >
                    <option value="">Select a date</option>
                    {availableDates.map((date) => (
                      <option key={date} value={date}>
                        {formatDate(date)}
                      </option>
                    ))}
                  </select>
                </div>
                
                <div>
                  <label htmlFor="newTime" className="block text-sm font-medium text-gray-700 mb-1">
                    New Time
                  </label>
                  <select
                    id="newTime"
                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                    value={newTime}
                    onChange={(e) => setNewTime(e.target.value)}
                    disabled={!newDate || loadingTimeSlots}
                  >
                    <option value="">Select a time</option>
                    {timeSlots.map((slot) => (
                      <option 
                        key={slot.value} 
                        value={slot.value}
                        disabled={!slot.available}
                      >
                        {slot.label} {!slot.available && '(Not Available)'}
                      </option>
                    ))}
                  </select>
                  {loadingTimeSlots && <p className="text-sm text-gray-500 mt-1">Loading available times...</p>}
                </div>
              </div>
              
              <div className="flex flex-col sm:flex-row gap-3 mt-4">
                <Button 
                  onClick={handleReschedule}
                  className="bg-blue-600 hover:bg-blue-700 text-white"
                  isDisabled={isUpdating || !newDate || !newTime}
                >
                  {isUpdating ? 'Saving Changes...' : 'Confirm New Time'}
                </Button>
                <Button 
                  onClick={() => {
                    setIsRescheduling(false);
                    setNewDate('');
                    setNewTime('');
                  }}
                  className="bg-gray-100 hover:bg-gray-200 text-gray-800"
                  isDisabled={isUpdating}
                >
                  Cancel
                </Button>
              </div>
            </div>
          )}
        </div>
      </Card>
      
      <Card className="mt-6">
        <div className="p-6">
          <h2 className="text-xl font-semibold text-gray-900 mb-4">Location</h2>
          <p className="text-gray-700">
            Huduma Center USA<br />
            30821 Pacific Hwy S<br />
            Federal Way, WA 98003
          </p>
          <p className="mt-2">
            <a 
              href="https://maps.google.com/?q=30821+Pacific+Hwy+S,+Federal+Way,+WA+98003" 
              target="_blank" 
              rel="noopener noreferrer"
              className="text-blue-600 hover:text-blue-800"
            >
              View on Google Maps
            </a>
          </p>
        </div>
      </Card>
      
      <Card className="mt-6">
        <div className="p-6">
          <h2 className="text-xl font-semibold text-gray-900 mb-4">What to Bring</h2>
          <ul className="list-disc pl-5 space-y-1 text-gray-700">
            <li>A valid photo ID</li>
            <li>Your appointment confirmation (printed or on your phone)</li>
            <li>Any documents relevant to your service</li>
          </ul>
        </div>
      </Card>
      
      <div className="mt-8 text-center text-sm text-gray-500">
        <p>
          If you need assistance, please contact us at{' '}
          <a href="mailto:booking@hudumacenter.com" className="text-blue-600 hover:text-blue-800">
            booking@hudumacenter.com
          </a>{' '}
          or call (206) 460-9022.
        </p>
      </div>
    </div>
  );
};

export default PublicAppointmentPage; 