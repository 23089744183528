import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import Card from '../components/ui/Card';
import Button from '../components/ui/Button';
import FormInput from '../components/ui/FormInput';
import FormSelect from '../components/ui/FormSelect';
import FormTextArea from '../components/ui/FormTextArea';
import { collection, addDoc, getDocs, query, where, orderBy, Timestamp, serverTimestamp, doc, updateDoc } from 'firebase/firestore';
import { db } from '../firebase/config';
import { sendEmail, sendEmailSimulated, sendEmailWithRetry } from '../services/emailService';
import { generateAppointmentToken, storeAppointmentToken } from '../services/appointmentTokenService';

// DEV MODE SETTINGS - Set to false in production
const DEBUG_MODE = true;
const DEBUG_VERBOSE = true;

// Debug logger function
const debugLog = (message: string, data?: any) => {
  if (DEBUG_MODE) {
    if (data) {
      console.log(`🔍 DEBUG: ${message}`, data);
    } else {
      console.log(`🔍 DEBUG: ${message}`);
    }
  }
};

// Verbose logger for detailed operations
const verboseLog = (message: string, data?: any) => {
  if (DEBUG_MODE && DEBUG_VERBOSE) {
    if (data) {
      console.log(`🔬 VERBOSE: ${message}`, data);
    } else {
      console.log(`🔬 VERBOSE: ${message}`);
    }
  }
};

interface AppointmentFormData {
  fullName: string;
  email: string;
  phoneNumber: string;
  serviceType: string;
  preferredDate: string;
  preferredTime: string;
  additionalNotes: string;
}

interface TimeSlot {
  value: string;
  label: string;
  available: boolean;
}

type FormStep = 'personal-info' | 'service-selection' | 'date-time' | 'review';

const AppointmentBooking: React.FC = () => {
  const [currentStep, setCurrentStep] = useState<FormStep>('personal-info');
  const [formData, setFormData] = useState<AppointmentFormData>({
    fullName: '',
    email: '',
    phoneNumber: '',
    serviceType: '',
    preferredDate: '',
    preferredTime: '',
    additionalNotes: ''
  });
  
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [errors, setErrors] = useState<Record<string, string>>({});
  const [timeSlots, setTimeSlots] = useState<TimeSlot[]>([]);
  const [loadingTimeSlots, setLoadingTimeSlots] = useState(false);
  
  const serviceTypeOptions = [
    { value: 'Passport Services', label: 'Passport Services' },
    { value: 'ID Card Application/Renewal', label: 'ID Card Application/Renewal' },
    { value: 'Business Registration', label: 'Business Registration' },
    { value: 'Tax Filing', label: 'Tax Filing' },
    { value: 'License Renewal', label: 'License Renewal' },
    { value: 'Work Permit', label: 'Work Permit' },
    { value: 'HCA Examination', label: 'HCA Examination' },
    { value: 'Equity Bank Services', label: 'Equity Bank Services' },
    { value: 'General Consultation', label: 'General Consultation' },
    { value: 'Other', label: 'Other' },
    { value: 'CNA Written Exam Taking', label: 'CNA Written Exam Taking' }
  ];
  
  const allTimeSlots: TimeSlot[] = [
    { value: '9:00 AM', label: '9:00 AM', available: true },
    { value: '10:00 AM', label: '10:00 AM', available: true },
    { value: '11:00 AM', label: '11:00 AM', available: true },
    { value: '12:00 PM', label: '12:00 PM', available: true },
    { value: '1:00 PM', label: '1:00 PM', available: true },
    { value: '2:00 PM', label: '2:00 PM', available: true },
    { value: '3:00 PM', label: '3:00 PM', available: true },
    { value: '4:00 PM', label: '4:00 PM', available: true }
  ];
  
  const navigate = useNavigate();
  const location = useLocation();
  
  const [selectedService, setSelectedService] = useState('');
  
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const serviceParam = queryParams.get('service');
    
    if (serviceParam) {
      setSelectedService(serviceParam);
      setFormData(prev => ({
        ...prev,
        serviceType: serviceParam
      }));
    }
  }, [location]);
  
  // Fetch available time slots when date changes
  useEffect(() => {
    async function fetchAvailableTimeSlots() {
      if (!formData.preferredDate) return;
      
      setLoadingTimeSlots(true);
      try {
        // Get all appointments for the selected date
        const appointmentsRef = collection(db, 'appointments');
        const q = query(
          appointmentsRef,
          where('appointmentDate', '==', formData.preferredDate),
          orderBy('appointmentTime')
        );
        
        const querySnapshot = await getDocs(q);
        
        // Create a map of booked time slots
        const bookedTimeSlots = new Set<string>();
        querySnapshot.forEach((doc) => {
          const appointmentData = doc.data();
          bookedTimeSlots.add(appointmentData.appointmentTime);
        });
        
        // Update available time slots
        const updatedTimeSlots = allTimeSlots.map(slot => ({
          ...slot,
          available: !bookedTimeSlots.has(slot.value)
        }));
        
        setTimeSlots(updatedTimeSlots);
      } catch (error) {
        console.error('Error fetching available time slots:', error);
      } finally {
        setLoadingTimeSlots(false);
      }
    }
    
    fetchAvailableTimeSlots();
  }, [formData.preferredDate]);
  
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    
    // Extra validation for date field to ensure it's in the future
    if (name === 'preferredDate') {
      const selectedDate = new Date(value);
      const today = new Date();
      today.setHours(0, 0, 0, 0); // Reset time to beginning of day for fair comparison
      
      if (selectedDate < today) {
        // If selected date is in the past, don't update the form and set an error
        setErrors(prev => ({ 
          ...prev, 
          [name]: 'Please select a future date' 
        }));
        return; // Don't update form with past date
      }
      
      // If date is changing, reset the time selection
      setFormData(prev => ({ ...prev, preferredTime: '', [name]: value }));
    } else {
      setFormData(prev => ({ ...prev, [name]: value }));
    }
    
    // Clear error for this field if it exists
    if (errors[name]) {
      setErrors(prev => {
        const newErrors = { ...prev };
        delete newErrors[name];
        return newErrors;
      });
    }
  };
  
  const validateStep = (): boolean => {
    const newErrors: Record<string, string> = {};
    
    switch(currentStep) {
      case 'personal-info':
        if (!formData.fullName.trim()) newErrors.fullName = 'Name is required';
        if (!formData.phoneNumber.trim()) newErrors.phoneNumber = 'Phone number is required';
        if (!formData.email.trim()) {
          newErrors.email = 'Email is required';
        } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
          newErrors.email = 'Please enter a valid email';
        }
        break;
        
      case 'service-selection':
        if (!formData.serviceType) newErrors.serviceType = 'Please select a service type';
        break;
        
      case 'date-time':
        if (!formData.preferredDate) newErrors.preferredDate = 'Please select a date';
        if (!formData.preferredTime) newErrors.preferredTime = 'Please select a time';
        
        // Validate that the date is in the future
        const selectedDate = new Date(formData.preferredDate);
        const today = new Date();
        today.setHours(0, 0, 0, 0); // Reset time to beginning of day for fair comparison
        
        if (selectedDate < today) {
          newErrors.preferredDate = 'Please select a future date';
        }
        
        // Check if the selected time slot is still available
        if (formData.preferredTime) {
          const selectedTimeSlot = timeSlots.find(slot => slot.value === formData.preferredTime);
          if (selectedTimeSlot && !selectedTimeSlot.available) {
            newErrors.preferredTime = 'This time slot is no longer available. Please select another time.';
          }
        }
        break;
    }
    
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };
  
  const moveToNext = () => {
    if (!validateStep()) return;
    
    switch(currentStep) {
      case 'personal-info':
        setCurrentStep('service-selection');
        break;
      case 'service-selection':
        setCurrentStep('date-time');
        break;
      case 'date-time':
        setCurrentStep('review');
        break;
    }
    
    // Scroll to top when changing steps
    window.scrollTo(0, 0);
  };
  
  const moveToPrevious = () => {
    switch(currentStep) {
      case 'service-selection':
        setCurrentStep('personal-info');
        break;
      case 'date-time':
        setCurrentStep('service-selection');
        break;
      case 'review':
        setCurrentStep('date-time');
        break;
    }
    
    // Scroll to top when changing steps
    window.scrollTo(0, 0);
  };
  
  // Check if a time slot is available
  const checkTimeSlotAvailability = async (date: string, time: string): Promise<boolean> => {
    try {
      const appointmentsRef = collection(db, 'appointments');
      const q = query(
        appointmentsRef,
        where('preferredDate', '==', date),
        where('preferredTime', '==', time)
      );
      
      const querySnapshot = await getDocs(q);
      return querySnapshot.empty; // If empty, the time slot is available
    } catch (error) {
      console.error("Error checking time slot availability:", error);
      // In case of error, assume the slot is available to prevent blocking users
      return true;
    }
  };

  // Function to submit appointment with retry logic
  const submitAppointmentWithRetry = async () => {
    const maxRetries = 3;
    let retryCount = 0;
    let lastError: unknown = null;
    
    debugLog("Starting appointment submission process");
    
    // Check if we have a pending submission in local storage
    const pendingSubmission = localStorage.getItem('pendingAppointmentSubmission');
    if (pendingSubmission) {
      try {
        // If we have a pending submission, try to use that first
        const pendingData = JSON.parse(pendingSubmission);
        if (pendingData && pendingData.formData) {
          debugLog("Found pending submission in localStorage", pendingData);
          setFormData(pendingData.formData);
        }
      } catch (e) {
        console.error("Error parsing pending submission:", e);
        localStorage.removeItem('pendingAppointmentSubmission');
      }
    }
    
    while (retryCount < maxRetries) {
      try {
        debugLog(`Submission attempt ${retryCount + 1} of ${maxRetries}`);
        
        // Save current form data to localStorage before attempting submission
        localStorage.setItem('pendingAppointmentSubmission', JSON.stringify({ formData }));
        verboseLog("Saved form data to localStorage", formData);
        
        setIsSubmitting(true);
        setErrors({});
        
        // Validate all fields one last time
        const formIsValid = validateStep();
        if (!formIsValid) {
          debugLog("Form validation failed");
          setIsSubmitting(false);
          return;
        }
        
        // Check if the selected time slot is still available
        debugLog("Checking time slot availability");
        const isAvailable = await checkTimeSlotAvailability(
          formData.preferredDate,
          formData.preferredTime
        );
        
        if (!isAvailable) {
          debugLog("Time slot is no longer available");
          setErrors(prev => ({
            ...prev,
            preferredTime: "This time slot is no longer available. Please select another time."
          }));
          setIsSubmitting(false);
          return;
        }
        
        // Create the appointment data with more explicit fields
        const appointmentData = {
          ...formData,
          status: "pending",
          createdAt: serverTimestamp(),
          clientSubmissionTime: new Date().toISOString(),
          bookingSource: "web_public",
          metaData: {
            userAgent: navigator.userAgent,
            screenSize: `${window.innerWidth}x${window.innerHeight}`,
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
          }
        };
        
        debugLog("Creating appointment document", appointmentData);
        verboseLog("Full appointment data being sent", appointmentData);
        
        // Create a new appointment document
        const docRef = await addDoc(collection(db, "appointments"), appointmentData);
        
        debugLog("Appointment created successfully", { id: docRef.id });
        
        // Generate and store a verification token for this appointment
        debugLog("Generating verification token");
        const verificationToken = generateAppointmentToken(docRef.id, formData.email);
        
        try {
          debugLog("Storing verification token");
          await storeAppointmentToken(docRef.id, verificationToken);
          debugLog("Token stored successfully");
        } catch (tokenError) {
          console.error("Error storing verification token:", tokenError);
          debugLog("Token storage failed but continuing", tokenError);
          // Don't fail the entire booking process if token storage fails
        }
        
        // Mark success now - the booking is technically complete
        setSubmitSuccess(true);
        debugLog("Appointment booking marked as successful");
        
        // Store success in localStorage
        localStorage.setItem('lastSuccessfulAppointment', docRef.id);
        
        // Clear pending submission since we've succeeded
        localStorage.removeItem('pendingAppointmentSubmission');
        
        // Email sending can happen in background
        debugLog("Starting email sending process");
        Promise.all([
          // Send confirmation email to customer
          (async () => {
            try {
              debugLog("Sending confirmation email");
              await sendConfirmationEmail(docRef.id, verificationToken);
              debugLog("Confirmation email sent successfully");
            } catch (emailError) {
              console.error("Error sending confirmation email:", emailError);
              debugLog("Confirmation email failed", emailError);
            }
          })(),
          
          // Send admin notification email
          (async () => {
            try {
              debugLog("Sending admin notification");
              await sendAdminNotificationEmail(docRef.id);
              debugLog("Admin notification sent successfully");
            } catch (adminEmailError) {
              console.error("Error sending admin notification:", adminEmailError);
              debugLog("Admin notification failed", adminEmailError);
            }
          })()
        ]).catch(err => {
          console.error("Error in email sending process:", err);
          debugLog("Email sending process failed", err);
        });
        
        // Success! Exit the retry loop
        debugLog("Appointment booking process completed successfully");
        return;
      } catch (err) {
        lastError = err;
        console.error(`Attempt ${retryCount + 1} failed:`, err);
        
        // Enhanced error logging
        debugLog(`Submission attempt ${retryCount + 1} failed`, err);
        
        if (err instanceof Error) {
          verboseLog("Detailed error information", {
            name: err.name,
            message: err.message,
            stack: err.stack,
            isFirebaseError: err.name === 'FirebaseError',
            errorCode: err.name === 'FirebaseError' ? (err as any).code : 'unknown'
          });
        }
        
        retryCount++;
        
        // Wait before retrying (exponential backoff)
        if (retryCount < maxRetries) {
          const waitTime = Math.pow(2, retryCount) * 1000; // 2s, 4s, 8s...
          debugLog(`Waiting ${waitTime}ms before retry ${retryCount + 1}`);
          await new Promise(resolve => setTimeout(resolve, waitTime));
        }
      } finally {
        setIsSubmitting(false);
      }
    }
    
    // If we get here, all retries failed
    console.error(`All ${maxRetries} attempts failed. Last error:`, lastError);
    debugLog("All retry attempts failed", { maxRetries, lastError });
    
    let errorMessage = "Unknown error";
    if (lastError instanceof Error) {
      errorMessage = lastError.message;
      
      // Provide more user-friendly messages for common errors
      if (lastError.message.includes("permission") || lastError.message.includes("unauthorized")) {
        errorMessage = "We're experiencing permission issues with our system. Our team has been notified.";
      } else if (lastError.message.includes("network") || lastError.message.includes("connection")) {
        errorMessage = "Network connection issue. Please check your internet and try again.";
      } else if (lastError.message.includes("timeout")) {
        errorMessage = "The request timed out. Please try again when you have a stronger connection.";
      }
    } else if (typeof lastError === 'string') {
      errorMessage = lastError;
    } else if (lastError && typeof lastError === 'object') {
      errorMessage = JSON.stringify(lastError);
    }
    
    setErrors(prev => ({
      ...prev,
      submit: `We couldn't complete your booking after multiple attempts. Please try again later. ${errorMessage}`
    }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    await submitAppointmentWithRetry();
  };

  // Get confirmation email HTML 
  const getConfirmationEmailHtml = (appointmentId: string, verificationToken: string): string => {
    // Format date for the email
    let formattedDate = "Appointment scheduled";
    try {
      const appointmentDate = new Date(formData.preferredDate);
      formattedDate = appointmentDate.toLocaleDateString("en-US", {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
      });
    } catch (error) {
      console.error("Error formatting date for email:", error);
    }

    // Create the appointment management link
    const baseUrl = window.location.origin;
    const managementLink = `${baseUrl}/appointment/${appointmentId}?token=${verificationToken}`;

    return `
      <div style="font-family: Arial, sans-serif; max-width: 600px; margin: 0 auto; padding: 20px; border: 1px solid #e0e0e0; border-radius: 5px;">
        <div style="text-align: center; margin-bottom: 20px;">
          <h1 style="color: #1e3a8a;">Appointment Confirmation</h1>
          <p style="color: #666;">Thank you for booking an appointment with Huduma Center USA.</p>
        </div>
        
        <div style="background-color: #f5f7ff; padding: 15px; border-radius: 5px; margin-bottom: 20px;">
          <h2 style="color: #1e3a8a; margin-top: 0;">Appointment Details</h2>
          <p><strong>Service:</strong> ${formData.serviceType}</p>
          <p><strong>Date:</strong> ${formattedDate}</p>
          <p><strong>Time:</strong> ${formData.preferredTime}</p>
          <p><strong>Appointment ID:</strong> ${appointmentId}</p>
        </div>
        
        <div style="margin-bottom: 20px;">
          <h2 style="color: #1e3a8a;">Your Information</h2>
          <p><strong>Name:</strong> ${formData.fullName}</p>
          <p><strong>Email:</strong> ${formData.email}</p>
          <p><strong>Phone:</strong> ${formData.phoneNumber}</p>
        </div>
        
        <div style="text-align: center; margin-bottom: 20px;">
          <a href="${managementLink}" style="display: inline-block; background-color: #1e3a8a; color: white; padding: 12px 24px; text-decoration: none; border-radius: 4px; font-weight: bold;">
            Manage Your Appointment
          </a>
          <p style="color: #666; margin-top: 10px; font-size: 14px;">
            Click the button above to view, reschedule, or cancel your appointment.
          </p>
        </div>
        
        <div style="background-color: #f9f9f9; padding: 15px; border-radius: 5px; margin-bottom: 20px;">
          <h2 style="color: #1e3a8a; margin-top: 0;">What to Bring</h2>
          <ul style="padding-left: 20px;">
            <li>A valid photo ID</li>
            <li>Confirmation email (printed or on your phone)</li>
            <li>Any documents relevant to your service</li>
          </ul>
        </div>
        
        <div style="margin-bottom: 20px;">
          <h2 style="color: #1e3a8a;">Location</h2>
          <p>Huduma Center USA<br>30821 Pacific Hwy S<br>Federal Way, WA 98003</p>
          <p><a href="https://maps.google.com/?q=30821+Pacific+Hwy+S,+Federal+Way,+WA+98003" style="color: #1e3a8a;">View on Google Maps</a></p>
        </div>
        
        <div style="border-top: 1px solid #e0e0e0; padding-top: 20px; text-align: center; font-size: 14px; color: #666;">
          <p>If you need further assistance, please contact us at <a href="mailto:booking@hudumacenter.com" style="color: #1e3a8a;">booking@hudumacenter.com</a> or call us at (206) 460-9022.</p>
          <p>© ${new Date().getFullYear()} Huduma Center USA. All rights reserved.</p>
        </div>
      </div>
    `;
  };

  /**
   * Sends a confirmation email to the user after booking
   */
  const sendConfirmationEmail = async (appointmentId: string, verificationToken: string) => {
    if (!formData.email) {
      console.error("Cannot send confirmation email: No email address provided");
      return;
    }

    try {
      await sendEmailWithRetry({
        to: formData.email,
        from: '"Huduma Center USA" <booking@hudumacenter.com>',
        subject: "Your Huduma Center Appointment Confirmation",
        html: getConfirmationEmailHtml(appointmentId, verificationToken),
      }, 3);
      
      console.log("Confirmation email sent successfully!");
      
      // Try to update the appointment document, but don't fail if there's a permission error
      try {
        const appointmentRef = doc(db, "appointments", appointmentId);
        await updateDoc(appointmentRef, {
          clientEmailSent: true,
          clientEmailSentAt: serverTimestamp()
        });
      } catch (updateError) {
        // Log the error but continue - email was still sent successfully
        console.error("Error updating appointment with client email status:", updateError);
        console.log("Note: The email was still sent successfully despite the database update error");
      }
    } catch (error) {
      console.error("Error sending confirmation email:", error);
    }
  };
  
  /**
   * Sends a notification email to the admin about a new booking
   */
  const sendAdminNotificationEmail = async (appointmentId: string) => {
    // Format date for the email
    let formattedDate = "Appointment scheduled";
    try {
      const appointmentDate = new Date(formData.preferredDate);
      formattedDate = appointmentDate.toLocaleDateString("en-US", {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
      });
    } catch (error) {
      console.error("Error formatting date for email:", error);
    }

    // Create HTML content for admin notification
    const adminHtml = `
      <div style="font-family: Arial, sans-serif; max-width: 600px; margin: 0 auto; padding: 20px; border: 1px solid #e0e0e0; border-radius: 5px;">
        <h1 style="color: #1e3a8a;">New Appointment Notification</h1>
        <p style="font-size: 16px; color: #444;">A new appointment has been booked through the website.</p>
        
        <div style="background-color: #f5f7ff; padding: 15px; border-radius: 5px; margin-bottom: 20px;">
          <h2 style="margin-top: 0;">Appointment Details</h2>
          <p><strong>Service:</strong> ${formData.serviceType}</p>
          <p><strong>Date:</strong> ${formattedDate}</p>
          <p><strong>Time:</strong> ${formData.preferredTime}</p>
          <p><strong>Appointment ID:</strong> ${appointmentId}</p>
        </div>
        
        <div style="background-color: #fff; padding: 15px; border-radius: 5px; border: 1px solid #e0e0e0; margin-bottom: 20px;">
          <h2 style="margin-top: 0;">Customer Information</h2>
          <p><strong>Name:</strong> ${formData.fullName}</p>
          <p><strong>Email:</strong> ${formData.email}</p>
          <p><strong>Phone:</strong> ${formData.phoneNumber}</p>
          ${formData.additionalNotes ? `<p><strong>Additional Notes:</strong> ${formData.additionalNotes}</p>` : ''}
        </div>
        
        <div style="text-align: center; margin-top: 30px;">
          <a href="${window.location.origin}/appointments/${appointmentId}" 
             style="background-color: #1e3a8a; color: white; padding: 10px 20px; text-decoration: none; border-radius: 4px; font-weight: bold;">
            View Appointment Details
          </a>
        </div>
      </div>
    `;

    // Attempt to send admin notification email
    try {
      await sendEmailWithRetry({
        to: "booking@hudumacenter.com",
        from: '"Huduma Center Booking System" <booking@hudumacenter.com>',
        subject: `New Appointment: ${formData.fullName} on ${formattedDate}`,
        html: adminHtml,
      }, 3);
      
      console.log("Admin notification email sent successfully!");
      
      // Try to update the appointment document, but don't fail if there's a permission error
      try {
        const appointmentRef = doc(db, "appointments", appointmentId);
        await updateDoc(appointmentRef, {
          clientAdminNotificationSent: true,
          clientAdminNotificationSentAt: serverTimestamp()
        });
      } catch (updateError) {
        // Log the error but continue - email was still sent successfully
        console.error("Error updating appointment with admin notification status:", updateError);
        console.log("Note: The admin notification email was still sent successfully despite the database update error");
      }
    } catch (error) {
      console.error("Error sending admin notification email:", error);
      // This is a non-critical error, so we won't block the booking process
    }
  };
  
  // Get progress percentage based on current step
  const getProgressPercentage = () => {
    switch(currentStep) {
      case 'personal-info': return 25;
      case 'service-selection': return 50;
      case 'date-time': return 75;
      case 'review': return 100;
      default: return 0;
    }
  };
  
  // Get step title based on current step
  const getStepTitle = () => {
    switch(currentStep) {
      case 'personal-info': return 'Personal Information';
      case 'service-selection': return 'Service Selection';
      case 'date-time': return 'Date & Time';
      case 'review': return 'Review & Confirm';
      default: return '';
    }
  };
  
  // Add a useEffect to handle automatic redirection after success
  useEffect(() => {
    let redirectTimer: NodeJS.Timeout;
    
    if (submitSuccess) {
      // Set countdown for redirect
      redirectTimer = setTimeout(() => {
        navigate('/');
      }, 30000); // 30 seconds
      
      // Add countdown display logic here if desired
    }
    
    // Cleanup function to clear the timeout if component unmounts
    return () => {
      if (redirectTimer) clearTimeout(redirectTimer);
    };
  }, [submitSuccess, navigate]);
  
  // Add a countdown state to show remaining time
  const [countdown, setCountdown] = useState(30);
  
  // Add another useEffect to handle the visual countdown
  useEffect(() => {
    let countdownTimer: NodeJS.Timeout;
    
    if (submitSuccess && countdown > 0) {
      countdownTimer = setTimeout(() => {
        setCountdown(prev => prev - 1);
      }, 1000);
    }
    
    return () => {
      if (countdownTimer) clearTimeout(countdownTimer);
    };
  }, [submitSuccess, countdown]);
  
  return (
    <div className="max-w-3xl mx-auto py-12 px-4 sm:px-6 lg:px-8">
      <div className="text-center mb-10">
        <h1 className="text-3xl font-extrabold text-blue-900 mb-2">
          Book an Appointment
        </h1>
        <p className="text-lg text-gray-500 max-w-2xl mx-auto">
          Schedule your visit to Huduma Center USA. We'll confirm your appointment within 24 hours.
        </p>
      </div>
      
      {submitSuccess ? (
        <Card>
          <div className="flex flex-col items-center py-6">
            <div className="mx-auto flex items-center justify-center h-20 w-20 rounded-full bg-green-100 mb-6">
              <svg className="h-10 w-10 text-green-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
              </svg>
            </div>
            
            <h2 className="text-3xl font-bold text-gray-900 mb-4">Appointment Scheduled!</h2>
            
            <div className="bg-blue-50 rounded-lg p-6 mb-6 max-w-lg w-full">
              <div className="flex justify-between items-center mb-4">
                <p className="text-gray-700">Service:</p>
                <p className="font-semibold text-gray-900">{formData.serviceType}</p>
              </div>
              <div className="flex justify-between items-center mb-4">
                <p className="text-gray-700">Date:</p>
                <p className="font-semibold text-gray-900">
                  {new Date(formData.preferredDate).toLocaleDateString('en-US', {
                    weekday: 'long',
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric'
                  })}
                </p>
              </div>
              <div className="flex justify-between items-center">
                <p className="text-gray-700">Time:</p>
                <p className="font-semibold text-gray-900">{formData.preferredTime}</p>
              </div>
            </div>
            
            <p className="text-center text-gray-600 mb-8 max-w-lg">
              Thank you for booking with Huduma Center USA. We've sent a confirmation email to <span className="font-semibold">{formData.email}</span> with all the details.
            </p>
            
            {/* Add this countdown notification */}
            <div className="bg-gray-50 rounded-lg p-4 mb-6 max-w-lg w-full text-center">
              <p className="text-sm text-gray-600">
                You will be redirected to the home page in <span className="font-semibold">{countdown}</span> seconds
              </p>
              <div className="w-full bg-gray-200 rounded-full h-2.5 mt-2">
                <div className="bg-blue-600 h-2.5 rounded-full" style={{ width: `${(countdown / 30) * 100}%` }}></div>
              </div>
            </div>
            
            <div className="flex flex-col sm:flex-row space-y-3 sm:space-y-0 sm:space-x-4">
              <Button href="/" variant="primary" size="lg">
                Return to Home
              </Button>
              <Button 
                variant="outline"
                onClick={() => {
                  setSubmitSuccess(false);
                  setCurrentStep('personal-info');
                  setFormData({
                    fullName: '',
                    email: '',
                    phoneNumber: '',
                    serviceType: '',
                    preferredDate: '',
                    preferredTime: '',
                    additionalNotes: ''
                  });
                }}
              >
                Book Another Appointment
              </Button>
            </div>
          </div>
        </Card>
      ) : (
        <Card>
          {/* Progress bar */}
          <div className="mb-8">
            <div className="relative pt-1">
              <div className="flex items-center justify-between mb-2">
                <div>
                  <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blue-600 bg-blue-200">
                    Step {currentStep === 'personal-info' ? '1' : 
                          currentStep === 'service-selection' ? '2' : 
                          currentStep === 'date-time' ? '3' : '4'} of 4
                  </span>
                </div>
                <div className="text-right">
                  <span className="text-xs font-semibold inline-block text-blue-600">
                    {getProgressPercentage()}%
                  </span>
                </div>
              </div>
              <div className="overflow-hidden h-2 mb-4 text-xs flex rounded bg-blue-50">
                <div 
                  style={{ width: `${getProgressPercentage()}%` }}
                  className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-blue-600 transition-all duration-300 ease-in-out"
                ></div>
              </div>
            </div>
          </div>
          
          <div className="px-1 sm:px-3">
            <h2 className="text-xl font-semibold text-gray-900 mb-6">{getStepTitle()}</h2>

            {errors.form && (
              <div className="bg-red-50 border-l-4 border-red-400 p-4 mb-6">
                <div className="flex">
                  <div className="flex-shrink-0">
                    <svg className="h-5 w-5 text-red-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                      <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clipRule="evenodd" />
                    </svg>
                  </div>
                  <div className="ml-3">
                    <p className="text-sm text-red-700">{errors.form}</p>
                  </div>
                </div>
              </div>
            )}
            
            <form onSubmit={handleSubmit}>
              {/* Step 1: Personal Information */}
              {currentStep === 'personal-info' && (
                <div className="space-y-6">
                  <FormInput
                    id="fullName"
                    name="fullName"
                    label="Full Name"
                    value={formData.fullName}
                    onChange={handleInputChange}
                    required
                    error={errors.fullName}
                  />
                  
                  <FormInput
                    id="phoneNumber"
                    name="phoneNumber"
                    type="tel"
                    label="Phone Number"
                    value={formData.phoneNumber}
                    onChange={handleInputChange}
                    required
                    error={errors.phoneNumber}
                  />
                  
                  <FormInput
                    id="email"
                    name="email"
                    type="email"
                    label="Email Address"
                    value={formData.email}
                    onChange={handleInputChange}
                    required
                    error={errors.email}
                  />
                </div>
              )}
              
              {/* Step 2: Service Selection */}
              {currentStep === 'service-selection' && (
                <div className="space-y-6">
                  <FormSelect
                    id="serviceType"
                    name="serviceType"
                    label="Service Type"
                    value={formData.serviceType}
                    onChange={handleInputChange}
                    options={serviceTypeOptions}
                    placeholder="Select a service"
                    required
                    error={errors.serviceType}
                  />
                  
                  {formData.serviceType && (
                    <div className="bg-blue-50 p-4 rounded-lg border border-blue-100">
                      <h3 className="text-sm font-medium text-blue-800 mb-2">Service Description</h3>
                      <p className="text-sm text-blue-700">
                        {formData.serviceType === 'Passport Services' ? 
                          'Passport application, renewal, and related services for international travel.' :
                         formData.serviceType === 'ID Card Application/Renewal' ?
                          'National ID card application and renewal services.' :
                         formData.serviceType === 'Business Registration' ?
                          'Registration and licensing services for new and existing businesses.' :
                         formData.serviceType === 'Tax Filing' ?
                          'Assistance with tax filing requirements and documentation.' :
                         formData.serviceType === 'License Renewal' ?
                          'Renewal services for various licenses and permits.' :
                         formData.serviceType === 'Work Permit' ?
                          'Work permit application and renewal services for foreign nationals.' :
                         formData.serviceType === 'HCA Examination' ?
                          'Registration and administration of HCA examinations.' :
                         formData.serviceType === 'Equity Bank Services' ?
                          'Banking services in partnership with Equity Bank.' :
                         formData.serviceType === 'General Consultation' ?
                          'General consultation regarding government services and requirements.' :
                          'Other services not specifically listed.'
                        }
                      </p>
                    </div>
                  )}
                </div>
              )}
              
              {/* Step 3: Date & Time */}
              {currentStep === 'date-time' && (
                <div className="space-y-6">
                  <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
                    <FormInput
                      id="preferredDate"
                      name="preferredDate"
                      type="date"
                      label="Preferred Date"
                      value={formData.preferredDate}
                      onChange={handleInputChange}
                      required
                      min={new Date().toISOString().split('T')[0]}
                      error={errors.preferredDate}
                    />
                    
                    <div>
                      <label htmlFor="preferredTime" className="block text-sm font-medium text-gray-700 mb-1">
                        Preferred Time <span className="text-red-500">*</span>
                      </label>
                      
                      {loadingTimeSlots ? (
                        <div className="flex items-center justify-center h-10">
                          <svg className="animate-spin h-5 w-5 text-blue-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                          </svg>
                          <span className="ml-2 text-sm text-gray-500">Loading available times...</span>
                        </div>
                      ) : formData.preferredDate ? (
                        <>
                          <select
                            id="preferredTime"
                            name="preferredTime"
                            value={formData.preferredTime}
                            onChange={handleInputChange}
                            className={`mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md ${
                              errors.preferredTime ? 'border-red-300' : ''
                            }`}
                            required
                          >
                            <option value="">Select a time</option>
                            {timeSlots.map((slot) => (
                              <option 
                                key={slot.value} 
                                value={slot.value}
                                disabled={!slot.available}
                              >
                                {slot.label} {!slot.available ? '(Booked)' : ''}
                              </option>
                            ))}
                          </select>
                          {errors.preferredTime && (
                            <p className="mt-1 text-sm text-red-600">{errors.preferredTime}</p>
                          )}
                        </>
                      ) : (
                        <p className="text-sm text-gray-500 mt-2">Please select a date first to see available time slots.</p>
                      )}
                    </div>
                  </div>
                  
                  <FormTextArea
                    id="additionalNotes"
                    name="additionalNotes"
                    label="Additional Information (Optional)"
                    value={formData.additionalNotes}
                    onChange={handleInputChange}
                    placeholder="Provide any additional details that might help us prepare for your appointment"
                    rows={4}
                  />
                </div>
              )}
              
              {/* Step 4: Review */}
              {currentStep === 'review' && (
                <div className="space-y-6">
                  <div className="bg-blue-50 p-5 rounded-lg border border-blue-100">
                    <div className="flex items-start mb-4">
                      <div className="flex-shrink-0">
                        <svg className="h-5 w-5 text-blue-600" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                          <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd" />
                        </svg>
                      </div>
                      <div className="ml-3">
                        <h3 className="text-sm font-medium text-blue-800">Ready to schedule your visit?</h3>
                        <p className="text-sm text-blue-700 mt-1">
                          Please review your appointment details before submitting. We'll send a confirmation to your email with next steps.
                        </p>
                      </div>
                    </div>
                    
                    <div className="space-y-4 mt-5">
                      <div className="grid grid-cols-2 gap-4">
                        <div>
                          <p className="text-xs font-medium text-gray-500 uppercase">Name</p>
                          <p className="text-sm font-medium text-gray-900">{formData.fullName}</p>
                        </div>
                        <div>
                          <p className="text-xs font-medium text-gray-500 uppercase">Phone</p>
                          <p className="text-sm font-medium text-gray-900">{formData.phoneNumber}</p>
                        </div>
                      </div>
                      
                      <div>
                        <p className="text-xs font-medium text-gray-500 uppercase">Email</p>
                        <p className="text-sm font-medium text-gray-900">{formData.email}</p>
                      </div>
                      
                      <div>
                        <p className="text-xs font-medium text-gray-500 uppercase">Service</p>
                        <p className="text-sm font-medium text-gray-900">{formData.serviceType}</p>
                      </div>
                      
                      <div className="grid grid-cols-2 gap-4">
                        <div>
                          <p className="text-xs font-medium text-gray-500 uppercase">Date</p>
                          <p className="text-sm font-medium text-gray-900">
                            {new Date(formData.preferredDate).toLocaleDateString('en-US', {
                              weekday: 'short',
                              year: 'numeric',
                              month: 'short',
                              day: 'numeric'
                            })}
                          </p>
                        </div>
                        <div>
                          <p className="text-xs font-medium text-gray-500 uppercase">Time</p>
                          <p className="text-sm font-medium text-gray-900">{formData.preferredTime}</p>
                        </div>
                      </div>
                      
                      {formData.additionalNotes && (
                        <div>
                          <p className="text-xs font-medium text-gray-500 uppercase">Additional Notes</p>
                          <p className="text-sm text-gray-900">{formData.additionalNotes}</p>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}
              
              {/* Navigation Buttons */}
              <div className="mt-8 pt-5 border-t border-gray-200">
                <div className="flex justify-between">
                  <div>
                    {currentStep !== 'personal-info' && (
                      <Button 
                        type="button"
                        variant="outline"
                        onClick={moveToPrevious}
                        className="flex items-center"
                      >
                        <svg className="mr-2 h-4 w-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 19l-7-7 7-7"></path>
                        </svg>
                        Back
                      </Button>
                    )}
                  </div>
                  
                  <div>
                    {currentStep !== 'review' ? (
                      <Button 
                        type="button"
                        onClick={moveToNext}
                        className="flex items-center bg-blue-600 hover:bg-blue-700"
                      >
                        Next
                        <svg className="ml-2 h-4 w-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5l7 7-7 7"></path>
                        </svg>
                      </Button>
                    ) : (
                      <Button
                        type="submit"
                        isLoading={isSubmitting}
                        isDisabled={isSubmitting}
                        size="lg"
                        className="bg-blue-700 hover:bg-blue-800 px-10 shadow-lg transform transition duration-200 hover:scale-105"
                      >
                        {isSubmitting ? 'Processing...' : 'Book Appointment'}
                      </Button>
                    )}
                  </div>
                </div>
              </div>
            </form>
          </div>
        </Card>
      )}
    </div>
  );
};

export default AppointmentBooking; 