import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, Outlet } from 'react-router-dom';
import { AuthProvider, useAuth } from './contexts/AuthContext';
import { hasPermission, User, ALL_PERMISSIONS } from './services/userService';
import Layout from './components/Layout';
import HomePage from './pages/HomePage';
import LoginPage from './pages/LoginPage';
import Dashboard from './pages/Dashboard';
import VisitorRegistration from './pages/VisitorRegistration';
import VisitsManagementPage from './pages/VisitsManagementPage';
import VisitorDetailsPage from './pages/VisitorDetailsPage';
import HCAApplications from './pages/HCAApplications';
import HCAApplicationDetails from './pages/HCAApplicationDetails';
import AppointmentsPage from './pages/AppointmentsPage';
import Jobs from './pages/Jobs';
import Messages from './pages/Messages';
import Reports from './pages/Reports';
import BankServices from './pages/BankServices';
import NotFound from './pages/NotFound';
import AppointmentBooking from './pages/AppointmentBooking';
import ContactPage from './pages/ContactPage';
import ServicesCatalogPage from './pages/ServicesCatalogPage';
import ServiceDetailPage from './pages/ServiceDetailPage';
import ServiceApplicationPage from './pages/ServiceApplicationPage';
import HCAExamRegistrationForm from './pages/HCAExamRegistrationForm';
import FileUploadTest from './pages/FileUploadTest';
import AppointmentDetailsPage from './pages/AppointmentDetailsPage';
import EmailTest from './pages/EmailTest';
import InitializeDatabase from './components/InitializeDatabase';
import UsersManagementPage from './pages/UsersManagementPage';
import PublicAppointmentPage from './pages/PublicAppointmentPage';
import AboutUsPage from './pages/AboutUsPage';
import ResourcesPage from './pages/ResourcesPage';
import BankClientDetails from './pages/BankClientDetails';
import UserDetailsPage from './pages/UserDetailsPage';
import BankingPublic from './pages/BankingPublic';
import RoleBasedRoute from './components/RoleBasedRoute';
import CommissionDashboard from './pages/CommissionDashboard';
import './index.css';
import toast, { Toaster } from 'react-hot-toast';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import { verifyFirebaseOnStartup } from './utils/firebaseConnectionChecker';

// Protected route component
const ProtectedRoute = ({ children, requiredRole, requiredPermission }: { children: JSX.Element, requiredRole?: string, requiredPermission?: string }) => {
  const { currentUser, loading } = useAuth();
  
  console.log("ProtectedRoute: currentUser=", currentUser?.uid, "role=", currentUser?.role, "loading=", loading);
  
  // Use useEffect to handle redirects
  useEffect(() => {
    if (!loading && !currentUser) {
      console.log("No current user, redirecting to login");
      window.location.href = "/login";
    }
    
    // Check if the route requires a specific role
    if (!loading && currentUser && requiredRole && 
        currentUser.role !== requiredRole && 
        currentUser.role !== 'admin') {
      console.log(`User lacks required role: ${requiredRole}`);
      window.location.href = "/dashboard";
    }
    
    // Check if the route requires a specific permission
    if (!loading && currentUser && requiredPermission) {
      // Cast currentUser to User type for hasPermission function
      const userWithPermissions = currentUser as unknown as User;
      if (!hasPermission(userWithPermissions, requiredPermission)) {
        console.log(`User lacks required permission: ${requiredPermission}`);
        window.location.href = "/dashboard";
      }
    }
  }, [currentUser, loading, requiredRole, requiredPermission]);
  
  if (loading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-900"></div>
      </div>
    );
  }
  
  if (!currentUser) {
    // Return loading spinner while the redirect happens
    return (
      <div className="flex items-center justify-center h-screen">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-900"></div>
        <p className="ml-3 text-blue-900">Redirecting to login...</p>
      </div>
    );
  }

  console.log("User authenticated, rendering protected content");
  return children;
};

// Add a new RedirectToDashboard component
const RedirectToDashboard = () => {
  // useAuth is safe here because RedirectToDashboard is only rendered 
  // within Routes which is inside AuthProvider
  const { currentUser, loading } = useAuth();
  
  console.log("RedirectToDashboard: currentUser=", currentUser?.uid, "loading=", loading);
  
  useEffect(() => {
    if (currentUser && !loading) {
      console.log("User is authenticated on homepage, redirecting to dashboard");
      window.location.href = "/dashboard";
    }
  }, [currentUser, loading]);
  
  if (loading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-900"></div>
      </div>
    );
  }
  
  return <HomePage />;
};

// Create a higher-order component for strict authentication protection
const withAuthProtection = (WrappedComponent: React.ComponentType<any>) => {
  // Name this component for better debugging
  const WithAuthProtection = (props: any) => {
    // useAuth is safe to use here because this component will only be rendered
    // when wrapped within the AuthProvider
    const { currentUser, loading } = useAuth();
    
    // Add version logging for debugging
    console.log("AUTH PROTECTION V2: Running on", window.location.hostname, "currentUser=", currentUser?.uid);
    
    // Force redirect on component mount, regardless of auth state
    useEffect(() => {
      console.log("AUTH PROTECTION V2: Effect running, currentUser=", currentUser?.uid, "loading=", loading);
      
      // Only redirect if we're sure user isn't authenticated
      if (!loading && !currentUser) {
        console.log("AUTH PROTECTION V2: No authenticated user, forcing redirect to login");
        
        // Force a small delay to ensure the redirect happens
        setTimeout(() => {
          // Use direct window location for more forceful redirect
          window.location.href = "/login";
        }, 100);
      }
    }, [currentUser, loading]);
    
    // Force immediate check outside of useEffect
    if (!loading && !currentUser) {
      console.log("AUTH PROTECTION V2: Immediate redirect check triggered");
      // Return early to prevent component from mounting at all
      return (
        <div className="flex items-center justify-center h-screen">
          <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-900"></div>
          <p className="ml-3 text-blue-900">Redirecting to login...</p>
        </div>
      );
    }
    
    // Show loading state until authentication is checked
    if (loading) {
      return (
        <div className="flex items-center justify-center h-screen">
          <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-900"></div>
          <p className="ml-3 text-blue-900">Checking authentication...</p>
        </div>
      );
    }
    
    // If not authenticated, show loading while redirect happens
    if (!currentUser) {
      return (
        <div className="flex items-center justify-center h-screen">
          <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-900"></div>
          <p className="ml-3 text-blue-900">Redirecting to login...</p>
        </div>
      );
    }
    
    // If we get here, user is authenticated
    console.log("AUTH PROTECTION V2: User authenticated, rendering protected content");
    return <WrappedComponent {...props} />;
  };
  
  return WithAuthProtection;
};

// Create protected versions of all dashboard components
const SecureDashboard = withAuthProtection(Dashboard);
const SecureAppointmentsPage = withAuthProtection(AppointmentsPage);
const SecureVisitsPage = withAuthProtection(VisitsManagementPage);
const SecureApplicationsPage = withAuthProtection(HCAApplications);
const SecureJobsPage = withAuthProtection(Jobs);
const SecureMessagesPage = withAuthProtection(Messages);
const SecureReportsPage = withAuthProtection(Reports);
const SecureVisitorRegistration = withAuthProtection(VisitorRegistration);
const SecureVisitorDetailsPage = withAuthProtection(VisitorDetailsPage);
const SecureAppointmentDetailsPage = withAuthProtection(AppointmentDetailsPage);
const SecureHCAApplicationDetails = withAuthProtection(HCAApplicationDetails);
const SecureUsersManagementPage = withAuthProtection(UsersManagementPage);
const SecureUserDetailsPage = withAuthProtection(UserDetailsPage);

// Add a new component to handle the appointment management redirect
const AppointmentManageRedirect = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const token = searchParams.get('token');
  
  useEffect(() => {
    // Extract the appointmentId from the token
    if (token) {
      try {
        // Decode the token to extract appointmentId
        const decodedToken = atob(token);
        const appointmentId = decodedToken.split(':')[0];
        
        if (appointmentId) {
          // Redirect to the appointment page with the token
          navigate(`/appointment/${appointmentId}?token=${token}`);
          return;
        }
      } catch (error) {
        console.error('Error decoding token:', error);
      }
    }
    
    // If we can't extract appointmentId or there's no token, redirect to appointments page
    navigate('/appointments');
  }, [token, navigate]);
  
  return (
    <div className="flex items-center justify-center min-h-screen">
      <div className="text-center">
        <h1 className="text-2xl font-bold mb-4">Redirecting...</h1>
        <p className="text-gray-600">Please wait while we take you to your appointment.</p>
      </div>
    </div>
  );
};

// Add BankServices to the protected components
const SecureBankServices = withAuthProtection(BankServices);
const SecureBankClientDetails = withAuthProtection(BankClientDetails);

// Create a new RestrictedRoute component for the equity user
const RestrictedRoute = ({ children }: { children: JSX.Element }) => {
  return (
    <RoleBasedRoute 
      allowedRoles={[]} // Empty array means all roles are allowed
      restricedUserIds={['eMZvcqgcaYd7q8Ov5GfWJkk1xML2']} // ID of restricted "equity" role user
    >
      {children}
    </RoleBasedRoute>
  );
};

// Create role-specific routes
const EquityUserRoute = ({ children }: { children: JSX.Element }) => {
  return (
    <RoleBasedRoute 
      allowedRoles={['equity', 'admin', 'manager']} 
      restricedUserIds={[]}
    >
      {children}
    </RoleBasedRoute>
  );
};

// Create a PublicRoute component that doesn't require authentication
const PublicRoute = ({ children }: { children: JSX.Element }) => {
  return (
    <RoleBasedRoute 
      allowedRoles={[]} 
      restricedUserIds={[]}
      allowUnauthenticated={true} // Allow unauthenticated access
    >
      {children}
    </RoleBasedRoute>
  );
};

function App() {
  // Run Firebase health check on startup
  useEffect(() => {
    const runStartupChecks = async () => {
      try {
        await verifyFirebaseOnStartup();
      } catch (error) {
        console.error("Error during startup checks:", error);
      }
    };
    
    runStartupChecks();
  }, []);
  
  return (
    <AuthProvider>
      <SnackbarProvider maxSnack={3} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
        <Router>
          <InitializeDatabase />
          <Routes>
            <Route path="/" element={<Layout />}>
              <Route index element={<RedirectToDashboard />} />
              <Route path="login" element={<LoginPage />} />
              
              {/* Public routes */}
              <Route path="book-appointment" element={<AppointmentBooking />} />
              <Route path="services" element={<ServicesCatalogPage />} />
              <Route path="contact" element={<ContactPage />} />
              <Route path="about-us" element={<AboutUsPage />} />
              <Route path="resources" element={<ResourcesPage />} />
              <Route path="appointment/:appointmentId" element={<PublicAppointmentPage />} />
              <Route path="banking" element={<BankingPublic />} />
              
              {/* Make appointment booking and HCA registration publicly accessible */}
              <Route path="appointments/book" element={
                <PublicRoute>
                  <AppointmentBooking />
                </PublicRoute>
              } />
              <Route path="services/hca-exam-registration/apply" element={
                <PublicRoute>
                  <HCAExamRegistrationForm />
                </PublicRoute>
              } />
              <Route path="services/hca-exam-booking/apply" element={
                <PublicRoute>
                  <HCAExamRegistrationForm />
                </PublicRoute>
              } />
              
              {/* Protected Banking Routes - All logged-in users can access these */}
              <Route path="/bank-services" element={
                <RestrictedRoute>
                  <BankServices />
                </RestrictedRoute>
              } />
              <Route path="/bank-services/commissions" element={
                <EquityUserRoute>
                  <div className="p-8 text-center">
                    <h2 className="text-2xl font-bold text-gray-900 mb-4">Commission Dashboard Temporarily Unavailable</h2>
                    <p className="text-gray-600 mb-4">We're working on improving the status update functionality. The commission dashboard will be back soon.</p>
                    <a href="/bank-services" className="text-blue-600 hover:text-blue-800">Return to Bank Services</a>
                  </div>
                </EquityUserRoute>
              } />
              <Route path="/bank-services/clients/:clientId" element={
                <RestrictedRoute>
                  <BankClientDetails />
                </RestrictedRoute>
              } />
              
              {/* Protected routes - restricted for equity role user */}
              <Route path="/dashboard" element={
                <RestrictedRoute>
                  <Dashboard />
                </RestrictedRoute>
              } />
              <Route path="visitors/register" element={
                <RestrictedRoute>
                  <VisitorRegistration />
                </RestrictedRoute>
              } />
              <Route path="visits" element={
                <RestrictedRoute>
                  <VisitsManagementPage />
                </RestrictedRoute>
              } />
              <Route path="visits/:visitId" element={
                <RestrictedRoute>
                  <VisitorDetailsPage />
                </RestrictedRoute>
              } />
              <Route path="applications" element={
                <RestrictedRoute>
                  <HCAApplications />
                </RestrictedRoute>
              } />
              <Route path="applications/:applicationId" element={
                <RestrictedRoute>
                  <HCAApplicationDetails />
                </RestrictedRoute>
              } />
              <Route path="appointments" element={
                <RestrictedRoute>
                  <AppointmentsPage />
                </RestrictedRoute>
              } />
              <Route path="appointments/:appointmentId" element={
                <RestrictedRoute>
                  <AppointmentDetailsPage />
                </RestrictedRoute>
              } />
              <Route path="jobs" element={
                <RestrictedRoute>
                  <Jobs />
                </RestrictedRoute>
              } />
              <Route path="messages" element={
                <RestrictedRoute>
                  <Messages />
                </RestrictedRoute>
              } />
              <Route path="reports" element={
                <RestrictedRoute>
                  <Reports />
                </RestrictedRoute>
              } />
              <Route path="users" element={
                <RestrictedRoute>
                  <UsersManagementPage />
                </RestrictedRoute>
              } />
              <Route path="users/:userId" element={
                <RestrictedRoute>
                  <UserDetailsPage />
                </RestrictedRoute>
              } />
              <Route path="/services/:serviceId" element={
                <RestrictedRoute>
                  <ServiceDetailPage />
                </RestrictedRoute>
              } />
              <Route path="/services/:serviceId/apply" element={
                <RestrictedRoute>
                  <ServiceApplicationPage />
                </RestrictedRoute>
              } />
              
              {/* Test routes */}
              <Route path="upload-test" element={
                <RestrictedRoute>
                  <FileUploadTest />
                </RestrictedRoute>
              } />
              
              {/* Admin Tools */}
              <Route path="admin/email-test" element={
                <RestrictedRoute>
                  <EmailTest />
                </RestrictedRoute>
              } />
              
              {/* Appointment management redirect */}
              <Route path="appointments/manage" element={
                <RestrictedRoute>
                  <AppointmentManageRedirect />
                </RestrictedRoute>
              } />
              
              {/* 404 route */}
              <Route path="*" element={<NotFound />} />
            </Route>
          </Routes>
        </Router>
      </SnackbarProvider>
    </AuthProvider>
  );
}

// Component to either redirect authenticated users or show fallback content
const AuthRedirect = ({ fallback, redirectTo }: { fallback: JSX.Element, redirectTo: string }) => {
  const { currentUser, loading } = useAuth();
  
  if (loading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-900"></div>
      </div>
    );
  }
  
  if (currentUser) {
    console.log("User is authenticated on unknown page, redirecting to", redirectTo);
    return <Navigate to={redirectTo} replace />;
  }
  
  return fallback;
};

export default App; 