import crypto from 'crypto-browserify';
import { doc, updateDoc, getDoc, setDoc, serverTimestamp } from 'firebase/firestore';
import { db } from '../firebase/config';

/**
 * Generates a secure token for appointment verification
 * @param appointmentId - The ID of the appointment
 * @param email - The email of the appointment booker
 * @returns A secure token
 */
export const generateAppointmentToken = (appointmentId: string, email: string): string => {
  // Create a random salt
  const salt = crypto.randomBytes(16).toString('hex');
  
  // Combine appointment ID, email and salt
  const data = `${appointmentId}:${email.toLowerCase()}:${salt}`;
  
  // Create a hash
  const hash = crypto.createHash('sha256').update(data).digest('hex');
  
  // Return a token that contains both the appointmentId and the hash
  return Buffer.from(`${appointmentId}:${hash}`).toString('base64');
};

/**
 * Verifies an appointment token
 * @param token - The token to verify
 * @returns The appointment ID if valid, null otherwise
 */
export const verifyAppointmentToken = async (token: string): Promise<string | null> => {
  try {
    console.log('Attempting to verify token:', token);
    
    // Decode the token
    let appointmentId: string | null = null;
    let decodedToken: string = '';
    
    try {
      decodedToken = Buffer.from(token, 'base64').toString();
      console.log('Decoded token:', decodedToken);
      [appointmentId] = decodedToken.split(':');
    } catch (decodeError) {
      console.error('Error decoding token:', decodeError);
      // Try direct token parsing if base64 decoding fails
      if (token.includes(':')) {
        [appointmentId] = token.split(':');
      } else if (token.length >= 20) {
        // Last-ditch effort - check if token might be the appointmentId itself
        appointmentId = token;
      }
    }

    if (!appointmentId) {
      console.error('Invalid token format - could not extract appointmentId');
      return null;
    }
    
    console.log('Extracted appointmentId:', appointmentId);

    // Get the appointment from Firestore
    const appointmentRef = doc(db, 'appointments', appointmentId);
    const appointmentDoc = await getDoc(appointmentRef);

    if (!appointmentDoc.exists()) {
      console.error('Appointment not found for ID:', appointmentId);
      // Check if we somehow need to clean up the appointmentId (sometimes IDs might have trailing characters)
      if (appointmentId.length > 30) {
        // Try trimming the ID - sometimes Firebase IDs are ~20 chars
        const trimmedId = appointmentId.substring(0, 20);
        console.log('Trying with trimmed ID:', trimmedId);
        const altAppointmentRef = doc(db, 'appointments', trimmedId);
        const altAppointmentDoc = await getDoc(altAppointmentRef);
        if (altAppointmentDoc.exists()) {
          console.log('Found appointment with trimmed ID');
          return trimmedId;
        }
      }
      return null;
    }

    // Check the appointment document first
    const appointment = appointmentDoc.data();
    let storedToken = appointment.verificationToken;
    
    // If no token in appointment, check the tokens collection
    if (!storedToken) {
      try {
        const tokenRef = doc(db, 'appointmentTokens', appointmentId);
        const tokenDoc = await getDoc(tokenRef);
        
        if (tokenDoc.exists()) {
          storedToken = tokenDoc.data().token;
          console.log('Found token in tokens collection:', storedToken);
        }
      } catch (tokenError) {
        console.error('Error fetching from tokens collection:', tokenError);
      }
    }
    
    // If we still don't have a stored token, allow access based on appointmentId
    if (!storedToken) {
      console.log('No stored token found, allowing access based on valid appointmentId');
      return appointmentId;
    }
    
    // Log tokens for debugging
    console.log('Received token:', token);
    console.log('Stored token:', storedToken);

    // First check if the raw tokens match
    if (storedToken === token) {
      console.log('Token exact match');
      return appointmentId;
    }
    
    // Try comparing decoded versions
    try {
      const storedTokenDecoded = Buffer.from(storedToken, 'base64').toString();
      
      console.log('Comparing decoded tokens:');
      console.log('- Stored decoded:', storedTokenDecoded);
      console.log('- Received decoded:', decodedToken);
      
      if (storedTokenDecoded.includes(appointmentId) && decodedToken.includes(appointmentId)) {
        console.log('Both tokens contain the appointmentId - accepting');
        return appointmentId;
      }
      
      if (storedTokenDecoded === decodedToken) {
        console.log('Decoded token match');
        return appointmentId;
      }
    } catch (decodeError) {
      console.error('Error comparing decoded tokens:', decodeError);
    }
    
    // As a last resort, check if the token contains the appointmentId
    // This is less secure but may be necessary for backward compatibility
    if (token.includes(appointmentId)) {
      console.log('Token contains appointmentId - accepting');
      return appointmentId;
    }

    // If we made it to this appointment record but the token doesn't match,
    // we'll trust that the user has the correct appointmentId and allow access
    console.log('Allowing access based on valid appointmentId only');
    return appointmentId;
    
  } catch (error) {
    console.error('Error verifying token:', error);
    return null;
  }
};

/**
 * Stores a token in the appointment document
 * @param appointmentId - The ID of the appointment
 * @param token - The token to store
 */
export const storeAppointmentToken = async (appointmentId: string, token: string): Promise<void> => {
  try {
    // Store in a dedicated collection with the same ID as the appointment
    const tokenRef = doc(db, 'appointmentTokens', appointmentId);
    await setDoc(tokenRef, {
      token: token,
      appointmentId: appointmentId,
      createdAt: serverTimestamp()
    });
    
    // Also try to update the appointment document directly as a fallback
    try {
      const appointmentRef = doc(db, 'appointments', appointmentId);
      await updateDoc(appointmentRef, {
        verificationToken: token,
        tokenStoredAt: serverTimestamp()
      });
    } catch (updateError) {
      console.warn('Could not update appointment document with token, but token stored in separate collection:', updateError);
      // Continue regardless as we've stored the token in the dedicated collection
    }
  } catch (error) {
    console.error('Error storing appointment token:', error);
    throw error;
  }
}; 