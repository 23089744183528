import { 
  collection, 
  doc, 
  getDoc, 
  getDocs, 
  query, 
  where, 
  orderBy, 
  addDoc, 
  updateDoc,
  serverTimestamp,
  Timestamp 
} from 'firebase/firestore';
import { db } from '../config';

// Loan referral status tracking
export type LoanReferralStatus = 'submitted' | 'under_review' | 'client_contacted' | 'approved' | 'rejected';

// Loan types
export type LoanType = 'mortgage' | 'personal' | 'business' | 'auto' | 'education' | 'other';

// Commission status
export type CommissionStatus = 'pending' | 'received' | 'canceled';

export interface LoanReferral {
  id: string;
  clientId: string;
  loanType: LoanType;
  estimatedAmount?: number;
  status: LoanReferralStatus;
  submittedBy: string;
  submittedByName: string;
  submittedAt: Timestamp;
  lastUpdatedAt?: Timestamp;
  lastUpdatedBy?: string;
  notes?: string;
  approvedAmount?: number;
  commissionPercentage?: number;
  commissionAmount?: number;
  commissionStatus?: CommissionStatus;
  statusHistory?: {
    status: LoanReferralStatus;
    timestamp: Timestamp;
    updatedBy: string;
  }[];
}

// Collection reference
export const loanReferralsCollection = collection(db, 'loanReferrals');

/**
 * Get all loan referrals for a client
 */
export const getClientLoanReferrals = async (clientId: string): Promise<LoanReferral[]> => {
  try {
    const referralsRef = collection(db, 'clients', clientId, 'loanReferrals');
    const q = query(referralsRef, orderBy('submittedAt', 'desc'));
    const querySnapshot = await getDocs(q);
    
    if (querySnapshot.empty) {
      console.log(`No loan referrals found for client ${clientId}`);
      return [];
    }
    
    return querySnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    } as LoanReferral));
  } catch (error) {
    console.error(`Error fetching loan referrals for client ${clientId}:`, error);
    // Log detailed error for debugging but return empty array to prevent UI from breaking
    if (error instanceof Error) {
      console.error(`Error details: ${error.message}`);
    }
    return [];
  }
};

/**
 * Create a new loan referral
 */
export const createLoanReferral = async (
  clientId: string,
  loanType: LoanType,
  estimatedAmount: number | undefined,
  submittedBy: string,
  submittedByName: string,
  notes?: string
): Promise<string> => {
  try {
    const newReferral = {
      clientId,
      loanType,
      estimatedAmount,
      status: 'submitted' as LoanReferralStatus,
      submittedBy,
      submittedByName,
      submittedAt: serverTimestamp(),
      notes,
      statusHistory: [
        {
          status: 'submitted' as LoanReferralStatus,
          timestamp: serverTimestamp(),
          updatedBy: submittedBy
        }
      ]
    };
    
    const docRef = await addDoc(loanReferralsCollection, newReferral);
    return docRef.id;
  } catch (error) {
    console.error('Error creating loan referral:', error);
    throw error;
  }
};

/**
 * Update loan referral status
 */
export const updateLoanReferralStatus = async (
  referralId: string,
  newStatus: LoanReferralStatus,
  updatedBy: string
): Promise<void> => {
  try {
    const referralRef = doc(loanReferralsCollection, referralId);
    const referralDoc = await getDoc(referralRef);
    
    if (!referralDoc.exists()) {
      throw new Error('Loan referral not found');
    }
    
    const referral = referralDoc.data() as LoanReferral;
    
    const statusHistory = [
      ...(referral.statusHistory || []),
      {
        status: newStatus,
        timestamp: serverTimestamp(),
        updatedBy
      }
    ];
    
    await updateDoc(referralRef, {
      status: newStatus,
      lastUpdatedAt: serverTimestamp(),
      lastUpdatedBy: updatedBy,
      statusHistory
    });
  } catch (error) {
    console.error('Error updating loan referral status:', error);
    throw error;
  }
};

/**
 * Add commission information to a loan referral
 */
export const addCommissionToLoanReferral = async (
  referralId: string,
  approvedAmount: number,
  commissionPercentage: number,
  updatedBy: string,
  notes?: string
): Promise<void> => {
  try {
    const referralRef = doc(loanReferralsCollection, referralId);
    const commissionAmount = (approvedAmount * commissionPercentage) / 100;
    
    await updateDoc(referralRef, {
      approvedAmount,
      commissionPercentage,
      commissionAmount,
      commissionStatus: 'pending' as CommissionStatus,
      notes: notes,
      lastUpdatedAt: serverTimestamp(),
      lastUpdatedBy: updatedBy
    });
  } catch (error) {
    console.error('Error adding commission to loan referral:', error);
    throw error;
  }
};

/**
 * Update commission status
 */
export const updateCommissionStatus = async (
  referralId: string,
  status: CommissionStatus,
  updatedBy: string
): Promise<void> => {
  try {
    const referralRef = doc(loanReferralsCollection, referralId);
    
    await updateDoc(referralRef, {
      commissionStatus: status,
      lastUpdatedAt: serverTimestamp(),
      lastUpdatedBy: updatedBy
    });
  } catch (error) {
    console.error('Error updating commission status:', error);
    throw error;
  }
};

/**
 * Get all approved loan referrals for an agent
 */
export const getAgentApprovedReferrals = async (agentId: string): Promise<LoanReferral[]> => {
  try {
    const q = query(
      loanReferralsCollection,
      where('submittedBy', '==', agentId),
      where('status', '==', 'approved')
    );
    
    const querySnapshot = await getDocs(q);
    const referrals: LoanReferral[] = [];
    
    querySnapshot.forEach((doc) => {
      referrals.push({ id: doc.id, ...doc.data() } as LoanReferral);
    });
    
    return referrals;
  } catch (error) {
    console.error('Error fetching agent approved referrals:', error);
    throw error;
  }
};

/**
 * Calculate total commission earned
 */
export const calculateTotalCommission = (referrals: LoanReferral[]): number => {
  return referrals.reduce((total, referral) => {
    if (referral.status === 'approved' && 
        referral.commissionAmount && 
        referral.commissionStatus === 'received') {
      return total + referral.commissionAmount;
    }
    return total;
  }, 0);
};

/**
 * Calculate pending commission
 */
export const calculatePendingCommission = (referrals: LoanReferral[]): number => {
  return referrals.reduce((total, referral) => {
    if (referral.status === 'approved' && 
        referral.commissionAmount && 
        referral.commissionStatus === 'pending') {
      return total + referral.commissionAmount;
    }
    return total;
  }, 0);
}; 