import React, { useState, useEffect, useMemo, useCallback, useRef } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import Button from '../components/ui/Button';
import Card from '../components/ui/Card';
import { db } from '../firebase/config';
import { doc, getDoc, Timestamp, collection, query, where, getDocs, addDoc, updateDoc, arrayUnion, orderBy, serverTimestamp } from 'firebase/firestore';
import { useSnackbar } from 'notistack';
import { v4 as uuidv4 } from 'uuid';
import { toast } from 'react-hot-toast';
import EditClientModal from '../components/EditClientModal'; // Import the EditClientModal
import LoansTab from '../components/client-details/LoansTab'; // Import the LoansTab component
import { Select, SelectTrigger, SelectValue, SelectItem, SelectContent } from '../components/ui/select'; // Import Select components
import StatusDropdown from '../components/StatusDropdown'; // Import StatusDropdown component
import { updateClientStatus } from '../firebase/clientStatus';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { createAccountCommission, getFilteredCommissions, updateCommissionStatus } from '../services/commissionService';
import AccountTimeline from '../components/AccountTimeline';
import LoadingSpinner from '../components/LoadingSpinner';
import ClientHeader from '../components/ClientHeader';
import { BankClient, AccountStatus, ActivityLog } from '../types';
import { isManager } from '../utils';
// Import statements - comment out the ClientCommissions import
// import ClientCommissions from '../components/client-details/ClientCommissions';

type TabType = 'timeline' | 'documents' | 'loans' | 'atm';

// Define loan status workflow
type LoanStatus = 'not-applicable' | 'submitted' | 'under_review' | 'approved' | 'rejected';

// Define ATM status workflow
type ATMStatus = 'none' | 'not-applicable' | 'requested' | 'processing' | 'shipped' | 'delivered' | 'active' | 'inactive' | 'blocked';

// Add the missing type definition
type EmailIndemnityStatus = 'not-applicable' | 'collected-from-customer' | 'shipped-to-kenya' | 'equity-agent-received' | 'en-route' | 'equity-bank-received';

// Loan referral status tracking
type LoanReferralStatus = 'submitted' | 'under_review' | 'client_contacted' | 'approved' | 'rejected';

// Loan types
type LoanType = 'mortgage' | 'personal' | 'business' | 'auto' | 'education' | 'other';

// Commission status
type CommissionStatus = 'pending' | 'received' | 'canceled';

interface LoanRequest {
  id: string;
  requestType: 'personal' | 'mortgage' | 'business' | 'other';
  amount: number;
  approvedAmount?: number;
  purpose: string;
  status: 'submitted' | 'under_review' | 'approved' | 'rejected';
  dateRequested: Timestamp;
  lastUpdated: Timestamp;
  notes?: string;
  createdBy: string;
  commission?: number;
  statusHistory?: StatusHistory[];
  approvedBy?: string;
  rejectionReason?: string;
}

// Enhanced ATM request interface
interface ATMRequest {
  id: string;
  cardType: 'debit' | 'credit' | 'prepaid';
  status: 'requested' | 'processing' | 'shipped' | 'delivered';
  dateRequested: Timestamp;
  lastUpdated: Timestamp;
  notes?: string;
  createdBy: string;
  trackingNumber?: string;
  statusHistory?: StatusHistory[];
  estimatedDeliveryDate?: Timestamp;
  shippedBy?: string;
  deliveryConfirmedDate?: Timestamp;
}

interface StatusHistory {
  status: string;
  date: Timestamp;
  updatedBy: string;
  notes?: string;
}

// Loan referral interface
interface LoanReferral {
  id: string;
  clientId: string;
  loanType: LoanType;
  estimatedAmount?: number;
  status: LoanReferralStatus;
  submittedBy: string;
  submittedByName: string;
  submittedAt: Timestamp;
  lastUpdatedAt?: Timestamp;
  lastUpdatedBy?: string;
  notes?: string;
  approvedAmount?: number;
  commissionPercentage?: number;
  commissionAmount?: number;
  commissionStatus?: CommissionStatus;
  statusHistory?: {
    status: LoanReferralStatus;
    timestamp: Timestamp;
    updatedBy: string;
  }[];
}

interface ClientData {
  // ... existing code ...
}

// Sample client data for testing
const mockClients: BankClient[] = [
  {
    id: '1',
    firstName: 'John',
    lastName: 'Smith',
    phone: '206-555-1234',
    email: 'john.smith@example.com',
    accountNumber: 'EQB-2024-001',
    accountType: 'Personal Savings',
    accountStatus: 'pending',
    createdAt: Timestamp.fromDate(new Date()),
    notes: 'Client brought all required documents',
    managerNotes: '',
    activityLogs: [
      {
        timestamp: Timestamp.fromDate(new Date()),
        action: 'Account Created',
        performedBy: 'Agent Smith',
        details: 'Initial account creation'
      }
    ],
    documents: []
  }
];

interface EditClientFormData {
  firstName: string;
  middleName?: string;
  lastName: string;
  email: string;
  phone: string;
  dateOfBirth?: Date;
  nationality?: string;
  address?: string;
  city?: string;
  state?: string;
  zipCode?: string;
  branchName?: string;
  bankRepresentative?: string;
}

// A simple loading spinner component
const LoadingComponent: React.FC = () => {
  return (
    <div className="flex justify-center items-center h-screen">
      <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-blue-500"></div>
    </div>
  );
};

// Create a basic interface for AddNoteModal
interface AddNoteModalProps {
  clientId: string;
  isOpen: boolean;
  onClose: () => void;
  onNoteAdded: (updatedClient: BankClient) => void;
}

// Define the AddNoteModal component
const AddNoteModal: React.FC<AddNoteModalProps> = ({ clientId, isOpen, onClose, onNoteAdded }) => {
  const [noteText, setNoteText] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  
  if (!isOpen) return null;
  
  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (!noteText.trim()) return;
    
    setIsSubmitting(true);
    
    try {
      // In a real app, this would call an API to add the note
      console.log(`Adding note for client ${clientId}: ${noteText}`);
      
      // Mock the updated client with required properties
      const updatedClient: BankClient = {
        id: clientId,
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        accountNumber: '',
        accountStatus: 'pending',
        accountType: '',
        createdAt: Timestamp.now(),
        notes: noteText,
        activityLogs: [],
        documents: [],
        loanRequests: [],
        atmRequests: []
      };
      
      onNoteAdded(updatedClient);
      onClose();
    } catch (error) {
      console.error('Error adding note:', error);
    } finally {
      setIsSubmitting(false);
    }
  };
  
  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-75 flex items-center justify-center z-10">
      <div className="bg-white rounded-lg overflow-hidden shadow-xl max-w-md w-full">
        <div className="px-4 py-5 sm:px-6 border-b border-gray-200">
          <h3 className="text-lg font-medium text-gray-900">Add Note</h3>
        </div>
        
        <form onSubmit={handleSubmit}>
          <div className="px-4 py-5 sm:p-6">
            <div className="mb-4">
              <label htmlFor="note" className="block text-sm font-medium text-gray-700">Note</label>
              <textarea
                id="note"
                rows={4}
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                placeholder="Enter your note here..."
                value={noteText}
                onChange={(e) => setNoteText(e.target.value)}
                required
              />
            </div>
          </div>
          
          <div className="px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse bg-gray-50">
            <button
              type="submit"
              disabled={isSubmitting}
              className={`w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm ${isSubmitting ? 'opacity-75 cursor-not-allowed' : ''}`}
            >
              {isSubmitting ? 'Adding...' : 'Add Note'}
            </button>
            <button
              type="button"
              onClick={onClose}
              className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

// First, update the getStatusProgressBar function to restore the 4 steps
const getStatusProgressBar = (status: AccountStatus) => {
  let width = '0%';
  let color = 'bg-yellow-500';

  switch (status) {
    case 'pending':
      width = '25%';
      color = 'bg-yellow-500';
      break;
    case 'processing':
      width = '50%';
      color = 'bg-blue-500';
      break;
    case 'documents-requested':
      width = '50%';
      color = 'bg-orange-500';
      break;
    case 'activated':
      width = '75%';
      color = 'bg-green-500';
      break;
    case 'funded':
      width = '100%';
      color = 'bg-purple-500';
      break;
    case 'dormant':
      width = '75%';
      color = 'bg-gray-500';
      break;
    case 'denied':
      width = '25%';
      color = 'bg-red-500';
      break;
    case 'closed':
      width = '100%';
      color = 'bg-red-500';
      break;
    default:
      width = '0%';
  }

  return <div className={`${color} h-2.5 rounded-full`} style={{ width }}></div>;
};
  
// Helper function to get background and border colors for status indicators
const getStatusColor = (status: AccountStatus): string => {
  switch (status) {
    case 'pending':
      return 'bg-yellow-500 border-yellow-600';
    case 'processing':
      return 'bg-blue-500 border-blue-600';
    case 'documents-requested':
      return 'bg-orange-500 border-orange-600';
    case 'activated':
      return 'bg-green-500 border-green-600';
    case 'funded':
      return 'bg-purple-500 border-purple-600';
    case 'dormant':
      return 'bg-gray-500 border-gray-600';
    case 'denied':
      return 'bg-red-500 border-red-600';
    case 'closed':
      return 'bg-red-500 border-red-600';
    default:
      return 'bg-gray-300 border-gray-400';
  }
};

// Calculate progress percentage based on status
const getStatusProgress = (status: AccountStatus): number => {
  switch (status) {
    case 'pending': return 10;
    case 'processing': return 30;
    case 'documents-requested': return 40;
    case 'activated': return 70;
    case 'onboarded-online': return 80;
    case 'funded': return 100;
    case 'dormant': return 50;
    case 'denied': return 0;
    case 'closed': return 100;
    default: return 0;
  }
};
  
// Format timestamp
  const formatTimestamp = (timestamp: Timestamp | undefined): string => {
    if (!timestamp) return 'Unknown date';
    
    try {
      const date = timestamp.toDate();
      return new Intl.DateTimeFormat('en-US', {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        hour12: true
      }).format(date);
    } catch (error) {
      console.error('Error formatting timestamp:', error);
      return 'Invalid date';
    }
  };

const BankClientDetails: React.FC = () => {
  const { clientId } = useParams<{ clientId: string }>();
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const [client, setClient] = useState<BankClient | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [activeTab, setActiveTab] = useState<TabType>('timeline');
  const [showEditModal, setShowEditModal] = useState(false);
  const [showAddNoteModal, setShowAddNoteModal] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [pendingStatusChange, setPendingStatusChange] = useState<AccountStatus | null>(null);
  const [newStatus, setNewStatus] = useState<AccountStatus>('pending');
  const [statusNote, setStatusNote] = useState('');
  const [managerNotes, setManagerNotes] = useState('');

  // Fetch client data
  useEffect(() => {
    const fetchClientData = async () => {
      if (!clientId) {
        setError('No client ID provided');
        setIsLoading(false);
        return;
      }

      try {
        const docRef = doc(db, 'bankClients', clientId);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          setClient({ id: docSnap.id, ...docSnap.data() } as BankClient);
        } else {
          setError('Client not found');
        }
      } catch (err) {
        console.error('Error fetching client:', err);
        setError('Error fetching client data');
      } finally {
        setIsLoading(false);
      }
    };

    fetchClientData();
  }, [clientId]);

  // Handle client update
  const handleClientUpdate = (updatedClient: BankClient) => {
    try {
      const clientRef = doc(db, 'bankClients', updatedClient.id);
      
      // Create activity log for the update
      const activityLog = {
        timestamp: Timestamp.now(),
        action: 'Client Details Updated',
        performedBy: currentUser?.email || 'unknown',
        details: 'Client information was updated'
      };

      // Update the client document
      updateDoc(clientRef, {
        ...updatedClient,
        updatedAt: serverTimestamp(),
        updatedBy: currentUser?.email,
        activityLogs: arrayUnion(activityLog)
      });

      // Update local state
      setClient(updatedClient);
      
      // Show success message
      toast.success('Client details updated successfully');
      
      // Close the edit modal
      setShowEditModal(false);
    } catch (err) {
      console.error('Error updating client:', err);
      toast.error('Failed to update client details');
    }
  };

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (error || !client) {
    return (
      <div className="min-h-screen bg-gray-100 py-6 flex flex-col justify-center sm:py-12">
        <div className="relative py-3 sm:max-w-xl sm:mx-auto">
          <div className="relative px-4 py-10 bg-white shadow-lg sm:rounded-3xl sm:p-20">
            <div className="max-w-md mx-auto">
              <div className="divide-y divide-gray-200">
                <div className="py-8 text-base leading-6 space-y-4 text-gray-700 sm:text-lg sm:leading-7">
                  <p className="text-red-600">{error || 'Error loading client'}</p>
                  <button
                    onClick={() => navigate(-1)}
                    className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
                  >
                    Go Back
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-100">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <ClientHeader 
          client={client} 
          onEditClick={() => setShowEditModal(true)} 
        />

        <div className="mt-8">
          <div className="bg-white shadow overflow-hidden sm:rounded-lg">
            <div className="px-4 py-5 sm:px-6">
              <nav className="flex space-x-4">
                <button
                  onClick={() => setActiveTab('timeline')}
                  className={`px-3 py-2 font-medium text-sm rounded-md ${
                    activeTab === 'timeline'
                      ? 'bg-blue-100 text-blue-700'
                      : 'text-gray-500 hover:text-gray-700'
                  }`}
                >
                  Timeline
                </button>
                <button
                  onClick={() => setActiveTab('documents')}
                  className={`px-3 py-2 font-medium text-sm rounded-md ${
                    activeTab === 'documents'
                      ? 'bg-blue-100 text-blue-700'
                      : 'text-gray-500 hover:text-gray-700'
                  }`}
                >
                  Documents
                </button>
                <button
                  onClick={() => setActiveTab('loans')}
                  className={`px-3 py-2 font-medium text-sm rounded-md ${
                    activeTab === 'loans'
                      ? 'bg-blue-100 text-blue-700'
                      : 'text-gray-500 hover:text-gray-700'
                  }`}
                >
                  Loans
                </button>
                <button
                  onClick={() => setActiveTab('atm')}
                  className={`px-3 py-2 font-medium text-sm rounded-md ${
                    activeTab === 'atm'
                      ? 'bg-blue-100 text-blue-700'
                      : 'text-gray-500 hover:text-gray-700'
                  }`}
                >
                  ATM Cards
                </button>
              </nav>
            </div>

            <div className="border-t border-gray-200">
              {activeTab === 'timeline' && (
                <AccountTimeline client={client} />
              )}
              {activeTab === 'documents' && (
                <div className="p-4">Documents tab content</div>
              )}
              {activeTab === 'loans' && (
                <LoansTab 
                  client={client}
                  clientId={client.id}
                  isManager={isManager()}
                />
              )}
              {activeTab === 'atm' && (
                <div className="p-4">ATM Cards tab content</div>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* Edit Client Modal */}
      {showEditModal && client && (
        <EditClientModal
          client={client as BankClient}
          isOpen={showEditModal}
          onClose={() => setShowEditModal(false)}
          onClientUpdated={(updatedClient) => {
            handleClientUpdate(updatedClient as BankClient);
          }}
        />
      )}
    </div>
  );
};

export default BankClientDetails;