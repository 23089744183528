import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { initializeDefaultUsers } from '../services/userService';
import LoginModal from '../components/LoginModal';

const LoginPage: React.FC = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const { signIn, currentUser } = useAuth();
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(true);

  // Redirect if already logged in - Force immediate redirection
  useEffect(() => {
    if (currentUser) {
      console.log("User already logged in, forcing navigation to dashboard");
      // Use direct window location change for more immediate effect
      window.location.href = '/dashboard';
    }
  }, [currentUser]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    if (!email || !password) {
      setError('Please enter both email and password');
      return;
    }
    
    try {
      setError('');
      setIsLoading(true);
      
      // Use Promise pattern without await to reduce potential delay
      signIn(email, password)
        .then(user => {
          console.log("Login successful, forcing navigation to dashboard");
          // Force redirection with window.location for immediate effect
          window.location.href = '/dashboard';
        })
        .catch(err => {
          console.error("Login error:", err);
          setError('Failed to sign in. Please check your credentials.');
          setIsLoading(false);
        });
    } catch (err) {
      console.error('Error in login process:', err);
      setError('An unexpected error occurred. Please try again.');
      setIsLoading(false);
    }
  };

  const handleInitUsers = async () => {
    try {
      await initializeDefaultUsers();
      alert('Successfully initialized users');
    } catch (error) {
      console.error('Error initializing users:', error);
      alert('Error initializing users. Check console for details.');
    }
  };

  // Don't render the login form if already logged in
  if (currentUser) {
    return <div className="flex items-center justify-center h-screen">
      <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-900"></div>
      <p className="ml-3 text-blue-900">Redirecting to dashboard...</p>
    </div>;
  }

  return (
    <div className="min-h-screen bg-gray-100 flex flex-col items-center justify-center p-4">
      <div className="w-full max-w-md">
        <LoginModal 
          isOpen={isModalOpen} 
          onClose={() => setIsModalOpen(false)}
        />
        <button
          onClick={handleInitUsers}
          className="mt-4 w-full bg-gray-500 text-white py-2 px-4 rounded hover:bg-gray-600 transition-colors"
        >
          Initialize Default Users
        </button>
      </div>
    </div>
  );
};

export default LoginPage; 