import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import StaffPerformanceReport from '../components/Reports/StaffPerformanceReport';
import DailyIncomeReport from '../components/Reports/DailyIncomeReport';
import CompletedVisitsReport from '../components/Reports/CompletedVisitsReport';
import DateRangePicker from '../components/Reports/DateRangePicker';
import { format, startOfMonth, endOfToday } from 'date-fns';
import { Lock } from 'lucide-react';

// Report types
type ReportType = 'staff-performance' | 'daily-income' | 'completed-visits';

const Reports: React.FC = () => {
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [activeReport, setActiveReport] = useState<ReportType>('daily-income');
  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [isCheckingPassword, setIsCheckingPassword] = useState(false);
  
  // Date range state (default to current month)
  const [startDate, setStartDate] = useState<Date>(startOfMonth(new Date()));
  const [endDate, setEndDate] = useState<Date>(endOfToday());
  
  // Check authorization
  useEffect(() => {
    // Simple check - you might want to use a more robust solution in production
    const checkAuth = async () => {
      setIsLoading(true);
      
      // If no user is logged in, they definitely don't have access
      if (!currentUser) {
        setIsAuthorized(false);
        setIsLoading(false);
        return;
      }
      
      // Check if admin or manager user
      if (currentUser.role === 'admin' || currentUser.role === 'manager') {
        setIsAuthorized(true);
      } else {
        setIsAuthorized(false);
      }
      
      setIsLoading(false);
    };
    
    checkAuth();
  }, [currentUser]);
  
  // Handle password check
  const handlePasswordCheck = (e: React.FormEvent) => {
    e.preventDefault();
    setIsCheckingPassword(true);
    
    // Simple password check for demo purposes
    // In a real app, you'd verify this against a secure stored value or use a proper auth system
    setTimeout(() => {
      const correctPassword = 'reports2023'; // This would normally come from a secure source
      
      if (password === correctPassword) {
        setIsAuthorized(true);
        setPasswordError('');
      } else {
        setPasswordError('Incorrect password. Please try again.');
      }
      
      setIsCheckingPassword(false);
    }, 1000); // Simulate API call
  };
  
  // Handle date range changes
  const handleDateRangeChange = (start: Date, end: Date) => {
    setStartDate(start);
    setEndDate(end);
  };
  
  // If still loading, show loading spinner
  if (isLoading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-700"></div>
      </div>
    );
  }
  
  // If not authorized, show password form
  if (!isAuthorized) {
    return (
      <div className="flex items-center justify-center min-h-screen bg-gray-50">
        <div className="w-full max-w-md p-8 bg-white rounded-lg shadow-md">
          <div className="text-center mb-6">
            <Lock className="h-12 w-12 text-blue-600 mx-auto mb-2" />
            <h1 className="text-2xl font-bold text-gray-900">Reports Access</h1>
            <p className="text-gray-600">This page is protected. Please enter the reports password.</p>
          </div>
          
          <form onSubmit={handlePasswordCheck}>
            {passwordError && (
              <div className="mb-4 p-3 bg-red-50 text-red-800 rounded-md text-sm">
                {passwordError}
              </div>
            )}
            
            <div className="mb-6">
              <label htmlFor="password" className="block text-sm font-medium text-gray-700 mb-1">
                Password
              </label>
              <input
                type="password"
                id="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                required
              />
            </div>
            
            <button
              type="submit"
              disabled={isCheckingPassword}
              className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              {isCheckingPassword ? (
                <span className="flex items-center">
                  <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                  </svg>
                  Verifying...
                </span>
              ) : (
                'Access Reports'
              )}
            </button>
            
            <div className="mt-4 text-center">
              <button
                type="button"
                onClick={() => navigate('/dashboard')}
                className="text-sm text-blue-600 hover:text-blue-800"
              >
                Return to Dashboard
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  }
  
  // Main reports page content (authorized users)
  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
      <div className="mb-8">
        <h1 className="text-2xl font-bold text-gray-900">Business Reports</h1>
        <p className="text-gray-500">View and analyze business performance metrics</p>
      </div>
      
      {/* Report Navigation */}
      <div className="mb-8 border-b border-gray-200">
        <nav className="flex space-x-8" aria-label="Reports">
          <button
            className={`py-4 px-1 border-b-2 font-medium text-sm ${
              activeReport === 'daily-income'
                ? 'border-blue-500 text-blue-600'
                : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
            }`}
            onClick={() => setActiveReport('daily-income')}
          >
            Daily Income
          </button>
          <button
            className={`py-4 px-1 border-b-2 font-medium text-sm ${
              activeReport === 'staff-performance'
                ? 'border-blue-500 text-blue-600'
                : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
            }`}
            onClick={() => setActiveReport('staff-performance')}
          >
            Staff Performance
          </button>
          <button
            className={`py-4 px-1 border-b-2 font-medium text-sm ${
              activeReport === 'completed-visits'
                ? 'border-blue-500 text-blue-600'
                : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
            }`}
            onClick={() => setActiveReport('completed-visits')}
          >
            Completed Visits
          </button>
        </nav>
      </div>
      
      {/* Date Range Selector */}
      <div className="mb-6">
        <DateRangePicker 
          startDate={startDate}
          endDate={endDate}
          onChange={handleDateRangeChange}
        />
      </div>
      
      {/* Current Selected Report */}
      <div className="bg-white rounded-lg shadow-sm p-6">
        {activeReport === 'daily-income' && (
          <DailyIncomeReport 
            startDate={startDate} 
            endDate={endDate} 
          />
        )}
        
        {activeReport === 'staff-performance' && (
          <StaffPerformanceReport 
            startDate={startDate} 
            endDate={endDate} 
          />
        )}
        
        {activeReport === 'completed-visits' && (
          <CompletedVisitsReport 
            startDate={startDate} 
            endDate={endDate} 
          />
        )}
      </div>
    </div>
  );
};

export default Reports; 