import React from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';

const BankingPublic: React.FC = () => {
  const { currentUser } = useAuth();

  return (
    <div className="min-h-screen bg-gray-50 py-12">
      <div className="container mx-auto px-4">
        <div className="max-w-4xl mx-auto">
          {/* Header */}
          <div className="mb-12 text-center">
            <h1 className="text-4xl font-bold text-gray-900 mb-4">Equity Bank Agency Services</h1>
            <p className="text-xl text-gray-600">
              Supporting diaspora communities with essential Equity Bank services
            </p>
          </div>

          {/* Main Content */}
          <div className="bg-white shadow-md rounded-lg overflow-hidden mb-10">
            {/* Hero Section */}
            <div className="bg-blue-600 text-white p-8">
              <div className="flex flex-col md:flex-row items-center">
                <div className="md:w-2/3 mb-6 md:mb-0 md:pr-8">
                  <h2 className="text-2xl font-bold mb-4">Your Link to Equity Bank Services</h2>
                  <p className="text-white/90">
                    As an official Equity Bank agent, we provide assistance and support to help diaspora communities access banking services. We focus on customer outreach, referrals, and facilitating the account management process.
                  </p>
                </div>
                <div className="md:w-1/3 flex justify-center">
                  <svg className="w-28 h-28 text-white/90" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M3 6l18 0M3 12h18M3 18h6m4 0h8M3 6V22h18V6M3 6V2h18v4M14 14a2 2 0 100-4 2 2 0 000 4z" />
                  </svg>
                </div>
              </div>
            </div>

            {/* Services List */}
            <div className="p-8">
              <h3 className="text-2xl font-bold text-gray-800 mb-6">Our Banking Services</h3>

              <div className="grid md:grid-cols-2 gap-6">
                <div className="border border-gray-200 rounded-lg p-6 hover:shadow-md transition-shadow">
                  <div className="flex items-center mb-4">
                    <div className="rounded-full bg-blue-100 p-2 mr-4">
                      <svg className="w-6 h-6 text-blue-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                      </svg>
                    </div>
                    <h4 className="text-lg font-semibold text-gray-800">Account Opening Assistance</h4>
                  </div>
                  <p className="text-gray-600">
                    We guide you through the Equity Bank account opening process, helping with documentation requirements, form completion, and application submission to simplify the process.
                  </p>
                </div>

                <div className="border border-gray-200 rounded-lg p-6 hover:shadow-md transition-shadow">
                  <div className="flex items-center mb-4">
                    <div className="rounded-full bg-blue-100 p-2 mr-4">
                      <svg className="w-6 h-6 text-blue-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                      </svg>
                    </div>
                    <h4 className="text-lg font-semibold text-gray-800">Account Activation Support</h4>
                  </div>
                  <p className="text-gray-600">
                    We help reactivate dormant accounts, coordinate KYC information updates, and assist with restoring access to your existing Equity Bank accounts.
                  </p>
                </div>

                <div className="border border-gray-200 rounded-lg p-6 hover:shadow-md transition-shadow">
                  <div className="flex items-center mb-4">
                    <div className="rounded-full bg-blue-100 p-2 mr-4">
                      <svg className="w-6 h-6 text-blue-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
                      </svg>
                    </div>
                    <h4 className="text-lg font-semibold text-gray-800">Details Updating</h4>
                  </div>
                  <p className="text-gray-600">
                    We assist with updating personal information, contact details, and other account information with Equity Bank to ensure your account remains current and accessible.
                  </p>
                </div>

                <div className="border border-gray-200 rounded-lg p-6 hover:shadow-md transition-shadow">
                  <div className="flex items-center mb-4">
                    <div className="rounded-full bg-blue-100 p-2 mr-4">
                      <svg className="w-6 h-6 text-blue-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M18 9v3m0 0v3m0-3h3m-3 0h-3m-2-5a4 4 0 11-8 0 4 4 0 018 0zM3 20a6 6 0 0112 0v1H3v-1z" />
                      </svg>
                    </div>
                    <h4 className="text-lg font-semibold text-gray-800">Customer Outreach</h4>
                  </div>
                  <p className="text-gray-600">
                    We connect diaspora community members with Equity Bank services, providing information, answering questions, and facilitating referrals to the appropriate banking channels.
                  </p>
                </div>
              </div>
            </div>

            {/* Banking Partner */}
            <div className="bg-gray-50 p-8 border-t border-gray-200">
              <h3 className="text-2xl font-bold text-gray-800 mb-6">Our Banking Partner</h3>
              <div className="flex flex-col items-center">
                <div className="bg-white p-6 rounded-lg shadow-sm border border-gray-100 max-w-md mx-auto">
                  <div className="flex flex-col items-center">
                    <span className="text-2xl font-bold text-blue-800 mb-4">Equity Bank</span>
                    <p className="text-gray-600 text-center">
                      We are an official agent of Equity Bank, one of East Africa's leading financial institutions. With a strong presence across the region, Equity Bank offers a wide range of services tailored to meet the needs of both local and diaspora customers.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            {/* Process Section */}
            <div className="p-8 border-t border-gray-200">
              <h3 className="text-2xl font-bold text-gray-800 mb-6">How We Assist You</h3>
              
              <div className="space-y-8">
                <div className="flex">
                  <div className="flex-shrink-0 mr-4">
                    <div className="flex items-center justify-center w-10 h-10 rounded-full border-2 border-blue-600 text-blue-600 font-bold">1</div>
                  </div>
                  <div>
                    <h4 className="text-lg font-semibold text-gray-800 mb-2">Initial Consultation</h4>
                    <p className="text-gray-600">
                      Meet with our banking specialists to discuss your Equity Bank service needs and how we can assist you in connecting with the right banking solutions.
                    </p>
                  </div>
                </div>
                
                <div className="flex">
                  <div className="flex-shrink-0 mr-4">
                    <div className="flex items-center justify-center w-10 h-10 rounded-full border-2 border-blue-600 text-blue-600 font-bold">2</div>
                  </div>
                  <div>
                    <h4 className="text-lg font-semibold text-gray-800 mb-2">Guidance & Documentation</h4>
                    <p className="text-gray-600">
                      We help you understand what documents are needed and assist with completing the required forms according to Equity Bank's requirements.
                    </p>
                  </div>
                </div>
                
                <div className="flex">
                  <div className="flex-shrink-0 mr-4">
                    <div className="flex items-center justify-center w-10 h-10 rounded-full border-2 border-blue-600 text-blue-600 font-bold">3</div>
                  </div>
                  <div>
                    <h4 className="text-lg font-semibold text-gray-800 mb-2">Submission & Follow-up</h4>
                    <p className="text-gray-600">
                      We coordinate with Equity Bank to submit your application or request, and conduct follow-ups to track the progress of your service request.
                    </p>
                  </div>
                </div>
                
                <div className="flex">
                  <div className="flex-shrink-0 mr-4">
                    <div className="flex items-center justify-center w-10 h-10 rounded-full border-2 border-blue-600 text-blue-600 font-bold">4</div>
                  </div>
                  <div>
                    <h4 className="text-lg font-semibold text-gray-800 mb-2">Ongoing Support</h4>
                    <p className="text-gray-600">
                      We continue to provide assistance by answering questions, clarifying information, and supporting you with any additional banking service needs.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Call to Action */}
          <div className="bg-blue-50 border border-blue-100 rounded-lg p-8 text-center">
            <h3 className="text-2xl font-bold text-gray-800 mb-4">Need Assistance with Equity Bank Services?</h3>
            <p className="text-gray-600 mb-6 max-w-2xl mx-auto">
              Our banking specialists are ready to help you connect with Equity Bank and navigate their services. Book an appointment or contact us today.
            </p>
            <div className="flex flex-col sm:flex-row justify-center gap-4">
              <Link to="/book-appointment" className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-3 px-6 rounded-md transition-colors">
                Book an Appointment
              </Link>
              <Link to="/contact" className="bg-white hover:bg-gray-50 text-blue-600 font-bold py-3 px-6 rounded-md border border-blue-200 transition-colors">
                Contact Us
              </Link>
              {currentUser && (
                <Link to="/bank-services" className="bg-green-600 hover:bg-green-700 text-white font-bold py-3 px-6 rounded-md transition-colors">
                  Access Banking Dashboard
                </Link>
              )}
            </div>
          </div>

          {/* FAQ Section */}
          <div className="mt-12">
            <h3 className="text-2xl font-bold text-gray-800 mb-6">Frequently Asked Questions</h3>
            
            <div className="space-y-4">
              <div className="border border-gray-200 rounded-lg">
                <div className="px-6 py-4 bg-gray-50 font-semibold text-gray-800">
                  What documents do I need to open an Equity Bank account?
                </div>
                <div className="px-6 py-4 text-gray-600">
                  Typically, you'll need a valid passport, proof of address in your current country of residence, a tax identification number, and sometimes proof of income. We can provide specific guidance based on your situation.
                </div>
              </div>
              
              <div className="border border-gray-200 rounded-lg">
                <div className="px-6 py-4 bg-gray-50 font-semibold text-gray-800">
                  How do you assist with account activation?
                </div>
                <div className="px-6 py-4 text-gray-600">
                  We help coordinate the reactivation process by assisting with updating your documentation, verifying your identity, and communicating with Equity Bank to restore access to your account.
                </div>
              </div>
              
              <div className="border border-gray-200 rounded-lg">
                <div className="px-6 py-4 bg-gray-50 font-semibold text-gray-800">
                  Can you help me if I've lost access to my Equity Bank account?
                </div>
                <div className="px-6 py-4 text-gray-600">
                  Yes, we can help you reconnect with your account by guiding you through Equity Bank's account recovery process, assisting with necessary documentation, and following up on your behalf.
                </div>
              </div>
              
              <div className="border border-gray-200 rounded-lg">
                <div className="px-6 py-4 bg-gray-50 font-semibold text-gray-800">
                  What are your fees for banking assistance services?
                </div>
                <div className="px-6 py-4 text-gray-600">
                  We provide guidance and support as part of our customer service. Any applicable bank fees would be those charged directly by Equity Bank for their services. We'll always be transparent about any fees involved.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BankingPublic; 