import React, { useRef, useState } from 'react';
import { cn } from '@/lib/utils';
import { Info, Mail, Phone, Search, UserPlus } from 'lucide-react';

// Search type options
type SearchType = 'phone' | 'email' | 'name';

interface VisitorSearchProps {
  onSearch: (query: string, type: SearchType) => Promise<void>;
  onRegisterNew: () => void;
  isSearching: boolean;
  searchError: string | null;
}

const VisitorSearch: React.FC<VisitorSearchProps> = ({
  onSearch,
  onRegisterNew,
  isSearching,
  searchError
}) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [searchType, setSearchType] = useState<SearchType>('phone');
  const searchInputRef = useRef<HTMLInputElement>(null);
  
  const handleSearch = async (e: React.FormEvent) => {
    e.preventDefault();
    onSearch(searchQuery, searchType);
  };
  
  return (
    <div className="p-6">
      <div className="flex flex-col space-y-4">
        <div>
          <h2 className="text-xl font-semibold text-gray-900 mb-2">Search for Visitor</h2>
          <p className="text-sm text-gray-600 mb-4">
            Enter visitor information to search for existing records
          </p>
        </div>
        
        <div className="flex space-x-4 mb-4">
          <button
            type="button"
            onClick={() => setSearchType('phone')}
            className={cn(
              "flex items-center px-4 py-2 rounded-md text-sm font-medium",
              searchType === 'phone' 
                ? "bg-blue-600 text-white" 
                : "bg-gray-100 text-gray-700 hover:bg-gray-200"
            )}
          >
            <Phone className="w-4 h-4 mr-2" />
            Phone
          </button>
          
          <button
            type="button"
            onClick={() => setSearchType('email')}
            className={cn(
              "flex items-center px-4 py-2 rounded-md text-sm font-medium",
              searchType === 'email' 
                ? "bg-blue-600 text-white" 
                : "bg-gray-100 text-gray-700 hover:bg-gray-200"
            )}
          >
            <Mail className="w-4 h-4 mr-2" />
            Email
          </button>
          
          <button
            type="button"
            onClick={() => setSearchType('name')}
            className={cn(
              "flex items-center px-4 py-2 rounded-md text-sm font-medium",
              searchType === 'name' 
                ? "bg-blue-600 text-white" 
                : "bg-gray-100 text-gray-700 hover:bg-gray-200"
            )}
          >
            <Info className="w-4 h-4 mr-2" />
            Name
          </button>
        </div>
        
        <form onSubmit={handleSearch} className="flex flex-col space-y-4">
          <div className="relative">
            <label htmlFor="visitor-search" className="block text-sm font-medium text-gray-700 mb-1">
              {searchType === 'phone' ? 'Phone Number' : searchType === 'email' ? 'Email Address' : 'Full Name'}
              <span className="text-red-500">*</span>
            </label>
            <div className="relative">
              <span className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none text-gray-500">
                {searchType === 'phone' ? (
                  <Phone className="w-5 h-5" />
                ) : searchType === 'email' ? (
                  <Mail className="w-5 h-5" />
                ) : (
                  <Info className="w-5 h-5" />
                )}
              </span>
              <input
                type="text"
                id="visitor-search"
                className={cn(
                  "bg-gray-50 border border-gray-300 text-gray-900 text-base rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5",
                  searchError ? "border-red-500" : ""
                )}
                placeholder={
                  searchType === 'phone' 
                    ? "Enter phone number (e.g., 555-123-4567)" 
                    : searchType === 'email'
                      ? "Enter email address"
                      : "Enter full name"
                }
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                ref={searchInputRef}
                aria-label={`Search by ${searchType}`}
                required
              />
            </div>
            {searchError && (
              <p className="mt-2 text-sm text-red-600">{searchError}</p>
            )}
          </div>
          
          <div className="flex space-x-3">
            <button
              type="submit"
              className={cn(
                "flex items-center justify-center px-5 py-2.5 text-sm font-medium text-white bg-blue-700 rounded-lg focus:ring-4 focus:ring-blue-200 hover:bg-blue-800",
                isSearching ? "opacity-70 cursor-not-allowed" : ""
              )}
              disabled={isSearching}
            >
              {isSearching ? (
                <>
                  <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                  </svg>
                  Searching...
                </>
              ) : (
                <>
                  <Search className="w-5 h-5 mr-2" />
                  Search
                </>
              )}
            </button>
            
            <button
              type="button"
              className="flex items-center justify-center px-5 py-2.5 text-sm font-medium text-blue-700 bg-white border border-blue-700 rounded-lg focus:ring-4 focus:ring-blue-200 hover:bg-blue-50"
              onClick={onRegisterNew}
            >
              <UserPlus className="w-5 h-5 mr-2" />
              Register New Visitor
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default VisitorSearch; 