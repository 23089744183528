import React, { useState } from 'react';
import { cn } from '@/lib/utils';
import { Search, UserCheck } from 'lucide-react';

interface VisitorRegistrationFormProps {
  initialData?: {
    phoneNumber?: string;
    email?: string;
  };
  onRegister: (visitorData: any) => Promise<void>;
  onCancel: () => void;
  isProcessing: boolean;
}

const VisitorRegistrationForm: React.FC<VisitorRegistrationFormProps> = ({
  initialData = {},
  onRegister,
  onCancel,
  isProcessing
}) => {
  const [visitorData, setVisitorData] = useState({
    fullName: '',
    phoneNumber: initialData.phoneNumber || '',
    email: initialData.email || '',
    purpose: '',
    priority: 'standard' as 'standard' | 'urgent' | 'vip',
    notes: '',
    consentGiven: false
  });
  
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
    const { name, value, type } = e.target;
    
    if (type === 'checkbox') {
      const target = e.target as HTMLInputElement;
      setVisitorData({...visitorData, [name]: target.checked});
    } else {
      setVisitorData({...visitorData, [name]: value});
    }
  };
  
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    onRegister(visitorData);
  };
  
  return (
    <div className="p-6">
      <div className="flex flex-col space-y-4">
        <div>
          <h2 className="text-xl font-semibold text-gray-900 mb-2">Register New Visitor</h2>
          <p className="text-sm text-gray-600 mb-4">
            Please fill in the visitor information to register them
          </p>
        </div>
        
        <form onSubmit={handleSubmit} className="space-y-4">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div>
              <label htmlFor="fullName" className="block text-sm font-medium text-gray-700 mb-1">
                Full Name <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                id="fullName"
                name="fullName"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-base rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                placeholder="Enter full name"
                value={visitorData.fullName}
                onChange={handleInputChange}
                required
              />
            </div>
            
            <div>
              <label htmlFor="phoneNumber" className="block text-sm font-medium text-gray-700 mb-1">
                Phone Number <span className="text-red-500">*</span>
              </label>
              <input
                type="tel"
                id="phoneNumber"
                name="phoneNumber"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-base rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                placeholder="Enter phone number"
                value={visitorData.phoneNumber}
                onChange={handleInputChange}
                required
              />
            </div>
            
            <div>
              <label htmlFor="email" className="block text-sm font-medium text-gray-700 mb-1">
                Email Address
              </label>
              <input
                type="email"
                id="email"
                name="email"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-base rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                placeholder="Enter email address"
                value={visitorData.email}
                onChange={handleInputChange}
              />
            </div>
            
            <div>
              <label htmlFor="purpose" className="block text-sm font-medium text-gray-700 mb-1">
                Purpose of Visit <span className="text-red-500">*</span>
              </label>
              <select
                id="purpose"
                name="purpose"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-base rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                value={visitorData.purpose}
                onChange={handleInputChange}
                required
              >
                <option value="">Select purpose</option>
                <option value="Tax Filing">Tax Filing</option>
                <option value="Work Permit">Work Permit</option>
                <option value="Passport Services">Passport Services</option>
                <option value="HCA Examination">HCA Examination</option>
                <option value="Job Application">Job Application</option>
                <option value="Consultation">Consultation</option>
                <option value="General Inquiry">General Inquiry</option>
                <option value="Other">Other</option>
              </select>
            </div>
            
            <div>
              <label htmlFor="priority" className="block text-sm font-medium text-gray-700 mb-1">
                Queue Priority
              </label>
              <select
                id="priority"
                name="priority"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-base rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                value={visitorData.priority}
                onChange={handleInputChange}
              >
                <option value="standard">Standard</option>
                <option value="urgent">Urgent</option>
                <option value="vip">VIP</option>
              </select>
            </div>
            
            <div className="md:col-span-2">
              <label htmlFor="notes" className="block text-sm font-medium text-gray-700 mb-1">
                Additional Notes
              </label>
              <textarea
                id="notes"
                name="notes"
                rows={3}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-base rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                placeholder="Enter any additional information"
                value={visitorData.notes}
                onChange={handleInputChange}
              ></textarea>
            </div>
            
            <div className="md:col-span-2">
              <div className="flex items-start">
                <div className="flex items-center h-5">
                  <input
                    id="consentGiven"
                    name="consentGiven"
                    type="checkbox"
                    className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-blue-300"
                    checked={visitorData.consentGiven}
                    onChange={handleInputChange}
                    required
                  />
                </div>
                <label htmlFor="consentGiven" className="ml-2 text-sm font-medium text-gray-700">
                  I consent to the storage and processing of my personal data in accordance with the <a href="#" className="text-blue-600 hover:underline">Privacy Policy</a>.
                </label>
              </div>
            </div>
          </div>
          
          <div className="flex space-x-3 pt-2">
            <button
              type="submit"
              className={cn(
                "flex items-center justify-center px-5 py-2.5 text-sm font-medium text-white bg-blue-700 rounded-lg focus:ring-4 focus:ring-blue-200 hover:bg-blue-800",
                isProcessing ? "opacity-70 cursor-not-allowed" : ""
              )}
              disabled={isProcessing}
            >
              {isProcessing ? (
                <>
                  <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                  </svg>
                  Processing...
                </>
              ) : (
                <>
                  <UserCheck className="w-5 h-5 mr-2" />
                  Register & Check In
                </>
              )}
            </button>
            
            <button
              type="button"
              className="flex items-center justify-center px-5 py-2.5 text-sm font-medium text-blue-700 bg-white border border-blue-700 rounded-lg focus:ring-4 focus:ring-blue-200 hover:bg-blue-50"
              onClick={onCancel}
            >
              <Search className="w-5 h-5 mr-2" />
              Back to Search
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default VisitorRegistrationForm; 