import React, { useEffect } from 'react';
import { cn } from '@/lib/utils';
import { format } from 'date-fns';
import { ArrowRight, UserCheck, UserMinus } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

interface VisitorConfirmationProps {
  visitor: any;
  action: 'check-in' | 'check-out';
  onNewSearch: () => void;
  onVisitorDetails?: () => void;
}

const VisitorConfirmation: React.FC<VisitorConfirmationProps> = ({
  visitor,
  action,
  onNewSearch,
  onVisitorDetails
}) => {
  const navigate = useNavigate();
  const isCheckIn = action === 'check-in';
  
  // Format time for display
  const formatTime = (date: Date | null | undefined): string => {
    if (!date) return 'N/A';
    try {
      return format(date, 'hh:mm a');
    } catch (error) {
      return 'Invalid Time';
    }
  };
  
  // Redirect to dashboard after successful check-in
  useEffect(() => {
    if (isCheckIn) {
      const redirectTimer = setTimeout(() => {
        navigate('/dashboard');
      }, 3000); // Redirect after 3 seconds
      
      return () => clearTimeout(redirectTimer);
    }
  }, [isCheckIn, navigate]);
  
  return (
    <div className={cn(
      "border rounded-lg p-6 m-6 text-center",
      isCheckIn ? "bg-green-50 border-green-200" : "bg-amber-50 border-amber-200"
    )}>
      <div className="flex flex-col items-center justify-center">
        <div className={cn(
          "w-16 h-16 rounded-full flex items-center justify-center mb-4",
          isCheckIn ? "bg-green-100" : "bg-amber-100"
        )}>
          {isCheckIn ? (
            <UserCheck className={cn("w-8 h-8 text-green-600")} />
          ) : (
            <UserMinus className={cn("w-8 h-8 text-amber-600")} />
          )}
        </div>
        
        <h2 className="text-xl font-semibold text-gray-900 mb-2">
          {isCheckIn ? 'Check-In Successful!' : 'Check-Out Successful!'}
        </h2>
        
        <p className="text-base text-gray-600 mb-6">
          {visitor.fullName} has been {isCheckIn ? 'checked in' : 'checked out'} successfully.
        </p>
        
        {isCheckIn && (
          <div className="bg-white rounded-lg border border-gray-200 p-4 mb-6 w-full max-w-md">
            <div className="grid grid-cols-2 gap-4 text-left">
              <div>
                <p className="text-sm font-medium text-gray-500">Name</p>
                <p className="text-base font-semibold">{visitor.fullName}</p>
              </div>
              
              <div>
                <p className="text-sm font-medium text-gray-500">Check-In Time</p>
                <p className="text-base">{formatTime(visitor.checkInTime)}</p>
              </div>
              
              {visitor.purpose && (
                <div>
                  <p className="text-sm font-medium text-gray-500">Purpose</p>
                  <p className="text-base">{visitor.purpose}</p>
                </div>
              )}
              
              {visitor.queueNumber && (
                <div>
                  <p className="text-sm font-medium text-gray-500">Queue Number</p>
                  <p className="text-base font-semibold text-lg">{visitor.queueNumber}</p>
                </div>
              )}
              
              {visitor.estimatedWaitTime && (
                <div className="col-span-2">
                  <p className="text-sm font-medium text-gray-500">Estimated Wait Time</p>
                  <p className="text-base">{visitor.estimatedWaitTime} minutes</p>
                </div>
              )}
            </div>
          </div>
        )}
        
        {isCheckIn && (
          <p className="text-sm text-gray-500 mb-4">
            Redirecting to dashboard in 3 seconds...
          </p>
        )}
        
        <div className="flex space-x-4">
          <button
            type="button"
            className="flex items-center justify-center px-5 py-2.5 text-sm font-medium text-white bg-blue-700 rounded-lg focus:ring-4 focus:ring-blue-200 hover:bg-blue-800"
            onClick={onNewSearch}
          >
            <UserCheck className="w-5 h-5 mr-2" />
            New Registration
          </button>
          
          {onVisitorDetails && (
            <button
              type="button"
              className="flex items-center justify-center px-5 py-2.5 text-sm font-medium text-blue-700 bg-white border border-blue-700 rounded-lg focus:ring-4 focus:ring-blue-200 hover:bg-blue-50"
              onClick={onVisitorDetails}
            >
              <ArrowRight className="w-5 h-5 mr-2" />
              View Details
            </button>
          )}
          
          <button
            type="button"
            className="flex items-center justify-center px-5 py-2.5 text-sm font-medium text-green-700 bg-white border border-green-700 rounded-lg focus:ring-4 focus:ring-green-200 hover:bg-green-50"
            onClick={() => navigate('/dashboard')}
          >
            <ArrowRight className="w-5 h-5 mr-2" />
            Go to Dashboard Now
          </button>
        </div>
      </div>
    </div>
  );
};

export default VisitorConfirmation; 