import React, { useState, useEffect } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { useSnackbar } from 'notistack';
import { doc, updateDoc, Timestamp } from 'firebase/firestore';
import { db } from '../firebase/config';

// Import interface for BankClient from types or define it here
interface BankClient {
  id: string;
  firstName: string;
  middleName?: string;
  lastName: string;
  email: string;
  phone: string;
  accountNumber: string;
  accountStatus: string;
  accountType: string;
  createdAt: Timestamp;
  updatedAt?: Timestamp;
  updatedBy?: string;
  [key: string]: any; // For additional properties
}

interface EditClientFormData {
  firstName: string;
  middleName: string;
  lastName: string;
  email: string;
  phone: string;
  accountNumber: string;
}

interface EditClientModalProps {
  client: BankClient;
  isOpen: boolean;
  onClose: () => void;
  onClientUpdated: (updatedClient: BankClient) => void;
}

const EditClientModal: React.FC<EditClientModalProps> = ({ 
  client, 
  isOpen, 
  onClose,
  onClientUpdated
}) => {
  const { currentUser } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState<string | null>(null);
  
  const [formData, setFormData] = useState<EditClientFormData>({
    firstName: client.firstName || '',
    middleName: client.middleName || '',
    lastName: client.lastName || '',
    email: client.email || '',
    phone: client.phone || '',
    accountNumber: client.accountNumber || ''
  });

  // Update form data when client changes
  useEffect(() => {
    if (client) {
      setFormData({
        firstName: client.firstName || '',
        middleName: client.middleName || '',
        lastName: client.lastName || '',
        email: client.email || '',
        phone: client.phone || '',
        accountNumber: client.accountNumber || ''
      });
    }
  }, [client]);
  
  if (!isOpen) return null;
  
  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };
  
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    // Form validation
    if (!formData.firstName.trim()) {
      setError('First name is required');
      return;
    }
    
    if (!formData.lastName.trim()) {
      setError('Last name is required');
      return;
    }
    
    if (!formData.email.trim() || !/\S+@\S+\.\S+/.test(formData.email)) {
      setError('Valid email is required');
      return;
    }
    
    if (!formData.phone.trim()) {
      setError('Phone number is required');
      return;
    }

    if (!formData.accountNumber.trim()) {
      setError('Account number is required');
      return;
    }
    
    setIsSubmitting(true);
    setError(null);
    
    try {
      // Create updated client object
      const updatedClient = {
        ...client,
        firstName: formData.firstName,
        middleName: formData.middleName,
        lastName: formData.lastName,
        email: formData.email,
        phone: formData.phone,
        accountNumber: formData.accountNumber,
        updatedAt: Timestamp.now(),
        updatedBy: currentUser?.uid || 'unknown',
        activityLogs: [
          ...(client.activityLogs || []),
          {
            timestamp: Timestamp.now(),
            action: 'Client Details Updated',
            performedBy: currentUser?.uid || 'unknown',
            details: `Client details updated by ${currentUser?.displayName || currentUser?.email || 'Bank Agent'}`
          }
        ]
      };
      
      // Update in Firestore
      const docRef = doc(db, 'bankClients', client.id);
      await updateDoc(docRef, {
        firstName: formData.firstName,
        middleName: formData.middleName,
        lastName: formData.lastName,
        email: formData.email,
        phone: formData.phone,
        accountNumber: formData.accountNumber,
        updatedAt: Timestamp.now(),
        updatedBy: currentUser?.uid || 'unknown',
        activityLogs: updatedClient.activityLogs
      });
      
      // Notify parent component
      onClientUpdated(updatedClient);
      
      // Show success message
      enqueueSnackbar('Client details updated successfully', { variant: 'success' });
      
      // Close the modal
      onClose();
    } catch (error) {
      console.error('Error updating client details:', error);
      setError('Failed to update client details. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };
  
  return (
    <div className="fixed inset-0 z-50 overflow-y-auto bg-gray-600 bg-opacity-75 flex items-center justify-center">
      <div className="relative max-w-3xl w-full mx-4 bg-white rounded-lg shadow-xl">
        <div className="border-t-4 border-blue-600 rounded-t-lg"></div>
        
        <div className="p-6">
          <div className="flex justify-between items-start mb-4">
            <h2 className="text-xl font-semibold text-gray-800 font-sans">Edit Account</h2>
            <button
              type="button"
              onClick={onClose}
              className="text-gray-400 hover:text-gray-500 focus:outline-none"
              aria-label="Close"
            >
              <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          </div>
          
          <p className="text-sm text-gray-600 mb-6 font-sans">
            You can edit the phone number, email, account number, and names. Required fields are marked with an asterisk (*).
          </p>
          
          {error && (
            <div className="mb-6 p-4 bg-red-50 border-l-4 border-red-500 text-red-700 rounded">
              <p className="font-sans text-sm">{error}</p>
            </div>
          )}
          
          <form onSubmit={handleSubmit} className="space-y-6">
            {/* Names Section */}
            <fieldset>
              <legend className="text-base font-medium text-gray-900 mb-4 font-sans">Names</legend>
              <div className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                <div className="sm:col-span-2">
                  <label htmlFor="firstName" className="block text-sm font-medium text-gray-700 font-sans">
                    First Name <span className="text-red-500">*</span>
                  </label>
                  <input
                    type="text"
                    id="firstName"
                    name="firstName"
                    value={formData.firstName}
                    onChange={handleChange}
                    required
                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm font-sans"
                  />
                </div>
                
                <div className="sm:col-span-2">
                  <label htmlFor="middleName" className="block text-sm font-medium text-gray-700 font-sans">
                    Middle Name
                  </label>
                  <input
                    type="text"
                    id="middleName"
                    name="middleName"
                    value={formData.middleName}
                    onChange={handleChange}
                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm font-sans"
                  />
                </div>
                
                <div className="sm:col-span-2">
                  <label htmlFor="lastName" className="block text-sm font-medium text-gray-700 font-sans">
                    Last Name <span className="text-red-500">*</span>
                  </label>
                  <input
                    type="text"
                    id="lastName"
                    name="lastName"
                    value={formData.lastName}
                    onChange={handleChange}
                    required
                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm font-sans"
                  />
                </div>
              </div>
            </fieldset>
            
            {/* Contact Information */}
            <fieldset>
              <legend className="text-base font-medium text-gray-900 mb-4 font-sans">Contact Information</legend>
              <div className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                <div className="sm:col-span-3">
                  <label htmlFor="email" className="block text-sm font-medium text-gray-700 font-sans">
                    Email Address <span className="text-red-500">*</span>
                  </label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    required
                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm font-sans"
                  />
                </div>
                
                <div className="sm:col-span-3">
                  <label htmlFor="phone" className="block text-sm font-medium text-gray-700 font-sans">
                    Phone Number <span className="text-red-500">*</span>
                  </label>
                  <input
                    type="tel"
                    id="phone"
                    name="phone"
                    value={formData.phone}
                    onChange={handleChange}
                    required
                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm font-sans"
                  />
                </div>
              </div>
            </fieldset>
            
            {/* Account Information */}
            <fieldset>
              <legend className="text-base font-medium text-gray-900 mb-4 font-sans">Account Information</legend>
              <div className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                <div className="sm:col-span-3">
                  <label htmlFor="accountNumber" className="block text-sm font-medium text-gray-700 font-sans">
                    Account Number <span className="text-red-500">*</span>
                  </label>
                  <input
                    type="text"
                    id="accountNumber"
                    name="accountNumber"
                    value={formData.accountNumber}
                    onChange={handleChange}
                    required
                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm font-sans"
                  />
                </div>
              </div>
            </fieldset>
            
            {/* Form Actions */}
            <div className="flex justify-end space-x-3 pt-5">
              <button
                type="button"
                onClick={onClose}
                className="px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                Cancel
              </button>
              <button
                type="submit"
                disabled={isSubmitting}
                className={`px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 ${isSubmitting ? 'opacity-75 cursor-not-allowed' : ''}`}
              >
                {isSubmitting ? 'Saving...' : 'Save Changes'}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default EditClientModal; 