import React, { useState, useEffect, useRef } from 'react';
import { User } from '../../services/userService';
import { db } from '../../firebase/config';
import { collection, query, where, orderBy, onSnapshot, addDoc, serverTimestamp, or, updateDoc, getDocs, DocumentReference } from 'firebase/firestore';

interface Message {
  id: string;
  senderId: string;
  recipientId: string;
  content: string;
  timestamp: Date;
  read: boolean;
}

interface ChatWindowProps {
  recipient: User;
  currentUser: User;
}

const ChatWindow: React.FC<ChatWindowProps> = ({ recipient, currentUser }) => {
  const [messages, setMessages] = useState<Message[]>([]);
  const [newMessage, setNewMessage] = useState('');
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const [loading, setLoading] = useState(true);

  // Scroll to bottom of messages
  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  // Listen to messages and mark them as read
  useEffect(() => {
    const messagesRef = collection(db, 'messages');
    const q = query(
      messagesRef,
      or(
        where('senderId', '==', currentUser.uid),
        where('recipientId', '==', currentUser.uid)
      ),
      orderBy('timestamp', 'asc')
    );

    const unsubscribe = onSnapshot(q, async (snapshot) => {
      const newMessages: Message[] = [];
      const unreadMessages: DocumentReference[] = [];

      snapshot.forEach((doc) => {
        const data = doc.data();
        // Only include messages between current user and recipient
        if ((data.senderId === currentUser.uid && data.recipientId === recipient.uid) ||
            (data.senderId === recipient.uid && data.recipientId === currentUser.uid)) {
          newMessages.push({
            id: doc.id,
            ...data,
            timestamp: data.timestamp?.toDate(),
          } as Message);

          // Collect unread messages sent to current user
          if (!data.read && data.recipientId === currentUser.uid) {
            unreadMessages.push(doc.ref);
          }
        }
      });

      // Mark unread messages as read
      if (unreadMessages.length > 0) {
        const updatePromises = unreadMessages.map(ref =>
          updateDoc(ref, { read: true })
        );
        await Promise.all(updatePromises);
      }

      setMessages(newMessages);
      setLoading(false);
    });

    return () => unsubscribe();
  }, [currentUser.uid, recipient.uid]);

  const handleSendMessage = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!newMessage.trim()) return;

    try {
      const messagesRef = collection(db, 'messages');
      await addDoc(messagesRef, {
        senderId: currentUser.uid,
        recipientId: recipient.uid,
        content: newMessage.trim(),
        timestamp: serverTimestamp(),
        read: false
      });
      setNewMessage('');
    } catch (error) {
      console.error('Error sending message:', error);
    }
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center h-full w-full">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  return (
    <div className="flex flex-col w-full h-full bg-white">
      {/* Chat Header */}
      <div className="flex-shrink-0 px-6 py-4 border-b border-gray-200 bg-white">
        <div className="flex items-center">
          <div className="flex-shrink-0">
            <div className="w-10 h-10 rounded-full bg-gray-300 flex items-center justify-center">
              {recipient.photoURL ? (
                <img
                  src={recipient.photoURL}
                  alt={recipient.displayName}
                  className="w-10 h-10 rounded-full"
                />
              ) : (
                <span className="text-lg font-medium text-white">
                  {recipient.displayName?.charAt(0).toUpperCase()}
                </span>
              )}
            </div>
          </div>
          <div className="ml-3">
            <p className="text-sm font-medium text-gray-900">{recipient.displayName}</p>
            <p className="text-sm text-gray-500">{recipient.department || recipient.role}</p>
          </div>
        </div>
      </div>

      {/* Messages */}
      <div className="flex-1 overflow-y-auto px-6 py-4">
        <div className="space-y-4">
          {messages.map((message) => {
            const isCurrentUser = message.senderId === currentUser.uid;
            return (
              <div
                key={message.id}
                className={`flex ${isCurrentUser ? 'justify-end' : 'justify-start'}`}
              >
                <div
                  className={`max-w-[70%] rounded-2xl px-4 py-2 ${
                    isCurrentUser
                      ? 'bg-blue-500 text-white rounded-br-none'
                      : 'bg-gray-100 text-gray-900 rounded-bl-none'
                  }`}
                >
                  <p className="text-sm break-words">{message.content}</p>
                  <div className="flex items-center justify-end mt-1 space-x-2">
                    <p className="text-xs opacity-75">
                      {message.timestamp?.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
                    </p>
                    {isCurrentUser && (
                      <span className="text-xs">
                        {message.read ? '✓✓' : '✓'}
                      </span>
                    )}
                  </div>
                </div>
              </div>
            );
          })}
          <div ref={messagesEndRef} />
        </div>
      </div>

      {/* Message Input */}
      <div className="flex-shrink-0 px-6 py-4 border-t border-gray-200 bg-white">
        <form onSubmit={handleSendMessage} className="flex space-x-4">
          <input
            type="text"
            value={newMessage}
            onChange={(e) => setNewMessage(e.target.value)}
            placeholder="Type a message..."
            className="flex-1 rounded-full border border-gray-300 px-6 py-3 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
          />
          <button
            type="submit"
            disabled={!newMessage.trim()}
            className="px-6 py-3 bg-blue-500 text-white rounded-full hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 disabled:opacity-50 disabled:cursor-not-allowed"
          >
            Send
          </button>
        </form>
      </div>
    </div>
  );
};

export default ChatWindow; 