import React, { useState, useEffect } from 'react';
import { getDailyIncomeReport, DailyIncomeData } from '../../services/reportService';
import { BarChart3, LayoutList, DollarSign, CalendarClock, Users, Calendar } from 'lucide-react';
import { format, parseISO, isValid } from 'date-fns';
import DateRangePicker from './DateRangePicker';
import Card from '../ui/Card';
import { formatCurrency } from '../../utils/formatters';

// No need for extended interface anymore as we'll format dates directly
// interface ExtendedDailyIncomeData extends DailyIncomeData {
//   formattedDate: string;
// }

interface DailyIncomeReportProps {
  startDate: Date;
  endDate: Date;
}

const DailyIncomeReport: React.FC<DailyIncomeReportProps> = ({ startDate, endDate }) => {
  const [reportData, setReportData] = useState<DailyIncomeData[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [viewMode, setViewMode] = useState<'table' | 'chart'>('table');
  
  // Fetch report data
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      setError(null);
      
      try {
        const data = await getDailyIncomeReport(startDate, endDate);
        
        // No need to transform the data anymore
        setReportData(data);
      } catch (err) {
        console.error('Error fetching daily income report:', err);
        setError('Failed to load daily income data.');
      } finally {
        setIsLoading(false);
      }
    };
    
    fetchData();
  }, [startDate, endDate]);
  
  // Format date for display
  const formatDateDisplay = (dateString: string): string => {
    try {
      const date = parseISO(dateString);
      if (isValid(date)) {
        return format(date, 'MMM d, yyyy');
      }
      return dateString;
    } catch (error) {
      console.error('Error formatting date:', error);
      return dateString;
    }
  };
  
  // Calculate summary metrics
  const calculateSummary = () => {
    if (!reportData.length) return { 
      totalVisitIncome: 0, 
      totalAppointmentIncome: 0, 
      totalIncome: 0, 
      totalVisits: 0, 
      totalAppointments: 0 
    };
    
    return reportData.reduce((summary, day) => ({
      totalVisitIncome: summary.totalVisitIncome + day.visitIncome,
      totalAppointmentIncome: summary.totalAppointmentIncome + day.appointmentIncome,
      totalIncome: summary.totalIncome + day.totalIncome,
      totalVisits: summary.totalVisits + day.visitsCount,
      totalAppointments: summary.totalAppointments + day.appointmentsCount
    }), { 
      totalVisitIncome: 0, 
      totalAppointmentIncome: 0, 
      totalIncome: 0, 
      totalVisits: 0, 
      totalAppointments: 0 
    });
  };
  
  const summary = calculateSummary();
  
  // Find day with highest income
  const findHighestIncomeDay = () => {
    if (!reportData.length) return null;
    return reportData.reduce((highest, current) => 
      current.totalIncome > highest.totalIncome ? current : highest, reportData[0]);
  };
  
  const highestIncomeDay = findHighestIncomeDay();
  
  // Render loading state
  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-64">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-700"></div>
      </div>
    );
  }
  
  // Render error state
  if (error) {
    return (
      <div className="bg-red-50 p-4 rounded-md text-red-800">
        <p className="font-medium">Error loading report</p>
        <p className="text-sm mt-1">{error}</p>
      </div>
    );
  }
  
  // Render empty state
  if (!reportData.length) {
    return (
      <div className="text-center py-12">
        <CalendarClock className="h-12 w-12 text-gray-400 mx-auto mb-4" />
        <h3 className="text-lg font-medium text-gray-900">No income data found</h3>
        <p className="text-gray-500 mt-2">
          There is no income data available for the selected date range.
          <br />
          Try selecting a different date range or check back later.
        </p>
      </div>
    );
  }
  
  return (
    <div>
      <div className="mb-6">
        <h2 className="text-xl font-bold text-gray-900">Daily Income Report</h2>
        <p className="text-gray-500 text-sm">
          {format(startDate, 'MMMM d, yyyy')} - {format(endDate, 'MMMM d, yyyy')}
        </p>
      </div>
      
      {/* Summary Cards */}
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-8">
        <div className="bg-white p-4 rounded-lg border border-gray-200 shadow-sm">
          <div className="flex justify-between items-start">
            <div>
              <p className="text-gray-500 text-sm">Total Income</p>
              <p className="text-2xl font-bold text-gray-900">{formatCurrency(summary.totalIncome)}</p>
            </div>
            <div className="p-2 bg-blue-100 rounded-full">
              <DollarSign className="h-5 w-5 text-blue-600" />
            </div>
          </div>
          <div className="mt-1 text-sm text-gray-500">
            {reportData.length > 0 && (
              <>
                {formatCurrency(summary.totalIncome / reportData.length)} daily average
              </>
            )}
          </div>
        </div>
        
        <div className="bg-white p-4 rounded-lg border border-gray-200 shadow-sm">
          <div className="flex justify-between items-start">
            <div>
              <p className="text-gray-500 text-sm">Total Visits</p>
              <p className="text-2xl font-bold text-gray-900">{summary.totalVisits}</p>
            </div>
            <div className="p-2 bg-purple-100 rounded-full">
              <Users className="h-5 w-5 text-purple-600" />
            </div>
          </div>
          <div className="mt-1 text-sm text-gray-500">
            {formatCurrency(summary.totalVisitIncome)} in visit income
          </div>
        </div>
        
        <div className="bg-white p-4 rounded-lg border border-gray-200 shadow-sm">
          <div className="flex justify-between items-start">
            <div>
              <p className="text-gray-500 text-sm">Total Appointments</p>
              <p className="text-2xl font-bold text-gray-900">{summary.totalAppointments}</p>
            </div>
            <div className="p-2 bg-green-100 rounded-full">
              <Calendar className="h-5 w-5 text-green-600" />
            </div>
          </div>
          <div className="mt-1 text-sm text-gray-500">
            {formatCurrency(summary.totalAppointmentIncome)} in appointment income
          </div>
        </div>
      </div>
      
      {/* View Toggle */}
      <div className="flex justify-end mb-4">
        <div className="bg-gray-100 rounded-lg p-1 inline-flex">
          <button
            className={`px-3 py-1 rounded-md text-sm font-medium ${
              viewMode === 'table' 
                ? 'bg-white shadow text-gray-800' 
                : 'text-gray-600 hover:text-gray-800'
            }`}
            onClick={() => setViewMode('table')}
          >
            <div className="flex items-center">
              <LayoutList className="h-4 w-4 mr-1" />
              Table
            </div>
          </button>
          <button
            className={`px-3 py-1 rounded-md text-sm font-medium ${
              viewMode === 'chart' 
                ? 'bg-white shadow text-gray-800' 
                : 'text-gray-600 hover:text-gray-800'
            }`}
            onClick={() => setViewMode('chart')}
          >
            <div className="flex items-center">
              <BarChart3 className="h-4 w-4 mr-1" />
              Chart
            </div>
          </button>
        </div>
      </div>
      
      {/* Table View */}
      {viewMode === 'table' && (
        <div className="bg-white shadow overflow-hidden border border-gray-200 sm:rounded-lg">
          <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Date
                  </th>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Visits
                  </th>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Visit Income
                  </th>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Appointments
                  </th>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Appointment Income
                  </th>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Total Income
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {reportData.map((day) => (
                  <tr key={day.date} className="hover:bg-gray-50">
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm font-medium text-gray-900">{formatDateDisplay(day.date)}</div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                      {day.visitsCount}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm font-medium text-gray-900">{formatCurrency(day.visitIncome)}</div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                      {day.appointmentsCount}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm font-medium text-gray-900">{formatCurrency(day.appointmentIncome)}</div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm font-medium text-gray-900 font-bold">{formatCurrency(day.totalIncome)}</div>
                    </td>
                  </tr>
                ))}
                <tr className="bg-gray-50 font-medium">
                  <td className="px-6 py-4 whitespace-nowrap text-sm">Total</td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm">{summary.totalVisits}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm">{formatCurrency(summary.totalVisitIncome)}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm">{summary.totalAppointments}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm">{formatCurrency(summary.totalAppointmentIncome)}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm font-bold">{formatCurrency(summary.totalIncome)}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      )}
      
      {/* Chart View */}
      {viewMode === 'chart' && (
        <div className="bg-white shadow overflow-hidden border border-gray-200 sm:rounded-lg">
          <div className="p-4">
            <h3 className="text-lg font-medium text-gray-900 mb-4">Income Distribution</h3>
            <div className="flex items-end h-64 mt-8 space-x-2">
              {reportData.map((day) => {
                const maxIncome = Math.max(...reportData.map(d => d.totalIncome));
                const visitBarHeight = maxIncome > 0 
                  ? (day.visitIncome / maxIncome) * 100 
                  : 0;
                const appointmentBarHeight = maxIncome > 0 
                  ? (day.appointmentIncome / maxIncome) * 100 
                  : 0;
                
                return (
                  <div key={day.date} className="flex-1 flex flex-col items-center">
                    <div className="w-full flex justify-center mb-1">
                      <div 
                        className="bg-blue-500 rounded-t-sm w-5/12"
                        style={{ height: `${visitBarHeight}%` }}
                        title={`Visit Income: ${formatCurrency(day.visitIncome)}`}
                      ></div>
                      <div 
                        className="bg-green-500 rounded-t-sm w-5/12"
                        style={{ height: `${appointmentBarHeight}%` }}
                        title={`Appointment Income: ${formatCurrency(day.appointmentIncome)}`}
                      ></div>
                    </div>
                    <div className="text-xs text-gray-500 whitespace-nowrap overflow-hidden text-ellipsis max-w-full rotate-45 origin-left translate-y-6 h-8">
                      {format(new Date(day.date), 'MMM d')}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default DailyIncomeReport; 