import React from 'react';
import { VisitStats } from '../services/visitorService';

interface VisitStatisticsProps {
  stats: VisitStats;
}

const VisitStatistics: React.FC<VisitStatisticsProps> = ({ stats }) => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-8">
      {/* Total Checked In */}
      <div className="bg-white rounded-lg shadow p-6 flex flex-col items-center justify-center transition-all hover:shadow-md">
        <h3 className="text-sm font-medium text-gray-500">Total Checked In Today</h3>
        <p className="mt-2 text-3xl font-semibold text-blue-600">{stats.totalCheckedIn}</p>
      </div>
      
      {/* Visitors In Queue */}
      <div className="bg-white rounded-lg shadow p-6 flex flex-col items-center justify-center transition-all hover:shadow-md">
        <h3 className="text-sm font-medium text-gray-500">Visitors In Queue</h3>
        <p className="mt-2 text-3xl font-semibold text-yellow-500">{stats.inQueue}</p>
      </div>
      
      {/* Completed */}
      <div className="bg-white rounded-lg shadow p-6 flex flex-col items-center justify-center transition-all hover:shadow-md">
        <h3 className="text-sm font-medium text-gray-500">Completed Today</h3>
        <p className="mt-2 text-3xl font-semibold text-indigo-500">{stats.completed}</p>
      </div>
    </div>
  );
};

export default VisitStatistics; 