import React, { useState } from 'react';
import Card from '../components/ui/Card';
import FormInput from '../components/ui/FormInput';
import FormTextArea from '../components/ui/FormTextArea';
import FormSelect from '../components/ui/FormSelect';
import Button from '../components/ui/Button';
import GoogleReviews from '../components/GoogleReviews';

interface ContactFormData {
  fullName: string;
  email: string;
  phone: string;
  subject: string;
  message: string;
}

const ContactPage: React.FC = () => {
  const [formData, setFormData] = useState<ContactFormData>({
    fullName: '',
    email: '',
    phone: '',
    subject: '',
    message: ''
  });
  
  const [errors, setErrors] = useState<Record<string, string>>({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  
  // Target email for messages
  const targetEmail = 'contact@hudumacenter.com';
  
  const subjectOptions = [
    { value: 'General Inquiry', label: 'General Inquiry' },
    { value: 'Service Inquiry', label: 'Service Inquiry' },
    { value: 'Appointment Issue', label: 'Appointment Issue' },
    { value: 'Feedback', label: 'Feedback' },
    { value: 'Partnership Opportunity', label: 'Partnership Opportunity' },
    { value: 'Other', label: 'Other' }
  ];
  
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
    
    // Clear error when field is edited
    if (errors[name]) {
      setErrors(prev => {
        const newErrors = { ...prev };
        delete newErrors[name];
        return newErrors;
      });
    }
  };
  
  const validateForm = (): boolean => {
    const newErrors: Record<string, string> = {};
    
    // Name validation
    if (!formData.fullName.trim()) {
      newErrors.fullName = 'Full name is required';
    }
    
    // Email validation
    if (!formData.email.trim()) {
      newErrors.email = 'Email address is required';
    } else if (!/^\S+@\S+\.\S+$/.test(formData.email)) {
      newErrors.email = 'Please enter a valid email address';
    }
    
    // Subject validation
    if (!formData.subject) {
      newErrors.subject = 'Please select a subject';
    }
    
    // Message validation
    if (!formData.message.trim()) {
      newErrors.message = 'Please enter your message';
    } else if (formData.message.length < 10) {
      newErrors.message = 'Message must be at least 10 characters';
    }
    
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };
  
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    if (!validateForm()) return;
    
    setIsSubmitting(true);
    
    try {
      // In a production environment, you would send this data to your backend API
      // which would handle sending the email to contact@hudumacenter.com
      
      const emailData = {
        to: targetEmail,
        from: formData.email,
        subject: `[Contact Form] ${formData.subject}`,
        replyTo: formData.email,
        message: `
          Name: ${formData.fullName}
          Email: ${formData.email}
          Phone: ${formData.phone || 'Not provided'}
          
          Message:
          ${formData.message}
        `,
        // Add any additional metadata you might need
        metadata: {
          source: 'Website Contact Form',
          timestamp: new Date().toISOString()
        }
      };
      
      console.log('Sending email data:', emailData);
      
      // Simulate API call with timeout
      await new Promise(resolve => setTimeout(resolve, 1500));
      
      // Set success state
      setSubmitSuccess(true);
      
      // Reset form
      setFormData({
        fullName: '',
        email: '',
        phone: '',
        subject: '',
        message: ''
      });
    } catch (error) {
      console.error('Error submitting form:', error);
      setErrors({ form: 'There was an error submitting your message. Please try again.' });
    } finally {
      setIsSubmitting(false);
    }
  };
  
  return (
    <div className="py-12 px-4 sm:px-6 lg:px-8 max-w-7xl mx-auto">
      <div className="text-center mb-12">
        <h1 className="text-4xl font-extrabold text-gray-900 sm:text-5xl">
          Contact Us
        </h1>
        <p className="mt-4 text-xl text-gray-500 max-w-3xl mx-auto">
          Have questions or need assistance? Reach out to our team and we'll be happy to help.
        </p>
      </div>
      
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-10">
        <div className="lg:col-span-1">
          <Card className="h-full">
            <div className="space-y-8">
              <div>
                <h3 className="text-xl font-semibold text-gray-900">Contact Information</h3>
                <p className="mt-2 text-gray-600">Reach out to us through the following channels:</p>
              </div>
              
              <div className="space-y-4">
                <div className="flex items-start">
                  <div className="flex-shrink-0">
                    <svg className="h-6 w-6 text-blue-600" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6" />
                    </svg>
                  </div>
                  <div className="ml-3">
                    <h4 className="text-sm font-medium text-gray-900">Our Office</h4>
                    <p className="mt-1 text-sm text-gray-600">
                      30821 Pacific Hwy S<br />
                      Federal Way, WA 98003<br />
                      Training Centre in Federal Way, Washington
                    </p>
                  </div>
                </div>
                
                <div className="flex items-start">
                  <div className="flex-shrink-0">
                    <svg className="h-6 w-6 text-blue-600" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z" />
                    </svg>
                  </div>
                  <div className="ml-3">
                    <h4 className="text-sm font-medium text-gray-900">Phone</h4>
                    <p className="mt-1 text-sm text-gray-600">(206) 460-9022</p>
                    <p className="mt-1 text-sm text-gray-500">Mon-Fri from 9am to 8pm EST</p>
                    <p className="mt-1 text-sm text-gray-500">Saturday from 11am to 5pm</p>
                    <p className="mt-1 text-sm text-gray-500">Sunday Closed</p>
                  </div>
                </div>
                
                <div className="flex items-start">
                  <div className="flex-shrink-0">
                    <svg className="h-6 w-6 text-blue-600" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
                    </svg>
                  </div>
                  <div className="ml-3">
                    <h4 className="text-sm font-medium text-gray-900">Email</h4>
                    <p className="mt-1 text-sm text-gray-600">{targetEmail}</p>
                    <p className="mt-1 text-sm text-gray-500">We'll respond as soon as possible</p>
                  </div>
                </div>
              </div>
              
              <div>
                <h3 className="text-xl font-semibold text-gray-900">Business Hours</h3>
                <div className="mt-2 space-y-1 text-sm text-gray-600">
                  <p className="flex justify-between"><span>Monday:</span> <span>9 AM - 8 PM</span></p>
                  <p className="flex justify-between"><span>Tuesday:</span> <span>9 AM - 8 PM</span></p>
                  <p className="flex justify-between"><span>Wednesday:</span> <span>9 AM - 8 PM</span></p>
                  <p className="flex justify-between"><span>Thursday:</span> <span>9 AM - 8 PM</span></p>
                  <p className="flex justify-between"><span>Friday:</span> <span>9 AM - 8 PM</span></p>
                  <p className="flex justify-between"><span>Saturday:</span> <span>11 AM - 5 PM</span></p>
                  <p className="flex justify-between"><span>Sunday:</span> <span>Closed</span></p>
                </div>
              </div>
              
              <div>
                <h3 className="text-xl font-semibold text-gray-900">Follow Us</h3>
                <div className="mt-4 flex space-x-6">
                  <a href="#" className="text-gray-400 hover:text-blue-600" aria-label="Facebook">
                    <svg className="h-6 w-6" fill="currentColor" viewBox="0 0 24 24">
                      <path fillRule="evenodd" d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z" clipRule="evenodd" />
                    </svg>
                  </a>
                  <a href="#" className="text-gray-400 hover:text-blue-600" aria-label="Twitter">
                    <svg className="h-6 w-6" fill="currentColor" viewBox="0 0 24 24">
                      <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
                    </svg>
                  </a>
                  <a href="https://g.page/r/CeRbpBBUkl9aEBM/review" className="text-gray-400 hover:text-blue-600" aria-label="Google Reviews">
                    <svg className="h-6 w-6" fill="currentColor" viewBox="0 0 24 24">
                      <path d="M12.48 10.92v3.28h7.84c-.24 1.84-.853 3.187-1.787 4.133-1.147 1.147-2.933 2.4-6.053 2.4-4.827 0-8.6-3.893-8.6-8.72s3.773-8.72 8.6-8.72c2.6 0 4.507 1.027 5.907 2.347l2.307-2.307C18.747 1.44 16.133 0 12.48 0 5.867 0 .307 5.387.307 12s5.56 12 12.173 12c3.573 0 6.267-1.173 8.373-3.36 2.16-2.16 2.84-5.213 2.84-7.667 0-.76-.053-1.467-.173-2.053H12.48z"/>
                    </svg>
                  </a>
                </div>
                <p className="mt-2 text-sm text-gray-600">
                  <a href="https://g.page/r/CeRbpBBUkl9aEBM/review" className="text-blue-600 hover:underline" target="_blank" rel="noopener noreferrer">
                    Leave us a review on Google (5.0/21 reviews)
                  </a>
                </p>
              </div>
            </div>
          </Card>
        </div>
        
        <div className="lg:col-span-2">
          <Card>
            {submitSuccess ? (
              <div className="text-center py-8">
                <div className="mx-auto flex items-center justify-center h-16 w-16 rounded-full bg-green-100 mb-6">
                  <svg className="h-8 w-8 text-green-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                  </svg>
                </div>
                
                <h3 className="text-2xl font-bold text-gray-900 mb-4">Message Sent!</h3>
                <p className="text-gray-600 mb-3">
                  Thank you for reaching out. We've received your message and will get back to you as soon as possible.
                </p>
                <p className="text-gray-600 mb-6">
                  Your message has been sent to <span className="font-semibold">{targetEmail}</span>.
                </p>
                
                <Button onClick={() => setSubmitSuccess(false)}>
                  Send Another Message
                </Button>
              </div>
            ) : (
              <form onSubmit={handleSubmit} className="space-y-6">
                {errors.form && (
                  <div className="bg-red-50 border border-red-200 text-red-700 px-4 py-3 rounded">
                    {errors.form}
                  </div>
                )}
                
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                  <FormInput
                    id="fullName"
                    name="fullName"
                    label="Full Name"
                    value={formData.fullName}
                    onChange={handleInputChange}
                    error={errors.fullName}
                    required
                  />
                  
                  <FormInput
                    id="email"
                    name="email"
                    type="email"
                    label="Email Address"
                    value={formData.email}
                    onChange={handleInputChange}
                    error={errors.email}
                    required
                  />
                </div>
                
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                  <FormInput
                    id="phone"
                    name="phone"
                    type="tel"
                    label="Phone Number (Optional)"
                    value={formData.phone}
                    onChange={handleInputChange}
                  />
                  
                  <FormSelect
                    id="subject"
                    name="subject"
                    label="Subject"
                    value={formData.subject}
                    onChange={handleInputChange}
                    options={subjectOptions}
                    error={errors.subject}
                    required
                  />
                </div>
                
                <FormTextArea
                  id="message"
                  name="message"
                  label="Your Message"
                  value={formData.message}
                  onChange={handleInputChange}
                  rows={6}
                  error={errors.message}
                  required
                />
                
                <div className="pt-4 flex justify-end">
                  <Button
                    type="submit"
                    isLoading={isSubmitting}
                    isDisabled={isSubmitting}
                    size="lg"
                  >
                    {isSubmitting ? 'Sending...' : 'Send Message'}
                  </Button>
                </div>
              </form>
            )}
          </Card>
        </div>
      </div>
      
      {/* Google Reviews Section */}
      <GoogleReviews />
      
      {/* Map Section */}
      <div className="mt-16">
        <div className="rounded-lg overflow-hidden shadow-lg h-96">
          <iframe 
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2693.4126487275866!2d-122.31247852414083!3d47.35435787116921!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x54905937e1693bbb%3A0x4e4a204c9a6600fa!2s30821%20Pacific%20Hwy%20S%2C%20Federal%20Way%2C%20WA%2098003!5e0!3m2!1sen!2sus!4v1638208423206!5m2!1sen!2sus"
            width="100%" 
            height="100%" 
            style={{ border: 0 }} 
            allowFullScreen 
            loading="lazy"
            title="Huduma Center Location"
          ></iframe>
        </div>
      </div>
    </div>
  );
};

export default ContactPage; 