import React, { useEffect, useState } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import Button from '../components/ui/Button';
import Breadcrumbs from '../components/ui/Breadcrumbs';

// Service type definition (same as in catalog)
interface Service {
  id: string;
  title: string;
  shortDescription: string;
  fullDescription: string;
  category: string;
  estimatedTime: string;
  fee: string;
  requiresAppointment: boolean;
  documentsRequired: string[];
  applicationProcess: string[];
  eligibility: string;
  relatedServices: string[];
  icon: string;
}

const ServiceDetailPage: React.FC = () => {
  const { serviceId } = useParams<{ serviceId: string }>();
  const navigate = useNavigate();
  const [service, setService] = useState<Service | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  
  // Fetch service data based on serviceId
  useEffect(() => {
    // Simulating API call with mock data for now
    setLoading(true);
    
    // Sample data - in a real app this would come from an API
    const mockServices: Record<string, Service> = {
      'passport-application': {
        id: 'passport-application',
        title: 'Passport Application Services',
        shortDescription: 'Apply for or renew your passport',
        fullDescription: 'Our passport application services include assistance with first-time applications, renewals, and expedited processing for urgent travel needs. Our trained staff will help you navigate the application process, verify your documents, and ensure your application is complete and accurate before submission.',
        category: 'immigration',
        estimatedTime: '2-3 weeks',
        fee: '$145 - $240',
        requiresAppointment: true,
        documentsRequired: [
          'Proof of citizenship (birth certificate or previous passport)',
          'Government-issued photo ID',
          'Passport photo (2x2 inches)',
          'Completed application form'
        ],
        applicationProcess: [
          'Complete the application form',
          'Gather required documents',
          'Schedule an appointment',
          'Pay the application fee',
          'Attend your appointment',
          'Wait for processing'
        ],
        eligibility: 'U.S. citizens and eligible non-citizens',
        relatedServices: ['visa-application', 'id-verification'],
        icon: 'passport'
      },
      'business-registration': {
        id: 'business-registration',
        title: 'Business Registration',
        shortDescription: 'Register your business, LLC, or corporation',
        fullDescription: 'Complete business registration services including LLC formation, corporation setup, obtaining EINs, and business license applications. Our team can help you choose the right business structure, complete all necessary paperwork, and ensure your business is properly registered with state and federal authorities.',
        category: 'business',
        estimatedTime: '7-10 business days',
        fee: '$125 - $300',
        requiresAppointment: false,
        documentsRequired: [
          'Business name and address',
          'Owner identification',
          'Articles of organization/incorporation',
          'Operating agreement (if applicable)'
        ],
        applicationProcess: [
          'Choose your business structure',
          'Complete the registration forms',
          'Submit your application',
          'Pay registration fees',
          'Receive your business documents'
        ],
        eligibility: 'Anyone looking to start a business entity',
        relatedServices: ['tax-id-application', 'business-license'],
        icon: 'building'
      }
    };
    
    setTimeout(() => {
      if (serviceId && mockServices[serviceId]) {
        setService(mockServices[serviceId]);
        setError(null);
      } else {
        setError('Service not found');
      }
      setLoading(false);
    }, 500);
  }, [serviceId]);
  
  if (loading) {
    return (
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <div className="flex justify-center">
          <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-600"></div>
        </div>
      </div>
    );
  }
  
  if (error || !service) {
    return (
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <div className="bg-white rounded-lg shadow-md p-6 text-center">
          <h1 className="text-2xl font-bold text-red-600 mb-4">Service Not Found</h1>
          <p className="text-gray-600 mb-6">The service you are looking for doesn't exist or has been moved.</p>
          <Button onClick={() => navigate('/services')}>Back to Services</Button>
        </div>
      </div>
    );
  }
  
  return (
    <div className="bg-gray-50 py-8">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        {/* Breadcrumbs */}
        <Breadcrumbs
          items={[
            { label: 'Home', path: '/' },
            { label: 'Services', path: '/services' },
            { label: service.title, path: `/services/${service.id}` }
          ]}
        />
        
        <div className="mt-6">
          {/* Service Header */}
          <div className="bg-white rounded-lg shadow-sm overflow-hidden">
            <div className="bg-blue-700 px-6 py-4 text-white">
              <h1 className="text-2xl font-bold">{service.title}</h1>
              <p className="text-blue-100 mt-1">{service.shortDescription}</p>
            </div>
            
            <div className="p-6">
              {/* Service Overview */}
              <div className="prose max-w-none mb-8">
                <h2 className="text-xl font-semibold text-gray-900 mb-4">Overview</h2>
                <p className="text-gray-700">{service.fullDescription}</p>
              </div>
              
              {/* Service Details */}
              <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-8">
                <div className="bg-gray-50 p-4 rounded-lg border border-gray-200">
                  <h3 className="font-medium text-gray-900 mb-2">Estimated Time</h3>
                  <p className="text-gray-700">{service.estimatedTime}</p>
                </div>
                
                <div className="bg-gray-50 p-4 rounded-lg border border-gray-200">
                  <h3 className="font-medium text-gray-900 mb-2">Fee</h3>
                  <p className="text-gray-700">{service.fee}</p>
                </div>
                
                <div className="bg-gray-50 p-4 rounded-lg border border-gray-200">
                  <h3 className="font-medium text-gray-900 mb-2">Appointment Required</h3>
                  <p className="text-gray-700">{service.requiresAppointment ? 'Yes' : 'No'}</p>
                </div>
              </div>
              
              {/* Required Documents */}
              <div className="mb-8">
                <h2 className="text-xl font-semibold text-gray-900 mb-4">Required Documents</h2>
                <ul className="list-disc list-inside space-y-2 text-gray-700">
                  {service.documentsRequired.map((doc, index) => (
                    <li key={index}>{doc}</li>
                  ))}
                </ul>
              </div>
              
              {/* Application Process */}
              <div className="mb-8">
                <h2 className="text-xl font-semibold text-gray-900 mb-4">Application Process</h2>
                <ol className="relative border-l border-gray-200 ml-3 space-y-6 pt-2">
                  {service.applicationProcess.map((step, index) => (
                    <li key={index} className="ml-6">
                      <span className="absolute flex items-center justify-center w-8 h-8 bg-blue-100 rounded-full -left-4 ring-4 ring-white text-blue-800 font-semibold">{index + 1}</span>
                      <p className="ml-4 text-gray-700">{step}</p>
                    </li>
                  ))}
                </ol>
              </div>
              
              {/* Eligibility */}
              <div className="mb-8">
                <h2 className="text-xl font-semibold text-gray-900 mb-4">Eligibility</h2>
                <p className="text-gray-700">{service.eligibility}</p>
              </div>
              
              {/* Action Buttons */}
              <div className="flex flex-col sm:flex-row sm:justify-end space-y-4 sm:space-y-0 sm:space-x-4 border-t border-gray-200 pt-6">
                <Button
                  href={`/services/${service.id}/apply`}
                  variant="primary"
                  size="lg"
                >
                  Apply Now
                </Button>
                
                {service.requiresAppointment && (
                  <Button
                    href="/appointments/book"
                    variant="outline"
                    size="lg"
                  >
                    Schedule Appointment
                  </Button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServiceDetailPage; 