import React, { useState, useEffect } from 'react';
import { Select, SelectTrigger, SelectValue, SelectContent, SelectItem } from "@/components/ui/select";
import Button from '@/components/ui/Button';
import Card from '@/components/ui/Card';
import { useAuth } from '@/contexts/AuthContext';
import { toast } from 'react-hot-toast';
import FormInput from '@/components/ui/FormInput';
import FormSelect from '@/components/ui/FormSelect';
import {
  LoanReferral,
  LoanReferralStatus,
  LoanType,
  CommissionStatus,
  getClientLoanReferrals,
  createLoanReferral,
  updateLoanReferralStatus,
  addCommissionToLoanReferral,
  updateCommissionStatus,
  calculateTotalCommission,
  calculatePendingCommission
} from '@/firebase/collections/loanReferrals';
import { Timestamp } from 'firebase/firestore';

interface Loan {
  id: string;
  type: string;
  status: string;
  amount?: number;
  date: Date;
  commission?: number;
}

interface Client {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  accountNumber: string;
}

interface LoansTabProps {
  clientId: string;
  client: Client;
  isManager: boolean;
}

const LoansTab: React.FC<LoansTabProps> = ({ clientId, client, isManager }) => {
  const { currentUser } = useAuth();
  const [loanReferrals, setLoanReferrals] = useState<LoanReferral[]>([]);
  const [loading, setLoading] = useState(true);
  const [showReferralForm, setShowReferralForm] = useState(false);
  const [showCommissionForm, setShowCommissionForm] = useState(false);
  const [selectedReferral, setSelectedReferral] = useState<LoanReferral | null>(null);
  
  // Form states
  const [newLoanType, setNewLoanType] = useState<LoanType>('mortgage');
  const [estimatedAmount, setEstimatedAmount] = useState('');
  const [referralNotes, setReferralNotes] = useState('');
  
  // Commission form states
  const [approvedAmount, setApprovedAmount] = useState('');
  const [commissionPercentage, setCommissionPercentage] = useState('2');
  const [commissionNotes, setCommissionNotes] = useState('');
  const [calculatedCommission, setCalculatedCommission] = useState(0);
  
  // Summary metrics
  const [totalApprovedLoans, setTotalApprovedLoans] = useState(0);
  const [totalLoanAmount, setTotalLoanAmount] = useState(0);
  const [totalCommission, setTotalCommission] = useState(0);
  const [pendingCommission, setPendingCommission] = useState(0);

  useEffect(() => {
    fetchLoanReferrals();
  }, [clientId]);

  useEffect(() => {
    calculateSummaryMetrics();
  }, [loanReferrals]);

  useEffect(() => {
    if (approvedAmount && commissionPercentage) {
      const amount = parseFloat(approvedAmount);
      const percentage = parseFloat(commissionPercentage);
      if (!isNaN(amount) && !isNaN(percentage)) {
        setCalculatedCommission((amount * percentage) / 100);
      }
    } else {
      setCalculatedCommission(0);
    }
  }, [approvedAmount, commissionPercentage]);

  const fetchLoanReferrals = async () => {
    if (!clientId) return;
    
    try {
      setLoading(true);
      const referrals = await getClientLoanReferrals(clientId);
      setLoanReferrals(referrals);
    } catch (error) {
      console.error('Error fetching loan referrals:', error);
      toast.error('Failed to load loan referrals');
      setLoanReferrals([]);
    } finally {
      setLoading(false);
    }
  };

  const calculateSummaryMetrics = () => {
    let approvedCount = 0;
    let totalAmount = 0;
    
    loanReferrals.forEach(referral => {
      if (referral.status === 'approved' && referral.approvedAmount) {
        approvedCount++;
        totalAmount += referral.approvedAmount;
      }
    });
    
    setTotalApprovedLoans(approvedCount);
    setTotalLoanAmount(totalAmount);
    setTotalCommission(calculateTotalCommission(loanReferrals));
    setPendingCommission(calculatePendingCommission(loanReferrals));
  };

  const handleSubmitReferral = async (e: React.FormEvent) => {
    e.preventDefault();
    
    if (!currentUser) {
      toast.error('You must be logged in to submit a referral');
      return;
    }
    
    try {
      await createLoanReferral(
        clientId,
        newLoanType,
        estimatedAmount ? parseFloat(estimatedAmount) : undefined,
        currentUser.uid,
        currentUser.displayName || 'Unknown User',
        referralNotes
      );
      
      toast.success('Loan referral submitted successfully');
      setShowReferralForm(false);
      setNewLoanType('mortgage');
      setEstimatedAmount('');
      setReferralNotes('');
      fetchLoanReferrals();
    } catch (error) {
      console.error('Error submitting loan referral:', error);
      toast.error('Failed to submit loan referral');
    }
  };

  const handleUpdateStatus = async (referral: LoanReferral, newStatus: LoanReferralStatus) => {
    if (!currentUser || !isManager) {
      toast.error('Only managers can update referral status');
      return;
    }
    
    try {
      await updateLoanReferralStatus(
        referral.id,
        newStatus,
        currentUser.uid
      );
      
      toast.success(`Status updated to: ${formatStatus(newStatus)}`);
      fetchLoanReferrals();
    } catch (error) {
      console.error('Error updating referral status:', error);
      toast.error('Failed to update status');
    }
  };

  const handleAddCommission = async (e: React.FormEvent) => {
    e.preventDefault();
    
    if (!currentUser || !isManager || !selectedReferral) {
      toast.error('Only managers can add commission information');
      return;
    }
    
    try {
      const amount = parseFloat(approvedAmount);
      const percentage = parseFloat(commissionPercentage);
      
      if (isNaN(amount) || isNaN(percentage)) {
        toast.error('Please enter valid numeric values');
        return;
      }
      
      await addCommissionToLoanReferral(
        selectedReferral.id,
        amount,
        percentage,
        currentUser.uid,
        commissionNotes
      );
      
      toast.success('Commission information added successfully');
      setShowCommissionForm(false);
      setSelectedReferral(null);
      setApprovedAmount('');
      setCommissionPercentage('2');
      setCommissionNotes('');
      setCalculatedCommission(0);
      fetchLoanReferrals();
    } catch (error) {
      console.error('Error adding commission:', error);
      toast.error('Failed to add commission information');
    }
  };

  const handleCommissionStatusChange = async (referral: LoanReferral, status: CommissionStatus) => {
    if (!currentUser || !isManager) {
      toast.error('Only managers can update commission status');
      return;
    }
    
    try {
      await updateCommissionStatus(
        referral.id,
        status,
        currentUser.uid
      );
      
      toast.success(`Commission status updated to: ${formatCommissionStatus(status)}`);
      fetchLoanReferrals();
    } catch (error) {
      console.error('Error updating commission status:', error);
      toast.error('Failed to update commission status');
    }
  };

  const formatDate = (timestamp: Timestamp) => {
    if (!timestamp) return 'N/A';
    
    const date = timestamp.toDate();
    return date.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    });
  };

  const formatStatus = (status: LoanReferralStatus | undefined) => {
    if (!status) return 'Unknown';
    
    const statusMap: Record<LoanReferralStatus, string> = {
      'submitted': 'Submitted',
      'under_review': 'Under Review',
      'client_contacted': 'Client Contacted',
      'approved': 'Approved',
      'rejected': 'Rejected'
    };
    
    return statusMap[status] || 'Unknown';
  };

  const formatCommissionStatus = (status: CommissionStatus | undefined) => {
    if (!status) return 'N/A';
    
    const statusMap: Record<CommissionStatus, string> = {
      'pending': 'Pending',
      'received': 'Received',
      'canceled': 'Canceled'
    };
    
    return statusMap[status] || status;
  };

  const formatLoanType = (type: LoanType) => {
    const typeMap: Record<LoanType, string> = {
      'mortgage': 'Mortgage',
      'personal': 'Personal Loan',
      'business': 'Business Loan',
      'auto': 'Auto Loan',
      'education': 'Education Loan',
      'other': 'Other'
    };
    
    return typeMap[type] || type;
  };

  const getStatusColor = (status: LoanReferralStatus) => {
    const colorMap: Record<LoanReferralStatus, string> = {
      'submitted': 'bg-blue-100 text-blue-800',
      'under_review': 'bg-yellow-100 text-yellow-800',
      'client_contacted': 'bg-purple-100 text-purple-800',
      'approved': 'bg-green-100 text-green-800',
      'rejected': 'bg-red-100 text-red-800'
    };
    
    return colorMap[status] || 'bg-gray-100 text-gray-800';
  };

  const getCommissionStatusColor = (status: CommissionStatus | undefined) => {
    if (!status) return 'bg-gray-100 text-gray-800';
    
    const colorMap: Record<CommissionStatus, string> = {
      'pending': 'bg-yellow-100 text-yellow-800',
      'received': 'bg-green-100 text-green-800',
      'canceled': 'bg-red-100 text-red-800'
    };
    
    return colorMap[status] || 'bg-gray-100 text-gray-800';
  };

  const formatCurrency = (amount: number | undefined) => {
    if (amount === undefined) return 'N/A';
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD'
    }).format(amount);
  };

  const handleOpenCommissionForm = (referral: LoanReferral) => {
    setSelectedReferral(referral);
    if (referral.approvedAmount) {
      setApprovedAmount(referral.approvedAmount.toString());
    }
    if (referral.commissionPercentage) {
      setCommissionPercentage(referral.commissionPercentage.toString());
    }
    if (referral.notes) {
      setCommissionNotes(referral.notes);
    }
    setShowCommissionForm(true);
  };

  if (loading) {
    return (
      <div className="space-y-6">
        {/* Summary Cards */}
        <div className="grid grid-cols-1 md:grid-cols-4 gap-4 mb-6">
          <Card className="p-4">
            <h3 className="font-semibold text-sm text-gray-500">Total Referrals</h3>
            <p className="text-2xl font-bold">{loanReferrals.length}</p>
          </Card>
          <Card className="p-4">
            <h3 className="font-semibold text-sm text-gray-500">Approved Loans</h3>
            <p className="text-2xl font-bold">{totalApprovedLoans}</p>
          </Card>
          <Card className="p-4">
            <h3 className="font-semibold text-sm text-gray-500">Total Commission</h3>
            <p className="text-2xl font-bold">{formatCurrency(totalCommission)}</p>
          </Card>
          <Card className="p-4">
            <h3 className="font-semibold text-sm text-gray-500">Pending Commission</h3>
            <p className="text-2xl font-bold">{formatCurrency(pendingCommission)}</p>
          </Card>
        </div>

        {/* Actions */}
        <div className="flex justify-between items-center">
          <h2 className="text-xl font-bold">Loan & Mortgage Referrals</h2>
          <Button onClick={() => setShowReferralForm(!showReferralForm)}>
            {showReferralForm ? 'Cancel' : 'Add Referral'}
          </Button>
        </div>

        {/* New Referral Form */}
        {showReferralForm && (
          <Card className="p-6">
            <h3 className="text-lg font-semibold mb-4">New Loan Referral</h3>
            <form onSubmit={handleSubmitReferral} className="space-y-4">
              <FormSelect 
                id="loanType"
                name="loanType"
                label="Loan Type" 
                value={newLoanType}
                onChange={(e) => setNewLoanType(e.target.value as LoanType)}
                required
                options={[
                  { value: 'mortgage', label: 'Mortgage' },
                  { value: 'personal', label: 'Personal Loan' },
                  { value: 'business', label: 'Business Loan' },
                  { value: 'auto', label: 'Auto Loan' },
                  { value: 'education', label: 'Education Loan' },
                  { value: 'other', label: 'Other' }
                ]}
              />
              
              <FormInput
                id="estimatedAmount"
                name="estimatedAmount"
                label="Estimated Amount"
                type="number"
                value={estimatedAmount}
                onChange={(e) => setEstimatedAmount(e.target.value)}
                placeholder="Estimated loan amount"
              />
              
              <FormInput
                id="referralNotes"
                name="referralNotes"
                label="Notes"
                type="textarea"
                value={referralNotes}
                onChange={(e) => setReferralNotes(e.target.value)}
                placeholder="Additional details about this referral"
              />
              
              <div className="flex justify-end">
                <Button type="submit">Submit Referral</Button>
              </div>
            </form>
          </Card>
        )}

        {/* Commission Form */}
        {showCommissionForm && selectedReferral && (
          <Card className="p-6">
            <h3 className="text-lg font-semibold mb-4">Add Commission Details</h3>
            <form onSubmit={handleAddCommission} className="space-y-4">
              <FormInput
                id="approvedAmount"
                name="approvedAmount"
                label="Approved Amount"
                type="number"
                value={approvedAmount}
                onChange={(e) => setApprovedAmount(e.target.value)}
                placeholder="Approved loan amount"
                required
              />
              
              <FormInput
                id="commissionPercentage"
                name="commissionPercentage"
                label="Commission Percentage"
                type="number"
                value={commissionPercentage}
                onChange={(e) => setCommissionPercentage(e.target.value)}
                placeholder="Commission percentage"
                required
              />
              
              {/* Commission Preview */}
              <div className="bg-blue-50 p-4 rounded-md">
                <h4 className="text-sm font-medium text-blue-800 mb-2">Commission Preview</h4>
                <div className="flex items-center justify-between">
                  <span className="text-sm text-blue-700">
                    {parseFloat(approvedAmount) > 0 && parseFloat(commissionPercentage) > 0 
                      ? `${commissionPercentage}% of ${formatCurrency(parseFloat(approvedAmount))}`
                      : 'Enter amount and percentage to see preview'
                    }
                  </span>
                  <span className="text-lg font-bold text-blue-800">
                    {formatCurrency(calculatedCommission)}
                  </span>
                </div>
              </div>
              
              <FormInput
                id="commissionNotes"
                name="commissionNotes"
                label="Notes"
                type="textarea"
                value={commissionNotes}
                onChange={(e) => setCommissionNotes(e.target.value)}
                placeholder="Additional notes about commission"
              />
              
              <div className="flex justify-end space-x-2">
                <Button 
                  variant="outline" 
                  onClick={() => {
                    setShowCommissionForm(false);
                    setSelectedReferral(null);
                  }}
                >
                  Cancel
                </Button>
                <Button type="submit">Save Commission Details</Button>
              </div>
            </form>
          </Card>
        )}

        {/* Referrals List */}
        <div className="flex justify-center py-8 items-center space-x-2">
          <svg className="animate-spin h-5 w-5 text-blue-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
          </svg>
          <p>Loading loan referrals...</p>
        </div>
      </div>
    );
  }

  return (
    <div className="space-y-6">
      {/* Summary Cards */}
      <div className="grid grid-cols-1 md:grid-cols-4 gap-4 mb-6">
        <Card className="p-4">
          <h3 className="font-semibold text-sm text-gray-500">Total Referrals</h3>
          <p className="text-2xl font-bold">{loanReferrals.length}</p>
        </Card>
        <Card className="p-4">
          <h3 className="font-semibold text-sm text-gray-500">Approved Loans</h3>
          <p className="text-2xl font-bold">{totalApprovedLoans}</p>
        </Card>
        <Card className="p-4">
          <h3 className="font-semibold text-sm text-gray-500">Total Commission</h3>
          <p className="text-2xl font-bold">{formatCurrency(totalCommission)}</p>
        </Card>
        <Card className="p-4">
          <h3 className="font-semibold text-sm text-gray-500">Pending Commission</h3>
          <p className="text-2xl font-bold">{formatCurrency(pendingCommission)}</p>
        </Card>
      </div>

      {/* Actions */}
      <div className="flex justify-between items-center">
        <h2 className="text-xl font-bold">Loan & Mortgage Referrals</h2>
        <Button onClick={() => setShowReferralForm(!showReferralForm)}>
          {showReferralForm ? 'Cancel' : 'Add Referral'}
        </Button>
      </div>

      {/* New Referral Form */}
      {showReferralForm && (
        <Card className="p-6">
          <h3 className="text-lg font-semibold mb-4">New Loan Referral</h3>
          <form onSubmit={handleSubmitReferral} className="space-y-4">
            <FormSelect 
              id="loanType"
              name="loanType"
              label="Loan Type" 
              value={newLoanType}
              onChange={(e) => setNewLoanType(e.target.value as LoanType)}
              required
              options={[
                { value: 'mortgage', label: 'Mortgage' },
                { value: 'personal', label: 'Personal Loan' },
                { value: 'business', label: 'Business Loan' },
                { value: 'auto', label: 'Auto Loan' },
                { value: 'education', label: 'Education Loan' },
                { value: 'other', label: 'Other' }
              ]}
            />
            
            <FormInput
              id="estimatedAmount"
              name="estimatedAmount"
              label="Estimated Amount"
              type="number"
              value={estimatedAmount}
              onChange={(e) => setEstimatedAmount(e.target.value)}
              placeholder="Estimated loan amount"
            />
            
            <FormInput
              id="referralNotes"
              name="referralNotes"
              label="Notes"
              type="textarea"
              value={referralNotes}
              onChange={(e) => setReferralNotes(e.target.value)}
              placeholder="Additional details about this referral"
            />
            
            <div className="flex justify-end">
              <Button type="submit">Submit Referral</Button>
            </div>
          </form>
        </Card>
      )}

      {/* Commission Form */}
      {showCommissionForm && selectedReferral && (
        <Card className="p-6">
          <h3 className="text-lg font-semibold mb-4">Add Commission Details</h3>
          <form onSubmit={handleAddCommission} className="space-y-4">
            <FormInput
              id="approvedAmount"
              name="approvedAmount"
              label="Approved Amount"
              type="number"
              value={approvedAmount}
              onChange={(e) => setApprovedAmount(e.target.value)}
              placeholder="Approved loan amount"
              required
            />
            
            <FormInput
              id="commissionPercentage"
              name="commissionPercentage"
              label="Commission Percentage"
              type="number"
              value={commissionPercentage}
              onChange={(e) => setCommissionPercentage(e.target.value)}
              placeholder="Commission percentage"
              required
            />
            
            {/* Commission Preview */}
            <div className="bg-blue-50 p-4 rounded-md">
              <h4 className="text-sm font-medium text-blue-800 mb-2">Commission Preview</h4>
              <div className="flex items-center justify-between">
                <span className="text-sm text-blue-700">
                  {parseFloat(approvedAmount) > 0 && parseFloat(commissionPercentage) > 0 
                    ? `${commissionPercentage}% of ${formatCurrency(parseFloat(approvedAmount))}`
                    : 'Enter amount and percentage to see preview'
                  }
                </span>
                <span className="text-lg font-bold text-blue-800">
                  {formatCurrency(calculatedCommission)}
                </span>
              </div>
            </div>
            
            <FormInput
              id="commissionNotes"
              name="commissionNotes"
              label="Notes"
              type="textarea"
              value={commissionNotes}
              onChange={(e) => setCommissionNotes(e.target.value)}
              placeholder="Additional notes about commission"
            />
            
            <div className="flex justify-end space-x-2">
              <Button 
                variant="outline" 
                onClick={() => {
                  setShowCommissionForm(false);
                  setSelectedReferral(null);
                }}
              >
                Cancel
              </Button>
              <Button type="submit">Save Commission Details</Button>
            </div>
          </form>
        </Card>
      )}

      {/* Referrals List */}
      {loanReferrals.length === 0 ? (
        <div className="text-center py-8 bg-gray-50 rounded-lg">
          <p className="text-gray-500">No loan referrals found for this client.</p>
        </div>
      ) : (
        <div className="overflow-x-auto">
          <table className="w-full border-collapse">
            <thead>
              <tr className="bg-gray-50 text-left">
                <th className="px-4 py-2 border-b">Date</th>
                <th className="px-4 py-2 border-b">Type</th>
                <th className="px-4 py-2 border-b">Amount</th>
                <th className="px-4 py-2 border-b">Status</th>
                <th className="px-4 py-2 border-b">Commission</th>
                <th className="px-4 py-2 border-b">Actions</th>
              </tr>
            </thead>
            <tbody>
              {loanReferrals.map((referral) => (
                <tr key={referral.id} className="hover:bg-gray-50">
                  <td className="px-4 py-3 border-b">
                    {formatDate(referral.submittedAt)}
                  </td>
                  <td className="px-4 py-3 border-b">
                    {formatLoanType(referral.loanType)}
                  </td>
                  <td className="px-4 py-3 border-b">
                    {referral.approvedAmount 
                      ? formatCurrency(referral.approvedAmount)
                      : referral.estimatedAmount
                        ? formatCurrency(referral.estimatedAmount) + ' (est.)'
                        : 'Not specified'}
                  </td>
                  <td className="px-4 py-3 border-b">
                    <span className={`px-2 py-1 rounded-full text-xs ${getStatusColor(referral.status)}`}>
                      {formatStatus(referral.status)}
                    </span>
                  </td>
                  <td className="px-4 py-3 border-b">
                    {referral.commissionAmount ? (
                      <div>
                        <p className={`font-medium ${
                          referral.commissionStatus === 'received' 
                            ? 'text-green-600' 
                            : referral.commissionStatus === 'canceled'
                              ? 'text-red-500'
                              : 'text-blue-600'
                        }`}>
                          {formatCurrency(referral.commissionAmount)}
                        </p>
                        <div className="flex items-center justify-between mt-1">
                          <span className="text-xs text-gray-500">
                            {referral.commissionPercentage}% rate
                          </span>
                          <span className={`inline-block px-2 py-0.5 rounded-full text-xs ${getCommissionStatusColor(referral.commissionStatus)}`}>
                            {formatCommissionStatus(referral.commissionStatus)}
                          </span>
                        </div>
                      </div>
                    ) : (
                      referral.status === 'approved' ? (
                        <span className="text-amber-600 text-sm italic">
                          Pending commission
                        </span>
                      ) : (
                        <span className="text-gray-500">—</span>
                      )
                    )}
                  </td>
                  <td className="px-4 py-3 border-b">
                    {isManager && (
                      <div className="flex space-x-2">
                        {/* Status Update Button */}
                        <Select
                          onValueChange={(value: string) => handleUpdateStatus(referral, value as LoanReferralStatus)}
                          value={referral.status}
                        >
                          <SelectTrigger className="w-[140px]">
                            <SelectValue placeholder="Update Status" />
                          </SelectTrigger>
                          <SelectContent>
                            <SelectItem value="submitted">Submitted</SelectItem>
                            <SelectItem value="under_review">Under Review</SelectItem>
                            <SelectItem value="client_contacted">Client Contacted</SelectItem>
                            <SelectItem value="approved">Approved</SelectItem>
                            <SelectItem value="rejected">Rejected</SelectItem>
                          </SelectContent>
                        </Select>
                        
                        {/* Commission Buttons */}
                        {referral.status === 'approved' && (
                          <>
                            {!referral.commissionAmount ? (
                              <Button 
                                size="sm"
                                variant="primary"
                                className="bg-green-600 hover:bg-green-700 text-white flex items-center gap-1.5"
                                onClick={() => handleOpenCommissionForm(referral)}
                              >
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                                </svg>
                                Add Commission
                              </Button>
                            ) : (
                              referral.commissionStatus === 'pending' && (
                                <Button 
                                  size="sm"
                                  variant="outline"
                                  className="border-green-500 text-green-600 hover:bg-green-50"
                                  onClick={() => handleCommissionStatusChange(referral, 'received')}
                                >
                                  Mark Paid
                                </Button>
                              )
                            )}
                          </>
                        )}
                      </div>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default LoansTab; 