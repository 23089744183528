import React, { useState, useMemo, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { Visit, Staff, VisitStatus } from '../services/visitorService';
import { format, isAfter, isBefore, parseISO, startOfDay, endOfDay } from 'date-fns';
import VisitCompletionDialog from './VisitCompletionDialog';

// Define a helper function to convert any timestamp to a Date
const toDate = (timestamp: any): Date => {
  if (!timestamp) {
    return new Date();
  }
  
  // Firebase Timestamp has a toDate() method
  if (timestamp?.toDate && typeof timestamp.toDate === 'function') {
    return timestamp.toDate();
  }
  
  // Already a Date
  if (timestamp instanceof Date) {
    return timestamp;
  }
  
  // String, number, or other format
  try {
    return new Date(timestamp);
  } catch (error) {
    console.error("Error converting timestamp to Date:", error);
    return new Date();
  }
};

interface VisitTableProps {
  visits: Visit[];
  staffMembers: Staff[];
  onAssign: (visitId: string, staffId: string) => void;
  onStatusChange: (visitId: string, status: VisitStatus) => void;
  onComplete: (visitId: string) => void;
  title: string;
  emptyMessage: string;
  isCompletedVisits?: boolean;
  onDateFilterChange?: (startDate: Date, endDate: Date, isActive: boolean) => void;
}

const VisitTable: React.FC<VisitTableProps> = ({
  visits,
  staffMembers,
  onAssign,
  onStatusChange,
  onComplete,
  title,
  emptyMessage,
  isCompletedVisits = false,
  onDateFilterChange
}) => {
  const navigate = useNavigate();
  const [sortField, setSortField] = useState<keyof Visit>('checkInTime');
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('desc');
  const [selectedVisit, setSelectedVisit] = useState<string | null>(null);
  const [selectedStaff, setSelectedStaff] = useState<string>('');
  
  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const visitsPerPage = 8;
  
  // Date filtering state (for completed visits)
  // Initialize with default dates for the last 30 days
  const today = new Date();
  const thirtyDaysAgo = new Date(today);
  thirtyDaysAgo.setDate(today.getDate() - 30);
  
  const [startDate, setStartDate] = useState<string>(format(thirtyDaysAgo, 'yyyy-MM-dd'));
  const [endDate, setEndDate] = useState<string>(format(today, 'yyyy-MM-dd'));
  const [isFilteringByDate, setIsFilteringByDate] = useState(true); // Always start with filtering active
  
  // Visit completion dialog state
  const [completionDialogOpen, setCompletionDialogOpen] = useState(false);
  const [selectedVisitForCompletion, setSelectedVisitForCompletion] = useState<Visit | null>(null);
  
  // Log for debugging
  useEffect(() => {
    if (isCompletedVisits) {
      console.log("Rendering completed visits table:", { visits, isCompletedVisits });
    }
  }, [visits, isCompletedVisits]);
  
  // Apply date filter to parent component when filter changes
  useEffect(() => {
    if (isCompletedVisits && onDateFilterChange && isFilteringByDate) {
      console.log("Notifying parent of date filter change:", { startDate, endDate, isFilteringByDate });
      
      try {
        // Parse the date strings into Date objects
        const start = startOfDay(parseISO(startDate));
        const end = endOfDay(parseISO(endDate));
        
        // Update parent component
        onDateFilterChange(start, end, isFilteringByDate);
      } catch (error) {
        console.error("Error parsing dates for filter change:", error);
      }
    }
  }, [isCompletedVisits]); // Only run when isCompletedVisits changes, not on every filter change
  
  // Reset to first page when visits or filter changes
  useEffect(() => {
    setCurrentPage(1);
  }, [visits]); // Only reset page when visits change
  
  // Initialize filtering state once on mount for completed visits
  useEffect(() => {
    if (isCompletedVisits) {
      console.log("Initializing filter for completed visits - ONE TIME ONLY");
      
      // Set filtering to true by default for completed visits
      setIsFilteringByDate(true);
      
      // Set initial date range (only do this once)
      const today = new Date();
      const thirtyDaysAgo = new Date(today);
      thirtyDaysAgo.setDate(today.getDate() - 30);
      
      setStartDate(format(thirtyDaysAgo, 'yyyy-MM-dd'));
      setEndDate(format(today, 'yyyy-MM-dd'));
      
      // Don't call onDateFilterChange here - it will be called in the handleApplyFilter
    }
  }, []); // Empty dependency array means this only runs once on mount
  
  // Handle sorting
  const handleSort = (field: keyof Visit) => {
    if (field === sortField) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortField(field);
      setSortDirection('asc');
    }
  };
  
  // Filter visits by date range (for completed visits)
  const filteredVisits = useMemo(() => {
    if (!isCompletedVisits || !isFilteringByDate) {
      return visits;
    }
    
    console.log("Filtering in component:", { startDate, endDate });
    
    try {
      const start = startOfDay(parseISO(startDate));
      const end = endOfDay(parseISO(endDate));
      
      // Filter locally for immediate feedback
      return visits.filter(visit => {
        if (!visit.checkInTime) return false;
        
        // Handle Firebase Timestamp objects
        const visitDate = toDate(visit.checkInTime);
        const isAfterStart = isAfter(visitDate, start) || visitDate.getTime() === start.getTime();
        const isBeforeEnd = isBefore(visitDate, end) || visitDate.getTime() === end.getTime();
        
        console.log(`Visit ${visit.id} date:`, visitDate, 
                    "isAfterStart:", isAfterStart, 
                    "isBeforeEnd:", isBeforeEnd);
        
        return isAfterStart && isBeforeEnd;
      });
    } catch (error) {
      console.error("Error filtering visits by date:", error);
      return visits;
    }
  }, [visits, startDate, endDate, isFilteringByDate, isCompletedVisits]);
  
  // Get sorted and filtered visits
  const sortedVisits = useMemo(() => {
    return [...filteredVisits].sort((a, b) => {
      if (sortField === 'checkInTime') {
        const timeA = toDate(a.checkInTime).getTime();
        const timeB = toDate(b.checkInTime).getTime();
        return sortDirection === 'asc' ? timeA - timeB : timeB - timeA;
      } else if (sortField === 'visitorName' || sortField === 'service' || sortField === 'phoneNumber') {
        const valueA = a[sortField].toLowerCase();
        const valueB = b[sortField].toLowerCase();
        return sortDirection === 'asc'
          ? valueA.localeCompare(valueB)
          : valueB.localeCompare(valueA);
      } else if (sortField === 'status') {
        return sortDirection === 'asc'
          ? a.status.localeCompare(b.status)
          : b.status.localeCompare(a.status);
      } else if (sortField === 'waitingTime') {
        const timeA = a.waitingTime || 0;
        const timeB = b.waitingTime || 0;
        return sortDirection === 'asc' ? timeA - timeB : timeB - timeA;
      }
      return 0;
    });
  }, [filteredVisits, sortField, sortDirection]);
  
  // Pagination calculation
  const indexOfLastVisit = currentPage * visitsPerPage;
  const indexOfFirstVisit = indexOfLastVisit - visitsPerPage;
  const currentVisits = sortedVisits.slice(indexOfFirstVisit, indexOfLastVisit);
  const totalPages = Math.ceil(sortedVisits.length / visitsPerPage);
  
  // Change page
  const paginate = (pageNumber: number) => setCurrentPage(pageNumber);
  
  // Get status badge style
  const getStatusBadgeStyle = (status: VisitStatus) => {
    switch (status) {
      case 'waiting':
        return 'bg-yellow-100 text-yellow-800';
      case 'being-served':
        return 'bg-green-100 text-green-800';
      case 'on-hold':
        return 'bg-orange-100 text-orange-800';
      case 'completed':
        return 'bg-blue-100 text-blue-800';
      case 'abandoned':
        return 'bg-red-100 text-red-800';
      default:
        return 'bg-gray-100 text-gray-800';
    }
  };
  
  // Format status for display
  const formatStatus = (status: VisitStatus) => {
    return status.split('-').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
  };
  
  // Format time for display
  const formatTime = (timestamp: any) => {
    if (!timestamp) return '';
    
    try {
      return format(toDate(timestamp), 'h:mm a');
    } catch (error) {
      console.error('Error formatting time:', error);
      return '';
    }
  };
  
  // Format date for display
  const formatDate = (timestamp: any) => {
    if (!timestamp) return '';
    
    try {
      return format(toDate(timestamp), 'MMM d, yyyy');
    } catch (error) {
      console.error('Error formatting date:', error);
      return '';
    }
  };
  
  // Handle assign staff click
  const handleAssignClick = (visitId: string) => {
    setSelectedVisit(visitId);
    setSelectedStaff('');
  };
  
  // Handle submit assignment
  const handleSubmitAssignment = () => {
    if (selectedVisit && selectedStaff) {
      onAssign(selectedVisit, selectedStaff);
      setSelectedVisit(null);
      setSelectedStaff('');
    }
  };
  
  // Handle cancel assignment
  const handleCancelAssignment = () => {
    setSelectedVisit(null);
    setSelectedStaff('');
  };
  
  // Handle row click to navigate to visitor details
  const handleRowClick = (visitId: string) => {
    navigate(`/visits/${visitId}`);
  };
  
  // Helper function to get sort icon
  const getSortIcon = (field: keyof Visit) => {
    if (field !== sortField) return null;
    
    return sortDirection === 'asc' 
      ? <span className="ml-1">↑</span> 
      : <span className="ml-1">↓</span>;
  };
  
  // Handle apply filter
  const handleApplyFilter = useCallback(() => {
    console.log("User clicked Apply Filter button with dates:", { startDate, endDate });
    
    try {
      // Validate dates
      const start = parseISO(startDate);
      const end = parseISO(endDate);
      
      if (isNaN(start.getTime()) || isNaN(end.getTime())) {
        console.error("Invalid date format");
        return;
      }
      
      // Ensure start date is not after end date
      if (isAfter(start, end)) {
        console.warn("Start date is after end date - swapping dates");
        // Swap dates
        setStartDate(endDate);
        setEndDate(startDate);
        
        // Use swapped dates for the filter
        const tempStart = end;
        const tempEnd = start;
        
        // Set filtering state
        setIsFilteringByDate(true);
        setCurrentPage(1); // Reset to first page when filter changes
        
        // Notify parent if available with the corrected dates
        if (onDateFilterChange) {
          console.log("Manually applying filter with swapped dates:", { 
            start: tempStart.toISOString(), 
            end: tempEnd.toISOString()
          });
          onDateFilterChange(tempStart, tempEnd, true);
        }
        return;
      }
      
      // Set filtering state
      setIsFilteringByDate(true);
      setCurrentPage(1); // Reset to first page when filter changes
      
      // Notify parent if available
      if (onDateFilterChange) {
        console.log("Manually applying filter:", { 
          start: start.toISOString(), 
          end: end.toISOString()
        });
        
        onDateFilterChange(start, end, true);
      }
    } catch (error) {
      console.error("Error parsing dates for filter:", error);
    }
  }, [startDate, endDate, onDateFilterChange]);
  
  // Reset filter function
  const handleResetFilter = useCallback(() => {
    console.log("User clicked Reset Filter button");
    
    // Set dates to a wide range - last 30 days
    const today = new Date();
    const thirtyDaysAgo = new Date(today);
    thirtyDaysAgo.setDate(today.getDate() - 30);
    
    // Update local state
    setStartDate(format(thirtyDaysAgo, 'yyyy-MM-dd'));
    setEndDate(format(today, 'yyyy-MM-dd'));
    setIsFilteringByDate(true);
    setCurrentPage(1); // Reset to first page
    
    // Manually notify parent
    if (onDateFilterChange) {
      console.log("Manually resetting filter to 30-day range:", {
        start: thirtyDaysAgo.toISOString(),
        end: today.toISOString()
      });
      
      onDateFilterChange(thirtyDaysAgo, today, true);
    }
  }, [onDateFilterChange]);
  
  // Handle opening completion dialog
  const handleOpenCompletionDialog = (visit: Visit) => {
    setSelectedVisitForCompletion(visit);
    setCompletionDialogOpen(true);
  };
  
  // Handle completion dialog close
  const handleCompletionDialogClose = () => {
    setCompletionDialogOpen(false);
    setSelectedVisitForCompletion(null);
  };
  
  // Handle completion success
  const handleCompletionSuccess = () => {
    if (selectedVisitForCompletion) {
      onComplete(selectedVisitForCompletion.id);
    }
    handleCompletionDialogClose();
  };
  
  // Log component render with props
  console.log("VisitTable render:", { 
    isCompletedVisits, 
    title, 
    visitsCount: visits.length, 
    filteredVisitsCount: filteredVisits.length,
    sortedVisitsCount: sortedVisits.length,
    currentVisitsCount: currentVisits.length,
    isFilteringByDate,
    startDate,
    endDate 
  });

  // We'll add a debug message if the filter is active but filtering to 0 results
  const showFilterDebugMessage = isCompletedVisits && isFilteringByDate && 
    filteredVisits.length === 0 && visits.length > 0;
    
  // Check if we're in a loading state (no visits yet but filter is active)
  const isLoading = false; // Remove the custom loading state as it's causing issues

  return (
    <div className="bg-white rounded-lg shadow overflow-hidden">
      <div className="p-4 border-b border-gray-200 flex flex-col md:flex-row justify-between items-start md:items-center gap-4">
        <h2 className="text-lg font-semibold text-gray-800">{title}</h2>
        
        {/* Date Filter UI - always visible for completed visits */}
        {isCompletedVisits && (
          <div className="flex flex-wrap items-center gap-2 w-full md:w-auto">
            <div className={`flex flex-wrap items-end gap-2 p-2 rounded-md border shadow-sm ${isFilteringByDate ? 'bg-blue-100 border-blue-300' : 'bg-blue-50 border-blue-100'}`}>
              <div>
                <label htmlFor="startDate" className="block text-xs font-medium text-gray-700 mb-1">From:</label>
                <input
                  type="date"
                  id="startDate"
                  value={startDate}
                  onChange={(e) => setStartDate(e.target.value)}
                  className="px-2 py-1 text-sm border border-gray-300 rounded shadow-sm focus:ring-blue-500 focus:border-blue-500"
                  aria-label="Filter start date"
                />
              </div>
              
              <div>
                <label htmlFor="endDate" className="block text-xs font-medium text-gray-700 mb-1">To:</label>
                <input
                  type="date"
                  id="endDate"
                  value={endDate}
                  onChange={(e) => setEndDate(e.target.value)}
                  className="px-2 py-1 text-sm border border-gray-300 rounded shadow-sm focus:ring-blue-500 focus:border-blue-500"
                  aria-label="Filter end date"
                />
              </div>
              
              <div className="flex items-end gap-2">
                <button
                  onClick={handleApplyFilter}
                  className="px-3 py-1 text-sm bg-blue-600 hover:bg-blue-700 text-white rounded font-medium"
                  aria-label="Apply date filter"
                >
                  Apply
                </button>
                
                <button
                  onClick={handleResetFilter}
                  className="px-3 py-1 text-sm bg-gray-200 hover:bg-gray-300 text-gray-800 rounded"
                  aria-label="Reset date filter"
                >
                  Reset
                </button>
              </div>
            </div>
            
            <div className="text-sm text-gray-500 ml-auto">
              {filteredVisits.length} {filteredVisits.length === 1 ? 'visitor' : 'visitors'}
            </div>
          </div>
        )}
        
        {!isCompletedVisits && (
          <span className="text-sm text-gray-500">
            {visits.length} {visits.length === 1 ? 'visitor' : 'visitors'}
          </span>
        )}
      </div>
      
      {/* Debug information */}
      {showFilterDebugMessage && (
        <div className="bg-yellow-50 p-3 border-b border-yellow-200">
          <p className="text-sm text-yellow-800">
            <strong>Filter active but no results found.</strong> Try adjusting your date range or clicking Reset.
            <br />
            <span className="text-xs">
              Current filter: {startDate} to {endDate} 
              ({isFilteringByDate ? 'Active' : 'Inactive'})
            </span>
          </p>
        </div>
      )}
      
      {isLoading ? (
        <div className="p-6 text-center">
          <div className="inline-block animate-spin rounded-full h-8 w-8 border-b-2 border-blue-600 mb-2"></div>
          <p className="text-gray-500">Loading visits data...</p>
        </div>
      ) : currentVisits.length === 0 ? (
        <div className="p-6 text-center text-gray-500">{emptyMessage}</div>
      ) : (
        <>
          <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th 
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
                    onClick={() => handleSort('visitorName')}
                  >
                    <div className="flex items-center">
                      Visitor
                      {getSortIcon('visitorName')}
                    </div>
                  </th>
                  <th 
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
                    onClick={() => handleSort('service')}
                  >
                    <div className="flex items-center">
                      Service
                      {getSortIcon('service')}
                    </div>
                  </th>
                  <th 
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
                    onClick={() => handleSort('status')}
                  >
                    <div className="flex items-center">
                      Status
                      {getSortIcon('status')}
                    </div>
                  </th>
                  <th 
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Assigned Staff
                  </th>
                  <th 
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
                    onClick={() => handleSort('waitingTime')}
                  >
                    <div className="flex items-center">
                      Waiting Time
                      {getSortIcon('waitingTime')}
                    </div>
                  </th>
                  <th 
                    className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {currentVisits.map((visit) => (
                  <tr 
                    key={visit.id} 
                    className="hover:bg-gray-50 cursor-pointer"
                    onClick={() => handleRowClick(visit.id)}
                  >
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="flex items-center">
                        <div>
                          <div className="text-sm font-medium text-gray-900">{visit.visitorName}</div>
                          <div className="text-sm text-gray-500">{visit.phoneNumber}</div>
                        </div>
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm text-gray-900">{visit.service}</div>
                      <div className="text-sm text-gray-500">
                        {formatTime(visit.checkInTime)}
                        {isCompletedVisits && (
                          <span className="ml-1">({formatDate(visit.checkInTime)})</span>
                        )}
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${getStatusBadgeStyle(visit.status)}`}>
                        {formatStatus(visit.status)}
                      </span>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {visit.assignedStaff || 'Unassigned'}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {visit.waitingTime ? `${visit.waitingTime} min` : '-'}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium" onClick={(e) => e.stopPropagation()}>
                      {selectedVisit === visit.id ? (
                        <div className="flex items-center justify-end space-x-2">
                          <select
                            className="text-sm border border-gray-300 rounded-md p-1.5"
                            value={selectedStaff}
                            onChange={(e) => setSelectedStaff(e.target.value)}
                            aria-label="Select staff member"
                          >
                            <option value="">Select staff...</option>
                            {staffMembers && staffMembers.length > 0 ? (
                              staffMembers
                                .filter(staff => staff.available)
                                .map(staff => (
                                  <option key={staff.id} value={staff.id}>
                                    {staff.name} ({staff.role || 'Staff'})
                                  </option>
                                ))
                            ) : (
                              <option value="" disabled>No staff members available</option>
                            )}
                          </select>
                          <button
                            onClick={handleSubmitAssignment}
                            disabled={!selectedStaff}
                            className="bg-blue-100 text-blue-800 px-2 py-1 rounded text-xs disabled:opacity-50"
                            aria-label="Assign staff"
                          >
                            Assign
                          </button>
                          <button
                            onClick={handleCancelAssignment}
                            className="bg-gray-100 text-gray-800 px-2 py-1 rounded text-xs"
                            aria-label="Cancel assignment"
                          >
                            Cancel
                          </button>
                        </div>
                      ) : (
                        <div className="flex items-center justify-end space-x-2">
                          <button
                            onClick={(e) => {
                              e.stopPropagation();
                              navigate(`/visits/${visit.id}`);
                            }}
                            className="text-blue-600 hover:text-blue-900 text-xs bg-blue-50 px-2 py-1 rounded mr-2"
                            aria-label={`View details for ${visit.visitorName}`}
                          >
                            View
                          </button>
                          
                          {!visit.assignedStaff && !isCompletedVisits && (
                            <button
                              onClick={(e) => {
                                e.stopPropagation();
                                handleAssignClick(visit.id);
                              }}
                              className="text-blue-600 hover:text-blue-900 text-xs bg-blue-100 px-2 py-1 rounded"
                              aria-label="Assign staff to visitor"
                            >
                              Assign
                            </button>
                          )}
                          
                          {visit.status === 'waiting' && visit.assignedStaff && !isCompletedVisits && (
                            <button
                              onClick={(e) => {
                                e.stopPropagation();
                                onStatusChange(visit.id, 'being-served');
                              }}
                              className="text-green-600 hover:text-green-900 text-xs bg-green-100 px-2 py-1 rounded"
                              aria-label="Start serving visitor"
                            >
                              Start
                            </button>
                          )}
                          
                          {visit.status === 'being-served' && !isCompletedVisits && (
                            <button
                              onClick={(e) => {
                                e.stopPropagation();
                                onStatusChange(visit.id, 'on-hold');
                              }}
                              className="text-orange-600 hover:text-orange-900 text-xs bg-orange-100 px-2 py-1 rounded"
                              aria-label="Put visitor on hold"
                            >
                              Hold
                            </button>
                          )}
                          
                          {visit.status === 'on-hold' && !isCompletedVisits && (
                            <button
                              onClick={(e) => {
                                e.stopPropagation();
                                onStatusChange(visit.id, 'being-served');
                              }}
                              className="text-green-600 hover:text-green-900 text-xs bg-green-100 px-2 py-1 rounded"
                              aria-label="Resume serving visitor"
                            >
                              Resume
                            </button>
                          )}
                          
                          {(visit.status === 'being-served' || visit.status === 'on-hold') && !isCompletedVisits && (
                            <button
                              onClick={(e) => {
                                e.stopPropagation();
                                handleOpenCompletionDialog(visit);
                              }}
                              className="text-indigo-600 hover:text-indigo-900 text-xs bg-indigo-100 px-2 py-1 rounded"
                              aria-label="Mark visit as complete"
                            >
                              Complete
                            </button>
                          )}
                          
                          {visit.status === 'waiting' && !visit.assignedStaff && !isCompletedVisits && (
                            <button
                              onClick={(e) => {
                                e.stopPropagation();
                                onStatusChange(visit.id, 'abandoned');
                              }}
                              className="text-red-600 hover:text-red-900 text-xs bg-red-100 px-2 py-1 rounded"
                              aria-label="Mark visitor as abandoned"
                            >
                              Abandon
                            </button>
                          )}
                        </div>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          
          {/* Pagination */}
          {sortedVisits.length > visitsPerPage && (
            <div className="px-6 py-3 flex items-center justify-between border-t border-gray-200">
              <div className="flex-1 flex justify-between sm:hidden">
                <button
                  onClick={() => paginate(currentPage > 1 ? currentPage - 1 : 1)}
                  disabled={currentPage === 1}
                  className={`relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md ${
                    currentPage === 1 
                      ? 'bg-gray-100 text-gray-400 cursor-not-allowed' 
                      : 'bg-white text-gray-700 hover:bg-gray-50'
                  }`}
                >
                  Previous
                </button>
                <button
                  onClick={() => paginate(currentPage < totalPages ? currentPage + 1 : totalPages)}
                  disabled={currentPage === totalPages}
                  className={`ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md ${
                    currentPage === totalPages 
                      ? 'bg-gray-100 text-gray-400 cursor-not-allowed' 
                      : 'bg-white text-gray-700 hover:bg-gray-50'
                  }`}
                >
                  Next
                </button>
              </div>
              <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
                <div>
                  <p className="text-sm text-gray-700">
                    Showing <span className="font-medium">{indexOfFirstVisit + 1}</span> to{' '}
                    <span className="font-medium">{Math.min(indexOfLastVisit, sortedVisits.length)}</span> of{' '}
                    <span className="font-medium">{sortedVisits.length}</span> results
                  </p>
                </div>
                <div>
                  <nav className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px" aria-label="Pagination">
                    <button
                      onClick={() => paginate(currentPage > 1 ? currentPage - 1 : 1)}
                      disabled={currentPage === 1}
                      className={`relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 text-sm font-medium ${
                        currentPage === 1 
                          ? 'bg-gray-100 text-gray-400 cursor-not-allowed' 
                          : 'bg-white text-gray-500 hover:bg-gray-50'
                      }`}
                    >
                      <span className="sr-only">Previous</span>
                      <svg className="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                        <path fillRule="evenodd" d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z" clipRule="evenodd" />
                      </svg>
                    </button>
                    
                    {[...Array(totalPages)].map((_, i) => (
                      <button
                        key={i}
                        onClick={() => paginate(i + 1)}
                        className={`relative inline-flex items-center px-4 py-2 border ${
                          currentPage === i + 1
                            ? 'bg-blue-50 border-blue-500 text-blue-600 z-10'
                            : 'bg-white border-gray-300 text-gray-500 hover:bg-gray-50'
                        } text-sm font-medium`}
                      >
                        {i + 1}
                      </button>
                    ))}
                    
                    <button
                      onClick={() => paginate(currentPage < totalPages ? currentPage + 1 : totalPages)}
                      disabled={currentPage === totalPages}
                      className={`relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 text-sm font-medium ${
                        currentPage === totalPages 
                          ? 'bg-gray-100 text-gray-400 cursor-not-allowed' 
                          : 'bg-white text-gray-500 hover:bg-gray-50'
                      }`}
                    >
                      <span className="sr-only">Next</span>
                      <svg className="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                        <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" />
                      </svg>
                    </button>
                  </nav>
                </div>
              </div>
            </div>
          )}
        </>
      )}
      
      {/* Visit Completion Dialog */}
      {selectedVisitForCompletion && (
        <VisitCompletionDialog
          visitId={selectedVisitForCompletion.id}
          visitorName={selectedVisitForCompletion.visitorName}
          isOpen={completionDialogOpen}
          onClose={handleCompletionDialogClose}
          onSuccess={handleCompletionSuccess}
        />
      )}
    </div>
  );
};

export default VisitTable; 