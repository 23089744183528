import { 
  collection, 
  query, 
  where, 
  getDocs, 
  Timestamp,
  orderBy,
  startAt,
  endAt,
  DocumentData
} from 'firebase/firestore';
import { db } from '../firebase/config';
import { Visit, VisitCompletion, Staff } from './visitorService';
import { format, isWithinInterval, parseISO, differenceInDays, addDays, startOfDay, endOfDay, set } from 'date-fns';

// Interface for staff performance metrics
export interface StaffPerformanceMetrics {
  staffId: string;
  name: string;
  tasksCompleted: number;
  visitsCompleted: number;
  appointmentsCompleted: number;
  totalIncome: number;
  averageRating: number;
  averageServiceTime: number;
}

// Interface for daily income data
export interface DailyIncomeData {
  date: string;
  visitsCount: number;
  visitIncome: number;
  appointmentsCount: number;
  appointmentIncome: number;
  totalIncome: number;
}

// Interface for service performance
export interface ServicePerformanceData {
  serviceId: string;
  serviceName: string;
  visitsCount: number;
  income: number;
  averageWaitTime: number;
}

// Interface for conversion rates
export interface ConversionRateData {
  metricName: string;
  rate: number;
  count: number;
  total: number;
}

// Interface for business summary data
export interface BusinessSummaryData {
  totalCustomers: number;
  newCustomers: number;
  repeatCustomers: number;
  topService: string;
  topServiceCount: number;
  topStaff: string;
  topStaffCount: number;
  peakHours: { hour: number; count: number; label: string }[];
}

// Helper function to convert Firestore timestamp to Date
const timestampToDate = (timestamp: any): Date => {
  if (timestamp instanceof Timestamp) {
    return timestamp.toDate();
  } else if (timestamp?.seconds) {
    return new Date(timestamp.seconds * 1000);
  } else if (typeof timestamp === 'string') {
    return parseISO(timestamp);
  }
  return new Date(timestamp);
};

/**
 * Get staff performance metrics within a date range
 */
export const getStaffPerformanceReport = async (
  startDate: Date,
  endDate: Date
): Promise<StaffPerformanceMetrics[]> => {
  try {
    console.log(`Fetching staff performance data from ${startDate.toISOString()} to ${endDate.toISOString()}`);
    
    // Initialize a map to store staff metrics
    const staffMap = new Map<string, StaffPerformanceMetrics>();
    
    // Convert dates to Firestore Timestamps for direct comparison in query
    const startTimestamp = Timestamp.fromDate(startOfDay(startDate));
    const endTimestamp = Timestamp.fromDate(endOfDay(endDate));
    
    // 1. Query completed visits from the registrations collection 
    // (this is the main collection where staff assignments are stored)
    const registrationsQuery = query(
      collection(db, 'registrations'),
      where('status', '==', 'completed'),
      where('checkInTime', '>=', startTimestamp),
      where('checkInTime', '<=', endTimestamp)
    );
    
    const registrationsSnapshot = await getDocs(registrationsQuery);
    console.log(`Retrieved ${registrationsSnapshot.size} completed registrations`);
    
    registrationsSnapshot.forEach(doc => {
      const regData = doc.data();
      
      // Skip if no staff assigned
      if (!regData.staffId && !regData.assignedStaff) {
        return;
      }
      
      // Extract staff data
      const staffId = regData.staffId || 'unknown';
      const staffName = regData.assignedStaff || 'Unknown Staff';
      
      // Initialize staff metrics if not exists
      if (!staffMap.has(staffId)) {
        staffMap.set(staffId, {
          staffId,
          name: staffName,
          tasksCompleted: 0,
          visitsCompleted: 0,
          appointmentsCompleted: 0,
          totalIncome: 0,
          averageRating: 0,
          averageServiceTime: 0
        });
      }
      
      const staffMetrics = staffMap.get(staffId)!;
      staffMetrics.tasksCompleted += 1;
      staffMetrics.visitsCompleted += 1;
    });
    
    // 2. Query visitCompletions collection for payment details
    const visitCompletionsQuery = query(
      collection(db, 'visitCompletions'),
      where('completedAt', '>=', startTimestamp),
      where('completedAt', '<=', endTimestamp)
    );
    
    const visitCompletionsSnapshot = await getDocs(visitCompletionsQuery);
    console.log(`Retrieved ${visitCompletionsSnapshot.size} visit completion records`);
    
    // Create a map to track visit IDs we've processed to avoid double counting
    const processedVisitIds = new Set<string>();
    
    visitCompletionsSnapshot.forEach(doc => {
      const completionData = doc.data();
      const visitId = completionData.visitId;
      
      // Skip if we've already processed this visit or no visit ID
      if (!visitId || processedVisitIds.has(visitId)) {
        return;
      }
      
      processedVisitIds.add(visitId);
      
      // Try to get staff information from the completion data
      // Note: The completion data might not have staff info directly,
      // so we will associate it based on who completed it
      const staffId = completionData.completedBy || 'unknown';
      const staffName = completionData.completedByName || 'Unknown Staff';
      
      // Initialize staff metrics if not exists
      if (!staffMap.has(staffId)) {
        staffMap.set(staffId, {
          staffId,
          name: staffName,
          tasksCompleted: 0,
          visitsCompleted: 0,
          appointmentsCompleted: 0,
          totalIncome: 0,
          averageRating: 0,
          averageServiceTime: 0
        });
      }
      
      const staffMetrics = staffMap.get(staffId)!;
      
      // Calculate income if available
      if (completionData.serviceRendered && completionData.wasCharged && completionData.amountCharged) {
        const amount = Number(completionData.amountCharged) || 0;
        staffMetrics.totalIncome += amount;
      }
    });
    
    // 3. Query completed visits from the visits collection (as a backup)
    const visitsQuery = query(
      collection(db, 'visits'),
      where('status', '==', 'completed'),
      where('completedAt', '>=', startTimestamp),
      where('completedAt', '<=', endTimestamp)
    );
    
    const visitsSnapshot = await getDocs(visitsQuery);
    console.log(`Retrieved ${visitsSnapshot.size} completed visits from visits collection`);
    
    visitsSnapshot.forEach(doc => {
      const visitData = doc.data();
      
      // Skip if no staff assigned
      if (!visitData.assignedStaff?.id && !visitData.staffId) {
        return;
      }
      
      // Skip if we've already processed this visit
      if (processedVisitIds.has(doc.id)) {
        return;
      }
      
      // Extract staff data
      const staffId = visitData.assignedStaff?.id || visitData.staffId || 'unknown';
      const staffName = visitData.assignedStaff?.name || visitData.staffName || 'Unknown Staff';
      
      // Initialize staff metrics if not exists
      if (!staffMap.has(staffId)) {
        staffMap.set(staffId, {
          staffId,
          name: staffName,
          tasksCompleted: 0,
          visitsCompleted: 0,
          appointmentsCompleted: 0,
          totalIncome: 0,
          averageRating: 0,
          averageServiceTime: 0
        });
      }
      
      const staffMetrics = staffMap.get(staffId)!;
      staffMetrics.tasksCompleted += 1;
      staffMetrics.visitsCompleted += 1;
      
      // Calculate income if available
      if (visitData.paymentDetails?.amount) {
        const amount = Number(visitData.paymentDetails.amount) || 0;
        staffMetrics.totalIncome += amount;
      }
      
      // Calculate rating if available
      if (visitData.feedback && visitData.feedback.rating) {
        const prevTotalRating = staffMetrics.averageRating * (staffMetrics.visitsCompleted - 1);
        const rating = Number(visitData.feedback.rating) || 0;
        staffMetrics.averageRating = (prevTotalRating + rating) / staffMetrics.visitsCompleted;
      }
      
      // Calculate service time if check-in and check-out times are available
      if (visitData.checkInTime && visitData.completedAt) {
        const checkInTime = timestampToDate(visitData.checkInTime);
        const checkOutTime = timestampToDate(visitData.completedAt);
        
        let serviceTime = 0;
        
        if (checkInTime && checkOutTime) {
          serviceTime = Math.round((checkOutTime.getTime() - checkInTime.getTime()) / (1000 * 60)); // in minutes
        }
        
        // Only include positive service times
        if (serviceTime > 0) {
          const prevTotalTime = staffMetrics.averageServiceTime * (staffMetrics.visitsCompleted - 1);
          staffMetrics.averageServiceTime = (prevTotalTime + serviceTime) / staffMetrics.visitsCompleted;
        }
      }
    });
    
    // 4. Query completed appointments within date range
    const appointmentsQuery = query(
      collection(db, 'appointments'),
      where('status', '==', 'completed'),
      where('completedAt', '>=', startTimestamp),
      where('completedAt', '<=', endTimestamp)
    );
    
    const appointmentsSnapshot = await getDocs(appointmentsQuery);
    console.log(`Retrieved ${appointmentsSnapshot.size} completed appointments`);
    
    appointmentsSnapshot.forEach(doc => {
      const appointmentData = doc.data();
      
      // Skip if no staff assigned
      if (!appointmentData.assignedStaffId && !appointmentData.assignedStaff) {
        return;
      }
      
      // Extract staff data
      const staffId = appointmentData.assignedStaffId || 'unknown';
      const staffName = appointmentData.assignedStaff || 'Unknown Staff';
      
      // Initialize staff metrics if not exists
      if (!staffMap.has(staffId)) {
        staffMap.set(staffId, {
          staffId,
          name: staffName,
          tasksCompleted: 0,
          visitsCompleted: 0,
          appointmentsCompleted: 0,
          totalIncome: 0,
          averageRating: 0,
          averageServiceTime: 0
        });
      }
      
      const staffMetrics = staffMap.get(staffId)!;
      staffMetrics.tasksCompleted += 1;
      staffMetrics.appointmentsCompleted += 1;
      
      // Calculate income if available
      if (appointmentData.paymentDetails?.amount) {
        const amount = Number(appointmentData.paymentDetails.amount) || 0;
        staffMetrics.totalIncome += amount;
      }
      
      // Calculate rating if available
      if (appointmentData.feedback && appointmentData.feedback.rating) {
        const prevAppointments = staffMetrics.appointmentsCompleted - 1;
        const prevTotalRating = staffMetrics.averageRating * (staffMetrics.visitsCompleted + prevAppointments);
        const newTotalCompletions = staffMetrics.visitsCompleted + staffMetrics.appointmentsCompleted;
        const rating = Number(appointmentData.feedback.rating) || 0;
        staffMetrics.averageRating = (prevTotalRating + rating) / newTotalCompletions;
      }
    });
    
    // 5. Query appointmentCompletions collection for additional payment data
    const appointmentCompletionsQuery = query(
      collection(db, 'appointmentCompletions'),
      where('completedAt', '>=', startTimestamp),
      where('completedAt', '<=', endTimestamp)
    );
    
    const appointmentCompletionsSnapshot = await getDocs(appointmentCompletionsQuery);
    console.log(`Retrieved ${appointmentCompletionsSnapshot.size} appointment completion records`);
    
    // Process appointment completions data
    appointmentCompletionsSnapshot.forEach(doc => {
      const completionData = doc.data();
      
      // Skip if no staff assigned or already counted in appointments
      if (!completionData.staffId && !completionData.completedBy) {
        return;
      }
      
      // Extract staff data
      const staffId = completionData.staffId || completionData.completedBy || 'unknown';
      const staffName = completionData.staffName || completionData.completedByName || 'Unknown Staff';
      
      // Initialize staff metrics if not exists
      if (!staffMap.has(staffId)) {
        staffMap.set(staffId, {
          staffId,
          name: staffName,
          tasksCompleted: 0,
          visitsCompleted: 0,
          appointmentsCompleted: 0,
          totalIncome: 0,
          averageRating: 0,
          averageServiceTime: 0
        });
      }
      
      const staffMetrics = staffMap.get(staffId)!;
      
      // Only count income if it wasn't already counted from the appointments collection
      // This logic assumes the appointmentCompletions collection has more accurate payment data
      if (completionData.amountCharged && completionData.serviceRendered) {
        const amount = Number(completionData.amountCharged) || 0;
        
        // Only add this income if it's from a completion record that wasn't counted earlier
        // or if the amount here is more accurate (non-zero when the previous was zero)
        if (amount > 0) {
          staffMetrics.totalIncome += amount;
        }
      }
    });
    
    // Convert map to array and sort by total income (descending)
    const staffPerformance = Array.from(staffMap.values())
      .sort((a, b) => b.totalIncome - a.totalIncome);
    
    return staffPerformance;
  } catch (error) {
    console.error('Error getting staff performance report:', error);
    return [];
  }
};

/**
 * Get daily income data within a date range
 */
export const getDailyIncomeReport = async (
  startDate: Date,
  endDate: Date
): Promise<DailyIncomeData[]> => {
  try {
    console.log(`Fetching daily income data from ${startDate.toISOString()} to ${endDate.toISOString()}`);
    
    // Initialize a map to store daily income data
    const dailyIncomeMap = new Map<string, DailyIncomeData>();
    
    // Convert dates to Firestore Timestamps for direct comparison in query
    const startTimestamp = Timestamp.fromDate(startOfDay(startDate));
    const endTimestamp = Timestamp.fromDate(endOfDay(endDate));
    
    // Initialize all days in the range
    const daysDiff = differenceInDays(endDate, startDate);
    for (let i = 0; i <= daysDiff; i++) {
      const currentDate = addDays(startDate, i);
      const dateKey = format(currentDate, 'yyyy-MM-dd');
      
      dailyIncomeMap.set(dateKey, {
        date: dateKey,
        visitsCount: 0,
        visitIncome: 0,
        appointmentsCount: 0,
        appointmentIncome: 0,
        totalIncome: 0
      });
    }
    
    // 1. Query completed visits within date range from registrations collection
    const registrationsQuery = query(
      collection(db, 'registrations'),
      where('status', '==', 'completed'),
      where('completedAt', '>=', startTimestamp),
      where('completedAt', '<=', endTimestamp)
    );
    
    const registrationsSnapshot = await getDocs(registrationsQuery);
    console.log(`Retrieved ${registrationsSnapshot.size} completed registrations`);
    
    registrationsSnapshot.forEach(doc => {
      const registrationData = doc.data();
      
      // Get completion date - use completedAt, fallback to createdAt
      const completionDate = timestampToDate(registrationData.completedAt || registrationData.createdAt);
      
      // Format date for use as key
      const dateKey = format(completionDate, 'yyyy-MM-dd');
      
      // Ensure the date entry exists
      if (!dailyIncomeMap.has(dateKey)) {
        dailyIncomeMap.set(dateKey, {
          date: dateKey,
          visitsCount: 0,
          visitIncome: 0,
          appointmentsCount: 0,
          appointmentIncome: 0,
          totalIncome: 0
        });
      }
      
      const dailyData = dailyIncomeMap.get(dateKey)!;
      
      // Only count this as a visit if it has the wasCharged or serviceRendered flag
      // This ensures we don't double count
      if (registrationData.serviceRendered !== undefined) {
        dailyData.visitsCount += 1;
        
        // Add income if the visit was charged
        if (registrationData.wasCharged && registrationData.amountCharged) {
          const visitIncome = Number(registrationData.amountCharged) || 0;
          dailyData.visitIncome += visitIncome;
          dailyData.totalIncome += visitIncome;
        }
      }
    });
    
    // 1.1 Also query the original visits collection as fallback
    const visitsQuery = query(
      collection(db, 'visits'),
      where('status', '==', 'completed'),
      where('completedAt', '>=', startTimestamp),
      where('completedAt', '<=', endTimestamp)
    );
    
    const visitsSnapshot = await getDocs(visitsQuery);
    console.log(`Retrieved ${visitsSnapshot.size} completed visits from visits collection`);
    
    visitsSnapshot.forEach(doc => {
      const visitData = doc.data();
      const visitDate = timestampToDate(visitData.completedAt || visitData.createdAt);
      
      // Format date for use as key
      const dateKey = format(visitDate, 'yyyy-MM-dd');
      
      // Ensure the date entry exists
      if (!dailyIncomeMap.has(dateKey)) {
        dailyIncomeMap.set(dateKey, {
          date: dateKey,
          visitsCount: 0,
          visitIncome: 0,
          appointmentsCount: 0,
          appointmentIncome: 0,
          totalIncome: 0
        });
      }
      
      const dailyData = dailyIncomeMap.get(dateKey)!;
      
      // Only count if we haven't already counted this as a visit in the registrations collection
      if (dailyData.visitsCount === 0) {
        dailyData.visitsCount += 1;
        
        // Add income if available (from multiple potential sources)
        let visitIncome = 0;
        
        // Try from paymentDetails
        if (visitData.paymentDetails?.amount) {
          visitIncome = Number(visitData.paymentDetails.amount) || 0;
        }
        
        dailyData.visitIncome += visitIncome;
        dailyData.totalIncome += visitIncome;
      }
    });
    
    // 1.2 Also query visitCompletions for amount charged data as final fallback
    const visitCompletionsQuery = query(
      collection(db, 'visitCompletions'),
      where('completedAt', '>=', startTimestamp),
      where('completedAt', '<=', endTimestamp)
    );
    
    const visitCompletionsSnapshot = await getDocs(visitCompletionsQuery);
    console.log(`Retrieved ${visitCompletionsSnapshot.size} visit completion records`);
    
    visitCompletionsSnapshot.forEach(doc => {
      const completionData = doc.data();
      if (!completionData.wasCharged || !completionData.amountCharged) return;
      
      const completionDate = timestampToDate(completionData.completedAt);
      const dateKey = format(completionDate, 'yyyy-MM-dd');
      
      // Ensure the date entry exists
      if (!dailyIncomeMap.has(dateKey)) {
        dailyIncomeMap.set(dateKey, {
          date: dateKey,
          visitsCount: 0,
          visitIncome: 0,
          appointmentsCount: 0,
          appointmentIncome: 0,
          totalIncome: 0
        });
      }
      
      const dailyData = dailyIncomeMap.get(dateKey)!;
      const amount = Number(completionData.amountCharged) || 0;
      
      // Only add income if it wasn't already captured from the registrations collection
      // to avoid double counting
      if (dailyData.visitIncome === 0 && amount > 0) {
        dailyData.visitIncome += amount;
        dailyData.totalIncome += amount;
        // If we count the income, also count the visit if it wasn't counted yet
        if (dailyData.visitsCount === 0) {
          dailyData.visitsCount += 1;
        }
      }
    });
    
    // 2. Query completed appointments within date range
    // Try with different field names based on database structure
    let appointmentsQuery;
    
    // First, try with completedAt field
    try {
      appointmentsQuery = query(
        collection(db, 'appointments'),
        where('status', '==', 'completed'),
        where('completedAt', '>=', startTimestamp),
        where('completedAt', '<=', endTimestamp)
      );
    } catch (error) {
      console.warn('Error with completedAt query, falling back to appointmentDate:', error);
      
      // If that doesn't work, try with appointmentDate field (string format)
      const startDateStr = format(startDate, 'yyyy-MM-dd');
      const endDateStr = format(endDate, 'yyyy-MM-dd');
      
      appointmentsQuery = query(
        collection(db, 'appointments'),
        where('status', '==', 'completed'),
        where('appointmentDate', '>=', startDateStr),
        where('appointmentDate', '<=', endDateStr)
      );
    }
    
    const appointmentsSnapshot = await getDocs(appointmentsQuery);
    console.log(`Retrieved ${appointmentsSnapshot.size} completed appointments`);
    
    appointmentsSnapshot.forEach(doc => {
      const appointmentData = doc.data();
      let appointmentDate;
      
      // Try to get the date from different possible fields
      if (appointmentData.completedAt) {
        appointmentDate = timestampToDate(appointmentData.completedAt);
      } else if (appointmentData.appointmentDate) {
        // If it's stored as a string like "2023-05-15"
        try {
          appointmentDate = parseISO(appointmentData.appointmentDate);
        } catch (error) {
          console.warn(`Error parsing appointmentDate: ${appointmentData.appointmentDate}`, error);
          return; // Skip this appointment if date can't be parsed
        }
      } else if (appointmentData.dateTime) {
        appointmentDate = timestampToDate(appointmentData.dateTime);
      } else {
        console.warn('Appointment has no valid date field:', doc.id);
        return; // Skip this appointment
      }
      
      // Format date for use as key
      const dateKey = format(appointmentDate, 'yyyy-MM-dd');
      
      // Ensure the date entry exists
      if (!dailyIncomeMap.has(dateKey)) {
        dailyIncomeMap.set(dateKey, {
          date: dateKey,
          visitsCount: 0,
          visitIncome: 0,
          appointmentsCount: 0,
          appointmentIncome: 0,
          totalIncome: 0
        });
      }
      
      const dailyData = dailyIncomeMap.get(dateKey)!;
      dailyData.appointmentsCount += 1;
      
      // Add income if available
      let appointmentIncome = 0;
      
      // Try from paymentDetails
      if (appointmentData.paymentDetails?.amount) {
        appointmentIncome = Number(appointmentData.paymentDetails.amount) || 0;
      }
      
      // Set appointment income if found
      if (appointmentIncome > 0) {
        dailyData.appointmentIncome += appointmentIncome;
        dailyData.totalIncome += appointmentIncome;
      }
    });
    
    // 2.1 Also query appointmentCompletions for amount charged data
    const appointmentCompletionsQuery = query(
      collection(db, 'appointmentCompletions'),
      where('completedAt', '>=', startTimestamp),
      where('completedAt', '<=', endTimestamp)
    );
    
    try {
      const appointmentCompletionsSnapshot = await getDocs(appointmentCompletionsQuery);
      console.log(`Retrieved ${appointmentCompletionsSnapshot.size} appointment completion records`);
      
      appointmentCompletionsSnapshot.forEach(doc => {
        const completionData = doc.data();
        if (!completionData.wasCharged || !completionData.amountCharged) return;
        
        const completionDate = timestampToDate(completionData.completedAt);
        const dateKey = format(completionDate, 'yyyy-MM-dd');
        
        // Ensure the date entry exists
        if (!dailyIncomeMap.has(dateKey)) {
          dailyIncomeMap.set(dateKey, {
            date: dateKey,
            visitsCount: 0,
            visitIncome: 0,
            appointmentsCount: 0,
            appointmentIncome: 0,
            totalIncome: 0
          });
        }
        
        const dailyData = dailyIncomeMap.get(dateKey)!;
        const amount = Number(completionData.amountCharged) || 0;
        
        // Only add income if it wasn't already captured from the appointments collection
        // to avoid double counting
        if (dailyData.appointmentIncome === 0 && amount > 0) {
          dailyData.appointmentIncome += amount;
          dailyData.totalIncome += amount;
        }
      });
    } catch (error) {
      console.warn('Error fetching appointment completions, continuing with available data:', error);
    }
    
    // Convert map to array and sort by date
    const dailyIncomeData = Array.from(dailyIncomeMap.values())
      .sort((a, b) => a.date.localeCompare(b.date));
    
    return dailyIncomeData;
  } catch (error) {
    console.error('Error getting daily income report:', error);
    return [];
  }
};

/**
 * Get visits by service within a date range
 */
export const getServicePerformanceReport = async (
  startDate: Date,
  endDate: Date
): Promise<ServicePerformanceData[]> => {
  try {
    console.log(`Fetching service performance data from ${startDate.toISOString()} to ${endDate.toISOString()}`);
    
    // Initialize a map to store service metrics
    const serviceMap = new Map<string, ServicePerformanceData>();
    
    // Convert dates to Firestore Timestamps for direct comparison in query
    const startTimestamp = Timestamp.fromDate(startOfDay(startDate));
    const endTimestamp = Timestamp.fromDate(endOfDay(endDate));
    
    // Query completed visits within date range
    const visitsQuery = query(
      collection(db, 'visits'),
      where('status', '==', 'completed'),
      where('completedAt', '>=', startTimestamp),
      where('completedAt', '<=', endTimestamp)
    );
    
    const visitsSnapshot = await getDocs(visitsQuery);
    console.log(`Retrieved ${visitsSnapshot.size} completed visits for service performance`);
    
    visitsSnapshot.forEach(doc => {
      const visitData = doc.data();
      
      // Skip if no service data
      if (!visitData.service?.id && !visitData.serviceId) {
        return;
      }
      
      // Extract service data, handling different field structures
      const serviceId = visitData.service?.id || visitData.serviceId || 'unknown';
      const serviceName = visitData.service?.name || visitData.serviceName || 'Unknown Service';
      
      // Initialize service metrics if not exists
      if (!serviceMap.has(serviceId)) {
        serviceMap.set(serviceId, {
          serviceId,
          serviceName,
          visitsCount: 0,
          income: 0,
          averageWaitTime: 0
        });
      }
      
      const serviceMetrics = serviceMap.get(serviceId)!;
      serviceMetrics.visitsCount += 1;
      
      // Calculate income if available
      if (visitData.paymentDetails?.amount) {
        const amount = Number(visitData.paymentDetails.amount) || 0;
        serviceMetrics.income += amount;
      }
      
      // Calculate wait time if check-in time and service start time are available
      if (visitData.checkInTime && (visitData.serviceStartTime || visitData.startTime)) {
        const checkInTime = timestampToDate(visitData.checkInTime);
        const serviceStartTime = timestampToDate(visitData.serviceStartTime || visitData.startTime);
        
        const waitTime = Math.round((serviceStartTime.getTime() - checkInTime.getTime()) / (1000 * 60)); // in minutes
        
        if (waitTime >= 0) {
          const prevTotalWaitTime = serviceMetrics.averageWaitTime * (serviceMetrics.visitsCount - 1);
          serviceMetrics.averageWaitTime = (prevTotalWaitTime + waitTime) / serviceMetrics.visitsCount;
        }
      }
    });
    
    // Convert map to array and sort by visit count (descending)
    const servicePerformance = Array.from(serviceMap.values())
      .sort((a, b) => b.visitsCount - a.visitsCount);
    
    return servicePerformance;
  } catch (error) {
    console.error('Error getting service performance report:', error);
    return [];
  }
};

/**
 * Get conversion rate metrics within a date range
 */
export const getConversionRateReport = async (
  startDate: Date,
  endDate: Date
): Promise<ConversionRateData[]> => {
  try {
    console.log(`Fetching conversion rate data from ${startDate.toISOString()} to ${endDate.toISOString()}`);
    
    // Initialize metrics
    let totalVisits = 0;
    let completedVisits = 0;
    let noShowVisits = 0;
    let totalAppointments = 0;
    let completedAppointments = 0;
    let noShowAppointments = 0;
    let totalRevenue = 0;
    let totalTransactions = 0;
    
    // Convert dates to Firestore Timestamps for direct comparison in query
    const startTimestamp = Timestamp.fromDate(startOfDay(startDate));
    const endTimestamp = Timestamp.fromDate(endOfDay(endDate));
    
    // First, query all visits within date range
    const allVisitsQuery = query(
      collection(db, 'visits'),
      where('createdAt', '>=', startTimestamp),
      where('createdAt', '<=', endTimestamp)
    );
    
    const allVisitsSnapshot = await getDocs(allVisitsQuery);
    totalVisits = allVisitsSnapshot.size;
    console.log(`Retrieved ${totalVisits} total visits`);
    
    // Count completed and no-show visits
    allVisitsSnapshot.forEach(doc => {
      const visitData = doc.data();
      
      if (visitData.status === 'completed') {
        completedVisits += 1;
        
        if (visitData.paymentDetails?.amount) {
          const amount = Number(visitData.paymentDetails.amount) || 0;
          totalRevenue += amount;
          totalTransactions += 1;
        }
      } else if (visitData.status === 'no-show' || visitData.status === 'abandoned') {
        noShowVisits += 1;
      }
    });
    
    // Query appointments within date range - using appointmentDate field for better results
    const startDateStr = format(startDate, 'yyyy-MM-dd');
    const endDateStr = format(endDate, 'yyyy-MM-dd');
    
    const allAppointmentsQuery = query(
      collection(db, 'appointments'),
      where('appointmentDate', '>=', startDateStr),
      where('appointmentDate', '<=', endDateStr)
    );
    
    const allAppointmentsSnapshot = await getDocs(allAppointmentsQuery);
    totalAppointments = allAppointmentsSnapshot.size;
    console.log(`Retrieved ${totalAppointments} total appointments`);
    
    // Count completed and no-show appointments
    allAppointmentsSnapshot.forEach(doc => {
      const appointmentData = doc.data();
      
      if (appointmentData.status === 'completed') {
        completedAppointments += 1;
        
        if (appointmentData.paymentDetails?.amount) {
          const amount = Number(appointmentData.paymentDetails.amount) || 0;
          totalRevenue += amount;
          totalTransactions += 1;
        }
      } else if (appointmentData.status === 'no-show') {
        noShowAppointments += 1;
      }
    });
    
    // Calculate rates and prepare report data
    const conversionRates: ConversionRateData[] = [
      {
        metricName: 'Visit Completion Rate',
        rate: totalVisits > 0 ? (completedVisits / totalVisits) * 100 : 0,
        count: completedVisits,
        total: totalVisits
      },
      {
        metricName: 'Visit No-Show Rate',
        rate: totalVisits > 0 ? (noShowVisits / totalVisits) * 100 : 0,
        count: noShowVisits,
        total: totalVisits
      },
      {
        metricName: 'Appointment Completion Rate',
        rate: totalAppointments > 0 ? (completedAppointments / totalAppointments) * 100 : 0,
        count: completedAppointments,
        total: totalAppointments
      },
      {
        metricName: 'Appointment No-Show Rate',
        rate: totalAppointments > 0 ? (noShowAppointments / totalAppointments) * 100 : 0,
        count: noShowAppointments,
        total: totalAppointments
      },
      {
        metricName: 'Average Revenue per Transaction',
        rate: totalTransactions > 0 ? totalRevenue / totalTransactions : 0,
        count: totalTransactions,
        total: totalTransactions
      }
    ];
    
    return conversionRates;
  } catch (error) {
    console.error('Error getting conversion rate report:', error);
    return [];
  }
};

/**
 * Get business summary metrics within a date range
 */
export const getBusinessSummaryReport = async (
  startDate: Date,
  endDate: Date
): Promise<BusinessSummaryData> => {
  try {
    console.log(`Fetching business summary data from ${startDate.toISOString()} to ${endDate.toISOString()}`);
    
    // Convert dates to Firestore Timestamps for direct comparison in query
    const startTimestamp = Timestamp.fromDate(startOfDay(startDate));
    const endTimestamp = Timestamp.fromDate(endOfDay(endDate));
    
    // Initialize result structure
    const result: BusinessSummaryData = {
      totalCustomers: 0,
      newCustomers: 0, 
      repeatCustomers: 0,
      topService: 'None',
      topServiceCount: 0,
      topStaff: 'None',
      topStaffCount: 0,
      peakHours: []
    };
    
    // Get all users who are customers
    const customersQuery = query(
      collection(db, 'users'),
      where('role', '==', 'customer')
    );
    const customersSnapshot = await getDocs(customersQuery);
    
    // Map to track customers seen in the date range
    const customersInRange = new Map<string, boolean>();
    
    // Set to track new customers in the range
    const newCustomersSet = new Set<string>();
    
    // Map for customer first visits
    const customerFirstVisits = new Map<string, Date>();
    
    // Maps for tracking service and staff metrics
    const serviceVisits = new Map<string, { name: string, count: number }>();
    const staffVisits = new Map<string, { name: string, count: number }>();
    
    // Array to track visit hours for peak hour determination
    const visitHours: number[] = Array(24).fill(0);
    
    // Query completed visits within date range
    const visitsQuery = query(
      collection(db, 'visits'),
      where('status', '==', 'completed'),
      where('completedAt', '>=', startTimestamp),
      where('completedAt', '<=', endTimestamp)
    );
    
    const visitsSnapshot = await getDocs(visitsQuery);
    console.log(`Retrieved ${visitsSnapshot.size} completed visits for business summary`);
    
    // Process all visits within the range
    visitsSnapshot.forEach(doc => {
      const visitData = doc.data();
      const customerId = visitData.customer?.id || visitData.customerId;
      
      if (!customerId) return;
      
      // Track unique customers in range
      customersInRange.set(customerId, true);
      
      // Track visit hour
      const visitDate = timestampToDate(visitData.completedAt || visitData.createdAt);
      const hour = visitDate.getHours();
      visitHours[hour]++;
      
      // Track service visits
      if (visitData.service?.id) {
        const serviceId = visitData.service.id;
        const serviceName = visitData.service.name || 'Unknown Service';
        
        if (!serviceVisits.has(serviceId)) {
          serviceVisits.set(serviceId, { name: serviceName, count: 0 });
        }
        
        const service = serviceVisits.get(serviceId)!;
        service.count++;
      }
      
      // Track staff visits
      if (visitData.assignedStaff?.id) {
        const staffId = visitData.assignedStaff.id;
        const staffName = visitData.assignedStaff.name || 'Unknown Staff';
        
        if (!staffVisits.has(staffId)) {
          staffVisits.set(staffId, { name: staffName, count: 0 });
        }
        
        const staff = staffVisits.get(staffId)!;
        staff.count++;
      }
    });
    
    // Now query all visits to determine first visit dates for each customer
    const allVisitsQuery = query(
      collection(db, 'visits'),
      where('status', '==', 'completed'),
      orderBy('createdAt', 'asc')
    );
    
    const allVisitsSnapshot = await getDocs(allVisitsQuery);
    
    allVisitsSnapshot.forEach(doc => {
      const visitData = doc.data();
      const customerId = visitData.customer?.id || visitData.customerId;
      
      if (!customerId) return;
      
      // If we haven't seen this customer's first visit yet
      if (!customerFirstVisits.has(customerId)) {
        const visitDate = timestampToDate(visitData.createdAt);
        customerFirstVisits.set(customerId, visitDate);
        
        // Check if this first visit is within our date range
        if (isWithinInterval(visitDate, { start: startOfDay(startDate), end: endOfDay(endDate) })) {
          newCustomersSet.add(customerId);
        }
      }
    });
    
    // Process results
    result.totalCustomers = customersInRange.size;
    result.newCustomers = newCustomersSet.size;
    result.repeatCustomers = result.totalCustomers - result.newCustomers;
    
    // Find top service
    let maxServiceCount = 0;
    serviceVisits.forEach((service, id) => {
      if (service.count > maxServiceCount) {
        maxServiceCount = service.count;
        result.topService = service.name;
        result.topServiceCount = service.count;
      }
    });
    
    // Find top staff
    let maxStaffCount = 0;
    staffVisits.forEach((staff, id) => {
      if (staff.count > maxStaffCount) {
        maxStaffCount = staff.count;
        result.topStaff = staff.name;
        result.topStaffCount = staff.count;
      }
    });
    
    // Determine peak hours (top 3 busiest hours)
    const hourEntries = visitHours
      .map((count, hour) => ({ hour, count }))
      .sort((a, b) => b.count - a.count)
      .slice(0, 3)
      .filter(entry => entry.count > 0);
    
    result.peakHours = hourEntries.map(entry => ({
      hour: entry.hour,
      count: entry.count,
      label: format(set(new Date(), { hours: entry.hour, minutes: 0 }), 'h a')
    }));
    
    return result;
  } catch (error) {
    console.error('Error getting business summary report:', error);
    return {
      totalCustomers: 0,
      newCustomers: 0,
      repeatCustomers: 0,
      topService: 'None',
      topServiceCount: 0,
      topStaff: 'None',
      topStaffCount: 0,
      peakHours: []
    };
  }
}; 