import React from 'react';
import Card from '@/components/ui/Card';
import { cn } from '@/lib/utils';

type MetricCardProps = {
  title: string;
  value: string | number;
  icon?: React.ReactNode;
  variant?: 'default' | 'primary' | 'success' | 'warning' | 'info' | 'pending';
  className?: string;
  onClick?: () => void;
};

// Variant color mappings
const variantClasses = {
  default: "bg-white",
  primary: "bg-blue-50",
  success: "bg-green-50",
  warning: "bg-amber-50",
  info: "bg-cyan-50",
  pending: "bg-yellow-50"
};

// Icon container variant mappings
const iconContainerClasses = {
  default: "bg-gray-100 text-gray-700",
  primary: "bg-blue-100 text-blue-700",
  success: "bg-green-100 text-green-700",
  warning: "bg-amber-100 text-amber-700",
  info: "bg-cyan-100 text-cyan-700",
  pending: "bg-yellow-100 text-yellow-700"
};

// Value text variant mappings
const valueTextClasses = {
  default: "text-gray-900",
  primary: "text-blue-700",
  success: "text-green-700",
  warning: "text-amber-700",
  info: "text-cyan-700",
  pending: "text-yellow-700"
};

const DashboardMetricCard: React.FC<MetricCardProps> = ({
  title,
  value,
  icon,
  variant = 'default',
  className,
  onClick
}) => {
  const cardContent = (
    <>
      {icon && (
        <div className={cn(
          "rounded-full p-3 mb-3 transition-all duration-200 group-hover:scale-110 self-start",
          iconContainerClasses[variant]
        )}>
          {icon}
        </div>
      )}
      <h3 className="text-sm font-medium text-gray-500 mb-1">{title}</h3>
      <p className={cn(
        "text-3xl font-bold tracking-tight transition-colors",
        valueTextClasses[variant],
        "group-hover:brightness-110"
      )}>
        {value}
      </p>
    </>
  );

  // Create a wrapper div if onClick is provided
  return (
    <div 
      className={cn(
        "cursor-pointer",
        onClick ? "" : "hidden"
      )}
      onClick={onClick}
      tabIndex={onClick ? 0 : undefined}
      onKeyDown={(e: React.KeyboardEvent) => {
        if (onClick && (e.key === 'Enter' || e.key === ' ')) {
          e.preventDefault();
          onClick();
        }
      }}
      role={onClick ? "button" : undefined}
      aria-label={onClick ? `${title}: ${value}` : undefined}
    >
      <Card 
        className={cn(
          "flex flex-col p-0 transition-all duration-200 hover:shadow-md group",
          variantClasses[variant],
          className
        )}
        isHoverable={!!onClick}
      >
        {cardContent}
      </Card>
    </div>
  );
};

export default DashboardMetricCard; 