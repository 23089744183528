import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { getHCAApplication, updateHCAApplicationStatus, addNoteToApplication } from '../services/applicationService';
import type { SavedHCAApplication, ApplicationStatus, ApplicationNote, ApplicationDocument } from '../services/applicationService';
import { useAuth } from '../contexts/AuthContext';
import { Timestamp } from 'firebase/firestore';
import DocumentUploader from '../components/DocumentUploader';
import { ref, getDownloadURL } from 'firebase/storage';
import { storage } from '../firebase/config';

// Tabs interface
interface TabData {
  id: string;
  label: string;
  icon: React.ReactNode;
}

const HCAApplicationDetails: React.FC = () => {
  const { applicationId } = useParams<{ applicationId: string }>();
  const { currentUser } = useAuth();
  const [application, setApplication] = useState<SavedHCAApplication | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<Error | null>(null);
  const [updatingStatus, setUpdatingStatus] = useState<boolean>(false);
  const [statusUpdateSuccess, setStatusUpdateSuccess] = useState<boolean>(false);
  const [currentTab, setCurrentTab] = useState<string>('overview');
  const [noteContent, setNoteContent] = useState('');
  const [addingNote, setAddingNote] = useState(false);

  // Define tabs with SVG icons
  const tabs: TabData[] = [
    { 
      id: 'overview', 
      label: 'Overview', 
      icon: (
        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2" />
        </svg>
      )
    },
    { 
      id: 'personal', 
      label: 'Personal Info', 
      icon: (
        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
        </svg>
      )
    },
    { 
      id: 'address', 
      label: 'Address', 
      icon: (
        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6" />
        </svg>
      )
    },
    { 
      id: 'identification', 
      label: 'Identification', 
      icon: (
        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 6H5a2 2 0 00-2 2v9a2 2 0 002 2h14a2 2 0 002-2V8a2 2 0 00-2-2h-5m-4 0V5a2 2 0 114 0v1m-4 0a2 2 0 104 0m-5 8a2 2 0 100-4 2 2 0 000 4zm0 0c1.306 0 2.417.835 2.83 2M9 14a3.001 3.001 0 00-2.83 2M15 11h3m-3 4h2" />
        </svg>
      )
    },
    { 
      id: 'documents', 
      label: 'Documents', 
      icon: (
        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M7 21h10a2 2 0 002-2V9.414a1 1 0 00-.293-.707l-5.414-5.414A1 1 0 0012.586 3H7a2 2 0 00-2 2v14a2 2 0 002 2z" />
        </svg>
      )
    },
    { 
      id: 'administrative', 
      label: 'Notes', 
      icon: (
        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
        </svg>
      )
    },
  ];

  const fetchApplication = async () => {
    if (!applicationId) {
      setError(new Error('No application ID provided'));
      setLoading(false);
      return;
    }

    try {
      const applicationData = await getHCAApplication(applicationId);
      
      if (!applicationData) {
        setError(new Error('Application not found'));
      } else {
        setApplication(applicationData);
      }
    } catch (err) {
      console.error('Error fetching application:', err);
      setError(err instanceof Error ? err : new Error('Error loading application data'));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchApplication();
  }, [applicationId]);

  const formatDate = (timestamp: any) => {
    if (!timestamp) return 'N/A';
    
    let date: Date;
    if (timestamp instanceof Timestamp) {
      date = timestamp.toDate();
    } else if (timestamp instanceof Date) {
      date = timestamp;
    } else {
      return 'N/A';
    }
    
    return new Intl.DateTimeFormat('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      hour12: true
    }).format(date);
  };

  const getStatusBadgeColor = (status: ApplicationStatus) => {
    switch (status) {
      case 'pending':
        return 'bg-yellow-100 text-yellow-800 border border-yellow-300';
      case 'waiting-for-certificate':
        return 'bg-blue-100 text-blue-800 border border-blue-300';
      case 'waiting-for-payment':
        return 'bg-purple-100 text-purple-800 border border-purple-300';
      case 'certificate-expired':
        return 'bg-red-100 text-red-800 border border-red-300';
      case 'completed':
        return 'bg-green-100 text-green-800 border border-green-300';
      case 'rejected':
        return 'bg-red-100 text-red-800 border border-red-300';
      default:
        return 'bg-gray-100 text-gray-800 border border-gray-300';
    }
  };

  const formatStatus = (status: ApplicationStatus) => {
    switch (status) {
      case 'pending':
        return 'Pending';
      case 'waiting-for-certificate':
        return 'Wait for 75hrs Certificate';
      case 'waiting-for-payment':
        return 'Waiting for Payment';
      case 'certificate-expired':
        return '75hrs Certificate Expired';
      case 'completed':
        return 'Completed';
      case 'rejected':
        return 'Rejected';
      default:
        return status;
    }
  };

  const handleStatusChange = async (newStatus: ApplicationStatus) => {
    if (!applicationId || !application || !currentUser) return;
    
    // Prevent status changes for completed or rejected applications
    if (application.status === 'completed' || application.status === 'rejected') {
      setError(new Error(`Cannot change status of ${application.status.toLowerCase()} applications`));
      return;
    }
    
    try {
      setUpdatingStatus(true);
      await updateHCAApplicationStatus(
        applicationId, 
        newStatus,
        currentUser.displayName || currentUser.email || 'Staff',
        currentUser.email || undefined
      );
      
      // Update the local state with staff information
      const updateData: Partial<SavedHCAApplication> = {
        status: newStatus
      };

      if (newStatus === 'completed') {
        updateData.completedBy = currentUser.displayName || currentUser.email || 'Staff';
        updateData.completedByEmail = currentUser.email || undefined;
        updateData.completedAt = new Date();
      } else if (newStatus === 'rejected') {
        updateData.rejectedBy = currentUser.displayName || currentUser.email || 'Staff';
        updateData.rejectedByEmail = currentUser.email || undefined;
        updateData.rejectedAt = new Date();
      }
      
      setApplication(prev => prev ? { ...prev, ...updateData } : null);
      setStatusUpdateSuccess(true);
      setTimeout(() => {
        setStatusUpdateSuccess(false);
      }, 3000);
    } catch (err) {
      console.error('Error updating status:', err);
      setError(err instanceof Error ? err : new Error('Failed to update application status'));
    } finally {
      setUpdatingStatus(false);
    }
  };

  const handleAddNote = async () => {
    if (!applicationId || !noteContent.trim() || !currentUser) return;
    
    setAddingNote(true);
    try {
      await addNoteToApplication(
        applicationId,
        noteContent,
        currentUser.displayName || currentUser.email || 'Unknown Staff',
        currentUser.email || undefined
      );
      
      // Refresh application data to get the new note
      await fetchApplication();
      setNoteContent('');
    } catch (err) {
      console.error('Error adding note:', err);
      setError(err instanceof Error ? err : new Error('Failed to add note'));
    } finally {
      setAddingNote(false);
    }
  };

  const getDocumentDownloadUrl = async (documentPath: string): Promise<string> => {
    try {
      // Remove any leading slashes if present
      const cleanPath = documentPath.startsWith('/') ? documentPath.substring(1) : documentPath;
      
      // Create a reference to the file
      const fileRef = ref(storage, cleanPath);
      
      // Get a fresh download URL (these expire after some time)
      const url = await getDownloadURL(fileRef);
      return url;
    } catch (error) {
      console.error("Error getting download URL:", error);
      setError(error instanceof Error ? error : new Error(`Failed to get download URL for ${documentPath}`));
      return "#"; // Return a placeholder to avoid breaking the UI
    }
  };

  // Handle document download
  const handleDocumentDownload = async (doc: ApplicationDocument) => {
    try {
      setError(null);
      
      // Always get a fresh download URL for Firebase Storage files to avoid CORS issues
      let path = `applications/${applicationId}/documents/${doc.fileName}`;
      
      // If URL contains a path parameter, try to extract it
      if (doc.fileURL && doc.fileURL.includes('?name=')) {
        const nameParam = new URL(doc.fileURL).searchParams.get('name');
        if (nameParam) {
          // URL decode the path
          path = decodeURIComponent(nameParam);
        }
      }
      
      console.log('Getting download URL for path:', path);
      const url = await getDocumentDownloadUrl(path);
      
      // Open in new tab with additional security measures
      if (url && url !== '#') {
        // Create a temporary link and click it programmatically
        const link = document.createElement('a');
        link.href = url;
        link.target = '_blank';
        link.rel = 'noopener noreferrer';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        setError(new Error('Could not generate download URL for this document'));
      }
    } catch (error) {
      console.error("Error downloading document:", error);
      setError(error instanceof Error ? error : new Error('Failed to download document'));
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-[60vh]">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-900"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="p-6 bg-red-50 border border-red-200 rounded-lg">
        <h2 className="text-xl font-semibold text-red-700 mb-2">Error</h2>
        <p className="text-red-600">{error.message}</p>
        <Link to="/applications" className="mt-4 inline-block text-blue-600 hover:underline">
          ← Back to Applications
        </Link>
      </div>
    );
  }

  if (!application) {
    return (
      <div className="p-6 bg-yellow-50 border border-yellow-200 rounded-lg">
        <h2 className="text-xl font-semibold text-yellow-700 mb-2">Application Not Found</h2>
        <p className="text-yellow-600">The requested application could not be found.</p>
        <Link to="/applications" className="mt-4 inline-block text-blue-600 hover:underline">
          ← Back to Applications
        </Link>
      </div>
    );
  }

  return (
    <div className="max-w-7xl mx-auto px-4 py-8">
      {/* Breadcrumbs */}
      <div className="flex items-center mb-6 text-sm text-gray-600">
        <Link to="/applications" className="hover:text-blue-600">Applications</Link>
        <span className="mx-2">›</span>
        <span className="font-medium text-gray-900">Application Details</span>
      </div>
      
      {/* Header */}
      <div className="mb-8">
        <h1 className="text-2xl lg:text-3xl font-bold text-gray-900">
          Application: {application.firstName} {application.lastName}
        </h1>
        <p className="text-sm text-gray-600 mt-1">
          Submitted on {formatDate(application.createdAt)}
        </p>
        <div className="mt-4">
          <Link 
            to="/applications" 
            className="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            <svg className="mr-2 -ml-1 h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 19l-7-7m0 0l7-7m-7 7h18" />
            </svg>
            Back to Applications
          </Link>
        </div>
      </div>

      {/* Status update success message */}
      {statusUpdateSuccess && (
        <div className="mb-6 p-4 bg-green-50 border border-green-200 rounded-lg">
          <div className="flex">
            <div className="flex-shrink-0">
              <svg className="h-5 w-5 text-green-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
              </svg>
            </div>
            <div className="ml-3">
              <p className="text-sm font-medium text-green-800">Application status updated successfully!</p>
            </div>
          </div>
        </div>
      )}

      {/* Tabs */}
      <div className="border-b border-gray-200 mb-6">
        <nav className="flex -mb-px overflow-x-auto sm:overflow-visible" aria-label="Application sections">
          {tabs.map((tab) => (
            <button
              key={tab.id}
              onClick={() => setCurrentTab(tab.id)}
              className={`
                whitespace-nowrap py-4 px-4 border-b-2 font-medium text-sm flex items-center transition
                ${currentTab === tab.id 
                  ? 'border-blue-500 text-blue-600' 
                  : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'}
              `}
              aria-current={currentTab === tab.id ? 'page' : undefined}
            >
              <span className="mr-2">{tab.icon}</span>
              {tab.label}
            </button>
          ))}
        </nav>
      </div>

      <div className="bg-white shadow-sm rounded-lg border border-gray-200 overflow-hidden">
        {/* Overview Tab */}
        {currentTab === 'overview' && (
          <div className="p-6">
            <div className="mb-6">
              <h2 className="text-lg font-semibold mb-4">Application Status</h2>
              <div className="flex flex-col sm:flex-row gap-4">
                <div className="flex-grow">
                  <div className="flex items-center mb-4">
                    <span className={`inline-flex items-center px-3 py-1 rounded-full text-sm font-medium ${getStatusBadgeColor(application.status)}`}>
                      {formatStatus(application.status)}
                    </span>
                  </div>
                  
                  {/* Add completion information here */}
                  {application.status === 'completed' && application.completedBy && (
                    <div className="mb-4 p-3 bg-green-50 border border-green-200 rounded-md">
                      <p className="text-sm text-green-800">
                        Completed by {application.completedBy}
                        {application.completedByEmail && ` (${application.completedByEmail})`}
                        {application.completedAt && ` on ${formatDate(application.completedAt)}`}
                      </p>
                    </div>
                  )}
                  
                  {application.status === 'rejected' && application.rejectedBy && (
                    <div className="mb-4 p-3 bg-red-50 border border-red-200 rounded-md">
                      <p className="text-sm text-red-800">
                        Rejected by {application.rejectedBy}
                        {application.rejectedByEmail && ` (${application.rejectedByEmail})`}
                        {application.rejectedAt && ` on ${formatDate(application.rejectedAt)}`}
                      </p>
                    </div>
                  )}
                  
                  <h3 className="text-sm font-medium text-gray-700 mb-3">Update Status</h3>
                  <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-2">
                    {['pending', 'waiting-for-certificate', 'waiting-for-payment', 'certificate-expired', 'completed', 'rejected'].map((status) => (
                      <button
                        key={status}
                        className={`px-3 py-2 text-sm rounded-md border transition-colors ${
                          application.status === status 
                            ? 'bg-blue-50 border-blue-300 text-blue-700' 
                            : (application.status === 'completed' || application.status === 'rejected')
                              ? 'bg-gray-100 border-gray-200 text-gray-400 cursor-not-allowed'
                              : 'bg-white border-gray-300 text-gray-700 hover:bg-gray-50'
                        }`}
                        onClick={() => handleStatusChange(status as ApplicationStatus)}
                        disabled={
                          updatingStatus || 
                          application.status === status || 
                          application.status === 'completed' || 
                          application.status === 'rejected'
                        }
                        title={
                          (application.status === 'completed' || application.status === 'rejected')
                            ? `Cannot change status of ${application.status.toLowerCase()} applications`
                            : undefined
                        }
                      >
                        {formatStatus(status as ApplicationStatus)}
                      </button>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div>
                <h2 className="text-lg font-semibold mb-4">Application Details</h2>
                <div className="space-y-3">
                  <div>
                    <label className="block text-xs font-medium text-gray-500">Application ID</label>
                    <p className="mt-1 text-sm text-gray-900 font-mono">{application.applicationId}</p>
                  </div>
                  <div>
                    <label className="block text-xs font-medium text-gray-500">Submission Date</label>
                    <p className="mt-1 text-sm text-gray-900">{formatDate(application.createdAt)}</p>
                  </div>
                  <div>
                    <label className="block text-xs font-medium text-gray-500">User ID</label>
                    <p className="mt-1 text-sm text-gray-900">{application.userId || 'anonymous'}</p>
                  </div>
                </div>
              </div>
              
              <div>
                <h2 className="text-lg font-semibold mb-4">Applicant Summary</h2>
                <div className="space-y-3">
                  <div>
                    <label className="block text-xs font-medium text-gray-500">Name</label>
                    <p className="mt-1 text-sm text-gray-900">{application.firstName} {application.lastName}</p>
                  </div>
                  <div>
                    <label className="block text-xs font-medium text-gray-500">Email</label>
                    <p className="mt-1 text-sm text-gray-900">{application.email}</p>
                  </div>
                  <div>
                    <label className="block text-xs font-medium text-gray-500">Phone</label>
                    <p className="mt-1 text-sm text-gray-900">{application.phoneNumber}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        
        {/* Personal Info Tab */}
        {currentTab === 'personal' && (
          <div className="p-6">
            <h2 className="text-lg font-semibold mb-4">Personal Information</h2>
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-x-6 gap-y-4">
              <div>
                <label className="block text-xs font-medium text-gray-500">First Name</label>
                <p className="mt-1 text-sm text-gray-900">{application.firstName}</p>
              </div>
              <div>
                <label className="block text-xs font-medium text-gray-500">Last Name</label>
                <p className="mt-1 text-sm text-gray-900">{application.lastName}</p>
              </div>
              <div>
                <label className="block text-xs font-medium text-gray-500">Date of Birth</label>
                <p className="mt-1 text-sm text-gray-900">{application.dateOfBirth}</p>
              </div>
              <div>
                <label className="block text-xs font-medium text-gray-500">Email Address</label>
                <p className="mt-1 text-sm text-gray-900">{application.email}</p>
              </div>
              <div>
                <label className="block text-xs font-medium text-gray-500">Phone Number</label>
                <p className="mt-1 text-sm text-gray-900">{application.phoneNumber}</p>
              </div>
            </div>
          </div>
        )}
        
        {/* Address Tab */}
        {currentTab === 'address' && (
          <div className="p-6">
            <h2 className="text-lg font-semibold mb-4">Address Information</h2>
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-x-6 gap-y-4">
              <div className="sm:col-span-2">
                <label className="block text-xs font-medium text-gray-500">Street Address</label>
                <p className="mt-1 text-sm text-gray-900">{application.streetAddress}</p>
              </div>
              <div>
                <label className="block text-xs font-medium text-gray-500">City</label>
                <p className="mt-1 text-sm text-gray-900">{application.city}</p>
              </div>
              <div>
                <label className="block text-xs font-medium text-gray-500">State</label>
                <p className="mt-1 text-sm text-gray-900">{application.state}</p>
              </div>
              <div>
                <label className="block text-xs font-medium text-gray-500">ZIP Code</label>
                <p className="mt-1 text-sm text-gray-900">{application.zipCode}</p>
              </div>
            </div>
          </div>
        )}
        
        {/* Identification Tab */}
        {currentTab === 'identification' && (
          <div className="p-6">
            <h2 className="text-lg font-semibold mb-4">Identification Information</h2>
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-x-6 gap-y-4">
              <div>
                <label className="block text-xs font-medium text-gray-500">Has SSN</label>
                <p className="mt-1 text-sm text-gray-900">{application.hasSSN === 'yes' ? 'Yes' : 'No'}</p>
              </div>
              <div>
                <label className="block text-xs font-medium text-gray-500">Social Security Number</label>
                <p className="mt-1 text-sm text-gray-900">
                  {application.ssn ? (
                    application.status === 'completed' || application.status === 'rejected' ? 
                    'XXX-XX-XXXX' : 
                    application.ssn
                  ) : 'Not provided'}
                </p>
              </div>
              <div>
                <label className="block text-xs font-medium text-gray-500">Identgo OCA Number</label>
                <p className="mt-1 text-sm text-gray-900">{application.identgoOCANumber || 'Not provided'}</p>
              </div>
            </div>
          </div>
        )}
        
        {/* Documents Tab */}
        {currentTab === 'documents' && (
          <div className="p-6">
            {applicationId && (
              <DocumentUploader 
                applicationId={applicationId} 
                onUploadComplete={fetchApplication} 
              />
            )}
            
            {application?.documents && application.documents.length > 0 ? (
              <div className="mt-4">
                <ul className="divide-y divide-gray-200">
                  {application.documents.map((doc, index) => (
                    <li key={index} className="py-4 flex items-start justify-between">
                      <div className="flex-1">
                        <h3 className="text-sm font-medium text-gray-900">{doc.fileName}</h3>
                        <div className="mt-1 text-xs text-gray-500">
                          {doc.uploadedAt && (
                            <span>Uploaded on {formatDate(doc.uploadedAt as any)} by {doc.uploadedBy}</span>
                          )}
                        </div>
                        {doc.description && <p className="mt-1 text-sm text-gray-500">{doc.description}</p>}
                      </div>
                      <button
                        onClick={() => handleDocumentDownload(doc)}
                        className="ml-4 font-medium text-blue-600 hover:text-blue-500 flex items-center"
                        aria-label={`Download ${doc.fileName}`}
                      >
                        <svg className="w-4 h-4 mr-1" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4" />
                        </svg>
                        Download
                      </button>
                    </li>
                  ))}
                </ul>
              </div>
            ) : (
              <div className="mt-4 text-center p-8 border border-dashed border-gray-300 rounded-lg">
                <svg className="mx-auto h-12 w-12 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z" />
                </svg>
                <p className="mt-2 text-sm text-gray-500">No documents have been uploaded yet.</p>
              </div>
            )}
          </div>
        )}
        
        {/* Administrative Notes Tab */}
        {currentTab === 'administrative' && (
          <div className="p-6">
            <div className="mb-6">
              <h2 className="text-lg font-semibold mb-4">Administrative Notes</h2>
              
              <div className="mb-6">
                <label htmlFor="note" className="block text-sm font-medium text-gray-700 mb-2">
                  Add Note
                </label>
                <div className="flex gap-2">
                  <textarea
                    id="note"
                    rows={3}
                    className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
                    value={noteContent}
                    onChange={(e) => setNoteContent(e.target.value)}
                    placeholder="Enter your note here..."
                  />
                  <button
                    onClick={handleAddNote}
                    disabled={addingNote || !noteContent.trim()}
                    className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50"
                  >
                    {addingNote ? (
                      <svg className="animate-spin h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                      </svg>
                    ) : (
                      'Add Note'
                    )}
                  </button>
                </div>
              </div>
              
              <div className="space-y-4">
                {application?.notes?.length === 0 && (
                  <p className="text-gray-500 italic">No notes added yet.</p>
                )}
                
                {application?.notes?.map((note, index) => (
                  <div key={index} className="bg-gray-50 rounded-lg p-4">
                    <p className="text-sm text-gray-900 whitespace-pre-wrap">{note.content}</p>
                    <p className="mt-2 text-xs text-gray-500">
                      Added by {note.createdBy} on {formatDate(note.createdAt)}
                    </p>
                  </div>
                ))}
              </div>
            </div>
            
            {/* Staff Actions History */}
            <div className="mt-8">
              <h3 className="text-lg font-semibold mb-4">Staff Actions</h3>
              <div className="space-y-4">
                {application?.completedBy && (
                  <div className="bg-green-50 rounded-lg p-4">
                    <p className="text-sm text-green-800">
                      Application completed by {application.completedBy}
                      {application.completedByEmail && ` (${application.completedByEmail})`}
                      {application.completedAt && ` on ${formatDate(application.completedAt)}`}
                    </p>
                  </div>
                )}
                
                {application?.rejectedBy && (
                  <div className="bg-red-50 rounded-lg p-4">
                    <p className="text-sm text-red-800">
                      Application rejected by {application.rejectedBy}
                      {application.rejectedByEmail && ` (${application.rejectedByEmail})`}
                      {application.rejectedAt && ` on ${formatDate(application.rejectedAt)}`}
                    </p>
                  </div>
                )}
                
                {!application?.completedBy && !application?.rejectedBy && (
                  <p className="text-gray-500 italic">No staff actions recorded yet.</p>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default HCAApplicationDetails; 