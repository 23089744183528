import React, { useEffect, useState } from 'react';
import { DashboardMetrics as DashboardMetricsType, fetchDashboardMetrics, subscribeToDashboardMetrics } from '../services/dashboardService';
import DashboardMetricCard from './Dashboard/DashboardMetricCard';
import { useNavigate } from 'react-router-dom';

// Icons for different metric cards
const CalendarIcon = () => (
  <svg className="h-6 w-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
  </svg>
);

const ApplicationIcon = () => (
  <svg className="h-6 w-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
  </svg>
);

const VisitorsIcon = () => (
  <svg className="h-6 w-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z" />
  </svg>
);

const PendingIcon = () => (
  <svg className="h-6 w-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
  </svg>
);

const CompletedIcon = () => (
  <svg className="h-6 w-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
  </svg>
);

// Pending Applications Icon
const PendingApplicationsIcon = () => (
  <svg className="h-6 w-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
  </svg>
);

// Loading skeleton for metrics
const MetricSkeleton = () => (
  <div className="bg-white rounded-lg shadow p-5 animate-pulse">
    <div className="rounded-full bg-gray-200 h-12 w-12 mb-3"></div>
    <div className="h-4 bg-gray-200 rounded mb-2 w-24"></div>
    <div className="h-8 bg-gray-200 rounded w-16"></div>
  </div>
);

const DashboardMetrics: React.FC = () => {
  const [metrics, setMetrics] = useState<DashboardMetricsType>({
    todayVisits: 0,
    todayAppointments: 0,
    todayApplications: 0,
    monthlyAppointments: 0,
    monthlyApplications: 0,
    pendingAppointments: 0,
    completedAppointments: 0,
    pendingApplications: 0
  });
  const [isLoading, setIsLoading] = useState(true);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [fetchError, setFetchError] = useState<string | null>(null);
  const [usingMockData, setUsingMockData] = useState(false);
  const navigate = useNavigate();

  // Function to fetch metrics data with retry
  const fetchMetricsWithRetry = async (retryCount = 0, maxRetries = 3) => {
    setIsRefreshing(true);
    setFetchError(null);
    setUsingMockData(false);
    
    try {
      console.log(`📊 Fetching dashboard metrics (attempt ${retryCount + 1}/${maxRetries + 1})...`);
      const data = await fetchDashboardMetrics();
      console.log("✅ Dashboard metrics fetched successfully:", data);
      
      // Check if this is mock data by checking if all metrics are the exact sample values
      const isMockData = data.todayAppointments === 3 && 
                         data.todayVisits === 5 && 
                         data.todayApplications === 2 && 
                         data.pendingApplications === 4;
      
      setUsingMockData(isMockData);
      if (isMockData) {
        console.log("⚠️ Using mock data because no real data was found in the database");
      }
      
      // Check if all metrics are zero, which might indicate a problem
      const allZeros = Object.values(data).every(value => value === 0);
      if (allZeros && retryCount < maxRetries) {
        console.warn(`⚠️ All metrics returned zero values. Retrying (${retryCount + 1}/${maxRetries})...`);
        setTimeout(() => fetchMetricsWithRetry(retryCount + 1, maxRetries), 2000);
        return;
      }
      
      setMetrics(data);
      setIsLoading(false);
      setIsRefreshing(false);
    } catch (error) {
      console.error('❌ Error loading dashboard metrics:', error);
      setFetchError('Failed to load metrics. Please try refreshing.');
      
      if (retryCount < maxRetries) {
        console.log(`🔄 Retrying (${retryCount + 1}/${maxRetries})...`);
        setTimeout(() => fetchMetricsWithRetry(retryCount + 1, maxRetries), 2000);
      } else {
        setIsLoading(false);
        setIsRefreshing(false);
      }
    }
  };

  // Initial data loading
  useEffect(() => {
    console.log("🔍 Dashboard component - Debugging metrics fetch");
    fetchMetricsWithRetry();
    
    // Set up real-time subscription for continuous updates
    console.log("🔔 Setting up real-time subscription for metrics");
    const unsubscribe = subscribeToDashboardMetrics((updatedMetrics) => {
      console.log("📊 Received updated metrics from real-time listener:", updatedMetrics);
      
      // Check if this is mock data by checking if all metrics are the exact sample values
      const isMockData = updatedMetrics.todayAppointments === 3 && 
                         updatedMetrics.todayVisits === 5 && 
                         updatedMetrics.todayApplications === 2 && 
                         updatedMetrics.pendingApplications === 4;
      
      setUsingMockData(isMockData);
      if (isMockData) {
        console.log("⚠️ Using mock data because no real data was found in the database");
      }
      
      setMetrics(updatedMetrics);
      if (isLoading) {
        setIsLoading(false);
      }
    });
    
    // Clean up subscription on unmount
    return () => {
      console.log("🧹 Cleaning up dashboard metrics subscription");
      unsubscribe();
    };
  }, []);

  if (isLoading) {
    return (
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 mb-8">
        {[...Array(4)].map((_, index) => (
          <MetricSkeleton key={index} />
        ))}
      </div>
    );
  }

  // Check if all metrics are zero, which might indicate a problem
  const allZeros = Object.values(metrics).every(value => value === 0);

  return (
    <>
      {/* Mock data notification */}
      {usingMockData && (
        <div className="mb-6 p-4 rounded-lg border bg-blue-50 border-blue-200">
          <div className="flex justify-between items-center">
            <div>
              <h3 className="font-medium text-blue-800">
                <span className="mr-2">ℹ️</span> Sample Data Mode
              </h3>
              <p className="text-sm text-blue-600">
                Currently displaying sample data. No real data found in the database. This is useful for UI testing.
              </p>
            </div>
            <button
              onClick={() => fetchMetricsWithRetry()}
              disabled={isRefreshing}
              className={`px-4 py-2 rounded-md text-white text-sm font-medium ${
                isRefreshing ? 'bg-gray-400 cursor-not-allowed' : 'bg-blue-600 hover:bg-blue-700'
              }`}
            >
              {isRefreshing ? (
                <div className="flex items-center">
                  <svg className="animate-spin -ml-1 mr-2 h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                  </svg>
                  Checking...
                </div>
              ) : 'Try Fetching Real Data'}
            </button>
          </div>
        </div>
      )}
      
      {/* Metrics display */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 mb-8">
        <DashboardMetricCard 
          title="Today's Appointments" 
          value={metrics.todayAppointments} 
          icon={<CalendarIcon />} 
          variant="primary"
          onClick={() => {
            // Format today's date as YYYY-MM-DD for the filter
            const today = new Date();
            const todayFormatted = `${today.getFullYear()}-${String(today.getMonth() + 1).padStart(2, '0')}-${String(today.getDate()).padStart(2, '0')}`;
            navigate(`/appointments?date=${todayFormatted}`);
          }}
        />
        <DashboardMetricCard 
          title="Today's Check-ins" 
          value={metrics.todayVisits} 
          icon={<VisitorsIcon />} 
          variant="info"
          onClick={() => navigate('/visits')}
        />
        <DashboardMetricCard 
          title="Pending Applications" 
          value={metrics.pendingApplications} 
          icon={<PendingApplicationsIcon />} 
          variant="pending"
          onClick={() => navigate('/applications?status=pending')}
        />
        <DashboardMetricCard 
          title="Completed Today" 
          value={metrics.completedAppointments} 
          icon={<CompletedIcon />} 
          variant="success"
          onClick={() => navigate('/appointments?status=completed')}
          className="group relative"
          aria-label={`${metrics.completedAppointments} total completed appointments and visitor check-ins for today`}
        />
      </div>
      
      {/* Error message or zero metrics message with refresh option */}
      {(fetchError || (allZeros && !usingMockData)) && (
        <div className={`mb-6 p-4 rounded-lg border ${fetchError ? 'bg-red-50 border-red-200' : 'bg-yellow-50 border-yellow-200'}`}>
          <div className="flex justify-between items-center">
            <div>
              <h3 className={`font-medium ${fetchError ? 'text-red-800' : 'text-yellow-800'}`}>
                {fetchError || 'No metrics data available'}
              </h3>
              <p className={`text-sm ${fetchError ? 'text-red-600' : 'text-yellow-600'}`}>
                {allZeros && !fetchError ? 'All metrics are showing zero, which might indicate a connection issue.' : 'Try refreshing to reload the data.'}
              </p>
            </div>
            <button
              onClick={() => fetchMetricsWithRetry()}
              disabled={isRefreshing}
              className={`px-4 py-2 rounded-md text-white text-sm font-medium ${
                isRefreshing ? 'bg-gray-400 cursor-not-allowed' : 'bg-blue-600 hover:bg-blue-700'
              }`}
            >
              {isRefreshing ? (
                <div className="flex items-center">
                  <svg className="animate-spin -ml-1 mr-2 h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                  </svg>
                  Refreshing...
                </div>
              ) : 'Refresh Metrics'}
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default DashboardMetrics; 