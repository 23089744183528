import React, { useState, useRef, useEffect } from 'react';
import { BankClient, AccountStatus } from '../types';
import { formatTimestamp, calculateTimePeriod, formatStatus, isManager } from '../utils';
import { updateClientStatus } from '../firebase/clientStatus';
import { toast } from 'react-hot-toast';
import { useAuth } from '../contexts/AuthContext';
import { doc, updateDoc, arrayUnion, serverTimestamp, Timestamp } from 'firebase/firestore';
import { db } from '../firebase/config';

interface AccountTimelineProps {
  client: BankClient;
}

// Define all possible account statuses
const ACCOUNT_STATUSES: AccountStatus[] = [
  'pending',
  'processing',
  'documents-requested',
  'activated',
  'onboarded-online',
  'funded',
  'dormant',
  'denied',
  'closed'
];

// Helper function to get status color
const getStatusColor = (status: AccountStatus): string => {
  switch (status) {
    case 'pending':
      return 'text-yellow-700 bg-yellow-50';
    case 'processing':
      return 'text-blue-700 bg-blue-50';
    case 'documents-requested':
      return 'text-orange-700 bg-orange-50';
    case 'activated':
      return 'text-green-700 bg-green-50';
    case 'onboarded-online':
      return 'text-emerald-700 bg-emerald-50';
    case 'funded':
      return 'text-purple-700 bg-purple-50';
    case 'dormant':
      return 'text-gray-700 bg-gray-50';
    case 'denied':
      return 'text-red-700 bg-red-50';
    case 'closed':
      return 'text-red-700 bg-red-50';
    default:
      return 'text-gray-700 bg-gray-50';
  }
};

const AccountTimeline: React.FC<AccountTimelineProps> = ({ client }) => {
  const [showStatusModal, setShowStatusModal] = useState(false);
  const [showUpdateForm, setShowUpdateForm] = useState(false);
  const [newStatus, setNewStatus] = useState<AccountStatus | ''>('');
  const [statusNote, setStatusNote] = useState('');
  const [isUpdatingStatus, setIsUpdatingStatus] = useState(false);
  const { currentUser } = useAuth();
  
  const modalRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);

  // Handle click outside to close modal
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        modalRef.current &&
        !modalRef.current.contains(event.target as Node) &&
        buttonRef.current &&
        !buttonRef.current.contains(event.target as Node)
      ) {
        setShowStatusModal(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  // Handle status update
  const handleStatusUpdate = async () => {
    if (!newStatus || !currentUser) {
      toast.error('Please select a status and ensure you are logged in');
      return;
    }

    // Don't update if status hasn't changed
    if (newStatus === client.accountStatus) {
      toast.error('Please select a different status');
      return;
    }

    setIsUpdatingStatus(true);
    try {
      // Update the client document in Firestore
      const clientRef = doc(db, 'bankClients', client.id);
      const activityLog = {
        timestamp: Timestamp.now(),
        action: 'Status Update',
        performedBy: currentUser.email || 'unknown',
        details: `Status changed from ${formatStatus(client.accountStatus)} to ${formatStatus(newStatus)}${statusNote ? ` - Note: ${statusNote}` : ''}`
      };

      await updateDoc(clientRef, {
        accountStatus: newStatus,
        updatedAt: serverTimestamp(),
        updatedBy: currentUser.email,
        activityLogs: arrayUnion(activityLog)
      });

      toast.success(`Status updated to ${formatStatus(newStatus)}`);
      
      // Close modals and reset state
      setShowUpdateForm(false);
      setShowStatusModal(false);
      setNewStatus('');
      setStatusNote('');
      
      // Refresh the page to show updated status
      window.location.reload();
    } catch (error: any) {
      console.error('Status update error:', error);
      toast.error(error.message || 'Failed to update status');
      setNewStatus(client.accountStatus); // Reset to current status on error
    } finally {
      setIsUpdatingStatus(false);
    }
  };

  return (
    <div className="p-4">
      <div className="flex justify-between items-center mb-6 px-4">
        <div className="flex-1">
          <h2 className="text-lg font-semibold text-gray-900">Account Processing Timeline</h2>
        </div>
        <button
          ref={buttonRef}
          onClick={() => setShowStatusModal(true)}
          className={`
            inline-flex items-center px-4 py-2 rounded-md text-sm font-medium
            transition-colors duration-200 ease-in-out justify-between min-w-[200px]
            ${getStatusColor(client.accountStatus)}
            hover:bg-opacity-80 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500
            shadow-sm border border-gray-200
          `}
        >
          <span>{client.accountStatus ? formatStatus(client.accountStatus) : 'Set Status'}</span>
          <svg 
            className="h-5 w-5 opacity-60 ml-2" 
            xmlns="http://www.w3.org/2000/svg" 
            viewBox="0 0 20 20" 
            fill="currentColor"
          >
            <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
          </svg>
        </button>
      </div>

      {/* Status Selection Modal */}
      {showStatusModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div
            ref={modalRef}
            className="bg-white rounded-lg shadow-xl max-w-md w-full mx-4 overflow-hidden"
          >
            <div className="px-4 py-3 border-b border-gray-200">
              <h3 className="text-lg font-semibold text-gray-900">Select Status</h3>
            </div>
            
            <div className="max-h-[400px] overflow-y-auto">
              {ACCOUNT_STATUSES.map((status) => (
                <button
                  key={status}
                  onClick={() => {
                    setNewStatus(status);
                    setShowStatusModal(false);
                    setShowUpdateForm(true);
                  }}
                  className={`
                    w-full text-left px-4 py-3 text-sm
                    transition-colors duration-150 ease-in-out
                    ${client.accountStatus === status 
                      ? `${getStatusColor(status)} font-medium` 
                      : 'text-gray-700 hover:bg-gray-50'
                    }
                    flex items-center justify-between border-b border-gray-100 last:border-0
                  `}
                >
                  <span>{formatStatus(status)}</span>
                  {client.accountStatus === status && (
                    <svg className="h-5 w-5 ml-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                      <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                    </svg>
                  )}
                </button>
              ))}
            </div>
            
            <div className="px-4 py-3 bg-gray-50 text-right">
              <button
                onClick={() => setShowStatusModal(false)}
                className="px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-100 rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Status Update Form */}
      {showUpdateForm && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center z-[60]">
          <div className="bg-white rounded-lg shadow-xl p-8 max-w-md w-full mx-4">
            <div className="flex justify-between items-center mb-4">
              <h3 className="text-lg font-medium text-gray-900">
                Update Status to {newStatus && formatStatus(newStatus)}
              </h3>
              <button
                onClick={() => {
                  setShowUpdateForm(false);
                  setNewStatus('');
                  setStatusNote('');
                }}
                className="text-gray-400 hover:text-gray-500"
              >
                <span className="sr-only">Close</span>
                <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                </svg>
              </button>
            </div>
            <div className="space-y-4">
              <div>
                <label htmlFor="statusNote" className="block text-sm font-medium text-gray-700">
                  Note
                </label>
                <textarea
                  id="statusNote"
                  value={statusNote}
                  onChange={(e) => setStatusNote(e.target.value)}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                  rows={3}
                  placeholder="Add a note about this status change..."
                />
              </div>
              <div className="flex justify-end space-x-3">
                <button
                  type="button"
                  onClick={() => {
                    setShowUpdateForm(false);
                    setNewStatus('');
                    setStatusNote('');
                  }}
                  className="inline-flex justify-center py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                >
                  Cancel
                </button>
                <button
                  type="button"
                  onClick={handleStatusUpdate}
                  disabled={isUpdatingStatus}
                  className="inline-flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50"
                >
                  {isUpdatingStatus ? (
                    <>
                      <svg className="animate-spin -ml-1 mr-2 h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                      </svg>
                      Updating...
                    </>
                  ) : (
                    'Update Status'
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Timeline */}
      <div className="flow-root">
        <ul role="list" className="-mb-8">
          {client.activityLogs?.map((activity, activityIdx) => (
            <li key={activityIdx}>
              <div className="relative pb-8">
                {activityIdx !== (client.activityLogs?.length ?? 0) - 1 ? (
                  <span className="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true" />
                ) : null}
                <div className="relative flex space-x-3">
                  <div>
                    <span className="h-8 w-8 rounded-full bg-blue-500 flex items-center justify-center ring-8 ring-white">
                      <svg className="h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                        <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                      </svg>
                    </span>
                  </div>
                  <div className="min-w-0 flex-1 pt-1.5 flex justify-between space-x-4">
                    <div>
                      <p className="text-sm text-gray-500">{activity.action}</p>
                      {activity.details && (
                        <p className="mt-1 text-sm text-gray-600">{activity.details}</p>
                      )}
                    </div>
                    <div className="text-right text-sm whitespace-nowrap text-gray-500">
                      <time dateTime={activity.timestamp?.toDate().toISOString()}>
                        {activity.timestamp?.toDate().toLocaleDateString()}
                      </time>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default AccountTimeline; 