import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Card from '../components/ui/Card';

// Resource type definition
interface Resource {
  id: string;
  title: string;
  description: string;
  type: ResourceType;
  url?: string;
  fileUrl?: string;
  thumbnail?: string;
  tags: string[];
  date: string;
  popularity?: number;
}

// Resource category types
type ResourceType = 'document' | 'guide' | 'community' | 'legal' | 'healthcare' | 'financial';

// Tab type for the navigation
type TabType = 'all' | ResourceType;

const ResourcesPage: React.FC = () => {
  const [activeTab, setActiveTab] = useState<TabType>('all');
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredResources, setFilteredResources] = useState<Resource[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  // Sample resource data (in a real application, this would come from a database)
  const resources: Resource[] = [
    {
      id: '1',
      title: 'Passport Application Guide',
      description: 'Step-by-step guide for completing your passport application correctly.',
      type: 'document',
      fileUrl: '/documents/passport-guide.pdf',
      tags: ['passport', 'application', 'travel'],
      date: '2024-05-01',
      popularity: 4.8
    },
    {
      id: '2',
      title: 'Visa Requirements Checklist',
      description: 'A comprehensive checklist of requirements for various visa types.',
      type: 'document',
      fileUrl: '/documents/visa-checklist.pdf',
      tags: ['visa', 'immigration', 'requirements'],
      date: '2024-04-15',
      popularity: 4.5
    },
    {
      id: '3',
      title: 'Community Support Programs',
      description: 'List of community support programs available to residents.',
      type: 'community',
      url: 'https://example.com/community-support',
      tags: ['community', 'support', 'programs'],
      date: '2024-03-22',
      popularity: 3.7
    },
    {
      id: '4',
      title: 'Legal Aid Resources',
      description: 'Information about free and low-cost legal assistance programs.',
      type: 'legal',
      url: 'https://example.com/legal-aid',
      tags: ['legal', 'aid', 'assistance'],
      date: '2024-02-18',
      popularity: 4.2
    },
    {
      id: '5',
      title: 'Healthcare Access Guide',
      description: 'Guide to accessing healthcare services in your area.',
      type: 'healthcare',
      fileUrl: '/documents/healthcare-guide.pdf',
      tags: ['healthcare', 'medical', 'services'],
      date: '2024-01-30',
      popularity: 4.7
    },
    {
      id: '6',
      title: 'Financial Assistance Programs',
      description: 'Overview of financial assistance programs available to eligible individuals.',
      type: 'financial',
      url: 'https://example.com/financial-assistance',
      tags: ['financial', 'assistance', 'support'],
      date: '2024-03-10',
      popularity: 3.9
    },
    {
      id: '7',
      title: 'Immigration Form I-90 Guide',
      description: 'How to correctly fill out and submit Form I-90 for green card renewal or replacement.',
      type: 'guide',
      fileUrl: '/documents/i90-guide.pdf',
      tags: ['immigration', 'I-90', 'green card', 'renewal'],
      date: '2024-04-05',
      popularity: 4.9
    },
    {
      id: '8',
      title: 'Business Registration Procedures',
      description: 'Step-by-step procedures for registering your business in the state.',
      type: 'document',
      fileUrl: '/documents/business-registration.pdf',
      tags: ['business', 'registration', 'legal'],
      date: '2024-02-25',
      popularity: 4.1
    }
  ];

  // Filter resources based on active tab and search query
  useEffect(() => {
    setIsLoading(true);
    
    // Simulate loading delay (remove in production)
    const timer = setTimeout(() => {
      let filtered = resources;
      
      // Filter by tab
      if (activeTab !== 'all') {
        filtered = filtered.filter(resource => resource.type === activeTab);
      }
      
      // Filter by search query
      if (searchQuery.trim() !== '') {
        const query = searchQuery.toLowerCase();
        filtered = filtered.filter(resource => 
          resource.title.toLowerCase().includes(query) || 
          resource.description.toLowerCase().includes(query) ||
          resource.tags.some(tag => tag.toLowerCase().includes(query))
        );
      }
      
      setFilteredResources(filtered);
      setIsLoading(false);
    }, 300);
    
    return () => clearTimeout(timer);
  }, [activeTab, searchQuery]);

  // Helper function to get the icon for a resource type
  const getResourceIcon = (type: ResourceType) => {
    switch (type) {
      case 'document':
        return (
          <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
          </svg>
        );
      case 'guide':
        return (
          <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 6.253v13m0-13C10.832 5.477 9.246 5 7.5 5S4.168 5.477 3 6.253v13C4.168 18.477 5.754 18 7.5 18s3.332.477 4.5 1.253m0-13C13.168 5.477 14.754 5 16.5 5c1.747 0 3.332.477 4.5 1.253v13C19.832 18.477 18.247 18 16.5 18c-1.746 0-3.332.477-4.5 1.253" />
          </svg>
        );
      case 'community':
        return (
          <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z" />
          </svg>
        );
      case 'legal':
        return (
          <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 6l3 1m0 0l-3 9a5.002 5.002 0 006.001 0M6 7l3 9M6 7l6-2m6 2l3-1m-3 1l-3 9a5.002 5.002 0 006.001 0M18 7l3 9m-3-9l-6-2m0-2v2m0 16V5m0 16H9m3 0h3" />
          </svg>
        );
      case 'healthcare':
        return (
          <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z" />
          </svg>
        );
      case 'financial':
        return (
          <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
          </svg>
        );
      default:
        return (
          <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
          </svg>
        );
    }
  };

  // Helper function to get human-readable names for resource types
  const getResourceTypeName = (type: ResourceType | 'all'): string => {
    switch (type) {
      case 'all': return 'All Resources';
      case 'document': return 'Documents & Forms';
      case 'guide': return 'Guides & Tutorials';
      case 'community': return 'Community Resources';
      case 'legal': return 'Legal Resources';
      case 'healthcare': return 'Healthcare Resources';
      case 'financial': return 'Financial Resources';
      default: return 'Other Resources';
    }
  };

  // Helper function to get a background color based on resource type
  const getResourceTypeColor = (type: ResourceType): string => {
    switch (type) {
      case 'document': return 'bg-blue-100 text-blue-800';
      case 'guide': return 'bg-purple-100 text-purple-800';
      case 'community': return 'bg-green-100 text-green-800';
      case 'legal': return 'bg-yellow-100 text-yellow-800';
      case 'healthcare': return 'bg-red-100 text-red-800';
      case 'financial': return 'bg-indigo-100 text-indigo-800';
      default: return 'bg-gray-100 text-gray-800';
    }
  };

  // Helper function to render popularity stars
  const renderPopularityStars = (popularity: number = 0) => {
    const maxStars = 5;
    const fullStars = Math.floor(popularity);
    const hasHalfStar = popularity % 1 >= 0.5;
    
    return (
      <div className="flex items-center space-x-1">
        {[...Array(maxStars)].map((_, i) => (
          <span key={i}>
            {i < fullStars ? (
              <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 text-yellow-500" viewBox="0 0 20 20" fill="currentColor">
                <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
              </svg>
            ) : hasHalfStar && i === fullStars ? (
              <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 text-yellow-500" viewBox="0 0 20 20" fill="currentColor">
                <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" fillOpacity="0.5" />
              </svg>
            ) : (
              <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 text-gray-300" viewBox="0 0 20 20" fill="currentColor">
                <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
              </svg>
            )}
          </span>
        ))}
        <span className="text-xs text-gray-500 ml-1">{popularity.toFixed(1)}</span>
      </div>
    );
  };

  // Array of tab types (for navigation)
  const tabs: TabType[] = ['all', 'document', 'guide', 'community', 'legal', 'healthcare', 'financial'];

  return (
    <div className="bg-white min-h-screen">
      {/* Hero Section - Enhanced */}
      <div className="bg-gradient-to-r from-blue-900 to-blue-700 text-white">
        <div className="max-w-7xl mx-auto px-4 py-16 sm:px-6 lg:px-8">
          <div className="text-center">
            <h1 className="text-4xl font-extrabold tracking-tight sm:text-5xl lg:text-6xl">
              Resources Center
            </h1>
            <p className="mt-6 max-w-3xl mx-auto text-xl text-blue-100">
              Access guides, documents, and resources to help you navigate our services and beyond.
            </p>
            <div className="mt-8 flex justify-center">
              <div className="inline-flex rounded-md shadow">
                <button 
                  onClick={() => setActiveTab('document')}
                  className="inline-flex items-center justify-center px-6 py-3 border border-transparent text-base font-medium rounded-md text-blue-700 bg-white hover:bg-blue-50"
                >
                  Browse Documents
                </button>
              </div>
              <div className="ml-3 inline-flex">
                <button 
                  onClick={() => setActiveTab('guide')}
                  className="inline-flex items-center justify-center px-6 py-3 border border-transparent text-base font-medium rounded-md text-white bg-blue-800 bg-opacity-60 hover:bg-opacity-70"
                >
                  Explore Guides
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      {/* Main Content */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        {/* Search Bar - Enhanced */}
        <div className="mb-10 max-w-2xl mx-auto">
          <div className="relative rounded-lg shadow-md overflow-hidden">
            <div className="absolute inset-y-0 left-0 pl-4 flex items-center pointer-events-none">
              <svg className="h-5 w-5 text-gray-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fillRule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clipRule="evenodd" />
              </svg>
            </div>
            <input
              type="text"
              className="focus:ring-blue-500 focus:border-blue-500 block w-full pl-12 pr-12 py-4 border-gray-300 rounded-lg text-gray-900 text-lg"
              placeholder="Search resources..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              aria-label="Search resources"
            />
            {searchQuery && (
              <button 
                onClick={() => setSearchQuery('')}
                className="absolute inset-y-0 right-0 pr-4 flex items-center text-gray-400 hover:text-gray-600"
                aria-label="Clear search"
              >
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                  <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clipRule="evenodd" />
                </svg>
              </button>
            )}
          </div>
        </div>
        
        {/* Tabs Navigation - Enhanced */}
        <div className="border-b border-gray-200 mb-10">
          <div className="flex overflow-x-auto hide-scrollbar space-x-1 md:space-x-4 justify-center">
            {tabs.map((tab) => (
              <button
                key={tab}
                onClick={() => setActiveTab(tab)}
                className={`whitespace-nowrap py-4 px-3 border-b-2 font-medium text-sm md:text-base flex items-center transition-all duration-200 ${
                  activeTab === tab
                    ? 'border-blue-600 text-blue-700 font-semibold'
                    : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
                }`}
                aria-current={activeTab === tab ? 'page' : undefined}
                tabIndex={0}
                aria-label={`Show ${getResourceTypeName(tab)} tab`}
              >
                {tab !== 'all' && (
                  <span className={`mr-2 p-1 rounded-md ${activeTab === tab ? getResourceTypeColor(tab as ResourceType) : 'bg-gray-100 text-gray-500'}`}>
                    {getResourceIcon(tab as ResourceType)}
                  </span>
                )}
                {getResourceTypeName(tab)}
                {tab !== 'all' && (
                  <span className="ml-2 bg-gray-100 text-gray-600 px-2 py-0.5 rounded-full text-xs">
                    {resources.filter(r => r.type === tab).length}
                  </span>
                )}
              </button>
            ))}
          </div>
        </div>
        
        {/* Resources Grid */}
        <div className="mt-8">
          <h2 className="text-2xl font-bold text-gray-900 mb-6">{getResourceTypeName(activeTab)}</h2>
          
          {isLoading ? (
            <div className="flex justify-center py-12">
              <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-900"></div>
            </div>
          ) : filteredResources.length > 0 ? (
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
              {filteredResources.map((resource) => (
                <Card 
                  key={resource.id} 
                  className="h-full flex flex-col overflow-hidden hover:shadow-lg transition-shadow duration-300 border border-gray-100 rounded-xl"
                >
                  <div className="p-6 flex-1 flex flex-col relative">
                    {/* Badge and Date */}
                    <div className="flex justify-between items-start">
                      <span className={`inline-flex items-center px-3 py-1 rounded-full text-xs font-medium ${getResourceTypeColor(resource.type)}`}>
                        {getResourceTypeName(resource.type)}
                      </span>
                      <span className="text-xs text-gray-500">
                        {new Date(resource.date).toLocaleDateString(undefined, {
                          year: 'numeric',
                          month: 'short',
                          day: 'numeric'
                        })}
                      </span>
                    </div>
                    
                    {/* Resource Content */}
                    <div className="mt-4 flex items-start space-x-4">
                      <div className="flex-shrink-0 mt-1 p-2 rounded-full bg-gray-100">
                        {getResourceIcon(resource.type)}
                      </div>
                      <div>
                        <h3 className="text-lg font-semibold text-gray-900 mb-2">{resource.title}</h3>
                        <p className="text-gray-600 text-sm flex-grow mb-4">{resource.description}</p>
                        
                        {/* Popularity Rating */}
                        {resource.popularity && (
                          <div className="mb-4">
                            {renderPopularityStars(resource.popularity)}
                          </div>
                        )}
                      </div>
                    </div>
                    
                    {/* "Coming Soon" placeholder for links/downloads */}
                    <div className="mt-auto">
                      <div className="flex items-center mt-4 py-2 px-4 bg-gray-50 rounded-lg">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-gray-400 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                        <div>
                          <span className="text-sm font-medium text-gray-700">
                            {resource.fileUrl ? 'Download' : 'View Resource'}
                          </span>
                          <div className="flex items-center">
                            <span className="text-xs text-gray-500">Coming soon</span>
                            <span className="ml-2 inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-blue-100 text-blue-800">
                              Pending
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    
                    {/* Tags */}
                    {resource.tags && resource.tags.length > 0 && (
                      <div className="mt-4 flex flex-wrap gap-2">
                        {resource.tags.map((tag, index) => (
                          <span 
                            key={index} 
                            className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800 hover:bg-gray-200 cursor-pointer transition-colors duration-200"
                            onClick={() => setSearchQuery(tag)}
                            tabIndex={0}
                            onKeyDown={(e) => e.key === 'Enter' && setSearchQuery(tag)}
                            aria-label={`Search for ${tag}`}
                          >
                            #{tag}
                          </span>
                        ))}
                      </div>
                    )}
                  </div>
                </Card>
              ))}
            </div>
          ) : (
            <div className="bg-gray-50 rounded-lg py-12 px-6 text-center">
              <svg xmlns="http://www.w3.org/2000/svg" className="mx-auto h-12 w-12 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9.172 16.172a4 4 0 015.656 0M9 10h.01M15 10h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
              </svg>
              <h3 className="mt-2 text-lg font-medium text-gray-900">No resources found</h3>
              <p className="mt-1 text-sm text-gray-500">
                No resources match your current search criteria. Try adjusting your search or select a different category.
              </p>
              <div className="mt-6">
                <button
                  type="button"
                  onClick={() => {
                    setSearchQuery('');
                    setActiveTab('all');
                  }}
                  className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                  aria-label="Clear all filters"
                >
                  Clear filters
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
      
      {/* Need Help Section - Enhanced */}
      <div className="bg-gray-50 py-16">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="bg-gradient-to-r from-blue-700 to-indigo-700 rounded-2xl shadow-xl overflow-hidden">
            <div className="px-6 py-12 sm:p-12 relative">
              <div className="absolute top-0 right-0 -mt-20 -mr-20 hidden lg:block">
                <svg width="404" height="404" fill="none" viewBox="0 0 404 404" aria-hidden="true" className="text-blue-500 text-opacity-20">
                  <defs>
                    <pattern id="85737c0e-0916-41d7-917f-596dc7edfa27" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                      <rect x="0" y="0" width="4" height="4" className="text-blue-200" fill="currentColor" />
                    </pattern>
                  </defs>
                  <rect width="404" height="404" fill="url(#85737c0e-0916-41d7-917f-596dc7edfa27)" />
                </svg>
              </div>
              <div className="flex flex-col items-center text-center">
                <h3 className="text-3xl leading-9 font-extrabold text-white sm:text-4xl sm:leading-10">
                  Need Additional Help?
                </h3>
                <div className="mt-5 text-lg text-blue-100 max-w-3xl">
                  Our team is here to help you find the resources you need. Contact us for personalized assistance with any questions about the available resources.
                </div>
                <div className="mt-8 flex flex-col sm:flex-row justify-center gap-4">
                  <div className="inline-flex rounded-md shadow">
                    <Link to="/contact" className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-blue-700 bg-white hover:bg-blue-50 transition-colors duration-200">
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 10h.01M12 10h.01M16 10h.01M9 16H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-5l-5 5v-5z" />
                      </svg>
                      Contact Support
                    </Link>
                  </div>
                  <div className="inline-flex">
                    <Link to="/services" className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-blue-800 bg-opacity-60 hover:bg-opacity-70 transition-colors duration-200">
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M21 12a9 9 0 01-9 9m9-9a9 9 0 00-9-9m9 9H3m9 9a9 9 0 01-9-9m9 9c1.657 0 3-4.03 3-9s-1.343-9-3-9m0 18c-1.657 0-3-4.03-3-9s1.343-9 3-9m-9 9a9 9 0 019-9" />
                      </svg>
                      Browse Services
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      {/* Resource Categories Overview - Enhanced */}
      <div className="max-w-7xl mx-auto px-4 py-16 sm:px-6 lg:px-8">
        <div className="text-center mb-12">
          <h2 className="text-3xl font-extrabold text-gray-900">Resource Categories</h2>
          <p className="mt-4 text-lg text-gray-500 max-w-3xl mx-auto">
            Explore our different categories of resources to find what you need
          </p>
        </div>
        
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {tabs.filter(tab => tab !== 'all').map((type) => (
            <button
              key={type}
              onClick={() => setActiveTab(type)}
              className="bg-white rounded-xl p-8 shadow-md hover:shadow-xl transition-all duration-300 text-left transform hover:-translate-y-1 border border-gray-100"
              tabIndex={0}
              aria-label={`View ${getResourceTypeName(type)} resources`}
            >
              <div className="flex items-start">
                <div className={`p-3 rounded-xl ${getResourceTypeColor(type as ResourceType)} mr-5`}>
                  {getResourceIcon(type as ResourceType)}
                </div>
                <div>
                  <h3 className="text-xl font-bold text-gray-900">{getResourceTypeName(type)}</h3>
                  <p className="text-sm text-gray-600 mt-2">
                    {type === 'document' && 'Forms, applications, and official documents for various services'}
                    {type === 'guide' && 'Step-by-step tutorials and instructional materials for procedures'}
                    {type === 'community' && 'Resources for community support, events, and local services'}
                    {type === 'legal' && 'Legal assistance, information, and resources for legal matters'}
                    {type === 'healthcare' && 'Health services, medical resources, and wellness information'}
                    {type === 'financial' && 'Financial planning, assistance programs, and money management'}
                  </p>
                  <div className="mt-4 flex items-center text-blue-600">
                    <span className="text-sm font-medium">View resources</span>
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 ml-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
                    </svg>
                  </div>
                </div>
              </div>
              <div className="mt-4 flex justify-between items-center">
                <span className="text-xs text-gray-500">
                  {resources.filter(r => r.type === type).length} resources available
                </span>
                <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-800">
                  {type === 'document' || type === 'guide' ? 'Downloadable' : 'Online'}
                </span>
              </div>
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ResourcesPage; 