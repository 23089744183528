import React, { useState, useEffect, useMemo } from 'react';
import { format } from 'date-fns';
import { getCompletedVisitsByDateRange } from '../../services/visitorService';
import { getVisitCompletionDetails } from '../../services/visitorService';
import { saveAs } from 'file-saver';
import { utils, write } from 'xlsx';
import { Search, Download, Filter } from 'lucide-react';

interface CompletedVisitsReportProps {
  startDate: Date;
  endDate: Date;
}

interface CompletedVisit {
  id: string;
  visitorName: string;
  phoneNumber: string;
  service: string;
  checkInTime: any;
  completionDetails?: {
    feedback: string;
    serviceRendered: boolean;
    wasCharged: boolean;
    amountCharged?: number;
    reasonNotRendered?: string;
    completedAt: any;
    completedBy: string;
    completedByName: string;
  };
}

const CompletedVisitsReport: React.FC<CompletedVisitsReportProps> = ({ startDate, endDate }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [completedVisits, setCompletedVisits] = useState<CompletedVisit[]>([]);
  const [detailsLoaded, setDetailsLoaded] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [filters, setFilters] = useState({
    serviceRendered: null as boolean | null,
    wasCharged: null as boolean | null,
  });
  
  // Fetch completed visits when date range changes
  useEffect(() => {
    const fetchCompletedVisits = async () => {
      setIsLoading(true);
      try {
        const visits = await getCompletedVisitsByDateRange(startDate, endDate);
        setCompletedVisits(visits.map(visit => ({
          id: visit.id,
          visitorName: visit.visitorName,
          phoneNumber: visit.phoneNumber,
          service: visit.service,
          checkInTime: visit.checkInTime,
        })));
        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching completed visits:', error);
        setIsLoading(false);
      }
    };
    
    fetchCompletedVisits();
  }, [startDate, endDate]);
  
  // Fetch completion details for all visits
  useEffect(() => {
    const fetchCompletionDetails = async () => {
      if (completedVisits.length === 0 || detailsLoaded) return;
      
      try {
        const updatedVisits = [...completedVisits];
        let updated = false;
        
        // Process in batches to avoid overwhelming the API
        const batchSize = 10;
        for (let i = 0; i < updatedVisits.length; i += batchSize) {
          const batch = updatedVisits.slice(i, i + batchSize);
          const promises = batch.map(async (visit) => {
            if (!visit.completionDetails) {
              const details = await getVisitCompletionDetails(visit.id);
              if (details) {
                visit.completionDetails = {
                  feedback: details.feedback,
                  serviceRendered: details.serviceRendered,
                  wasCharged: details.wasCharged,
                  amountCharged: details.amountCharged,
                  reasonNotRendered: details.reasonNotRendered,
                  completedAt: details.completedAt,
                  completedBy: details.completedBy,
                  completedByName: details.completedByName,
                };
                updated = true;
              }
            }
            return visit;
          });
          
          await Promise.all(promises);
        }
        
        if (updated) {
          setCompletedVisits(updatedVisits);
        }
        
        setDetailsLoaded(true);
      } catch (error) {
        console.error('Error fetching completion details:', error);
      }
    };
    
    fetchCompletionDetails();
  }, [completedVisits, detailsLoaded]);
  
  // Reset details loaded flag when visits change
  useEffect(() => {
    if (completedVisits.length > 0) {
      setDetailsLoaded(false);
    }
  }, [completedVisits.length]);
  
  // Filter and search functionality
  const filteredVisits = useMemo(() => {
    return completedVisits.filter(visit => {
      // Search filter
      const searchMatch = searchTerm === '' || 
        visit.visitorName.toLowerCase().includes(searchTerm.toLowerCase()) ||
        visit.phoneNumber.includes(searchTerm) ||
        visit.service.toLowerCase().includes(searchTerm.toLowerCase()) ||
        (visit.completionDetails?.feedback && visit.completionDetails.feedback.toLowerCase().includes(searchTerm.toLowerCase()));
      
      // Service rendered filter
      const serviceRenderedMatch = filters.serviceRendered === null || 
        (visit.completionDetails && visit.completionDetails.serviceRendered === filters.serviceRendered);
      
      // Was charged filter
      const wasChargedMatch = filters.wasCharged === null || 
        (visit.completionDetails && visit.completionDetails.wasCharged === filters.wasCharged);
      
      return searchMatch && serviceRenderedMatch && wasChargedMatch;
    });
  }, [completedVisits, searchTerm, filters]);
  
  // Export to Excel
  const handleExport = () => {
    const dataToExport = filteredVisits.map(visit => ({
      'Visitor Name': visit.visitorName,
      'Phone Number': visit.phoneNumber,
      'Service': visit.service,
      'Check-in Date': format(toDate(visit.checkInTime), 'MMM d, yyyy'),
      'Check-in Time': format(toDate(visit.checkInTime), 'h:mm a'),
      'Service Rendered': visit.completionDetails?.serviceRendered ? 'Yes' : 'No',
      'Service Charged': visit.completionDetails?.wasCharged ? 'Yes' : 'No',
      'Amount Charged': visit.completionDetails?.amountCharged || 0,
      'Served By': visit.completionDetails?.completedByName || 'Unknown',
      'Feedback': visit.completionDetails?.feedback || '',
      'Reason Not Rendered': visit.completionDetails?.reasonNotRendered || '',
    }));
    
    const worksheet = utils.json_to_sheet(dataToExport);
    const workbook = utils.book_new();
    utils.book_append_sheet(workbook, worksheet, 'Completed Visits');
    
    const excelBuffer = write(workbook, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    
    // Generate filename with date range
    const fileName = `Completed_Visits_${format(startDate, 'yyyy-MM-dd')}_to_${format(endDate, 'yyyy-MM-dd')}.xlsx`;
    saveAs(data, fileName);
  };
  
  // Helper function to convert any timestamp to Date
  const toDate = (timestamp: any): Date => {
    if (!timestamp) return new Date();
    
    // Firebase Timestamp
    if (timestamp?.toDate && typeof timestamp.toDate === 'function') {
      return timestamp.toDate();
    }
    
    // Already a Date
    if (timestamp instanceof Date) {
      return timestamp;
    }
    
    // String, number, or other format
    try {
      return new Date(timestamp);
    } catch (error) {
      console.error("Error converting timestamp to Date:", error);
      return new Date();
    }
  };
  
  // Reset filters
  const handleResetFilters = () => {
    setFilters({
      serviceRendered: null,
      wasCharged: null,
    });
    setSearchTerm('');
  };
  
  return (
    <div>
      <div className="mb-6 flex flex-col md:flex-row justify-between items-start gap-4">
        <div className="w-full md:w-1/2">
          <div className="relative">
            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              <Search className="h-5 w-5 text-gray-400" />
            </div>
            <input
              type="text"
              className="block w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md leading-5 bg-white placeholder-gray-500 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              placeholder="Search by name, phone, service or feedback..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
        </div>
        
        <div className="flex flex-wrap gap-3 w-full md:w-auto">
          <div className="inline-flex">
            <button
              onClick={() => setFilters({...filters, serviceRendered: filters.serviceRendered === true ? null : true})}
              className={`px-4 py-2 text-sm rounded-l-md border ${
                filters.serviceRendered === true
                  ? 'bg-green-100 border-green-500 text-green-800'
                  : 'bg-white border-gray-300 text-gray-700 hover:bg-gray-50'
              }`}
            >
              Service Rendered
            </button>
            <button
              onClick={() => setFilters({...filters, serviceRendered: filters.serviceRendered === false ? null : false})}
              className={`px-4 py-2 text-sm rounded-r-md border-t border-b border-r ${
                filters.serviceRendered === false
                  ? 'bg-red-100 border-red-500 text-red-800'
                  : 'bg-white border-gray-300 text-gray-700 hover:bg-gray-50'
              }`}
            >
              Not Rendered
            </button>
          </div>
          
          <div className="inline-flex">
            <button
              onClick={() => setFilters({...filters, wasCharged: filters.wasCharged === true ? null : true})}
              className={`px-4 py-2 text-sm rounded-l-md border ${
                filters.wasCharged === true
                  ? 'bg-blue-100 border-blue-500 text-blue-800'
                  : 'bg-white border-gray-300 text-gray-700 hover:bg-gray-50'
              }`}
            >
              Charged
            </button>
            <button
              onClick={() => setFilters({...filters, wasCharged: filters.wasCharged === false ? null : false})}
              className={`px-4 py-2 text-sm rounded-r-md border-t border-b border-r ${
                filters.wasCharged === false
                  ? 'bg-yellow-100 border-yellow-500 text-yellow-800'
                  : 'bg-white border-gray-300 text-gray-700 hover:bg-gray-50'
              }`}
            >
              Not Charged
            </button>
          </div>
          
          <button
            onClick={handleResetFilters}
            className="inline-flex items-center px-3 py-2 border border-gray-300 text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500"
          >
            <Filter className="h-4 w-4 mr-1" />
            Reset
          </button>
          
          <button
            onClick={handleExport}
            className="inline-flex items-center px-3 py-2 border border-gray-300 text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500"
          >
            <Download className="h-4 w-4 mr-1" />
            Export
          </button>
        </div>
      </div>
      
      {isLoading ? (
        <div className="flex justify-center items-center py-12">
          <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-700"></div>
        </div>
      ) : (
        <>
          <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Visitor
                  </th>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Service
                  </th>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Date & Time
                  </th>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Service Rendered
                  </th>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Amount
                  </th>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Served By
                  </th>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Feedback
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {filteredVisits.length === 0 ? (
                  <tr>
                    <td colSpan={7} className="px-6 py-12 text-center text-gray-500">
                      {completedVisits.length === 0 
                        ? 'No completed visits found for the selected date range.' 
                        : 'No visits match your search criteria.'}
                    </td>
                  </tr>
                ) : (
                  filteredVisits.map((visit) => (
                    <tr key={visit.id} className="hover:bg-gray-50">
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="text-sm font-medium text-gray-900">{visit.visitorName}</div>
                        <div className="text-sm text-gray-500">{visit.phoneNumber}</div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="text-sm text-gray-900">{visit.service}</div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="text-sm text-gray-900">{format(toDate(visit.checkInTime), 'MMM d, yyyy')}</div>
                        <div className="text-sm text-gray-500">{format(toDate(visit.checkInTime), 'h:mm a')}</div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        {visit.completionDetails ? (
                          <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                            visit.completionDetails.serviceRendered 
                              ? 'bg-green-100 text-green-800' 
                              : 'bg-red-100 text-red-800'
                          }`}>
                            {visit.completionDetails.serviceRendered ? 'Yes' : 'No'}
                          </span>
                        ) : (
                          <span className="text-sm text-gray-500">Loading...</span>
                        )}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        {visit.completionDetails ? (
                          visit.completionDetails.serviceRendered && visit.completionDetails.wasCharged ? (
                            <div className="text-sm text-gray-900">${visit.completionDetails.amountCharged?.toFixed(2) || '0.00'}</div>
                          ) : (
                            <div className="text-sm text-gray-500">
                              {visit.completionDetails.serviceRendered ? 'No charge' : 'N/A'}
                            </div>
                          )
                        ) : (
                          <span className="text-sm text-gray-500">Loading...</span>
                        )}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        {visit.completionDetails ? (
                          <div className="text-sm text-gray-900">
                            {visit.completionDetails.completedByName || 'Unknown'}
                          </div>
                        ) : (
                          <span className="text-sm text-gray-500">Loading...</span>
                        )}
                      </td>
                      <td className="px-6 py-4">
                        <div className="text-sm text-gray-900 max-w-xs truncate">
                          {visit.completionDetails ? (
                            visit.completionDetails.serviceRendered ? 
                              visit.completionDetails.feedback : 
                              <span className="text-red-600">{visit.completionDetails.reasonNotRendered}</span>
                          ) : (
                            <span className="text-sm text-gray-500">Loading...</span>
                          )}
                        </div>
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
          
          <div className="mt-4 text-sm text-gray-500">
            Showing {filteredVisits.length} of {completedVisits.length} completed visits
          </div>
        </>
      )}
    </div>
  );
};

export default CompletedVisitsReport; 