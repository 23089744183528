import React from 'react';
import { User } from '../../services/userService';

interface MessageListProps {
  users: User[];
  selectedUser: User | null;
  onSelectUser: (user: User) => void;
  unreadCounts: Record<string, number>;
}

const MessageList: React.FC<MessageListProps> = ({ users, selectedUser, onSelectUser, unreadCounts }) => {
  return (
    <div className="divide-y divide-gray-200">
      {users.map((user) => {
        const unreadCount = unreadCounts[user.uid] || 0;
        
        return (
          <button
            key={user.uid}
            onClick={() => onSelectUser(user)}
            className={`w-full px-4 py-3 flex items-center hover:bg-gray-50 focus:outline-none focus:bg-gray-50 transition-colors duration-150 ${
              selectedUser?.uid === user.uid ? 'bg-blue-50' : ''
            }`}
          >
            <div className="flex-shrink-0 relative">
              <div className="w-10 h-10 rounded-full bg-gray-300 flex items-center justify-center">
                {user.photoURL ? (
                  <img
                    src={user.photoURL}
                    alt={user.displayName}
                    className="w-10 h-10 rounded-full"
                  />
                ) : (
                  <span className="text-lg font-medium text-white">
                    {user.displayName?.charAt(0).toUpperCase()}
                  </span>
                )}
              </div>
              {unreadCount > 0 && (
                <span className="absolute -top-1 -right-1 bg-red-500 text-white text-xs font-bold rounded-full w-5 h-5 flex items-center justify-center">
                  {unreadCount > 99 ? '99+' : unreadCount}
                </span>
              )}
            </div>
            <div className="ml-3 flex-1 text-left">
              <p className="text-sm font-medium text-gray-900">{user.displayName}</p>
              <p className="text-sm text-gray-500">{user.department || user.role}</p>
            </div>
          </button>
        );
      })}
    </div>
  );
};

export default MessageList; 