import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import AccessDenied from './AccessDenied';

interface RoleBasedRouteProps {
  children: React.ReactNode;
  allowedRoles: string[];
  restricedUserIds?: string[];
  allowUnauthenticated?: boolean;
}

const RoleBasedRoute: React.FC<RoleBasedRouteProps> = ({ 
  children, 
  allowedRoles, 
  restricedUserIds = [],
  allowUnauthenticated = false
}) => {
  const { currentUser, loading } = useAuth();
  const location = useLocation();

  if (loading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-900"></div>
      </div>
    );
  }

  if (allowUnauthenticated) {
    return <>{children}</>;
  }

  if (!currentUser) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  if (restricedUserIds.includes(currentUser.uid)) {
    const isBankServicesRoute = location.pathname === '/bank-services' || 
                              location.pathname.startsWith('/bank-services/');
    
    if (!isBankServicesRoute) {
      return <AccessDenied />;
    }
  }

  if (
    allowedRoles.length > 0 && 
    currentUser.role && 
    !allowedRoles.includes(currentUser.role) && 
    currentUser.role !== 'admin'
  ) {
    return <AccessDenied />;
  }

  return <>{children}</>;
};

export default RoleBasedRoute; 