import React, { useState, useEffect } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { collection, query, orderBy, onSnapshot, where, doc, updateDoc, Timestamp, limit, startAfter, getDocs } from 'firebase/firestore';
import { db } from '../firebase/config';
import { useAuth } from '../contexts/AuthContext';
import Card from '../components/ui/Card';
import Button from '../components/ui/Button';

interface Appointment {
  id: string;
  fullName: string;
  email: string;
  phoneNumber: string;
  service: string;
  appointmentDate: string;
  appointmentTime: string;
  additionalNotes?: string;
  status: 'scheduled' | 'completed' | 'cancelled' | 'no-show';
  createdAt: Timestamp;
  dateTime: Timestamp;
}

// Sort options for the appointments
type SortOption = 'upcoming' | 'latest' | 'oldest';

const AppointmentsPage: React.FC = () => {
  const { currentUser } = useAuth();
  const [appointments, setAppointments] = useState<Appointment[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [selectedDate, setSelectedDate] = useState<string>('');
  const [selectedStatus, setSelectedStatus] = useState<string>('all');
  const [searchTerm, setSearchTerm] = useState('');
  const [isUpdating, setIsUpdating] = useState(false);
  
  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const [lastVisibleAppointment, setLastVisibleAppointment] = useState<any>(null);
  const [hasMoreAppointments, setHasMoreAppointments] = useState(true);
  const [totalAppointments, setTotalAppointments] = useState(0);
  const appointmentsPerPage = 8;
  
  // Sorting state
  const [sortOption, setSortOption] = useState<SortOption>('latest');
  
  // Initialize search params
  const [searchParams] = useSearchParams();
  
  // Read URL parameters on component mount
  useEffect(() => {
    // Check for date parameter
    const dateParam = searchParams.get('date');
    if (dateParam) {
      setSelectedDate(dateParam);
    }
    
    // Check for status parameter
    const statusParam = searchParams.get('status');
    if (statusParam) {
      setSelectedStatus(statusParam);
    }
    
    // Check for sort option
    const sortParam = searchParams.get('sort');
    if (sortParam && (sortParam === 'upcoming' || sortParam === 'latest' || sortParam === 'oldest')) {
      setSortOption(sortParam);
    }
  }, [searchParams]);
  
  // Subscribe to appointments collection with real-time updates
  useEffect(() => {
    setLoading(true);
    setAppointments([]);
    setCurrentPage(1);
    setLastVisibleAppointment(null);
    
    // Get total count of appointments for pagination
    const countQuery = buildQuery(true);
    getDocs(countQuery).then(snapshot => {
      setTotalAppointments(snapshot.size);
    }).catch(error => {
      console.error('Error counting appointments:', error);
    });
    
    // Fetch first page of appointments
    fetchAppointments();
    
  }, [selectedDate, selectedStatus, sortOption]);
  
  // Build the query based on filters and sorting
  const buildQuery = (forCount = false) => {
    let appointmentsQuery: any;
    const baseCollection = collection(db, 'appointments');
    
    // Apply sorting
    if (sortOption === 'upcoming') {
      // For upcoming, we want future dates first, sorted by nearest date
      const now = new Date();
      const nowTimestamp = Timestamp.fromDate(now);
      
      if (selectedStatus !== 'all') {
        appointmentsQuery = query(
          baseCollection,
          where('status', '==', selectedStatus),
          where('dateTime', '>=', nowTimestamp),
          orderBy('dateTime', 'asc')
        );
      } else {
        appointmentsQuery = query(
          baseCollection,
          where('dateTime', '>=', nowTimestamp),
          orderBy('dateTime', 'asc')
        );
      }
    } else if (sortOption === 'latest') {
      // Latest created appointments first
      if (selectedStatus !== 'all') {
        appointmentsQuery = query(
          baseCollection,
          where('status', '==', selectedStatus),
          orderBy('createdAt', 'desc')
        );
      } else {
        appointmentsQuery = query(
          baseCollection,
          orderBy('createdAt', 'desc')
        );
      }
    } else {
      // Oldest created appointments first
      if (selectedStatus !== 'all') {
        appointmentsQuery = query(
          baseCollection,
          where('status', '==', selectedStatus),
          orderBy('createdAt', 'asc')
        );
      } else {
        appointmentsQuery = query(
          baseCollection,
          orderBy('createdAt', 'asc')
        );
      }
    }
    
    // Filter by date if selected
    if (selectedDate) {
      // Reset the base query first to avoid conflicts with previous where clauses
      if (selectedStatus !== 'all') {
        appointmentsQuery = query(
          baseCollection,
          where('appointmentDate', '==', selectedDate),
          where('status', '==', selectedStatus),
          orderBy('appointmentTime', 'asc')
        );
      } else {
        appointmentsQuery = query(
          baseCollection,
          where('appointmentDate', '==', selectedDate),
          orderBy('appointmentTime', 'asc')
        );
      }
    }
    
    // If not counting, apply pagination
    if (!forCount) {
      // If we have a last visible document and not on first page
      if (lastVisibleAppointment && currentPage > 1) {
        appointmentsQuery = query(
          appointmentsQuery,
          startAfter(lastVisibleAppointment),
          limit(appointmentsPerPage)
        );
      } else {
        appointmentsQuery = query(
          appointmentsQuery,
          limit(appointmentsPerPage)
        );
      }
    }
    
    return appointmentsQuery;
  };
  
  // Fetch appointments with pagination
  const fetchAppointments = async (loadMore = false) => {
    setLoading(true);
    
    try {
      const appointmentsQuery = buildQuery();
      const snapshot = await getDocs(appointmentsQuery);
      
      // Check if we have more results
      if (snapshot.docs.length < appointmentsPerPage) {
        setHasMoreAppointments(false);
      } else {
        setHasMoreAppointments(true);
      }
      
      // Get the last visible document for pagination
      const lastVisible = snapshot.docs[snapshot.docs.length - 1];
      setLastVisibleAppointment(lastVisible);
      
      const appointmentsData: Appointment[] = [];
      snapshot.forEach((doc) => {
        const data = doc.data() as {
          fullName: string;
          email: string;
          phoneNumber: string;
          service: string;
          appointmentDate: string;
          appointmentTime: string;
          additionalNotes?: string;
          status: 'scheduled' | 'completed' | 'cancelled' | 'no-show';
          createdAt: Timestamp;
          dateTime: Timestamp;
        };
        appointmentsData.push({
          id: doc.id,
          fullName: data.fullName,
          email: data.email,
          phoneNumber: data.phoneNumber,
          service: data.service,
          appointmentDate: data.appointmentDate,
          appointmentTime: data.appointmentTime,
          additionalNotes: data.additionalNotes,
          status: data.status,
          createdAt: data.createdAt,
          dateTime: data.dateTime
        });
      });
      
      if (loadMore) {
        setAppointments(prev => [...prev, ...appointmentsData]);
      } else {
        setAppointments(appointmentsData);
      }
    } catch (error) {
      console.error('Error fetching appointments:', error);
      setError('Failed to load appointments. Please refresh the page.');
    } finally {
      setLoading(false);
    }
  };
  
  // Handle page change
  const handleNextPage = async () => {
    if (!hasMoreAppointments) return;
    
    setCurrentPage(prev => prev + 1);
    await fetchAppointments(true);
  };
  
  const handlePrevPage = () => {
    if (currentPage <= 1) return;
    
    setCurrentPage(prev => prev - 1);
    setLastVisibleAppointment(null); // Reset to fetch from beginning
    fetchAppointments();
  };
  
  // Filter appointments by search term
  const filteredAppointments = appointments.filter(appointment => {
    if (!searchTerm) return true;
    
    const searchLower = searchTerm.toLowerCase();
    return (
      appointment.fullName.toLowerCase().includes(searchLower) ||
      appointment.email.toLowerCase().includes(searchLower) ||
      appointment.service.toLowerCase().includes(searchLower) ||
      appointment.id.toLowerCase().includes(searchLower)
    );
  });
  
  // Handle status update
  const handleStatusUpdate = async (appointmentId: string, newStatus: 'scheduled' | 'completed' | 'cancelled' | 'no-show') => {
    if (!currentUser) {
      setError('You must be logged in to update appointment status');
      return;
    }
    
    setIsUpdating(true);
    try {
      const appointmentRef = doc(db, 'appointments', appointmentId);
      await updateDoc(appointmentRef, {
        status: newStatus,
        updatedAt: Timestamp.now(),
        updatedBy: currentUser.uid
      });
    } catch (error) {
      console.error('Error updating appointment status:', error);
      setError('Failed to update appointment status');
    } finally {
      setIsUpdating(false);
    }
  };
  
  // Format date for display
  const formatDate = (dateString: string) => {
    if (!dateString) return 'N/A';
    
    try {
      // Create date object without timezone conversion
      // Parse the date parts explicitly to avoid timezone issues
      const [year, month, day] = dateString.split('-').map(part => parseInt(part, 10));
      
      // Month is 0-indexed in JavaScript Date object
      const date = new Date(year, month - 1, day);
      
      return new Intl.DateTimeFormat('en-US', {
        weekday: 'short',
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        timeZone: 'UTC' // Use UTC to avoid timezone offset issues
      }).format(date);
    } catch (error) {
      console.error('Error formatting date:', error, dateString);
      return dateString; // Return original string if parsing fails
    }
  };
  
  // Get status badge color
  const getStatusBadgeColor = (status: string) => {
    switch (status) {
      case 'scheduled':
        return 'bg-blue-100 text-blue-800 border border-blue-300';
      case 'completed':
        return 'bg-green-100 text-green-800 border border-green-300';
      case 'cancelled':
        return 'bg-gray-100 text-gray-800 border border-gray-300';
      case 'no-show':
        return 'bg-red-100 text-red-800 border border-red-300';
      default:
        return 'bg-gray-100 text-gray-800 border border-gray-300';
    }
  };
  
  return (
    <div className="max-w-7xl mx-auto px-4 py-8">
      <div className="flex flex-col md:flex-row md:items-center justify-between mb-6">
        <div>
          <h1 className="text-2xl font-bold text-gray-900">Appointments</h1>
          <p className="mt-1 text-sm text-gray-500">
            View and manage all scheduled appointments
          </p>
        </div>
        
        <div className="mt-4 md:mt-0">
          <Button
            onClick={() => fetchAppointments()}
            variant="outline"
            className="mr-2"
            aria-label="Refresh appointments"
          >
            <svg className="h-5 w-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"></path>
            </svg>
            <span className="ml-1">Refresh</span>
          </Button>
          <Button
            href="/appointments/book"
            variant="primary"
            className="bg-blue-600 hover:bg-blue-700"
          >
            Book New Appointment
          </Button>
        </div>
      </div>
      
      {error && (
        <div className="mb-6 bg-red-50 border border-red-200 rounded-md p-4">
          <div className="flex">
            <div className="flex-shrink-0">
              <svg className="h-5 w-5 text-red-400" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clipRule="evenodd" />
              </svg>
            </div>
            <div className="ml-3">
              <p className="text-sm text-red-700">{error}</p>
            </div>
          </div>
        </div>
      )}
      
      {/* Filters and search */}
      <Card className="mb-6">
        <div className="p-4">
          <div className="flex flex-col md:flex-row md:items-end space-y-4 md:space-y-0 md:space-x-4">
            <div className="w-full md:w-1/5">
              <label htmlFor="dateFilter" className="block text-sm font-medium text-gray-700 mb-1">
                Filter by Date
              </label>
              <input
                type="date"
                id="dateFilter"
                value={selectedDate}
                onChange={(e) => setSelectedDate(e.target.value)}
                className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              />
            </div>
            
            <div className="w-full md:w-1/5">
              <label htmlFor="statusFilter" className="block text-sm font-medium text-gray-700 mb-1">
                Filter by Status
              </label>
              <select
                id="statusFilter"
                value={selectedStatus}
                onChange={(e) => setSelectedStatus(e.target.value)}
                className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              >
                <option value="all">All Statuses</option>
                <option value="scheduled">Scheduled</option>
                <option value="completed">Completed</option>
                <option value="cancelled">Cancelled</option>
                <option value="no-show">No Show</option>
              </select>
            </div>
            
            <div className="w-full md:w-1/5">
              <label htmlFor="sortOption" className="block text-sm font-medium text-gray-700 mb-1">
                Sort By
              </label>
              <select
                id="sortOption"
                value={sortOption}
                onChange={(e) => setSortOption(e.target.value as SortOption)}
                className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              >
                <option value="latest">Latest Created</option>
                <option value="upcoming">Upcoming First</option>
                <option value="oldest">Oldest Created</option>
              </select>
            </div>
            
            <div className="w-full md:w-2/5">
              <label htmlFor="search" className="block text-sm font-medium text-gray-700 mb-1">
                Search
              </label>
              <div className="relative">
                <input
                  type="text"
                  id="search"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  placeholder="Search by name, email, or service..."
                  className="block w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                />
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <svg className="h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor">
                    <path fillRule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clipRule="evenodd" />
                  </svg>
                </div>
              </div>
            </div>
            
            {selectedDate && (
              <div className="md:ml-2 flex-shrink-0">
                <Button 
                  onClick={() => setSelectedDate('')}
                  variant="outline"
                  size="sm"
                >
                  Clear Date
                </Button>
              </div>
            )}
          </div>
        </div>
      </Card>
      
      {/* Appointments Table */}
      <Card className="overflow-hidden">
        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Client
                </th>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Service
                </th>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Date & Time
                </th>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Status
                </th>
                <th scope="col" className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {loading && appointments.length === 0 ? (
                <tr>
                  <td colSpan={5} className="px-6 py-4 text-center">
                    <div className="flex justify-center items-center">
                      <div className="animate-spin rounded-full h-6 w-6 border-t-2 border-b-2 border-blue-500"></div>
                      <span className="ml-2 text-sm text-gray-500">Loading appointments...</span>
                    </div>
                  </td>
                </tr>
              ) : filteredAppointments.length === 0 ? (
                <tr>
                  <td colSpan={5} className="px-6 py-4 text-center text-sm text-gray-500">
                    {searchTerm ? 'No appointments match your search' : 'No appointments found'}
                  </td>
                </tr>
              ) : (
                filteredAppointments.map((appointment) => (
                  <tr key={appointment.id} className="hover:bg-gray-50">
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="flex items-center">
                        <div>
                          <div className="text-sm font-medium text-gray-900">
                            {appointment.fullName}
                          </div>
                          <div className="text-sm text-gray-500">
                            {appointment.email}
                          </div>
                          <div className="text-sm text-gray-500">
                            {appointment.phoneNumber}
                          </div>
                        </div>
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm text-gray-900">{appointment.service}</div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm text-gray-900">{formatDate(appointment.appointmentDate)}</div>
                      <div className="text-sm text-gray-500">{appointment.appointmentTime}</div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${getStatusBadgeColor(appointment.status)}`}>
                        {appointment.status}
                      </span>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                      <Link 
                        to={`/appointments/${appointment.id}`} 
                        className="text-blue-600 hover:text-blue-900 mr-4"
                      >
                        View Details
                      </Link>
                      <div className="inline-block relative mt-1">
                        <select
                          onChange={(e) => {
                            const newStatus = e.target.value as 'scheduled' | 'completed' | 'cancelled' | 'no-show';
                            if (newStatus !== appointment.status) {
                              handleStatusUpdate(appointment.id, newStatus);
                            }
                          }}
                          defaultValue={appointment.status}
                          className="block w-full pl-3 pr-10 py-1 text-sm border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 rounded-md"
                          disabled={isUpdating}
                        >
                          <option value="scheduled">Scheduled</option>
                          <option value="completed">Completed</option>
                          <option value="cancelled">Cancelled</option>
                          <option value="no-show">No Show</option>
                        </select>
                      </div>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
        
        {/* Pagination */}
        <div className="px-6 py-4 flex items-center justify-between border-t border-gray-200">
          <div className="flex-1 flex justify-between sm:hidden">
            <Button
              onClick={handlePrevPage}
              variant="outline"
              size="sm"
              isDisabled={currentPage <= 1}
            >
              Previous
            </Button>
            <Button
              onClick={handleNextPage}
              variant="outline"
              size="sm"
              isDisabled={!hasMoreAppointments}
            >
              Next
            </Button>
          </div>
          <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
            <div>
              <p className="text-sm text-gray-700">
                Showing <span className="font-medium">{(currentPage - 1) * appointmentsPerPage + 1}</span>
                {' '}-{' '}
                <span className="font-medium">
                  {Math.min(currentPage * appointmentsPerPage, totalAppointments)}
                </span>
                {' '}of{' '}
                <span className="font-medium">{totalAppointments}</span> appointments
              </p>
            </div>
            <div>
              <nav className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px" aria-label="Pagination">
                <button
                  onClick={handlePrevPage}
                  disabled={currentPage <= 1}
                  className={`relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 ${
                    currentPage <= 1
                      ? 'opacity-50 cursor-not-allowed'
                      : 'hover:bg-gray-50'
                  }`}
                >
                  <span className="sr-only">Previous</span>
                  <svg className="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path fillRule="evenodd" d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z" clipRule="evenodd" />
                  </svg>
                </button>
                <span className="relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700">
                  Page {currentPage}
                </span>
                <button
                  onClick={handleNextPage}
                  disabled={!hasMoreAppointments}
                  className={`relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 ${
                    !hasMoreAppointments
                      ? 'opacity-50 cursor-not-allowed'
                      : 'hover:bg-gray-50'
                  }`}
                >
                  <span className="sr-only">Next</span>
                  <svg className="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" />
                  </svg>
                </button>
              </nav>
            </div>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default AppointmentsPage; 