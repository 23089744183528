import React, { useState, useEffect } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { getAllUsers, User, getUserById } from '../services/userService';
import ChatWindow from '../components/chat/ChatWindow';
import MessageList from '../components/chat/MessageList';
import { db } from '../firebase/config';
import { collection, query, where, getDocs, updateDoc } from 'firebase/firestore';

const USERS_PER_PAGE = 15; // Show 15 users per page

const Messages: React.FC = () => {
  const { currentUser } = useAuth();
  const [users, setUsers] = useState<User[]>([]);
  const [selectedUser, setSelectedUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(true);
  const [unreadCounts, setUnreadCounts] = useState<Record<string, number>>({});
  const [currentUserData, setCurrentUserData] = useState<User | null>(null);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    const loadData = async () => {
      try {
        // Load current user data
        if (currentUser?.uid) {
          const userData = await getUserById(currentUser.uid);
          setCurrentUserData(userData);
        }

        // Load all users
        const allUsers = await getAllUsers();
        const otherUsers = allUsers.filter(user => user.uid !== currentUser?.uid);
        setUsers(otherUsers);

        // Load unread counts for each user
        if (currentUser?.uid) {
          const messagesRef = collection(db, 'messages');
          const unreadQuery = query(
            messagesRef,
            where('recipientId', '==', currentUser.uid),
            where('read', '==', false)
          );
          const unreadSnapshot = await getDocs(unreadQuery);
          
          const counts: Record<string, number> = {};
          unreadSnapshot.forEach(doc => {
            const message = doc.data();
            counts[message.senderId] = (counts[message.senderId] || 0) + 1;
          });
          setUnreadCounts(counts);
        }
      } catch (error) {
        console.error('Error loading data:', error);
      } finally {
        setLoading(false);
      }
    };

    loadData();
  }, [currentUser?.uid]);

  // Get paginated users
  const paginatedUsers = users.slice(
    (currentPage - 1) * USERS_PER_PAGE,
    currentPage * USERS_PER_PAGE
  );

  const totalPages = Math.ceil(users.length / USERS_PER_PAGE);

  // Mark messages as read when selecting a user
  const handleSelectUser = async (user: User) => {
    setSelectedUser(user);
    
    if (currentUser?.uid) {
      try {
        // Get all unread messages from this user
        const messagesRef = collection(db, 'messages');
        const unreadQuery = query(
          messagesRef,
          where('recipientId', '==', currentUser.uid),
          where('senderId', '==', user.uid),
          where('read', '==', false)
        );
        const unreadSnapshot = await getDocs(unreadQuery);
        
        // Mark all messages as read
        const updatePromises = unreadSnapshot.docs.map(doc =>
          updateDoc(doc.ref, { read: true })
        );
        await Promise.all(updatePromises);
        
        // Update unread counts
        setUnreadCounts(prev => ({
          ...prev,
          [user.uid]: 0
        }));
      } catch (error) {
        console.error('Error marking messages as read:', error);
      }
    }
  };

  const renderContent = () => {
    if (loading || !currentUserData) {
      return (
        <div className="flex items-center justify-center h-full">
          <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500"></div>
        </div>
      );
    }

    return (
      <div className="flex h-full">
        {/* Messages List Sidebar */}
        <div className="w-80 flex flex-col bg-white border-r border-gray-200">
          <div className="flex-shrink-0 p-4 border-b border-gray-200">
            <h2 className="text-lg font-semibold text-gray-700">Messages</h2>
          </div>
          
          {/* User List */}
          <div className="flex-1 overflow-hidden flex flex-col">
            <div className="flex-1 overflow-y-auto">
              <MessageList 
                users={paginatedUsers}
                selectedUser={selectedUser}
                onSelectUser={handleSelectUser}
                unreadCounts={unreadCounts}
              />
            </div>
            
            {/* Pagination */}
            {totalPages > 1 && (
              <div className="flex-shrink-0 p-2 border-t border-gray-200 bg-gray-50">
                <div className="flex justify-between items-center">
                  <button
                    onClick={() => setCurrentPage(p => Math.max(1, p - 1))}
                    disabled={currentPage === 1}
                    className="px-3 py-1 text-sm bg-white border border-gray-300 rounded-md disabled:opacity-50"
                  >
                    Previous
                  </button>
                  <span className="text-sm text-gray-600">
                    Page {currentPage} of {totalPages}
                  </span>
                  <button
                    onClick={() => setCurrentPage(p => Math.min(totalPages, p + 1))}
                    disabled={currentPage === totalPages}
                    className="px-3 py-1 text-sm bg-white border border-gray-300 rounded-md disabled:opacity-50"
                  >
                    Next
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>

        {/* Chat Area */}
        <div className="flex-1 flex">
          {selectedUser ? (
            <ChatWindow 
              recipient={selectedUser}
              currentUser={currentUserData}
            />
          ) : (
            <div className="flex items-center justify-center w-full text-gray-500">
              <div className="text-center">
                <svg 
                  className="w-16 h-16 mx-auto text-gray-400 mb-4" 
                  fill="none" 
                  stroke="currentColor" 
                  viewBox="0 0 24 24"
                >
                  <path 
                    strokeLinecap="round" 
                    strokeLinejoin="round" 
                    strokeWidth={1.5} 
                    d="M8 12h.01M12 12h.01M16 12h.01M21 12c0 4.418-4.03 8-9 8a9.863 9.863 0 01-4.255-.949L3 20l1.395-3.72C3.512 15.042 3 13.574 3 12c0-4.418 4.03-8 9-8s9 3.582 9 8z" 
                  />
                </svg>
                <p className="text-lg font-medium">Select a conversation</p>
                <p className="text-sm text-gray-400 mt-1">Choose a user to start messaging</p>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  };

  return (
    <div className="h-full bg-gray-50">
      {renderContent()}
    </div>
  );
};

export default Messages; 