import React, { useState } from 'react';
import { addVisit } from '../services/visitorService';

interface AddVisitFormProps {
  onVisitAdded: () => void;
  onCancel: () => void;
}

const AddVisitForm: React.FC<AddVisitFormProps> = ({ onVisitAdded, onCancel }) => {
  const [visitorName, setVisitorName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [service, setService] = useState('');
  const [notes, setNotes] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState<string | null>(null);
  
  // List of sample services
  const serviceOptions = [
    'ID Renewal',
    'Passport Application',
    'Business Permit',
    'Business Registration',
    'ID Card Application',
    'Utility Payment',
    'Document Certification',
    'License Renewal',
    'Tax Services',
    'Other'
  ];
  
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    // Form validation
    if (!visitorName.trim()) {
      setError('Visitor name is required');
      return;
    }
    
    if (!phoneNumber.trim()) {
      setError('Phone number is required');
      return;
    }
    
    if (!service) {
      setError('Service is required');
      return;
    }
    
    setIsSubmitting(true);
    setError(null);
    
    try {
      await addVisit({
        visitorName: visitorName.trim(),
        phoneNumber: phoneNumber.trim(),
        service,
        status: 'waiting',
        assignedStaff: null,
        notes: notes.trim()
      });
      
      // Reset form
      setVisitorName('');
      setPhoneNumber('');
      setService('');
      setNotes('');
      
      // Notify parent component
      onVisitAdded();
    } catch (err) {
      console.error('Error adding visit:', err);
      setError('Failed to add visitor. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="bg-white rounded-lg shadow overflow-hidden">
      <div className="p-4 border-b border-gray-200">
        <h2 className="text-lg font-semibold text-gray-800">Add New Visitor</h2>
      </div>
      
      <form onSubmit={handleSubmit} className="p-4 space-y-4">
        {error && (
          <div className="bg-red-50 text-red-800 p-3 rounded-md text-sm">
            {error}
          </div>
        )}
        
        {/* Visitor Name */}
        <div>
          <label htmlFor="visitorName" className="block text-sm font-medium text-gray-700 mb-1">
            Visitor Name *
          </label>
          <input
            type="text"
            id="visitorName"
            value={visitorName}
            onChange={(e) => setVisitorName(e.target.value)}
            className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
            placeholder="Full name"
            aria-required="true"
          />
        </div>
        
        {/* Phone Number */}
        <div>
          <label htmlFor="phoneNumber" className="block text-sm font-medium text-gray-700 mb-1">
            Phone Number *
          </label>
          <input
            type="tel"
            id="phoneNumber"
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
            className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
            placeholder="Phone number"
            aria-required="true"
          />
        </div>
        
        {/* Service */}
        <div>
          <label htmlFor="service" className="block text-sm font-medium text-gray-700 mb-1">
            Service Required *
          </label>
          <select
            id="service"
            value={service}
            onChange={(e) => setService(e.target.value)}
            className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
            aria-required="true"
          >
            <option value="">Select a service</option>
            {serviceOptions.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
        </div>
        
        {/* Notes */}
        <div>
          <label htmlFor="notes" className="block text-sm font-medium text-gray-700 mb-1">
            Notes (Optional)
          </label>
          <textarea
            id="notes"
            value={notes}
            onChange={(e) => setNotes(e.target.value)}
            rows={3}
            className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
            placeholder="Any additional information"
          />
        </div>
        
        {/* Form Actions */}
        <div className="flex justify-end space-x-3 pt-2">
          <button
            type="button"
            onClick={onCancel}
            className="px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            aria-label="Cancel adding visitor"
          >
            Cancel
          </button>
          <button
            type="submit"
            disabled={isSubmitting}
            className="px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:bg-blue-300"
            aria-label="Add visitor"
          >
            {isSubmitting ? 'Adding...' : 'Add Visitor'}
          </button>
        </div>
      </form>
    </div>
  );
};

export default AddVisitForm; 