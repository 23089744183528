import React, { useState, useEffect } from 'react';
import { recordVisitCompletion } from '../services/visitorService';
import { useAuth } from '../contexts/AuthContext';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../firebase/config';
import { toast } from 'react-hot-toast';

interface VisitCompletionDialogProps {
  visitId: string;
  visitorName: string;
  isOpen: boolean;
  onClose: () => void;
  onSuccess: () => void;
}

const VisitCompletionDialog: React.FC<VisitCompletionDialogProps> = ({
  visitId,
  visitorName,
  isOpen,
  onClose,
  onSuccess
}) => {
  const { currentUser } = useAuth();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState<string | null>(null);
  
  // Form state
  const [feedback, setFeedback] = useState('');
  const [serviceRendered, setServiceRendered] = useState<boolean | null>(null);
  const [wasCharged, setWasCharged] = useState<boolean | null>(null);
  const [amountCharged, setAmountCharged] = useState<number | undefined>(undefined);
  const [reasonNotRendered, setReasonNotRendered] = useState('');
  
  // Reset form state
  const resetForm = () => {
    setFeedback('');
    setServiceRendered(null);
    setWasCharged(null);
    setAmountCharged(undefined);
    setReasonNotRendered('');
    setError(null);
  };
  
  // Handle dialog close
  const handleClose = () => {
    resetForm();
    onClose();
  };
  
  // Handle form submission
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    // Form validation
    if (!feedback.trim()) {
      setError('Please provide feedback about the visit');
      return;
    }
    
    if (serviceRendered === null) {
      setError('Please indicate if the service was rendered');
      return;
    }
    
    if (serviceRendered && wasCharged === null) {
      setError('Please indicate if the service was charged');
      return;
    }
    
    if (serviceRendered && wasCharged && (amountCharged === undefined || amountCharged <= 0)) {
      setError('Please enter a valid amount charged');
      return;
    }
    
    if (!serviceRendered && !reasonNotRendered.trim()) {
      setError('Please provide a reason why the service was not rendered');
      return;
    }
    
    if (!currentUser) {
      setError('User authentication required');
      return;
    }
    
    setIsSubmitting(true);
    setError(null);
    
    try {
      // First check if the registration document exists
      const registrationRef = doc(db, 'registrations', visitId);
      const registrationSnap = await getDoc(registrationRef);
      
      if (!registrationSnap.exists()) {
        // If registration doesn't exist, try to find it in the visits collection
        const visitRef = doc(db, 'visits', visitId);
        const visitSnap = await getDoc(visitRef);
        
        if (!visitSnap.exists()) {
          throw new Error('Visit record not found in either registrations or visits collection');
        }
        
        // If we found it in visits, we need to migrate it to registrations
        const visitData = visitSnap.data();
        // TODO: Implement visit to registration migration if needed
        throw new Error('Visit record needs to be migrated to the registrations collection');
      }
      
      // Get the user's name with fallback options
      const userName = currentUser.displayName || currentUser.email?.split('@')[0] || currentUser.uid;
      
      // Record the completion
      await recordVisitCompletion(
        visitId,
        {
          feedback: feedback.trim(),
          serviceRendered,
          wasCharged: serviceRendered ? (wasCharged || false) : false,
          amountCharged: serviceRendered && wasCharged ? amountCharged : undefined,
          reasonNotRendered: !serviceRendered ? reasonNotRendered.trim() : undefined
        },
        currentUser.uid,
        userName
      );
      
      toast.success('Visit completed successfully');
      resetForm();
      onSuccess();
    } catch (err) {
      console.error('Error completing visit:', err);
      const errorMessage = err instanceof Error ? err.message : 'Failed to complete the visit';
      setError(`${errorMessage}. Please try again.`);
      toast.error(errorMessage);
    } finally {
      setIsSubmitting(false);
    }
  };
  
  if (!isOpen) return null;
  
  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-75 overflow-y-auto h-full w-full z-50 flex justify-center items-center">
      <div className="relative bg-white rounded-lg shadow-xl mx-4 max-w-md md:max-w-lg w-full">
        {/* Close button */}
        <button
          type="button"
          onClick={handleClose}
          className="absolute top-4 right-4 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500 p-1 rounded-full transition-colors"
          disabled={isSubmitting}
          aria-label="Close"
        >
          <span className="sr-only">Close</span>
          <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
        
        {/* Dialog header with decorative stripe */}
        <div className="border-t-4 border-blue-600 rounded-t-lg"></div>
        
        <div className="px-6 pt-6 pb-6">
          <h3 className="text-xl leading-6 font-semibold text-gray-900 mb-2">
            Complete Visit
          </h3>
          <p className="text-sm text-gray-600 mb-6">
            Please provide feedback about <span className="font-medium">{visitorName}'s</span> visit
          </p>
          
          {error && (
            <div className="mb-6 p-4 bg-red-50 border-l-4 border-red-500 text-red-700 rounded-md text-sm">
              <div className="flex">
                <div className="flex-shrink-0">
                  <svg className="h-5 w-5 text-red-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                    <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z" clipRule="evenodd" />
                  </svg>
                </div>
                <div className="ml-3">
                  <p className="text-sm text-red-700">
                    {error}
                  </p>
                </div>
              </div>
            </div>
          )}
          
          <form onSubmit={handleSubmit} className="space-y-6">
            {/* Feedback */}
            <div className="space-y-2">
              <label htmlFor="feedback" className="block text-sm font-medium text-gray-700">
                Visit Feedback
                <span className="text-red-500 ml-1">*</span>
              </label>
              <textarea
                id="feedback"
                name="feedback"
                rows={3}
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                placeholder="How was the visit? Please provide details..."
                value={feedback}
                onChange={(e) => setFeedback(e.target.value)}
                disabled={isSubmitting}
                required
              />
            </div>
            
            {/* Service Rendered */}
            <div className="space-y-3">
              <label className="block text-sm font-medium text-gray-700">
                Was the service rendered?
                <span className="text-red-500 ml-1">*</span>
              </label>
              <div className="flex space-x-4">
                <button
                  type="button"
                  className={`px-4 py-2 border rounded-md transition-colors flex items-center justify-center min-w-[80px] ${
                    serviceRendered === true
                      ? 'bg-green-50 border-green-500 text-green-700 ring-2 ring-green-500 ring-opacity-50'
                      : 'bg-white border-gray-300 text-gray-700 hover:bg-gray-50'
                  }`}
                  onClick={() => setServiceRendered(true)}
                  disabled={isSubmitting}
                >
                  {serviceRendered === true && (
                    <svg className="mr-2 h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                      <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                    </svg>
                  )}
                  Yes
                </button>
                <button
                  type="button"
                  className={`px-4 py-2 border rounded-md transition-colors flex items-center justify-center min-w-[80px] ${
                    serviceRendered === false
                      ? 'bg-red-50 border-red-500 text-red-700 ring-2 ring-red-500 ring-opacity-50'
                      : 'bg-white border-gray-300 text-gray-700 hover:bg-gray-50'
                  }`}
                  onClick={() => setServiceRendered(false)}
                  disabled={isSubmitting}
                >
                  {serviceRendered === false && (
                    <svg className="mr-2 h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                      <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
                    </svg>
                  )}
                  No
                </button>
              </div>
            </div>
            
            {/* Conditional fields based on if service was rendered */}
            {serviceRendered === true && (
              <div className="space-y-6 pt-2">
                <div className="space-y-3">
                  <label className="block text-sm font-medium text-gray-700">
                    Was the service charged?
                    <span className="text-red-500 ml-1">*</span>
                  </label>
                  <div className="flex space-x-4">
                    <button
                      type="button"
                      className={`px-4 py-2 border rounded-md transition-colors flex items-center justify-center min-w-[80px] ${
                        wasCharged === true
                          ? 'bg-blue-50 border-blue-500 text-blue-700 ring-2 ring-blue-500 ring-opacity-50'
                          : 'bg-white border-gray-300 text-gray-700 hover:bg-gray-50'
                      }`}
                      onClick={() => setWasCharged(true)}
                      disabled={isSubmitting}
                    >
                      {wasCharged === true && (
                        <svg className="mr-2 h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                          <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                        </svg>
                      )}
                      Yes
                    </button>
                    <button
                      type="button"
                      className={`px-4 py-2 border rounded-md transition-colors flex items-center justify-center min-w-[80px] ${
                        wasCharged === false
                          ? 'bg-gray-200 border-gray-400 text-gray-800 ring-2 ring-gray-400 ring-opacity-50'
                          : 'bg-white border-gray-300 text-gray-700 hover:bg-gray-50'
                      }`}
                      onClick={() => setWasCharged(false)}
                      disabled={isSubmitting}
                    >
                      {wasCharged === false && (
                        <svg className="mr-2 h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                          <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
                        </svg>
                      )}
                      No
                    </button>
                  </div>
                </div>
                
                {/* Amount charged - Enhanced section */}
                {wasCharged && (
                  <div className="pt-2">
                    <label htmlFor="amountCharged" className="block text-sm font-medium text-gray-700 mb-2">
                      Amount Charged
                      <span className="text-red-500 ml-1">*</span>
                    </label>
                    <div className="mt-1 relative rounded-md shadow-md bg-gray-50 border border-gray-300 p-4">
                      <div className="flex items-center">
                        <div className="flex-shrink-0 bg-green-100 rounded-full p-2 mr-3">
                          <svg className="h-5 w-5 text-green-600" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                            <path d="M8.433 7.418c.155-.103.346-.196.567-.267v1.698a2.305 2.305 0 01-.567-.267C8.07 8.34 8 8.114 8 8c0-.114.07-.34.433-.582zM11 12.849v-1.698c.22.071.412.164.567.267.364.243.433.468.433.582 0 .114-.07.34-.433.582a2.305 2.305 0 01-.567.267z" />
                            <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-13a1 1 0 10-2 0v.092a4.535 4.535 0 00-1.676.662C6.602 6.234 6 7.009 6 8c0 .99.602 1.765 1.324 2.246.48.32 1.054.545 1.676.662v1.941c-.391-.127-.68-.317-.843-.504a1 1 0 10-1.51 1.31c.562.649 1.413 1.076 2.353 1.253V15a1 1 0 102 0v-.092a4.535 4.535 0 001.676-.662C13.398 13.766 14 12.991 14 12c0-.99-.602-1.765-1.324-2.246A4.535 4.535 0 0011 9.092V7.151c.391.127.68.317.843.504a1 1 0 101.511-1.31c-.563-.649-1.413-1.076-2.354-1.253V5z" clipRule="evenodd" />
                          </svg>
                        </div>
                        <div className="relative flex-1">
                          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                            <span className="text-gray-500 sm:text-sm font-medium">$</span>
                          </div>
                          <input
                            type="number"
                            name="amountCharged"
                            id="amountCharged"
                            className="block w-full pl-8 pr-12 py-3 border-0 bg-transparent text-gray-900 placeholder-gray-500 focus:ring-blue-500 focus:border-blue-500 text-lg font-medium"
                            placeholder="0.00"
                            min="0"
                            step="0.01"
                            value={amountCharged || ''}
                            onChange={(e) => setAmountCharged(e.target.value ? parseFloat(e.target.value) : undefined)}
                            disabled={isSubmitting}
                            aria-describedby="price-currency"
                          />
                          <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                            <span className="text-gray-500 sm:text-sm" id="price-currency">
                              USD
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <p className="mt-2 text-sm text-gray-500">Enter the total amount charged for the service.</p>
                  </div>
                )}
              </div>
            )}
            
            {/* Reason not rendered */}
            {serviceRendered === false && (
              <div className="space-y-2 pt-2">
                <label htmlFor="reasonNotRendered" className="block text-sm font-medium text-gray-700">
                  Reason not rendered
                  <span className="text-red-500 ml-1">*</span>
                </label>
                <select
                  id="reasonNotRendered"
                  name="reasonNotRendered"
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 bg-white focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                  value={reasonNotRendered}
                  onChange={(e) => setReasonNotRendered(e.target.value)}
                  disabled={isSubmitting}
                  required
                >
                  <option value="">Select a reason</option>
                  <option value="Visitor canceled">Visitor canceled</option>
                  <option value="Visitor not eligible">Visitor not eligible</option>
                  <option value="Service not available">Service not available</option>
                  <option value="Staff not available">Staff not available</option>
                  <option value="Incomplete documentation">Incomplete documentation</option>
                  <option value="Technical issues">Technical issues</option>
                  <option value="Other">Other</option>
                </select>
              </div>
            )}
            
            {/* Submit button */}
            <div className="pt-4">
              <button
                type="submit"
                className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-6 py-3 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
                disabled={isSubmitting}
              >
                {isSubmitting ? (
                  <>
                    <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                      <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>
                    Processing...
                  </>
                ) : (
                  'Complete Visit'
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default VisitCompletionDialog; 