import React from 'react';
import { useNavigate } from 'react-router-dom';
import { BankClient } from '../types';
import { formatStatus } from '../utils';

interface ClientHeaderProps {
  client: BankClient;
  onEditClick: () => void;
}

const ClientHeader: React.FC<ClientHeaderProps> = ({ client, onEditClick }) => {
  const navigate = useNavigate();

  // Format phone number for WhatsApp
  const formatPhoneForWhatsApp = (phone: string = '') => {
    const digitsOnly = phone.replace(/\D/g, '');
    return digitsOnly.startsWith('1') ? '+' + digitsOnly : '+1' + digitsOnly;
  };

  // Format name
  const formatName = (fullName: string) => {
    const nameParts = fullName.split(' ').filter(Boolean);
    if (nameParts.length === 0) return '';
    if (nameParts.length === 1) return fullName;
    
    const firstName = nameParts[0];
    const rest = nameParts.slice(1).map(name => name && name.length > 0 ? `${name.charAt(0)}.` : '').join(' ');
    return `${firstName} ${rest}`;
  };

  // Format account number
  const truncateAccountNumber = (accountNumber: string) => {
    if (!accountNumber || accountNumber === 'Pending' || accountNumber.length < 8) {
      return accountNumber;
    }
    const firstFour = accountNumber.substring(0, 4);
    const lastFour = accountNumber.substring(accountNumber.length - 4);
    return `${firstFour} **** **** ${lastFour}`;
  };

  // Get status badge style
  const getStatusBadgeStyle = (status: string) => {
    switch (status) {
      case 'pending':
        return 'bg-yellow-100 text-yellow-800';
      case 'processing':
        return 'bg-blue-100 text-blue-800';
      case 'documents-requested':
        return 'bg-orange-100 text-orange-800';
      case 'activated':
        return 'bg-green-100 text-green-800';
      case 'onboarded-online':
        return 'bg-emerald-100 text-emerald-800';
      case 'funded':
        return 'bg-purple-100 text-purple-800';
      case 'dormant':
        return 'bg-gray-100 text-gray-800';
      case 'denied':
        return 'bg-red-100 text-red-800';
      case 'closed':
        return 'bg-red-100 text-red-800';
      default:
        return 'bg-gray-100 text-gray-600';
    }
  };

  return (
    <div className="bg-white shadow overflow-hidden sm:rounded-lg mb-6">
      <div className="px-4 py-5 sm:px-6 flex justify-between items-center">
        {/* Back Button */}
        <button
          onClick={() => navigate('/bank-services')}
          className="mr-4 p-2 rounded-full hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-blue-500"
          aria-label="Back to client list"
          title="Back to client list"
          tabIndex={0}
        >
          <svg className="h-5 w-5 text-gray-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
            <path fillRule="evenodd" d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z" clipRule="evenodd" />
          </svg>
        </button>
        
        <div className="flex-1">
          <h3 className="text-lg leading-6 font-medium text-gray-900 flex items-center">
            {formatName(`${client.firstName} ${client.lastName}`)}
            {client.accountStatus && (
              <span className={`ml-3 inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${getStatusBadgeStyle(client.accountStatus)}`}>
                {formatStatus(client.accountStatus)}
              </span>
            )}
          </h3>
          <div className="mt-1 max-w-2xl text-sm text-gray-500 flex items-center">
            <span className="inline-block mr-1">Account:</span> 
            <span className="font-mono">{truncateAccountNumber(client.accountNumber || 'Pending')}</span>
            {client.accountType && (
              <span className="ml-2 inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-800">
                {client.accountType}
              </span>
            )}
          </div>
          <div className="mt-2 flex items-center text-sm text-gray-500">
            <svg className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
            </svg>
            {client?.email ? (
              <a 
                href={`mailto:${client.email}`} 
                className="text-gray-500 hover:text-blue-600 transition-colors"
                aria-label={`Email ${client.email}`}
                title="Click to email"
              >
                {client.email}
              </a>
            ) : 'No email available'}
          </div>
          <div className="mt-2 flex items-center text-sm text-gray-500">
            <svg className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z" />
            </svg>
            {client?.phone ? (
              <a 
                href={`tel:${client.phone}`}
                className="text-gray-500 hover:text-blue-600 transition-colors"
                aria-label={`Call ${client.phone}`}
                title="Click to call"
              >
                {client.phone}
              </a>
            ) : 'No phone available'}
            {client?.phone && (
              <a 
                href={`https://wa.me/${formatPhoneForWhatsApp(client.phone)}`}
                target="_blank"
                rel="noopener noreferrer"
                className="ml-2 text-green-500 hover:text-green-700 focus:outline-none transition-colors"
                aria-label={`Send WhatsApp message to ${client.phone}`}
                tabIndex={0}
                title="Open WhatsApp"
              >
                <svg className="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
                  <path d="M17.472 14.382c-.297-.149-1.758-.867-2.03-.967-.273-.099-.471-.148-.67.15-.197.297-.767.966-.94 1.164-.173.199-.347.223-.644.075-.297-.15-1.255-.463-2.39-1.475-.883-.788-1.48-1.761-1.653-2.059-.173-.297-.018-.458.13-.606.134-.133.298-.347.446-.52.149-.174.198-.298.298-.497.099-.198.05-.371-.025-.52-.075-.149-.669-1.612-.916-2.207-.242-.579-.487-.5-.669-.51-.173-.008-.371-.01-.57-.01-.198 0-.52.074-.792.372-.272.297-1.04 1.016-1.04 2.479 0 1.462 1.065 2.875 1.213 3.074.149.198 2.096 3.2 5.077 4.487.709.306 1.262.489 1.694.625.712.227 1.36.195 1.871.118.571-.085 1.758-.719 2.006-1.413.248-.694.248-1.289.173-1.413-.074-.124-.272-.198-.57-.347m-5.421 7.403h-.004a9.87 9.87 0 01-5.031-1.378l-.361-.214-3.741.982.998-3.648-.235-.374a9.86 9.86 0 01-1.51-5.26c.001-5.45 4.436-9.884 9.888-9.884 2.64 0 5.122 1.03 6.988 2.898a9.825 9.825 0 012.893 6.994c-.003 5.45-4.437 9.884-9.885 9.884m8.413-18.297A11.815 11.815 0 0012.05 0C5.495 0 .16 5.335.157 11.892c0 2.096.547 4.142 1.588 5.945L.057 24l6.305-1.654a11.882 11.882 0 005.683 1.448h.005c6.554 0 11.89-5.335 11.893-11.893a11.821 11.821 0 00-3.48-8.413Z"/>
                </svg>
              </a>
            )}
          </div>
        </div>
        
        {/* Edit button */}
        <div>
          <button
            onClick={onEditClick}
            className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            aria-label="Edit client details"
            tabIndex={0}
          >
            <svg className="-ml-1 mr-2 h-5 w-5 text-gray-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
              <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
            </svg>
            Edit
          </button>
        </div>
      </div>
    </div>
  );
};

export default ClientHeader; 