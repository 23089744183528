import React, { useState, useEffect, useCallback } from 'react';
import { 
  subscribeToActiveVisits, 
  subscribeToCompletedVisitsToday,
  subscribeToCompletedVisitsByDateRange,
  subscribeToCompletedAppointments,
  getStaffMembers, 
  calculateVisitStats,
  assignStaffToVisit,
  updateVisitStatus,
  completeVisit,
  VisitStatus,
  Visit,
  Staff
} from '../services/visitorService';
import VisitStatistics from '../components/VisitStatistics';
import VisitTable from '../components/VisitTable';
import AddVisitForm from '../components/AddVisitForm';
import { format } from 'date-fns';

type TabType = 'active' | 'completed' | 'add';

const VisitsManagementPage: React.FC = () => {
  // State variables
  const [activeVisits, setActiveVisits] = useState<Visit[]>([]);
  const [completedVisits, setCompletedVisits] = useState<Visit[]>([]);
  const [completedAppointmentsCount, setCompletedAppointmentsCount] = useState(0);
  const [staffMembers, setStaffMembers] = useState<Staff[]>([]);
  const [activeTab, setActiveTab] = useState<TabType>('active');
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [stats, setStats] = useState({
    totalCheckedIn: 0,
    inQueue: 0,
    beingServed: 0,
    completed: 0,
    avgWaitTime: 0,
  });
  
  // State for date filtering
  const [isDateFilterActive, setIsDateFilterActive] = useState(true);
  
  // Initialize with default dates for the last 30 days
  const today = new Date();
  const thirtyDaysAgo = new Date(today);
  thirtyDaysAgo.setDate(today.getDate() - 30);
  
  const [filterStartDate, setFilterStartDate] = useState<Date | null>(thirtyDaysAgo);
  const [filterEndDate, setFilterEndDate] = useState<Date | null>(today);
  
  // Add a state to track loading
  const [loadingTimeout, setLoadingTimeout] = useState(false);
  
  // Set a timeout to force loading to false after 10 seconds
  useEffect(() => {
    if (isLoading) {
      console.log('Setting up loading timeout');
      const timer = setTimeout(() => {
        console.log('Loading timeout reached, forcing loading state to false');
        setLoadingTimeout(true);
        setIsLoading(false);
      }, 10000); // 10 seconds timeout
      
      return () => {
        console.log('Clearing loading timeout');
        clearTimeout(timer);
      };
    } else {
      setLoadingTimeout(false);
    }
  }, [isLoading]);
  
  // Force loading to false when component unmounts
  useEffect(() => {
    return () => {
      console.log('Component unmounting, forcing loading state to false');
      setIsLoading(false);
    };
  }, []);
  
  // Handle date filter change from the VisitTable component
  const handleDateFilterChange = useCallback((startDate: Date, endDate: Date, isActive: boolean) => {
    console.log('Date filter change received from VisitTable:', { 
      startDate: startDate.toISOString(), 
      endDate: endDate.toISOString(), 
      isActive 
    });
    
    // Only update state if the dates are actually different to prevent loops
    if (!filterStartDate || !filterEndDate || 
        filterStartDate.getTime() !== startDate.getTime() || 
        filterEndDate.getTime() !== endDate.getTime() ||
        isDateFilterActive !== isActive) {
      
      // Important: create new date objects to avoid reference issues
      const startDateCopy = new Date(startDate.getTime());
      const endDateCopy = new Date(endDate.getTime());
      
      console.log('Updating filter state with new dates');
      setFilterStartDate(startDateCopy);
      setFilterEndDate(endDateCopy);
      setIsDateFilterActive(isActive);
      
      // Reset loading state to show loading indicator
      setIsLoading(true);
      
      // Clear any previous errors
      setError(null);
    } else {
      console.log('Ignoring date filter change - dates are the same');
    }
  }, [filterStartDate, filterEndDate, isDateFilterActive]);
  
  // Fetch staff members
  useEffect(() => {
    const fetchStaffMembers = async () => {
      try {
        console.log('Fetching staff members from userService...');
        const staff = await getStaffMembers();
        console.log('Staff members fetched:', staff);
        setStaffMembers(staff);
      } catch (err) {
        console.error('Error fetching staff members:', err);
        setError('Failed to load staff members. Please try refreshing the page.');
      }
    };
    
    fetchStaffMembers();
  }, []);
  
  // Subscribe to active visits
  useEffect(() => {
    setIsLoading(true);
    
    const unsubscribeActiveVisits = subscribeToActiveVisits((visits) => {
      setActiveVisits(visits);
      setIsLoading(false);
    });
    
    return () => {
      unsubscribeActiveVisits();
    };
  }, []);
  
  // Subscribe to completed appointments
  useEffect(() => {
    const unsubscribeCompletedAppointments = subscribeToCompletedAppointments((count) => {
      setCompletedAppointmentsCount(count);
    });
    
    return () => {
      unsubscribeCompletedAppointments();
    };
  }, []);
  
  // Subscribe to completed visits - either today or by date range
  useEffect(() => {
    // Don't do anything if not on the completed tab
    if (activeTab !== 'completed') return;
    
    let unsubscribe = () => {};
    
    console.log('Tab is completed, setting up subscription');
    setIsLoading(true);
    
    // Always use date filter for completed visits
    if (filterStartDate && filterEndDate) {
      console.log('Subscribing to date-filtered completed visits', {
        start: filterStartDate.toISOString(),
        end: filterEndDate.toISOString()
      });
      
      // Use the date filter service function with the proper date objects
      unsubscribe = subscribeToCompletedVisitsByDateRange(
        filterStartDate,
        filterEndDate,
        (visits) => {
          console.log(`Received ${visits.length} filtered visits from subscription`);
          setCompletedVisits(visits);
          setIsLoading(false);
        }
      );
      
      // Add a safety timeout to prevent infinite loading
      const safetyTimeout = setTimeout(() => {
        console.log('Safety timeout triggered - forcing loading state to false');
        setIsLoading(false);
      }, 5000); // 5 seconds timeout
      
      return () => {
        console.log('Unsubscribing from visits and clearing safety timeout');
        clearTimeout(safetyTimeout);
        unsubscribe();
      };
    }
  }, [activeTab, filterStartDate, filterEndDate]); // Only these dependencies are needed
  
  // Calculate statistics whenever visits or completed appointments count changes
  useEffect(() => {
    const fetchStats = async () => {
      try {
        // Get visit stats from the function
        const visitStats = await calculateVisitStats(activeVisits, completedVisits);
        
        // Set stats with the combined count
        setStats({
          ...visitStats,
          // Use the real-time completed appointments count from our separate subscription
          completed: visitStats.completed + completedAppointmentsCount
        });
      } catch (error) {
        console.error('Error calculating visit statistics:', error);
        // Use default values if there's an error
        setStats({
          totalCheckedIn: activeVisits.length + completedVisits.length,
          inQueue: activeVisits.filter(v => v.status === 'waiting').length,
          beingServed: 0,
          completed: completedVisits.length + completedAppointmentsCount,
          avgWaitTime: 0
        });
      }
    };
    
    fetchStats();
  }, [activeVisits, completedVisits, completedAppointmentsCount]);
  
  // Handle assigning staff to a visit
  const handleAssignStaff = useCallback(async (visitId: string, staffId: string) => {
    try {
      const staff = staffMembers.find(s => s.id === staffId);
      if (!staff) {
        console.error('Selected staff member not found:', staffId);
        setError('Selected staff member not found. Please try again.');
        return;
      }
      
      await assignStaffToVisit(visitId, staffId, staff.name);
    } catch (err) {
      console.error('Error assigning staff:', err);
      setError('Failed to assign staff. Please try again.');
    }
  }, [staffMembers]);
  
  // Handle changing visit status
  const handleStatusChange = useCallback(async (visitId: string, status: VisitStatus) => {
    try {
      await updateVisitStatus(visitId, status);
    } catch (err) {
      console.error('Error updating status:', err);
      setError('Failed to update status');
    }
  }, []);
  
  // Handle completing a visit
  const handleCompleteVisit = useCallback(async (visitId: string) => {
    try {
      await completeVisit(visitId);
    } catch (err) {
      console.error('Error completing visit:', err);
      setError('Failed to complete visit');
    }
  }, []);
  
  // Handle when a visit is added
  const handleVisitAdded = useCallback(() => {
    // Switch to active tab to show the new visit
    setActiveTab('active');
  }, []);

  // Initialize date filter when component mounts or tab changes to completed
  useEffect(() => {
    if (activeTab === 'completed' && (!filterStartDate || !filterEndDate)) {
      console.log('Initializing date filter for completed tab');
      
      // Default to last 30 days
      const today = new Date();
      const thirtyDaysAgo = new Date(today);
      thirtyDaysAgo.setDate(today.getDate() - 30);
      
      // Set the filter dates
      setFilterStartDate(thirtyDaysAgo);
      setFilterEndDate(today);
      setIsDateFilterActive(true);
      setIsLoading(true);
    } else if (activeTab !== 'completed') {
      // Not on completed tab, clear completed visits
      setCompletedVisits([]);
    }
  }, [activeTab, filterStartDate, filterEndDate]);

  return (
    <div className="p-6 max-w-7xl mx-auto">
      {/* Page Header */}
      <div className="mb-8">
        <h1 className="text-2xl font-bold text-gray-900">Visit Management</h1>
        <p className="text-sm text-gray-500">Manage and track visitor queue</p>
      </div>
      
      {/* Error message */}
      {error && (
        <div className="mb-6 bg-red-50 border border-red-200 text-red-800 px-4 py-3 rounded-md">
          {error}
          <button 
            className="float-right text-red-800 hover:text-red-600"
            onClick={() => setError(null)}
            aria-label="Dismiss error message"
          >
            &times;
          </button>
        </div>
      )}
      
      {/* Statistics Section */}
      <VisitStatistics stats={stats} />
      
      {/* Tabs */}
      <div className="mb-6 border-b border-gray-200">
        <nav className="flex space-x-8" aria-label="Visit management tabs">
          <button
            className={`py-4 px-1 border-b-2 font-medium text-sm ${
              activeTab === 'active'
                ? 'border-blue-500 text-blue-600'
                : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
            }`}
            onClick={() => setActiveTab('active')}
            aria-current={activeTab === 'active' ? 'page' : undefined}
            aria-label="View active visits"
          >
            Active Visits
          </button>
          <button
            className={`py-4 px-1 border-b-2 font-medium text-sm ${
              activeTab === 'completed'
                ? 'border-blue-500 text-blue-600'
                : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
            }`}
            onClick={() => setActiveTab('completed')}
            aria-current={activeTab === 'completed' ? 'page' : undefined}
            aria-label="View completed visits"
          >
            Completed Visits
          </button>
          <button
            className={`py-4 px-1 border-b-2 font-medium text-sm ${
              activeTab === 'add'
                ? 'border-blue-500 text-blue-600'
                : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
            }`}
            onClick={() => setActiveTab('add')}
            aria-current={activeTab === 'add' ? 'page' : undefined}
            aria-label="Add new visitor"
          >
            Add Visitor
          </button>
        </nav>
      </div>
      
      {/* Tab Content */}
      <div className="mt-6">
        {isLoading ? (
          <div className="text-center py-12">
            <svg className="animate-spin h-10 w-10 text-blue-500 mx-auto mb-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
              <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
              <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>
            <p className="text-gray-500">Loading visits data...</p>
          </div>
        ) : loadingTimeout ? (
          <div className="text-center py-6 bg-yellow-50 border border-yellow-200 rounded-md mb-4">
            <p className="text-yellow-700">
              <strong>Loading timed out.</strong> The data might still be loading in the background.
            </p>
            <button 
              onClick={() => window.location.reload()} 
              className="mt-2 px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700"
            >
              Refresh Page
            </button>
          </div>
        ) : (
          <>
            {activeTab === 'active' && (
              <VisitTable
                visits={activeVisits}
                staffMembers={staffMembers}
                onAssign={handleAssignStaff}
                onStatusChange={handleStatusChange}
                onComplete={handleCompleteVisit}
                title="Active Visits"
                emptyMessage="No active visits at the moment."
              />
            )}
            
            {activeTab === 'completed' && (
              <VisitTable
                visits={completedVisits}
                staffMembers={staffMembers}
                onAssign={handleAssignStaff}
                onStatusChange={handleStatusChange}
                onComplete={handleCompleteVisit}
                title={isDateFilterActive 
                  ? `Completed Visits (${filterStartDate ? format(filterStartDate, 'MMM d, yyyy') : ''} - ${filterEndDate ? format(filterEndDate, 'MMM d, yyyy') : ''})` 
                  : "Completed Visits (Last 30 Days)"}
                emptyMessage={isLoading 
                  ? "Loading visits data..." 
                  : (isDateFilterActive 
                    ? "No completed visits in the selected date range." 
                    : "No completed visits in the last 30 days.")}
                isCompletedVisits={true}
                onDateFilterChange={handleDateFilterChange}
              />
            )}
            
            {activeTab === 'add' && (
              <AddVisitForm
                onVisitAdded={handleVisitAdded}
                onCancel={() => setActiveTab('active')}
              />
            )}
          </>
        )}
      </div>
      
      {/* Action Button */}
      {activeTab !== 'add' && (
        <div className="fixed bottom-8 right-8">
          <button
            onClick={() => setActiveTab('add')}
            className="bg-blue-600 text-white rounded-full p-4 shadow-lg hover:bg-blue-700 transition-colors"
            aria-label="Add new visitor"
          >
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
            </svg>
          </button>
        </div>
      )}
    </div>
  );
};

export default VisitsManagementPage; 