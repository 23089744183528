import React, { useState, useEffect, useMemo } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Card from '../components/ui/Card';
import Button from '../components/ui/Button';
import { useAuth } from '../contexts/AuthContext';
import { db } from '../firebase/config';
import { collection, addDoc, getDocs, doc, updateDoc, serverTimestamp, query, orderBy, Timestamp, where, onSnapshot, arrayUnion } from 'firebase/firestore';
import { HiOutlineUsers, HiOutlineClipboardCheck, HiOutlineClock, HiOutlineCheckCircle } from 'react-icons/hi';
import { createAccountCommission } from '../services/commissionService';

// Type definitions for bank client accounts
type AccountStatus = 
  | 'pending' 
  | 'processing' 
  | 'activated' 
  | 'documents-requested'
  | 'onboarded-online'
  | 'funded' 
  | 'dormant'
  | 'denied'
  | 'closed';
type TabType = 'overview' | 'activity' | 'documents' | 'notes';
type LoanStatus = 'not-applicable' | 'submitted' | 'under_review' | 'approved' | 'rejected';
type ATMStatus = 'not-applicable' | 'requested' | 'processing' | 'shipped' | 'delivered';
type EmailIndemnityStatus = 'not-applicable' | 'collected-from-customer' | 'shipped-to-kenya' | 'equity-agent-received' | 'en-route' | 'equity-bank-received';

interface ActivityLog {
  timestamp: Timestamp;
  action: string;
  performedBy: string;
  details: string;
}

interface BankClient {
  id: string;
  firstName: string;
  middleName?: string;
  lastName: string;
  email: string;
  phone: string;
  accountNumber: string;
  accountStatus: AccountStatus;
  accountType: string;
  createdAt: Timestamp;
  updatedAt?: Timestamp;
  updatedBy?: string;
  createdBy?: string;
  service?: string;
  managerNotes?: string;
  dateOfBirth?: Timestamp;
  nationality?: string;
  address?: string;
  city?: string;
  state?: string;
  zipCode?: string;
  loanStatus?: LoanStatus;
  atmStatus?: ATMStatus;
  emailIndemnityStatus?: EmailIndemnityStatus;
  activityLogs?: ActivityLog[];
  documents?: any[];
  notes?: string[] | string;
  loanRequests?: any[];
  atmRequests?: any[];
}

// Add this after the type definitions
const ACCOUNT_STATUSES: AccountStatus[] = [
  'pending',
  'processing',
  'documents-requested',
  'activated',
  'onboarded-online',
  'funded',
  'dormant',
  'denied',
  'closed'
];

// Mock data for clients
const mockClients = [
  {
    id: '1',
    firstName: 'John',
    lastName: 'Smith',
    phone: '206-555-1234',
    email: 'john.smith@example.com',
    accountNumber: 'EQB-2024-001',
    accountType: 'Savings Account',
    accountStatus: 'processing' as AccountStatus,
    createdAt: Timestamp.fromDate(new Date('2024-01-15')),
    notes: 'Client needs to complete KYC verification',
    activityLogs: [
      {
        timestamp: Timestamp.fromDate(new Date('2024-01-15')),
        action: 'Account Created',
        performedBy: 'agent@example.com',
        details: 'New account application submitted'
      }
    ],
    documents: []
  },
  {
    id: '2',
    firstName: 'Mary',
    lastName: 'Johnson',
    phone: '206-555-5678',
    email: 'mary.johnson@example.com',
    accountNumber: 'EQB-2024-002',
    accountType: 'Checking Account',
    accountStatus: 'activated' as AccountStatus,
    createdAt: Timestamp.fromDate(new Date('2024-01-10')),
    notes: 'Everything verified and approved',
    activityLogs: [
      {
        timestamp: Timestamp.fromDate(new Date('2024-01-10')),
        action: 'Account Created',
        performedBy: 'agent@example.com',
        details: 'New account application submitted'
      },
      {
        timestamp: Timestamp.fromDate(new Date('2024-01-12')),
        action: 'Account Activated',
        performedBy: 'manager@example.com',
        details: 'All documents verified and approved'
      }
    ],
    documents: []
  },
  {
    id: '3',
    firstName: 'Robert',
    lastName: 'Williams',
    phone: '206-555-9101',
    email: 'robert.williams@example.com',
    accountNumber: 'EQB-2024-003',
    accountType: 'Joint Account',
    accountStatus: 'pending' as AccountStatus,
    createdAt: Timestamp.fromDate(new Date('2024-01-18')),
    notes: 'Waiting for second account holder information',
    activityLogs: [
      {
        timestamp: Timestamp.fromDate(new Date('2024-01-18')),
        action: 'Account Created',
        performedBy: 'agent@example.com',
        details: 'New joint account application submitted'
      }
    ],
    documents: []
  }
];

// Add the AnalyticsCard component definition
const AnalyticsCard = ({ 
  title, 
  count, 
  percentage, 
  colorClass, 
  loading 
}: { 
  title: string; 
  count: number | string; 
  percentage: number; 
  colorClass: string;
  loading: boolean;
}) => (
  <div className={`bg-${colorClass}-50 border border-${colorClass}-100 rounded-lg p-4`}>
    <div className="flex justify-between items-start">
      <div>
        <p className={`text-sm font-medium text-${colorClass}-800`}>{title}</p>
        {loading ? (
          <div className="h-8 w-16 bg-gray-200 animate-pulse rounded mt-1"></div>
        ) : (
          <h3 className={`text-2xl font-bold text-${colorClass}-900 mt-1`}>{count}</h3>
        )}
      </div>
      <span className={`bg-${colorClass}-100 text-${colorClass}-800 text-xs font-semibold px-2.5 py-0.5 rounded`}>
        {percentage}%
      </span>
    </div>
    <div className={`w-full bg-${colorClass}-200 rounded-full h-1.5 mt-2`}>
      <div 
        className={`bg-${colorClass}-500 h-1.5 rounded-full ${loading ? 'animate-pulse' : ''}`} 
        style={{ width: `${percentage}%` }}
      ></div>
    </div>
  </div>
);

// Add this outside the component to prevent recreating the query on every render
const createClientsQuery = (db: any) => {
  const clientsRef = collection(db, 'bankClients');
  return query(clientsRef, orderBy('createdAt', 'desc'));
};

const BankServices: React.FC = () => {
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  
  // State
  const [clients, setClients] = useState<BankClient[]>([]);
  const [selectedClient, setSelectedClient] = useState<BankClient | null>(null);
  const [newStatus, setNewStatus] = useState<AccountStatus>('pending');
  const [managerNotes, setManagerNotes] = useState('');
  const [isStatusModalOpen, setIsStatusModalOpen] = useState(false);
  const [newClient, setNewClient] = useState<Omit<BankClient, 'id' | 'createdAt'>>({
    firstName: '',
    lastName: '',
    phone: '',
    email: '',
    accountNumber: '',
    accountType: 'Personal Savings',
    accountStatus: 'pending',
    service: 'new-account',
    notes: ''
  });
  const [isAddClientModalOpen, setIsAddClientModalOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [sortField, setSortField] = useState<keyof BankClient>('createdAt');
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('desc');
  const [activeTab, setActiveTab] = useState<TabType>('overview');
  const [statusFilter, setStatusFilter] = useState<AccountStatus | ''>('');
  const [serviceFilter, setServiceFilter] = useState<string>('');
  const [dateRange, setDateRange] = useState<{start: string, end: string}>({start: '', end: ''});
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(6);
  const [pageSizeOptions] = useState([6, 25, 50, 100]);
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [lastUpdated, setLastUpdated] = useState(new Date());
  
  // Update the useEffect for real-time listener
  useEffect(() => {
    console.log('Setting up real-time client data listener...');
    setLoading(true);

    const clientsQuery = createClientsQuery(db);
    
    const unsubscribe = onSnapshot(
      clientsQuery,
      (snapshot) => {
        try {
          const clientsList: BankClient[] = snapshot.docs.map(doc => {
            const data = doc.data();
            return {
              id: doc.id,
              firstName: data.firstName || '',
              lastName: data.lastName || '',
              phone: data.phoneNumber || data.phone || '',
              email: data.email || '',
              accountNumber: data.accountNumber || '',
              accountType: data.accountType || 'Personal Savings',
              accountStatus: data.accountStatus || 'pending',
              createdAt: data.createdAt || Timestamp.now(),
              updatedAt: data.updatedAt,
              updatedBy: data.updatedBy,
              service: data.service,
              managerNotes: data.notes || data.managerNotes,
              dateOfBirth: data.dateOfBirth,
              nationality: data.nationality,
              address: data.address,
              city: data.city,
              state: data.state,
              zipCode: data.zipCode,
              loanStatus: data.loanStatus,
              atmStatus: data.atmStatus,
              emailIndemnityStatus: data.emailIndemnityStatus,
              activityLogs: data.activityLogs || [],
              documents: data.documents || [],
              notes: data.notes || '',
              loanRequests: data.loanRequests || [],
              atmRequests: data.atmRequests || []
            };
          });

          setClients(clientsList);
          setLastUpdated(new Date());
          setLoading(false);
        } catch (error) {
          console.error('Error processing client data:', error);
          setLoading(false);
        }
      },
      (error) => {
        console.error('Error in real-time listener:', error);
        setLoading(false);
      }
    );

    // Cleanup subscription
    return () => {
      console.log('Cleaning up real-time listener...');
      unsubscribe();
    };
  }, []); // Empty dependency array since we want this to run once
  
  // Toast notifications with simpler implementation
  const toast = {
    success: (message: string) => {
      console.log('Success:', message);
    },
    error: (message: string) => {
      console.error('Error:', message);
    }
  };
  
  // Helper function to get status color
  const getStatusColor = (status: AccountStatus): string => {
    switch (status) {
      case 'pending':
        return 'bg-yellow-500';
      case 'processing':
        return 'bg-blue-500';
      case 'activated':
        return 'bg-green-500';
      case 'funded':
        return 'bg-purple-500';
      case 'dormant':
        return 'bg-gray-500';
      case 'denied':
      case 'closed':
        return 'bg-red-500';
      default:
        return 'bg-gray-500';
    }
  };
  
  // Function to format service names
  const formatServiceName = (service: string) => {
    switch(service) {
      case 'new-account': return 'New Account';
      case 'reactivation': return 'Reactivation';
      case 'update': return 'Account Update';
      default: return service;
    }
  };
  
  // Helper function to check if user has manager or higher role
  const isManager = () => {
    return currentUser?.role && ['manager', 'admin'].includes(currentUser.role.toLowerCase());
  };
  
  // Helper function to check if user has the right role to access commissions
  const canAccessCommissions = () => {
    return currentUser?.role && ['equity', 'admin', 'manager'].includes(currentUser.role.toLowerCase());
  };
  
  // Filtered and sorted clients
  const filteredClients = useMemo(() => {
    if (!clients || clients.length === 0) return [];
    
    return clients
      .filter(client => {
        if (!client) return false;
        
        // Text search with null checks
        const matchesSearch = !searchTerm ? true : (
          (client?.firstName && client.firstName.toLowerCase().includes(searchTerm.toLowerCase())) ||
          (client?.email && client.email.toLowerCase().includes(searchTerm.toLowerCase())) ||
          (client?.phone && client.phone.includes(searchTerm))
        );
        
        // Status filter
        const matchesStatus = statusFilter === '' || client?.accountStatus === statusFilter;
        
        // Service filter
        const matchesService = serviceFilter === '' || client?.accountType === serviceFilter;
        
        // Date range filter
        let matchesDateRange = true;
        if (dateRange.start || dateRange.end) {
          if (!client?.createdAt) return false;
          
          try {
            const clientDate = client.createdAt.toDate();
            const startDate = dateRange.start ? new Date(dateRange.start) : null;
            const endDate = dateRange.end ? new Date(dateRange.end) : null;
            
            if (startDate && clientDate < startDate) {
              matchesDateRange = false;
            }
            
            if (endDate) {
              // Add one day to include the end date
              const endDatePlusOne = new Date(endDate);
              endDatePlusOne.setDate(endDatePlusOne.getDate() + 1);
              if (clientDate > endDatePlusOne) {
                matchesDateRange = false;
              }
            }
          } catch (error) {
            console.error('Error processing date range filter:', error);
            matchesDateRange = false;
          }
        }
        
        return matchesSearch && matchesStatus && matchesService && matchesDateRange;
      })
      .sort((a, b) => {
        if (sortField === 'createdAt' || sortField === 'updatedAt' as keyof BankClient) {
          const dateA = (a[sortField as keyof BankClient] as Timestamp)?.toDate?.() || new Date(0);
          const dateB = (b[sortField as keyof BankClient] as Timestamp)?.toDate?.() || new Date(0);
          return sortDirection === 'asc' ? dateA.getTime() - dateB.getTime() : dateB.getTime() - dateA.getTime();
        } else {
          const valueA = String(a[sortField]).toLowerCase();
          const valueB = String(b[sortField]).toLowerCase();
          return sortDirection === 'asc'
            ? valueA.localeCompare(valueB)
            : valueB.localeCompare(valueA);
        }
      });
  }, [clients, searchTerm, sortField, sortDirection, statusFilter, serviceFilter, dateRange]);
  
  // Filtered and sorted clients with pagination
  const paginatedClients = useMemo(() => {
    const startIndex = (currentPage - 1) * rowsPerPage;
    const endIndex = startIndex + rowsPerPage;
    return filteredClients.slice(startIndex, endIndex);
  }, [filteredClients, currentPage, rowsPerPage]);
  
  // Calculate total number of pages
  const totalPages = useMemo(() => 
    Math.max(1, Math.ceil(filteredClients.length / rowsPerPage))
  , [filteredClients.length, rowsPerPage]);
  
  // Handle page change
  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
  };
  
  // Handle rows per page change
  const handleRowsPerPageChange = (newRowsPerPage: number) => {
    setRowsPerPage(newRowsPerPage);
    setCurrentPage(1); // Reset to first page when changing rows per page
  };
  
  // Reset pagination when filters change
  useEffect(() => {
    setCurrentPage(1);
  }, [searchTerm, statusFilter, serviceFilter, dateRange]);
  
  // Handle sorting
  const handleSort = (field: keyof BankClient) => {
    if (field === sortField) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortField(field);
      setSortDirection('asc');
    }
  };
  
  // Get status badge style
  const getStatusBadgeStyle = (status: AccountStatus) => {
    switch (status) {
      case 'pending':
        return 'bg-yellow-100 text-yellow-800';
      case 'processing':
        return 'bg-blue-100 text-blue-800';
      case 'documents-requested':
        return 'bg-orange-100 text-orange-800';
      case 'activated':
        return 'bg-green-100 text-green-800';
      case 'onboarded-online':
        return 'bg-emerald-100 text-emerald-800';
      case 'funded':
        return 'bg-purple-100 text-purple-800';
      case 'dormant':
        return 'bg-gray-100 text-gray-800';
      case 'denied':
        return 'bg-red-100 text-red-800';
      case 'closed':
        return 'bg-red-100 text-red-800';
      default:
        return 'bg-gray-100 text-gray-600';
    }
  };
  
  // Format date
  const formatDate = (timestamp: Timestamp | undefined) => {
    if (!timestamp) return 'N/A';
    return timestamp.toDate().toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    });
  };
  
  // Handle modal for client details
  const handleOpenModal = (client: BankClient) => {
    setSelectedClient(client);
    setNewStatus(client.accountStatus);
    setManagerNotes(client.managerNotes || '');
    setIsStatusModalOpen(true);
  };
  
  // Handle status update
  const handleStatusUpdate = async () => {
    if (!selectedClient || !newStatus) return;
    
    setSubmitting(true);
    try {
      const clientRef = doc(db, 'bankClients', selectedClient.id);
      
      // Create activity log entry
      const activityLog: ActivityLog = {
        timestamp: Timestamp.now(),
        action: 'Status Update',
        performedBy: currentUser?.email || 'unknown',
        details: `Status changed from ${selectedClient.accountStatus} to ${newStatus}${managerNotes ? `. Notes: ${managerNotes}` : ''}`
      };
      
      // Update the client status
      await updateDoc(clientRef, {
        accountStatus: newStatus,
        updatedAt: serverTimestamp(),
        updatedBy: currentUser?.email || 'unknown',
        managerNotes: managerNotes,
        activityLogs: arrayUnion(activityLog)
      });

      // Update local state
      setClients(prevClients => 
        prevClients.map(client => 
          client.id === selectedClient.id 
            ? { 
                ...client, 
                accountStatus: newStatus,
                managerNotes: managerNotes,
                updatedAt: Timestamp.now(),
                activityLogs: [...(client.activityLogs || []), activityLog]
              } 
            : client
        )
      );

      // Close modal and show success message
      setIsStatusModalOpen(false);
      setNewStatus('pending');
      setManagerNotes('');
      toast.success('Status updated successfully');
      
    } catch (error) {
      console.error('Error updating status:', error);
      toast.error('Failed to update status');
    } finally {
      setSubmitting(false);
    }
  };
  
  // Update the handleAddClient function to use the real-time listener instead of fetchClients
  const handleAddClient = async () => {
    setSubmitting(true);
    try {
      // Validation checks
      if (!newClient.firstName || !newClient.lastName || !newClient.phone || !newClient.email || !newClient.accountNumber) {
        toast.error('Please fill in all required fields.');
        setSubmitting(false);
        return;
      }

      // Save to Firestore
      const clientCollection = collection(db, 'bankClients');
      
      // Create a complete client object with firstName and lastName
      const clientData = {
        ...newClient,
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp(),
        createdBy: currentUser?.uid || 'unknown'
      };
      
      const docRef = await addDoc(clientCollection, clientData);
      
      // Reset form
      setNewClient({
        firstName: '',
        lastName: '',
        phone: '',
        email: '',
        accountNumber: '',
        accountType: 'Personal Savings',
        accountStatus: 'pending',
        service: 'new-account',
        notes: ''
      });
      
      setIsAddClientModalOpen(false);
      toast.success('New client has been successfully added.');
      
    } catch (error) {
      console.error('Error adding client:', error);
      toast.error('Failed to add client. Please try again.');
    } finally {
      setSubmitting(false);
    }
  };
  
  // Helper function to get sort icon
  const getSortIcon = (field: keyof BankClient) => {
    if (field !== sortField) return null;
    return sortDirection === 'asc' ? '↑' : '↓';
  };
  
  // Handle viewing client details
  const handleViewClient = (clientId: string) => {
    if (!clientId || typeof clientId !== 'string' || clientId.trim() === '') {
      console.error('Cannot view client: Missing or invalid clientId');
      toast.error('Unable to view client details. Missing or invalid client ID.');
      return;
    }
    
    console.log('Attempting to navigate to client details with ID:', clientId);
    console.log('Navigation path:', `/bank-services/clients/${clientId}`);
    
    try {
      // Check if client exists first
      const client = clients.find(c => c.id === clientId);
      if (!client) {
        console.warn(`Client with ID ${clientId} not found in local data. Navigation may fail.`);
      } else {
        console.log('Found client in local data:', client.firstName, client.lastName);
      }
      
      // Use navigate function for client-side routing with absolute path
      navigate(`/bank-services/clients/${clientId}`);
      
      // Log successful navigation attempt
      console.log('Navigation attempted successfully');
    } catch (error) {
      console.error('Navigation failed:', error);
      
      // Try alternative navigation as fallback
      try {
        window.location.href = `/bank-services/clients/${clientId}`;
        console.log('Fallback navigation attempted');
      } catch (fallbackError) {
        console.error('Fallback navigation also failed:', fallbackError);
        toast.error('Unable to navigate to client details. Please try again.');
      }
    }
  };
  
  // Function to clear all filters
  const clearFilters = () => {
    setSearchTerm('');
    setStatusFilter('');
    setServiceFilter('');
    setDateRange({ start: '', end: '' });
  };
  
  // Analytics calculations from client data
  const analytics = useMemo(() => {
    if (loading && clients.length === 0) {
      // Return loading placeholder analytics
      return {
        total: '-',
        pending: { count: '-', percentage: 0 },
        processing: { count: '-', percentage: 0 },
        activated: { count: '-', percentage: 0 },
        funded: { count: '-', percentage: 0 }
      };
    }
    
    if (!clients.length) return {
      total: 0,
      pending: { count: 0, percentage: 0 },
      processing: { count: 0, percentage: 0 },
      activated: { count: 0, percentage: 0 },
      funded: { count: 0, percentage: 0 }
    };
    
    // Count various statuses
    const total = clients.length;
    const pending = clients.filter(c => c.accountStatus === 'pending').length;
    const processing = clients.filter(c => c.accountStatus === 'processing').length;
    const activated = clients.filter(c => c.accountStatus === 'activated').length;
    const funded = clients.filter(c => c.accountStatus === 'funded').length;
    
    // Calculate percentages
    const calculatePercentage = (count: number) => Math.round((count / total) * 100);
    
    return {
      total,
      pending: { 
        count: pending, 
        percentage: calculatePercentage(pending)
      },
      processing: { 
        count: processing, 
        percentage: calculatePercentage(processing)
      },
      activated: { 
        count: activated, 
        percentage: calculatePercentage(activated)
      },
      funded: { 
        count: funded, 
        percentage: calculatePercentage(funded)
      }
    };
  }, [clients, loading]);

  // Function to truncate account number for display
  const truncateAccountNumber = (accountNumber: string | undefined | null): string => {
    if (!accountNumber || accountNumber.length < 8) return accountNumber || '';
    
    const firstFour = accountNumber.substring(0, 4);
    const lastFour = accountNumber.substring(accountNumber.length - 4);
    return `${firstFour} **** **** ${lastFour}`;
  };

  // Update the formatName function to show first name and initials
  const formatName = (fullName: string | undefined | null) => {
    if (!fullName) return '';
    
    const nameParts = fullName.split(' ').filter(Boolean);
    if (nameParts.length === 0) return '';
    if (nameParts.length === 1) return fullName;
    
    const firstName = nameParts[0];
    const rest = nameParts.slice(1).map(name => name && name.length > 0 ? `${name.charAt(0)}.` : '').join(' ');
    
    return `${firstName} ${rest}`;
  };

  // Example helper function to format client name
  const formatClientName = (client: BankClient | null | undefined): string => {
    if (!client) return '';
    return `${client.firstName || ''} ${client.lastName || ''}`.trim();
  };

  // Near the top of the file, add this utility function for safely capitalizing a string
  const safeCapitalize = (text: string | undefined | null): string => {
    if (!text) return '';
    return text.charAt(0).toUpperCase() + text.slice(1);
  };

  return (
    <div className="min-h-screen bg-gray-50">
      <div className="max-w-[1920px] mx-auto px-4 sm:px-6 lg:px-8 py-6 sm:py-10">
        <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-6 gap-4">
          <div>
            <h1 className="text-2xl sm:text-3xl font-bold text-gray-900">Banking Client Management</h1>
            <p className="text-sm sm:text-base text-gray-600">Track and manage client banking service requests and account statuses.</p>
          </div>
          <div className="flex flex-col sm:flex-row gap-3">
            {canAccessCommissions() && (
              <Button
                onClick={() => navigate('/bank-services/commissions')}
                className="bg-green-600 hover:bg-green-700 text-white text-sm sm:text-base w-full sm:w-auto"
              >
                Commission Dashboard
              </Button>
            )}
            <Button
              onClick={() => setIsAddClientModalOpen(true)}
              className="bg-blue-600 hover:bg-blue-700 text-white text-sm sm:text-base w-full sm:w-auto"
            >
              Add New Client
            </Button>
          </div>
        </div>
        
        {/* Analytics Dashboard */}
        <div className="mb-6">
          <Card>
            <div className="p-4 sm:p-6">
              <h2 className="text-lg sm:text-xl font-semibold text-gray-800 mb-4">Account Analytics</h2>
              <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
                {/* Analytics cards with responsive text */}
                <AnalyticsCard
                  title="Pending"
                  count={analytics.pending.count}
                  percentage={analytics.pending.percentage}
                  colorClass="yellow"
                  loading={loading}
                />
                <AnalyticsCard
                  title="Processing"
                  count={analytics.processing.count}
                  percentage={analytics.processing.percentage}
                  colorClass="blue"
                  loading={loading}
                />
                <AnalyticsCard
                  title="Activated"
                  count={analytics.activated.count}
                  percentage={analytics.activated.percentage}
                  colorClass="green"
                  loading={loading}
                />
                <AnalyticsCard
                  title="Funded"
                  count={analytics.funded.count}
                  percentage={analytics.funded.percentage}
                  colorClass="purple"
                  loading={loading}
                />
              </div>
              
              <div className="mt-4 text-xs sm:text-sm text-gray-600 flex flex-col sm:flex-row justify-between items-start sm:items-center gap-2">
                <span>Total Accounts: <strong>{analytics.total}</strong></span>
                <span className="text-blue-600">Last updated: {lastUpdated.toLocaleString()}</span>
              </div>
            </div>
          </Card>
        </div>
        
        {/* Enhanced Filtering UI */}
        <Card>
          <div className="p-4">
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-6 gap-4 mb-4">
              {/* Search input - spans 2 columns */}
              <div className="sm:col-span-2">
                <label htmlFor="search" className="block text-sm font-medium text-gray-700 mb-1">Search</label>
                <input
                  id="search"
                  type="text"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  placeholder="Search by name, email, phone..."
                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                />
              </div>
              
              {/* Other filters - single column each */}
              <div>
                <label htmlFor="status-filter" className="block text-sm font-medium text-gray-700 mb-1">Status</label>
                <select
                  id="status-filter"
                  value={statusFilter}
                  onChange={(e) => setStatusFilter(e.target.value as AccountStatus | '')}
                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                >
                  <option value="">All Statuses</option>
                  {ACCOUNT_STATUSES.map((status) => (
                    <option key={status} value={status}>
                      {status.split('-').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}
                    </option>
                  ))}
                </select>
              </div>
              
              {/* Service filter */}
              <div>
                <label htmlFor="service-filter" className="block text-sm font-medium text-gray-700 mb-1">Service Type</label>
                <select
                  id="service-filter"
                  value={serviceFilter}
                  onChange={(e) => setServiceFilter(e.target.value)}
                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                >
                  <option value="">All Services</option>
                  <option value="Savings Account">Savings Account</option>
                  <option value="Checking Account">Checking Account</option>
                  <option value="Joint Account">Joint Account</option>
                  <option value="Fixed Deposit">Fixed Deposit</option>
                  <option value="Children's Account">Children's Account</option>
                </select>
              </div>
              
              {/* Date filters */}
              <div>
                <label htmlFor="date-from" className="block text-sm font-medium text-gray-700 mb-1">From Date</label>
                <input
                  id="date-from"
                  type="date"
                  value={dateRange.start}
                  onChange={(e) => setDateRange(prev => ({ ...prev, start: e.target.value }))}
                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                />
              </div>
              
              <div>
                <label htmlFor="date-to" className="block text-sm font-medium text-gray-700 mb-1">To Date</label>
                <input
                  id="date-to"
                  type="date"
                  value={dateRange.end}
                  onChange={(e) => setDateRange(prev => ({ ...prev, end: e.target.value }))}
                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                />
              </div>
            </div>
            
            <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-3">
              <button
                type="button"
                onClick={clearFilters}
                className="inline-flex items-center px-2.5 py-1.5 border border-gray-300 shadow-sm text-xs font-medium rounded text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                Clear Filters
              </button>
              
              <div className="text-xs sm:text-sm text-gray-500">
                Showing {paginatedClients?.length || 0} of {clients?.length || 0} clients
              </div>
            </div>
          </div>
        </Card>
        
        {/* Clients table */}
        <Card>
          <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th scope="col" className="px-3 sm:px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer" onClick={() => handleSort('firstName')}>
                    Client {getSortIcon('firstName')}
                  </th>
                  <th scope="col" className="px-3 sm:px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer hidden sm:table-cell" onClick={() => handleSort('accountType')}>
                    Service {getSortIcon('accountType')}
                  </th>
                  <th scope="col" className="px-3 sm:px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer" onClick={() => handleSort('accountNumber')}>
                    Account {getSortIcon('accountNumber')}
                  </th>
                  <th scope="col" className="px-3 sm:px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer" onClick={() => handleSort('accountStatus')}>
                    Status {getSortIcon('accountStatus')}
                  </th>
                  <th scope="col" className="px-3 sm:px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer hidden sm:table-cell" onClick={() => handleSort('createdAt')}>
                    Submitted {getSortIcon('createdAt')}
                  </th>
                  <th scope="col" className="px-3 sm:px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {paginatedClients && paginatedClients.length > 0 ? (
                  paginatedClients.map((client) => (
                    <tr key={client?.id || Math.random().toString()} className="hover:bg-gray-50 transition-colors duration-150">
                      <td className="px-3 sm:px-6 py-4 whitespace-nowrap">
                        <div className="flex items-center">
                          <div className="flex-shrink-0 h-8 w-8 sm:h-10 sm:w-10 bg-gray-100 rounded-full flex items-center justify-center">
                            <span className="text-xs sm:text-sm font-medium text-gray-600">
                              {(client?.firstName?.[0] || '') + (client?.lastName?.[0] || '')}
                            </span>
                          </div>
                          <div className="ml-2 sm:ml-4">
                            <div className="text-xs sm:text-sm font-medium text-gray-900">
                              {formatName((client?.firstName || '') + ' ' + (client?.lastName || ''))}
                            </div>
                            <div className="text-xs text-gray-500 hidden sm:block">{client?.phone || ''}</div>
                            <div className="text-xs text-gray-500 hidden sm:block">{client?.email || ''}</div>
                          </div>
                        </div>
                      </td>
                      <td className="px-3 sm:px-6 py-4 whitespace-nowrap hidden sm:table-cell">
                        <div className="text-xs sm:text-sm text-gray-900 font-medium">
                          {client?.accountType || ''}
                        </div>
                      </td>
                      <td className="px-3 sm:px-6 py-4 whitespace-nowrap">
                        <div className="text-xs sm:text-sm font-mono text-gray-900">{truncateAccountNumber(client?.accountNumber)}</div>
                      </td>
                      <td className="px-3 sm:px-6 py-4 whitespace-nowrap">
                        <div className="flex flex-col space-y-1">
                          <span className={`px-2 sm:px-3 py-1 inline-flex text-xs leading-5 font-semibold rounded-full ${getStatusBadgeStyle(client?.accountStatus || 'pending')}`}>
                            {safeCapitalize(client?.accountStatus)}
                          </span>
                          {isManager() && client?.accountStatus === 'pending' && (
                            <span className="text-xs text-orange-500 hidden sm:inline">Needs review</span>
                          )}
                        </div>
                      </td>
                      <td className="px-3 sm:px-6 py-4 whitespace-nowrap hidden sm:table-cell">
                        <div className="flex flex-col">
                          <span className="text-xs sm:text-sm text-gray-900">{formatDate(client.createdAt)}</span>
                          <span className="text-xs text-gray-500">
                            by {client.createdBy && client.createdBy.includes('@') ? client.createdBy.split('@')[0] : 'Agent'}
                          </span>
                        </div>
                      </td>
                      <td className="px-3 sm:px-6 py-4 whitespace-nowrap text-right">
                        <div className="flex justify-end space-x-1 sm:space-x-2">
                          <button
                            onClick={() => handleViewClient(client.id)}
                            className="inline-flex items-center px-2 sm:px-3 py-1 sm:py-1.5 border border-transparent text-xs font-medium rounded-md text-blue-700 bg-blue-50 hover:bg-blue-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                          >
                            <svg className="h-4 w-4 sm:mr-1" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
                            </svg>
                            <span className="hidden sm:inline">View</span>
                          </button>
                          <button
                            onClick={() => handleOpenModal(client)}
                            className="inline-flex items-center px-2 sm:px-3 py-1 sm:py-1.5 border border-transparent text-xs font-medium rounded-md text-green-700 bg-green-50 hover:bg-green-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                          >
                            <svg className="h-4 w-4 sm:mr-1" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
                            </svg>
                            <span className="hidden sm:inline">Update</span>
                          </button>
                          {isManager() && (
                            <button
                              onClick={() => handleOpenModal(client)}
                              className="inline-flex items-center px-2 sm:px-3 py-1 sm:py-1.5 border border-transparent text-xs font-medium rounded-md text-indigo-700 bg-indigo-50 hover:bg-indigo-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                            >
                              <svg className="h-4 w-4 sm:mr-1" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
                              </svg>
                              <span className="hidden sm:inline">Edit</span>
                            </button>
                          )}
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={6} className="px-6 py-4 text-center text-gray-500">
                      {loading ? (
                        <div className="flex items-center justify-center">
                          <svg className="animate-spin h-5 w-5 mr-3 text-blue-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                          </svg>
                          <span>Loading client data...</span>
                        </div>
                      ) : (
                        'No clients found matching your search criteria.'
                      )}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            
            {/* Pagination with responsive design */}
            <div className="px-3 sm:px-6 py-3 sm:py-4 flex flex-col sm:flex-row items-center justify-between border-t border-gray-200 gap-3">
              <div className="flex-1 flex flex-col sm:flex-row justify-between items-center gap-3">
                <p className="text-xs sm:text-sm text-gray-700">
                  Showing <span className="font-medium">{(currentPage - 1) * rowsPerPage + (paginatedClients.length > 0 ? 1 : 0)}</span> to{" "}
                  <span className="font-medium">{Math.min(currentPage * rowsPerPage, filteredClients.length)}</span> of{" "}
                  <span className="font-medium">{filteredClients.length}</span> results
                </p>
                
                <div className="flex items-center space-x-2 sm:space-x-4">
                  <div className="flex items-center">
                    <span className="text-xs sm:text-sm text-gray-700 mr-2">Show</span>
                    <select
                      value={rowsPerPage}
                      onChange={(e) => handleRowsPerPageChange(Number(e.target.value))}
                      className="rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 text-xs sm:text-sm"
                    >
                      {pageSizeOptions.map(option => (
                        <option key={option} value={option}>{option}</option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
              
              {/* Pagination controls */}
              <nav className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px" aria-label="Pagination">
                <button
                  onClick={() => handlePageChange(1)}
                  disabled={currentPage === 1}
                  className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 disabled:opacity-50 disabled:cursor-not-allowed"
                >
                  <span className="sr-only">First Page</span>
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                    <path fillRule="evenodd" d="M15.707 15.707a1 1 0 01-1.414 0l-5-5a1 1 0 010-1.414l5-5a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 010 1.414z" clipRule="evenodd" />
                    <path fillRule="evenodd" d="M8.707 15.707a1 1 0 01-1.414 0l-5-5a1 1 0 010-1.414l5-5a1 1 0 111.414 1.414L4.414 10l4.293 4.293a1 1 0 010 1.414z" clipRule="evenodd" />
                  </svg>
                </button>
                
                {/* Previous button */}
                <button
                  onClick={() => handlePageChange(Math.max(1, currentPage - 1))}
                  disabled={currentPage === 1}
                  className="relative inline-flex items-center px-2 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 disabled:opacity-50 disabled:cursor-not-allowed"
                >
                  <span className="sr-only">Previous</span>
                  <svg className="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                    <path fillRule="evenodd" d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z" clipRule="evenodd" />
                  </svg>
                </button>
                
                {/* Page numbers */}
                {Array.from({ length: Math.min(5, totalPages) }).map((_, i) => {
                  let pageNumber: number;
                  if (totalPages <= 5) {
                    pageNumber = i + 1;
                  } else if (currentPage <= 3) {
                    pageNumber = i + 1;
                  } else if (currentPage >= totalPages - 2) {
                    pageNumber = totalPages - 4 + i;
                  } else {
                    pageNumber = currentPage - 2 + i;
                  }
                  
                  return (
                    pageNumber > 0 && pageNumber <= totalPages && (
                      <button
                        key={pageNumber}
                        onClick={() => handlePageChange(pageNumber)}
                        aria-current={currentPage === pageNumber ? "page" : undefined}
                        className={`relative inline-flex items-center px-4 py-2 border text-sm font-medium ${
                          currentPage === pageNumber
                            ? "z-10 bg-blue-50 border-blue-500 text-blue-600"
                            : "bg-white border-gray-300 text-gray-500 hover:bg-gray-50"
                        }`}
                      >
                        {pageNumber}
                      </button>
                    )
                  );
                })}
                
                {/* Next button */}
                <button
                  onClick={() => handlePageChange(Math.min(totalPages, currentPage + 1))}
                  disabled={currentPage === totalPages}
                  className="relative inline-flex items-center px-2 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 disabled:opacity-50 disabled:cursor-not-allowed"
                >
                  <span className="sr-only">Next</span>
                  <svg className="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                    <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" />
                  </svg>
                </button>
                
                {/* Last page button */}
                <button
                  onClick={() => handlePageChange(totalPages)}
                  disabled={currentPage === totalPages}
                  className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 disabled:opacity-50 disabled:cursor-not-allowed"
                >
                  <span className="sr-only">Last Page</span>
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                    <path fillRule="evenodd" d="M4.293 15.707a1 1 0 001.414 0l5-5a1 1 0 000-1.414l-5-5a1 1 0 00-1.414 1.414L8.586 10l4.293 4.293a1 1 0 000 1.414z" clipRule="evenodd" />
                    <path fillRule="evenodd" d="M11.293 15.707a1 1 0 001.414 0l5-5a1 1 0 000-1.414l-5-5a1 1 0 00-1.414 1.414L15.586 10l-4.293 4.293a1 1 0 000 1.414z" clipRule="evenodd" />
                  </svg>
                </button>
              </nav>
            </div>
          </div>
        </Card>
        
        {/* Status Update Modal */}
        {isStatusModalOpen && selectedClient && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
            <div className="bg-white rounded-lg p-6 max-w-2xl w-full max-h-[90vh] overflow-y-auto">
              <div className="flex justify-between items-center mb-4">
                <h2 className="text-xl font-bold">Update Client Status</h2>
                <button 
                  onClick={() => {
                    setIsStatusModalOpen(false);
                    setSelectedClient(null);
                  }}
                  className="text-gray-500 hover:text-gray-700"
                >
                  ✕
                </button>
              </div>
              
              <div className="space-y-6">
                <div className="bg-blue-50 p-4 rounded-md mb-6">
                  <div className="flex items-center">
                    <div>
                      <h3 className="text-sm font-medium text-blue-800">Manager Review</h3>
                      <p className="text-xs text-blue-700 mt-1">
                        Update the client account status, provide notes, and manage additional services
                      </p>
                    </div>
                  </div>
                </div>
                
                <div className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-2">
                  {/* Client Info - Read Only */}
                  <div>
                    <h4 className="text-sm font-medium text-gray-700 mb-3">Client Information</h4>
                    <div className="space-y-3">
                      <div>
                        <label className="block text-xs font-medium text-gray-500">Name</label>
                        <p className="mt-1 text-sm text-gray-900">
                          {selectedClient ? formatName((selectedClient?.firstName || '') + ' ' + (selectedClient?.lastName || '')) : ''}
                        </p>
                      </div>
                      <div>
                        <label className="block text-xs font-medium text-gray-500">Phone</label>
                        <p className="mt-1 text-sm text-gray-900">{selectedClient?.phone || ''}</p>
                        <p className="text-xs text-gray-500 mt-1">{selectedClient?.email || ''}</p>
                      </div>
                      <div>
                        <label className="block text-xs font-medium text-gray-500">Account Number</label>
                        <p className="mt-1 text-sm text-gray-900">{truncateAccountNumber(selectedClient?.accountNumber || '')}</p>
                      </div>
                    </div>
                  </div>
                  
                  {/* Status Update */}
                  <div>
                    <h4 className="text-sm font-medium text-gray-700 mb-3">Update Status</h4>
                    <div className="space-y-3">
                      <div>
                        <label htmlFor="status-select" className="block text-xs font-medium text-gray-500">Account Status</label>
                        <select
                          id="status-select"
                          value={newStatus}
                          onChange={(e) => setNewStatus(e.target.value as AccountStatus)}
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                        >
                          {ACCOUNT_STATUSES.map((status) => (
                            <option key={status} value={status}>
                              {status.split('-').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}
                            </option>
                          ))}
                        </select>
                      </div>
                      
                      <div>
                        <label htmlFor="manager-notes" className="block text-xs font-medium text-gray-500">Manager Notes</label>
                        <textarea
                          id="manager-notes"
                          value={managerNotes}
                          onChange={(e) => setManagerNotes(e.target.value)}
                          rows={3}
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                          placeholder="Add notes about this account..."
                        />
                      </div>
                    </div>
                  </div>
                </div>
                
                {/* Client Editable Information */}
                {isManager() && (
                  <div className="border-t border-gray-200 pt-4">
                    <h4 className="text-sm font-medium text-gray-700 mb-3">Edit Client Information</h4>
                    <div className="grid grid-cols-1 gap-y-3 gap-x-4 sm:grid-cols-2">
                      <div>
                        <label htmlFor="firstName" className="block text-xs font-medium text-gray-500">First Name</label>
                        <input
                          id="firstName"
                          type="text"
                          className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                          value={selectedClient?.firstName || ''}
                          onChange={(e) => selectedClient && setSelectedClient({...selectedClient, firstName: e.target.value})}
                          placeholder="Client's first name"
                        />
                      </div>
                      <div>
                        <label htmlFor="lastName" className="block text-xs font-medium text-gray-500">Last Name</label>
                        <input
                          id="lastName"
                          type="text"
                          className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                          value={selectedClient?.lastName || ''}
                          onChange={(e) => selectedClient && setSelectedClient({...selectedClient, lastName: e.target.value})}
                          placeholder="Client's last name"
                        />
                      </div>
                      <div>
                        <label htmlFor="phone" className="block text-xs font-medium text-gray-500">Phone Number</label>
                        <input
                          id="phone"
                          type="text"
                          className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                          value={selectedClient?.phone || ''}
                          onChange={(e) => selectedClient && setSelectedClient({...selectedClient, phone: e.target.value})}
                          placeholder="e.g., 206-555-1234"
                        />
                      </div>
                      <div>
                        <label htmlFor="email" className="block text-xs font-medium text-gray-500">Email</label>
                        <input
                          id="email"
                          type="email"
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                          value={selectedClient?.email || ''}
                          onChange={(e) => selectedClient && setSelectedClient({...selectedClient, email: e.target.value})}
                          placeholder="client@example.com"
                        />
                      </div>
                    </div>
                  </div>
                )}
                
                {/* Additional Status Tracking */}
                <div className="border-t border-gray-200 pt-4">
                  <h4 className="text-sm font-medium text-gray-700 mb-3">Additional Service Tracking</h4>
                  <div className="grid grid-cols-1 gap-y-3 gap-x-4 sm:grid-cols-3">
                    {/* Email Indemnity Status */}
                    <div>
                      <label htmlFor="indemnity-status" className="block text-xs font-medium text-gray-500">Email Indemnity Status</label>
                      <select
                        id="indemnity-status"
                        value={selectedClient?.emailIndemnityStatus || 'not-applicable'}
                        onChange={(e) => selectedClient && setSelectedClient({
                          ...selectedClient, 
                          emailIndemnityStatus: e.target.value as 'not-applicable' | 'collected-from-customer' | 'shipped-to-kenya' | 'equity-agent-received' | 'en-route' | 'equity-bank-received'
                        })}
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                      >
                        <option value="not-applicable">Not Applicable</option>
                        <option value="collected-from-customer">Collected from Customer</option>
                        <option value="shipped-to-kenya">Shipped to Kenya</option>
                        <option value="equity-agent-received">Equity Agent Received</option>
                        <option value="en-route">En-route</option>
                        <option value="equity-bank-received">Equity Bank Received</option>
                      </select>
                    </div>
                    
                    {/* Loan Status */}
                    <div>
                      <label htmlFor="loan-status" className="block text-xs font-medium text-gray-500">Loan Status</label>
                      <select
                        id="loan-status"
                        value={selectedClient?.loanStatus || 'not-applicable'}
                        onChange={(e) => selectedClient && setSelectedClient({
                          ...selectedClient, 
                          loanStatus: e.target.value as LoanStatus
                        })}
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                      >
                        <option value="not-applicable">Not Applicable</option>
                        <option value="pending">Pending</option>
                        <option value="processing">Processing</option>
                        <option value="approved">Approved</option>
                        <option value="denied">Denied</option>
                      </select>
                    </div>
                    
                    {/* ATM Status */}
                    <div>
                      <label htmlFor="atm-status" className="block text-xs font-medium text-gray-500">ATM Card Status</label>
                      <select
                        id="atm-status"
                        value={selectedClient?.atmStatus || 'not-applicable'}
                        onChange={(e) => selectedClient && setSelectedClient({
                          ...selectedClient, 
                          atmStatus: e.target.value as ATMStatus
                        })}
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                      >
                        <option value="not-applicable">Not Applicable</option>
                        <option value="pending">Pending</option>
                        <option value="processing">Processing</option>
                        <option value="issued">Issued</option>
                      </select>
                    </div>
                  </div>
                </div>
                
                <div className="flex justify-end">
                  <button
                    onClick={() => {
                      setIsStatusModalOpen(false);
                      setSelectedClient(null);
                    }}
                    className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 mr-3"
                  >
                    Cancel
                  </button>
                  <button
                    onClick={handleStatusUpdate}
                    className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                  >
                    Update Client
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
        
        {/* Add New Client Modal */}
        {isAddClientModalOpen && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
            <div className="bg-white rounded-lg p-6 max-w-2xl w-full max-h-[90vh] overflow-y-auto">
              <div className="flex justify-between items-center mb-4">
                <h2 className="text-xl font-bold">Add New Client</h2>
                <button 
                  onClick={() => setIsAddClientModalOpen(false)}
                  className="text-gray-500 hover:text-gray-700"
                >
                  ✕
                </button>
              </div>
              
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div>
                  <label htmlFor="firstName" className="block text-sm font-medium text-gray-700 mb-1">First Name</label>
                  <input
                    id="firstName"
                    type="text"
                    className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                    value={newClient.firstName}
                    onChange={(e) => setNewClient({...newClient, firstName: e.target.value})}
                    placeholder="Client's first name"
                  />
                </div>
                
                <div>
                  <label htmlFor="lastName" className="block text-sm font-medium text-gray-700 mb-1">Last Name</label>
                  <input
                    id="lastName"
                    type="text"
                    className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                    value={newClient.lastName}
                    onChange={(e) => setNewClient({...newClient, lastName: e.target.value})}
                    placeholder="Client's last name"
                  />
                </div>
                
                <div>
                  <label htmlFor="phone" className="block text-sm font-medium text-gray-700 mb-1">Phone Number</label>
                  <input
                    id="phone"
                    type="text"
                    className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                    value={newClient.phone}
                    onChange={(e) => setNewClient({...newClient, phone: e.target.value})}
                    placeholder="e.g., 206-555-1234"
                  />
                </div>
                
                <div>
                  <label htmlFor="email" className="block text-sm font-medium text-gray-700 mb-1">Email Address</label>
                  <input
                    id="email"
                    type="email"
                    className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                    value={newClient.email}
                    onChange={(e) => setNewClient({...newClient, email: e.target.value})}
                    placeholder="client@example.com"
                  />
                </div>

                <div>
                  <label htmlFor="accountNumber" className="block text-sm font-medium text-gray-700 mb-1">Account Number</label>
                  <input
                    id="accountNumber"
                    type="text"
                    className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                    value={newClient.accountNumber}
                    onChange={(e) => setNewClient({...newClient, accountNumber: e.target.value})}
                    placeholder="Enter account number"
                  />
                </div>

                <div>
                  <label htmlFor="accountType" className="block text-sm font-medium text-gray-700 mb-1">Account Type</label>
                  <select
                    id="accountType"
                    className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                    value={newClient.accountType}
                    onChange={(e) => setNewClient({...newClient, accountType: e.target.value})}
                  >
                    <option value="Personal Savings">Personal Savings</option>
                    <option value="Personal Checking">Personal Checking</option>
                    <option value="Business Account">Business Account</option>
                    <option value="Joint Account">Joint Account</option>
                    <option value="Fixed Deposit">Fixed Deposit</option>
                    <option value="Children's Account">Children's Account</option>
                  </select>
                </div>
                
                <div>
                  <label htmlFor="service" className="block text-sm font-medium text-gray-700 mb-1">Service Type</label>
                  <select
                    id="service"
                    className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                    value={newClient.service}
                    onChange={(e) => setNewClient({...newClient, service: e.target.value as 'new-account' | 'reactivation' | 'update'})}
                  >
                    <option value="new-account">New Account</option>
                    <option value="reactivation">Account Reactivation</option>
                    <option value="update">Account Update</option>
                  </select>
                </div>
                
                <div className="md:col-span-2">
                  <label htmlFor="notes" className="block text-sm font-medium text-gray-700 mb-1">Notes</label>
                  <textarea
                    id="notes"
                    className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 h-32"
                    value={newClient.notes}
                    onChange={(e) => setNewClient({...newClient, notes: e.target.value})}
                    placeholder="Add any relevant information about the client or service request..."
                  ></textarea>
                </div>
              </div>
              
              <div className="flex justify-end space-x-4 mt-6">
                <Button
                  onClick={() => setIsAddClientModalOpen(false)}
                  className="bg-white hover:bg-gray-50 text-gray-700 border border-gray-300"
                >
                  Cancel
                </Button>
                <Button
                  onClick={handleAddClient}
                  className="bg-blue-600 hover:bg-blue-700 text-white"
                  isDisabled={!newClient.firstName || !newClient.lastName || !newClient.phone || !newClient.email || !newClient.accountNumber}
                >
                  Add Client
                </Button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default BankServices; 