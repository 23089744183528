import React from 'react';
import { Link } from 'react-router-dom';
import Button from '../components/ui/Button';
import HeroSection from '../components/HeroSection';
import ServicesSection from '../components/ServicesSection';

const HomePage = () => {
  return (
    <div className="min-h-screen flex flex-col">
      {/* Hero Section */}
      <HeroSection />
      
      {/* Services Section */}
      <ServicesSection />
      
      {/* Footer */}
      <footer className="bg-blue-900 text-white py-8 mt-auto">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="border-t border-blue-800 pt-4">
            <p className="text-center text-sm">
              © {new Date().getFullYear()} Huduma Center USA. All rights reserved.
            </p>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default HomePage; 