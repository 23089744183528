import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Button from '../components/ui/Button';
import FormInput from '../components/ui/FormInput';
import FormSelect from '../components/ui/FormSelect';
import Breadcrumbs from '../components/ui/Breadcrumbs';
import Card from '../components/ui/Card';
import { useAuth } from '../contexts/AuthContext';
import { saveHCAApplication, HCAExamFormData, getHCAApplication } from '../services/applicationService';

// Form steps type
type FormStep = 'personal' | 'address' | 'identification' | 'review';

// Alert type
type AlertType = 'success' | 'error' | 'warning' | null;

// Add a type for the submission status
type SubmissionStatus = 'idle' | 'submitting' | 'success' | 'error';

// Animation keyframes setup for alert - defined as a proper custom hook now
const useAlertAnimationStyles = () => {
  useEffect(() => {
    // Create the style element if it doesn't exist yet
    if (!document.getElementById('alert-animation-style')) {
      const styleEl = document.createElement('style');
      styleEl.id = 'alert-animation-style';
      styleEl.textContent = `
        @keyframes fadeInDown {
          from {
            opacity: 0;
            transform: translate(-50%, -20px);
          }
          to {
            opacity: 1;
            transform: translate(-50%, 0);
          }
        }
      `;
      document.head.appendChild(styleEl);
    }
    
    return () => {
      // Proper cleanup - remove the style element when component unmounts
      const styleEl = document.getElementById('alert-animation-style');
      if (styleEl) {
        styleEl.remove();
      }
    };
  }, []);
};

const HCAExamRegistrationForm: React.FC = () => {
  const { serviceId } = useParams<{ serviceId: string }>();
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const fileInputRef = useRef<HTMLInputElement>(null);
  
  // Use the animation styles hook here
  useAlertAnimationStyles();
  
  // Form state
  const [currentStep, setCurrentStep] = useState<FormStep>('personal');
  const [formData, setFormData] = useState<HCAExamFormData>({
    firstName: '',
    lastName: '',
    dateOfBirth: '',
    email: '',
    phoneNumber: '',
    streetAddress: '',
    apartmentNumber: '',
    city: '',
    state: '',
    zipCode: '',
    ssn: '',
    hasSSN: 'yes',
    identgoOCANumber: '',
    agreeToTerms: false
  });
  
  // UI state
  const [errors, setErrors] = useState<Record<string, string>>({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submissionStatus, setSubmissionStatus] = useState<SubmissionStatus>('idle');
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [alertVisible, setAlertVisible] = useState(false);
  const [alertType, setAlertType] = useState<AlertType>(null);
  const [alertMessage, setAlertMessage] = useState('');
  const [redirectTimer, setRedirectTimer] = useState<NodeJS.Timeout | null>(null);
  
  // Redirect to services page after successful submission
  useEffect(() => {
    console.log('Redirect useEffect triggered. Current submissionStatus:', submissionStatus);
    
    if (submissionStatus === 'success') {
      console.log('Setting up redirect timer for 15 seconds');
      
      const timer = setTimeout(() => {
        console.log('Redirect timer fired - navigating to /services');
        navigate('/services');
      }, 15000);
      
      setRedirectTimer(timer);
      
      return () => {
        if (timer) {
          console.log('Cleaning up redirect timer');
          clearTimeout(timer);
        }
      };
    }
    
    return () => {
      // Cleanup function
      if (redirectTimer) {
        console.log('Cleanup: clearing existing redirect timer');
        clearTimeout(redirectTimer);
      }
    };
  }, [submissionStatus, navigate]);
  
  // State options for dropdown
  const stateOptions = [
    { value: 'AL', label: 'Alabama' },
    { value: 'AK', label: 'Alaska' },
    { value: 'AZ', label: 'Arizona' },
    // ... (other states)
    { value: 'WA', label: 'Washington' },
    { value: 'WV', label: 'West Virginia' },
    { value: 'WI', label: 'Wisconsin' },
    { value: 'WY', label: 'Wyoming' }
  ];
  
  const hasSSNOptions = [
    { value: 'yes', label: 'Yes' },
    { value: 'no', label: 'No' }
  ];
  
  // Show alert helper function
  const showAlert = (type: AlertType, message: string) => {
    setAlertType(type);
    setAlertMessage(message);
    setAlertVisible(true);
    
    // Auto-hide alert after 5 seconds
    setTimeout(() => {
      setAlertVisible(false);
    }, 5000);
  };
  
  // Custom Alert Component
  const CustomAlert = () => {
    if (!alertVisible) return null;
    
    let bgColor = '';
    let borderColor = '';
    let textColor = '';
    let titleColor = '';
    let icon = null;
    let title = '';
    
    switch (alertType) {
      case 'success':
        bgColor = 'bg-green-50';
        borderColor = 'border-green-500';
        textColor = 'text-green-700';
        titleColor = 'text-green-800';
        title = 'Success';
        icon = (
          <svg className="h-6 w-6 text-green-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
          </svg>
        );
        break;
      case 'error':
        bgColor = 'bg-red-50';
        borderColor = 'border-red-500';
        textColor = 'text-red-700';
        titleColor = 'text-red-800';
        title = 'Error';
        icon = (
          <svg className="h-6 w-6 text-red-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
          </svg>
        );
        break;
      case 'warning':
        bgColor = 'bg-yellow-50';
        borderColor = 'border-yellow-500';
        textColor = 'text-yellow-700';
        titleColor = 'text-yellow-800';
        title = 'Warning';
        icon = (
          <svg className="h-6 w-6 text-yellow-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
          </svg>
        );
        break;
      default:
        return null;
    }
    
    // Animation with React inline styles
    const alertAnimationStyle = {
      opacity: 1,
      transform: 'translate(-50%, 0)',
      transition: 'opacity 0.3s ease-in-out, transform 0.4s ease-out',
      animation: 'fadeInDown 0.5s ease-out forwards',
    };
    
    // Define the keyframes in a style tag for the document head
    // useEffect has been moved outside this component to avoid conditional calling
    
    return (
      <div 
        className="fixed top-5 left-1/2 transform -translate-x-1/2 z-50 w-full max-w-md"
        style={alertAnimationStyle}
      >
        <div className={`rounded-md border-l-4 ${borderColor} ${bgColor} p-4 shadow-md`}>
          <div className="flex">
            <div className="flex-shrink-0">
              {icon}
            </div>
            <div className="ml-3 w-full">
              <div className="flex items-center justify-between">
                <h3 className={`text-sm font-medium ${titleColor}`}>{title}</h3>
                <button 
                  onClick={() => setAlertVisible(false)} 
                  className={`inline-flex rounded-md p-1.5 text-gray-500 hover:bg-gray-100 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-gray-400`}
                >
                  <span className="sr-only">Dismiss</span>
                  <svg className="h-4 w-4" fill="currentColor" viewBox="0 0 20 20">
                    <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
                  </svg>
                </button>
              </div>
              <p className={`mt-1 text-sm ${textColor}`}>{alertMessage}</p>
            </div>
          </div>
        </div>
      </div>
    );
  };
  
  // Field validation functions
  const validateSSN = (value: string): string => {
    if (!value.trim()) return 'SSN is required';
    
    // Basic SSN format validation (###-##-#### or without dashes)
    const ssnRegex = /^(?:\d{3}-\d{2}-\d{4}|\d{9})$/;
    if (!ssnRegex.test(value.trim())) {
      return 'Please enter a valid SSN (e.g., 123-45-6789 or 123456789)';
    }
    return '';
  };

  const validateDateOfBirth = (value: string): string => {
    if (!value) return 'Date of birth is required';
    
    // Date format validation (MM/DD/YYYY)
    const dateRegex = /^(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])\/\d{4}$/;
    if (!dateRegex.test(value)) {
      return 'Date of birth must be in MM/DD/YYYY format';
    }
    
    // Check that the date is valid and not in the future
    const parts = value.split('/');
    const inputDate = new Date(
      parseInt(parts[2]), // year
      parseInt(parts[0]) - 1, // month (0-indexed)
      parseInt(parts[1]) // day
    );
    
    const today = new Date();
    
    if (isNaN(inputDate.getTime())) {
      return 'Please enter a valid date';
    }
    
    if (inputDate > today) {
      return 'Date of birth cannot be in the future';
    }
    
    // Must be at least 16 years old
    const sixteenYearsAgo = new Date();
    sixteenYearsAgo.setFullYear(today.getFullYear() - 16);
    
    if (inputDate > sixteenYearsAgo) {
      return 'You must be at least 16 years old';
    }
    
    return '';
  };

  const validateEmail = (value: string): string => {
    if (!value.trim()) return 'Email is required';
    
    // Email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(value.trim())) {
      return 'Please enter a valid email address';
    }
    return '';
  };

  const validatePhoneNumber = (value: string): string => {
    if (!value.trim()) return 'Phone number is required';
    
    // Phone number validation (allows formats like: 123-456-7890, (123) 456-7890, 1234567890)
    const phoneRegex = /^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;
    if (!phoneRegex.test(value.trim())) {
      return 'Please enter a valid phone number';
    }
    return '';
  };

  const validateZipCode = (value: string): string => {
    if (!value.trim()) return 'ZIP code is required';
    
    // US ZIP code validation (5 digits or 5+4 format)
    const zipRegex = /^\d{5}(-\d{4})?$/;
    if (!zipRegex.test(value.trim())) {
      return 'Please enter a valid ZIP code (e.g., 12345 or 12345-6789)';
    }
    return '';
  };

  const validateIdentgoOCANumber = (value: string): string => {
    if (!value.trim()) return ''; // Make it optional by not returning an error for empty values
    
    // Remove the specific digit requirement
    return '';
  };

  const validateName = (value: string, fieldName: string): string => {
    if (!value.trim()) return `${fieldName} is required`;
    if (value.trim().length < 2) return `${fieldName} must be at least 2 characters`;
    return '';
  };

  // Handle input change with real-time validation
  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>,
    validateImmediately = false
  ) => {
    const { name, value } = e.target;
    
    // Update form data
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
    
    // For some fields, we want to validate immediately as user types
    // For others, we'll validate on completion of typing (when field loses focus)
    if (validateImmediately) {
      validateField(name, value);
    } else {
      // Clear error when user starts typing
      if (errors[name]) {
        setErrors(prev => ({
          ...prev,
          [name]: ''
        }));
      }
    }
  };

  // For when field loses focus - simulating an onBlur effect with onChange
  const handleFieldCompletion = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    validateField(name, value);
  };

  // Validate a single field
  const validateField = (name: string, value: string): boolean => {
    let errorMessage = '';
    
    switch (name) {
      case 'firstName':
        errorMessage = validateName(value, 'First name');
        break;
      case 'lastName':
        errorMessage = validateName(value, 'Last name');
        break;
      case 'dateOfBirth':
        errorMessage = validateDateOfBirth(value);
        break;
      case 'email':
        errorMessage = validateEmail(value);
        break;
      case 'phoneNumber':
        errorMessage = validatePhoneNumber(value);
        break;
      case 'streetAddress':
        errorMessage = value.trim() ? (value.trim().length < 5 ? 'Please enter a complete street address' : '') : 'Street address is required';
        break;
      case 'city':
        errorMessage = validateName(value, 'City');
        break;
      case 'state':
        errorMessage = value ? '' : 'State is required';
        break;
      case 'zipCode':
        errorMessage = validateZipCode(value);
        break;
      case 'ssn':
        if (formData.hasSSN === 'yes') {
          errorMessage = validateSSN(value);
        }
        break;
      case 'identgoOCANumber':
        errorMessage = validateIdentgoOCANumber(value);
        break;
      default:
        break;
    }
    
    // Update errors state
    setErrors(prev => ({
      ...prev,
      [name]: errorMessage
    }));
    
    return !errorMessage;
  };

  // Direct button click handler
  const handleSubmitButtonClick = () => {
    console.log("Submit button clicked");
    
    // Manually validate the form
    if (!validateForm()) {
      console.log("Validation failed on button click", errors);
      
      // Build a more specific error message
      const errorFields = Object.keys(errors);
      let errorMessage = "Please fill out all required fields before submitting.";
      
      if (errorFields.length > 0) {
        // Get the first three error fields to show (to keep message manageable)
        const fieldsToShow = errorFields.slice(0, 3);
        const fieldNames = fieldsToShow.map(field => {
          // Convert camelCase to readable text
          const readableField = field
            .replace(/([A-Z])/g, ' $1')
            .replace(/^./, str => str.toUpperCase());
          
          return readableField;
        });
        
        if (fieldsToShow.length === 1) {
          errorMessage = `Please complete the ${fieldNames[0]} field before submitting.`;
        } else if (fieldsToShow.length === 2) {
          errorMessage = `Please complete the ${fieldNames[0]} and ${fieldNames[1]} fields before submitting.`;
        } else {
          // If there are more than 3 errors, indicate there are more
          const additionalCount = errorFields.length - 3;
          const additionalText = additionalCount > 0 ? ` and ${additionalCount} more` : '';
          errorMessage = `Please complete the following fields: ${fieldNames.join(', ')}${additionalText}.`;
        }
      }
      
      showAlert('warning', errorMessage);
      return;
    }
    
    console.log("Validation passed, calling submit handler");
    // Call the submit handler
    handleSubmit({ preventDefault: () => {} } as React.FormEvent);
  };

  // Form submission handler
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    console.log('Submit handler called');
    
    if (submissionStatus === 'submitting') {
      console.log('Already submitting, preventing duplicate submission');
      return;
    }

    // First validate the form
    const isValid = validateForm();
    
    if (!isValid) {
      console.log('Form validation failed');
      showAlert('error', 'Please fill in all required fields and correct any errors before submitting.');
      return;
    }
    
    if (!formData.agreeToTerms) {
      console.log('Terms not agreed to');
      showAlert('error', 'You must agree to the terms and conditions to submit your application.');
      return;
    }
    
    console.log('Form validation passed, proceeding with submission');
    setSubmissionStatus('submitting');
    
    try {
      // Save application data to Firestore
      console.log('Attempting to save application to Firestore...');
      const applicationId = await saveHCAApplication(formData, currentUser?.uid);
      
      console.log('Successfully saved application with ID:', applicationId);
      
      // Reset form data and show success message
      setSubmissionStatus('success');
      
      let successMessage = 'Your application has been submitted successfully!';
      
      showAlert('success', successMessage);
      
      // Save to local storage in case user needs to return
      localStorage.setItem('hcaApplicationId', applicationId);
      
      // Reset the form data after successful submission
      resetForm();
    } catch (error) {
      console.error('Error submitting form:', error);
      
      // Check if the error is related to CORS or file upload issues
      const errorMessage = error instanceof Error ? error.message : String(error);
      console.log('Error message:', errorMessage);
      
      // For other errors, show error state
      setSubmissionStatus('error');
      showAlert('error', `Error submitting form: ${errorMessage}`);
    }
  };
  
  // Form validation
  const validateForm = (): boolean => {
    console.log(`Validating form at step ${currentStep}`, formData);
    const newErrors: Record<string, string> = {};
    
    // Validate based on current step
    if (currentStep === 'personal' || currentStep === 'review') {
      newErrors.firstName = validateName(formData.firstName, 'First name');
      newErrors.lastName = validateName(formData.lastName, 'Last name');
      newErrors.dateOfBirth = validateDateOfBirth(formData.dateOfBirth);
      newErrors.email = validateEmail(formData.email);
      newErrors.phoneNumber = validatePhoneNumber(formData.phoneNumber);
    }
    
    if (currentStep === 'address' || currentStep === 'review') {
      newErrors.streetAddress = formData.streetAddress.trim() 
        ? (formData.streetAddress.trim().length < 5 ? 'Please enter a complete street address' : '') 
        : 'Street address is required';
      
      newErrors.city = validateName(formData.city, 'City');
      newErrors.state = formData.state ? '' : 'State is required';
      newErrors.zipCode = validateZipCode(formData.zipCode);
    }
    
    if (currentStep === 'identification' || currentStep === 'review') {
      // Only validate SSN if the user has selected 'yes' for hasSSN
      if (formData.hasSSN === 'yes') {
        newErrors.ssn = validateSSN(formData.ssn);
      }
      
      // Only validate Identgo OCA Number if it's provided (now optional)
      if (formData.identgoOCANumber.trim()) {
        newErrors.identgoOCANumber = validateIdentgoOCANumber(formData.identgoOCANumber);
      }
    }
    
    if (currentStep === 'review') {
      if (!formData.agreeToTerms) {
        newErrors.agreeToTerms = 'You must agree to the terms to proceed';
      }
    }
    
    // Remove empty error messages
    Object.keys(newErrors).forEach(key => {
      if (!newErrors[key]) {
        delete newErrors[key];
      }
    });
    
    setErrors(newErrors);
    const isValid = Object.keys(newErrors).length === 0;
    console.log("Validation result:", isValid ? "Passed" : "Failed", newErrors);
    return isValid;
  };
  
  // Add form step components
  const renderStepContent = (step: FormStep) => {
    switch (step) {
      case 'personal':
        return (
          <div>
            <FormInput
              id="firstName"
              name="firstName"
              label="First Name"
              value={formData.firstName}
              onChange={(e) => {
                handleInputChange(e);
                if (e.target.value.length >= 2) {
                  handleFieldCompletion(e);
                }
              }}
              error={errors.firstName}
            />
            <FormInput
              id="lastName"
              name="lastName"
              label="Last Name"
              value={formData.lastName}
              onChange={(e) => {
                handleInputChange(e);
                if (e.target.value.length >= 2) {
                  handleFieldCompletion(e);
                }
              }}
              error={errors.lastName}
            />
            <FormInput
              id="dateOfBirth"
              name="dateOfBirth"
              label="Date of Birth"
              placeholder="MM/DD/YYYY"
              value={formData.dateOfBirth}
              onChange={(e) => handleInputChange(e, true)}
              error={errors.dateOfBirth}
            />
            <FormInput
              id="email"
              name="email"
              label="Email"
              type="email"
              value={formData.email}
              onChange={(e) => {
                handleInputChange(e);
                // Validate email when it looks complete (contains @ and .)
                if (e.target.value.includes('@') && e.target.value.includes('.')) {
                  handleFieldCompletion(e);
                }
              }}
              error={errors.email}
            />
            <FormInput
              id="phoneNumber"
              name="phoneNumber"
              label="Phone Number"
              value={formData.phoneNumber}
              onChange={(e) => {
                handleInputChange(e);
                // Validate when phone number is likely complete (at least 10 digits)
                if (e.target.value.replace(/\D/g, '').length >= 10) {
                  handleFieldCompletion(e);
                }
              }}
              error={errors.phoneNumber}
            />
          </div>
        );
      case 'address':
        return (
          <div>
            <FormInput
              id="streetAddress"
              name="streetAddress"
              label="Street Address"
              value={formData.streetAddress}
              onChange={(e) => {
                handleInputChange(e);
                if (e.target.value.length >= 5) {
                  handleFieldCompletion(e);
                }
              }}
              error={errors.streetAddress}
            />
            <FormInput
              id="apartmentNumber"
              name="apartmentNumber"
              label="Apt No (Optional)"
              value={formData.apartmentNumber || ''}
              onChange={(e) => handleInputChange(e)}
            />
            <FormInput
              id="city"
              name="city"
              label="City"
              value={formData.city}
              onChange={(e) => {
                handleInputChange(e);
                if (e.target.value.length >= 2) {
                  handleFieldCompletion(e);
                }
              }}
              error={errors.city}
            />
            <FormSelect
              id="state"
              name="state"
              label="State"
              value={formData.state}
              onChange={(e) => {
                handleInputChange(e);
                if (e.target.value) {
                  handleFieldCompletion(e);
                }
              }}
              options={stateOptions}
              error={errors.state}
            />
            <FormInput
              id="zipCode"
              name="zipCode"
              label="ZIP Code"
              value={formData.zipCode}
              onChange={(e) => handleInputChange(e, true)}
              error={errors.zipCode}
            />
          </div>
        );
      case 'identification':
        return (
          <div>
            <FormSelect
              id="hasSSN"
              name="hasSSN"
              label="Do you have a Social Security Number (SSN)?"
              value={formData.hasSSN}
              onChange={(e) => {
                handleInputChange(e);
                // Clear SSN if user selects 'no'
                if (e.target.value === 'no') {
                  setFormData(prev => ({
                    ...prev,
                    ssn: '',
                    hasSSN: 'no'
                  }));
                  // Also clear any SSN errors
                  setErrors(prev => ({
                    ...prev,
                    ssn: ''
                  }));
                }
              }}
              options={hasSSNOptions}
              error={errors.hasSSN}
            />
            
            {formData.hasSSN === 'yes' && (
              <FormInput
                id="ssn"
                name="ssn"
                label="Social Security Number"
                value={formData.ssn}
                onChange={(e) => handleInputChange(e, true)}
                error={errors.ssn}
                placeholder="123-45-6789"
              />
            )}
            
            <FormInput
              id="identgoOCANumber"
              name="identgoOCANumber"
              label="Identgo OCA Number"
              value={formData.identgoOCANumber}
              onChange={(e) => handleInputChange(e, true)}
              error={errors.identgoOCANumber}
              placeholder="Enter your Identgo OCA Number"
            />
          </div>
        );
      case 'review':
        // Get all validation errors for the form
        const validateAll = () => {
          const allErrors: Record<string, string> = {};
          
          // Personal info
          allErrors.firstName = validateName(formData.firstName, 'First name');
          allErrors.lastName = validateName(formData.lastName, 'Last name');
          allErrors.dateOfBirth = validateDateOfBirth(formData.dateOfBirth);
          allErrors.email = validateEmail(formData.email);
          allErrors.phoneNumber = validatePhoneNumber(formData.phoneNumber);
          
          // Address
          allErrors.streetAddress = formData.streetAddress.trim() 
            ? (formData.streetAddress.trim().length < 5 ? 'Please enter a complete street address' : '') 
            : 'Street address is required';
          allErrors.city = validateName(formData.city, 'City');
          allErrors.state = formData.state ? '' : 'State is required';
          allErrors.zipCode = validateZipCode(formData.zipCode);
          
          // Identification
          if (formData.hasSSN === 'yes') {
            allErrors.ssn = validateSSN(formData.ssn);
          }
          if (formData.identgoOCANumber.trim()) {
            allErrors.identgoOCANumber = validateIdentgoOCANumber(formData.identgoOCANumber);
          }
          
          // Remove empty error messages
          Object.keys(allErrors).forEach(key => {
            if (!allErrors[key]) {
              delete allErrors[key];
            }
          });
          
          return allErrors;
        };
        
        const reviewErrors = validateAll();
        const hasErrors = Object.keys(reviewErrors).length > 0;
        
        return (
          <div>
            <h2 className="text-xl font-semibold mb-4">Review Your Application</h2>
            
            {hasErrors && (
              <div className="mb-6 bg-red-50 border-l-4 border-red-500 p-4">
                <div className="flex">
                  <div className="flex-shrink-0">
                    <svg className="h-5 w-5 text-red-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                      <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
                    </svg>
                  </div>
                  <div className="ml-3">
                    <h3 className="text-sm leading-5 font-medium text-red-800">
                      There are issues with your application
                    </h3>
                    <div className="mt-2 text-sm leading-5 text-red-700">
                      <ul className="list-disc pl-5 space-y-1">
                        {Object.entries(reviewErrors).map(([field, error]) => (
                          <li key={field}>
                            {field.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase())}: {error}
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            )}
            
            <div className="bg-white shadow overflow-hidden sm:rounded-lg mb-6">
              <div className="px-4 py-5 sm:px-6 bg-gray-50">
                <h3 className="text-lg leading-6 font-medium text-gray-900">Personal Information</h3>
              </div>
              <div className="border-t border-gray-200">
                <dl>
                  <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt className="text-sm font-medium text-gray-500">Full name</dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                      {formData.firstName} {formData.lastName}
                      {(reviewErrors.firstName || reviewErrors.lastName) && (
                        <span className="ml-2 text-red-500 text-xs">
                          {reviewErrors.firstName || reviewErrors.lastName}
                        </span>
                      )}
                    </dd>
                  </div>
                  <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt className="text-sm font-medium text-gray-500">Date of Birth</dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                      {formData.dateOfBirth}
                      {reviewErrors.dateOfBirth && (
                        <span className="ml-2 text-red-500 text-xs">
                          {reviewErrors.dateOfBirth}
                        </span>
                      )}
                    </dd>
                  </div>
                  <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt className="text-sm font-medium text-gray-500">Email address</dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                      {formData.email}
                      {reviewErrors.email && (
                        <span className="ml-2 text-red-500 text-xs">
                          {reviewErrors.email}
                        </span>
                      )}
                    </dd>
                  </div>
                  <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt className="text-sm font-medium text-gray-500">Phone number</dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                      {formData.phoneNumber}
                      {reviewErrors.phoneNumber && (
                        <span className="ml-2 text-red-500 text-xs">
                          {reviewErrors.phoneNumber}
                        </span>
                      )}
                    </dd>
                  </div>
                </dl>
              </div>
            </div>
            
            <div className="bg-white shadow overflow-hidden sm:rounded-lg mb-6">
              <div className="px-4 py-5 sm:px-6 bg-gray-50">
                <h3 className="text-lg leading-6 font-medium text-gray-900">Address Information</h3>
              </div>
              <div className="border-t border-gray-200">
                <dl>
                  <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt className="text-sm font-medium text-gray-500">Street Address</dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                      {formData.streetAddress}
                      {reviewErrors.streetAddress && (
                        <span className="ml-2 text-red-500 text-xs">
                          {reviewErrors.streetAddress}
                        </span>
                      )}
                    </dd>
                  </div>
                  <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt className="text-sm font-medium text-gray-500">Apartment Number</dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                      {formData.apartmentNumber || 'N/A'}
                      {reviewErrors.apartmentNumber && (
                        <span className="ml-2 text-red-500 text-xs">
                          {reviewErrors.apartmentNumber}
                        </span>
                      )}
                    </dd>
                  </div>
                  <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt className="text-sm font-medium text-gray-500">City</dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                      {formData.city}
                      {reviewErrors.city && (
                        <span className="ml-2 text-red-500 text-xs">
                          {reviewErrors.city}
                        </span>
                      )}
                    </dd>
                  </div>
                  <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt className="text-sm font-medium text-gray-500">State</dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                      {formData.state}
                      {reviewErrors.state && (
                        <span className="ml-2 text-red-500 text-xs">
                          {reviewErrors.state}
                        </span>
                      )}
                    </dd>
                  </div>
                  <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt className="text-sm font-medium text-gray-500">ZIP Code</dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                      {formData.zipCode}
                      {reviewErrors.zipCode && (
                        <span className="ml-2 text-red-500 text-xs">
                          {reviewErrors.zipCode}
                        </span>
                      )}
                    </dd>
                  </div>
                </dl>
              </div>
            </div>
            
            <div className="bg-white shadow overflow-hidden sm:rounded-lg mb-6">
              <div className="px-4 py-5 sm:px-6 bg-gray-50">
                <h3 className="text-lg leading-6 font-medium text-gray-900">Identification Information</h3>
              </div>
              <div className="border-t border-gray-200">
                <dl>
                  {formData.hasSSN === 'yes' && (
                    <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <dt className="text-sm font-medium text-gray-500">Social Security Number</dt>
                      <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        {/* Mask the SSN for security */}
                        {formData.ssn.includes('-') 
                          ? `XXX-XX-${formData.ssn.split('-')[2]}`
                          : `XXX-XX-${formData.ssn.slice(-4)}`}
                        {reviewErrors.ssn && (
                          <span className="ml-2 text-red-500 text-xs">
                            {reviewErrors.ssn}
                          </span>
                        )}
                      </dd>
                    </div>
                  )}
                  <div className={`${formData.hasSSN === 'yes' ? 'bg-gray-50' : 'bg-white'} px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6`}>
                    <dt className="text-sm font-medium text-gray-500">Identgo OCA Number</dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                      {formData.identgoOCANumber}
                      {reviewErrors.identgoOCANumber && (
                        <span className="ml-2 text-red-500 text-xs">
                          {reviewErrors.identgoOCANumber}
                        </span>
                      )}
                    </dd>
                  </div>
                </dl>
              </div>
            </div>
            
            <div className="mt-6 mb-8">
              <div className="relative flex items-start">
                <div className="flex items-center h-5">
                  <input
                    id="agreeToTerms"
                    name="agreeToTerms"
                    type="checkbox"
                    checked={formData.agreeToTerms}
                    onChange={(e) => {
                      setFormData({ ...formData, agreeToTerms: e.target.checked });
                      // Clear error when checked
                      if (e.target.checked && errors.agreeToTerms) {
                        setErrors({
                          ...errors,
                          agreeToTerms: ''
                        });
                      }
                    }}
                    className={`h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded 
                      ${errors.agreeToTerms ? 'border-red-300' : ''}`}
                  />
                </div>
                <div className="ml-3 text-sm">
                  <label htmlFor="agreeToTerms" className="font-medium text-gray-700">I agree to the terms and conditions</label>
                  <p className="text-gray-500">By checking this box, I certify that all information provided is true and accurate.</p>
                  {errors.agreeToTerms && (
                    <p className="mt-1 text-sm text-red-600">{errors.agreeToTerms}</p>
                  )}
                </div>
              </div>
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  // Add navigation buttons
  const handleNext = () => {
    const steps: FormStep[] = ['personal', 'address', 'identification', 'review'];
    const currentIndex = steps.indexOf(currentStep);
    if (currentIndex < steps.length - 1) {
      setCurrentStep(steps[currentIndex + 1]);
    }
  };

  const handleBack = () => {
    const steps: FormStep[] = ['personal', 'address', 'identification', 'review'];
    const currentIndex = steps.indexOf(currentStep);
    if (currentIndex > 0) {
      setCurrentStep(steps[currentIndex - 1]);
    }
  };
  
  // Function to download application details
  const handleDownloadApplication = () => {
    // Generate application ID
    const appId = `HCA-${Date.now().toString().slice(-6)}`;
    // Create application data as an array of strings and join with newlines
    const dataLines = [
      "HCA EXAMINATION REGISTRATION APPLICATION",
      "=======================================",
      "",
      "PERSONAL INFORMATION",
      "-------------------",
      `First Name: ${formData.firstName}`,
      `Last Name: ${formData.lastName}`,
      `Date of Birth: ${formData.dateOfBirth}`,
      `Email: ${formData.email}`,
      `Phone Number: ${formData.phoneNumber}`,
      "",
      "ADDRESS INFORMATION",
      "-----------------",
      `Street Address: ${formData.streetAddress}`,
      `Apartment Number: ${formData.apartmentNumber || 'N/A'}`,
      `City: ${formData.city}`,
      `State: ${formData.state}`,
      `ZIP Code: ${formData.zipCode}`,
      "",
      "IDENTIFICATION",
      "------------",
      `SSN: ${formData.ssn}`,
      `Identgo OCA Number: ${formData.identgoOCANumber}`,
      "",
      `Application ID: ${appId}`,
      `Date Submitted: ${new Date().toLocaleDateString()}`
    ];
    
    const applicationData = dataLines.join('\n');
    
    // Create Blob and download link
    const blob = new Blob([applicationData], { type: 'text/plain' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = `HCA_Application_${formData.lastName}_${formData.firstName}.txt`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  // Form reset function
  const resetForm = () => {
    setFormData({
      firstName: '',
      lastName: '',
      dateOfBirth: '',
      email: '',
      phoneNumber: '',
      streetAddress: '',
      apartmentNumber: '',
      city: '',
      state: '',
      zipCode: '',
      ssn: '',
      hasSSN: 'yes',
      identgoOCANumber: '',
      agreeToTerms: false
    });
    
    // Reset to first step
    setCurrentStep('personal');
    setErrors({});
  };

  // If the form has been successfully submitted, show the confirmation screen
  if (isSubmitted) {
    // Generate application ID (make it consistent for this session)
    const appId = `HCA-${Date.now().toString().slice(-6)}`;
    
    return (
      <div className="max-w-3xl mx-auto bg-white rounded-lg shadow-md overflow-hidden my-10">
        {/* Success screen content */}
        <div className="bg-green-500 px-6 py-4">
          <div className="flex items-center">
            <svg className="h-8 w-8 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
            <h2 className="ml-2 text-2xl font-bold text-white">Application Submitted Successfully</h2>
          </div>
        </div>
        <div className="p-6">
          <div className="mb-6">
            {/* Add more success content here if needed */}
          </div>
        </div>
      </div>
    );
  }
  
  return (
    <div className="container mx-auto px-4 py-8">
      {/* Show the custom alert */}
      <CustomAlert />

      {/* Show success banner when form submission is successful */}
      {submissionStatus === 'success' && (
        <div className="bg-green-50 border border-green-200 rounded-md p-6 mb-6">
          <div className="flex flex-col items-center text-center">
            <div className="flex-shrink-0 mx-auto mb-4">
              <svg className="h-12 w-12 text-green-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
              </svg>
            </div>
            <h2 className="text-lg font-semibold text-green-800 mb-2">Application Submitted Successfully!</h2>
            <p className="text-green-700 mb-4">
              Your application has been received and is being processed. You will be redirected to the services page in 15 seconds.
            </p>
            <Button onClick={() => navigate('/services')}>
              Return to Services Now
            </Button>
          </div>
        </div>
      )}

      <Breadcrumbs
        items={[
          { label: 'Home', path: '/' },
          { label: 'Services', path: '/services' },
          { label: 'HCA Exam Registration', path: `/services/${serviceId}` }
        ]}
      />
      
      <h1 className="text-3xl font-bold text-gray-900 mt-6 mb-8 text-center">
        HCA Examination Registration
      </h1>
      
      <div className="mb-8">
        <div className="bg-blue-50 p-4 border border-blue-100 rounded-md">
          <h2 className="text-blue-800 text-lg font-medium mb-2">Important Information</h2>
          <p className="text-blue-700 mb-2">
            This form is for Home Care Aide (HCA) certification examination registration. Please ensure you have completed 
            the required 75-hour training before submitting this application.
          </p>
          <p className="text-blue-700">
            All fields marked with an asterisk (*) are required. You will need to provide personal information, 
            address, and identification.
          </p>
        </div>
      </div>
      
      {/* Progress Steps */}
      <div className="mb-6">
        <div className="flex justify-between items-center">
          {['personal', 'address', 'identification', 'review'].map((step, index) => (
            <div key={step} className="flex flex-col items-center">
              <div 
                className={`w-10 h-10 flex items-center justify-center rounded-full ${
                  step === currentStep 
                    ? 'bg-blue-600 text-white' 
                    : ['personal', 'address', 'identification', 'review'].indexOf(step as FormStep) < 
                      ['personal', 'address', 'identification', 'review'].indexOf(currentStep)
                      ? 'bg-green-500 text-white'
                      : 'bg-gray-200 text-gray-600'
                }`}
              >
                {['personal', 'address', 'identification', 'review'].indexOf(step as FormStep) < 
                 ['personal', 'address', 'identification', 'review'].indexOf(currentStep) ? (
                  <svg className="w-6 h-6 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                  </svg>
                ) : (
                  index + 1
                )}
              </div>
              <span className="text-xs mt-2 font-medium text-gray-600">
                {step.charAt(0).toUpperCase() + step.slice(1)}
              </span>
            </div>
          ))}
        </div>
        <div className="relative mt-3">
          <div className="absolute h-1 w-full bg-gray-200 rounded">
            <div
              className="absolute h-1 bg-blue-600 rounded"
              style={{
                width: `${((['personal', 'address', 'identification', 'review'].indexOf(currentStep) + 1) / 4) * 100}%`
              }}
            ></div>
          </div>
        </div>
      </div>
      
      {/* Main Card with Form */}
      {submissionStatus !== 'success' && (
        <Card>
          <div className="p-6">
            <form onSubmit={handleSubmit} className="space-y-6">
              {renderStepContent(currentStep)}
              
              <div className="mt-8 pt-5 border-t border-gray-200">
                <div className="flex justify-between">
                  {currentStep !== 'personal' ? (
                    <Button onClick={handleBack} variant="outline">
                      Back
                    </Button>
                  ) : (
                    <div className="w-20"></div>
                  )}
                  
                  {currentStep !== 'review' ? (
                    <Button type="button" onClick={handleNext}>
                      Next
                    </Button>
                  ) : (
                    <Button 
                      type="button" 
                      onClick={handleSubmitButtonClick}
                      isLoading={isSubmitting} 
                      isDisabled={isSubmitting}
                    >
                      Submit Application
                    </Button>
                  )}
                </div>
              </div>
            </form>
          </div>
        </Card>
      )}
    </div>
  );
};

export default HCAExamRegistrationForm; 