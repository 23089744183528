import React from 'react';
import VisitorRegistrationForm from '../components/VisitorRegistrationForm';

const VisitorRegistration: React.FC = () => {
  return (
    <div className="container px-4 py-8 mx-auto max-w-7xl">
      <div className="mb-8">
        <h1 className="text-2xl font-bold text-gray-900">Visitor Registration</h1>
        <p className="text-gray-500">Register new visitors or check in returning visitors</p>
      </div>
      
      <div className="bg-white shadow-sm rounded-lg">
        <VisitorRegistrationForm />
      </div>
    </div>
  );
};

export default VisitorRegistration; 