import React from 'react';
import Card from '@/components/ui/Card';
import { cn } from '@/lib/utils';
import { AppointmentSummary } from '@/services/dashboardService';
import { useNavigate } from 'react-router-dom';

interface UpcomingAppointmentsCardProps {
  appointments: AppointmentSummary[];
  className?: string;
  isLoading?: boolean;
}

// Loading skeleton for appointments
const AppointmentSkeleton = () => (
  <div className="border-b border-gray-100 last:border-0 pb-4 p-2 animate-pulse">
    <div className="flex justify-between items-start">
      <div>
        <div className="h-4 bg-gray-200 rounded w-32 mb-2"></div>
        <div className="h-3 bg-gray-200 rounded w-24"></div>
      </div>
      <div className="rounded-full bg-gray-200 h-6 w-16"></div>
    </div>
    <div className="mt-2 flex items-center">
      <div className="h-4 w-4 bg-gray-200 rounded-full mr-1"></div>
      <div className="h-3 bg-gray-200 rounded w-28"></div>
    </div>
  </div>
);

// Get badge color based on appointment status
const getStatusBadgeColor = (status: string): string => {
  const statusMap: Record<string, string> = {
    'scheduled': 'bg-blue-100 text-blue-800',
    'completed': 'bg-green-100 text-green-800',
    'cancelled': 'bg-red-100 text-red-800',
    'no-show': 'bg-gray-100 text-gray-800',
    'in-progress': 'bg-yellow-100 text-yellow-800',
    'waiting': 'bg-purple-100 text-purple-800',
  };
  
  return statusMap[status] || 'bg-gray-100 text-gray-800';
};

// Format the appointment time for display
const formatAppointmentTime = (time: string): string => {
  return time;
};

// Format the date display for each appointment
const formatDateDisplay = (date: string, time: string): string => {
  const today = new Date().toLocaleDateString('en-US', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit'
  });
  
  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);
  const tomorrowStr = tomorrow.toLocaleDateString('en-US', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit'
  });
  
  if (date === today) {
    return `Today, ${formatAppointmentTime(time)}`;
  } else if (date === tomorrowStr) {
    return `Tomorrow, ${formatAppointmentTime(time)}`;
  } else {
    return `${date}, ${time}`;
  }
};

const UpcomingAppointmentsCard: React.FC<UpcomingAppointmentsCardProps> = ({ 
  appointments,
  className,
  isLoading = false
}) => {
  const navigate = useNavigate();
  
  // Get only the first 5 upcoming appointments
  const displayAppointments = appointments.slice(0, 5);
  
  // Show loading state
  if (isLoading) {
    return (
      <Card className={cn("h-full", className)}>
        <div className="flex justify-between items-center mb-4">
          <h3 className="font-semibold text-lg">Upcoming Appointments</h3>
          <div className="animate-pulse bg-gray-200 h-5 w-20 rounded"></div>
        </div>
        <div className="space-y-4">
          {[...Array(3)].map((_, i) => (
            <AppointmentSkeleton key={i} />
          ))}
        </div>
      </Card>
    );
  }
  
  if (!appointments.length) {
    return (
      <Card className={cn("h-full", className)}>
        <div className="flex justify-between items-center mb-4">
          <h3 className="font-semibold text-lg">Upcoming Appointments</h3>
          <button 
            onClick={() => navigate('/appointments/new')}
            className="text-blue-600 hover:text-blue-800 text-sm font-medium transition-colors"
          >
            + Add New
          </button>
        </div>
        <div className="flex flex-col items-center justify-center h-40 text-center p-4">
          <svg
            className="h-12 w-12 text-gray-300 mb-3"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={1.5}
              d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
            />
          </svg>
          <p className="text-gray-400 mb-4">No upcoming appointments scheduled</p>
          <button
            onClick={() => navigate('/appointments/new')}
            className="px-4 py-2 text-sm font-medium text-white bg-blue-600 rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            Schedule New Appointment
          </button>
        </div>
      </Card>
    );
  }
  
  const handleViewDetails = (appointmentId: string) => {
    navigate(`/appointments/${appointmentId}`);
  };
  
  return (
    <Card className={cn("h-full", className)}>
      <div className="flex justify-between items-center mb-4">
        <h3 className="font-semibold text-lg">Upcoming Appointments</h3>
        <div className="flex space-x-3">
          <button 
            onClick={() => navigate('/appointments/new')}
            className="text-blue-600 hover:text-blue-800 text-sm font-medium transition-colors"
          >
            + Add New
          </button>
          <button 
            onClick={() => navigate('/appointments')}
            className="text-blue-600 hover:text-blue-800 text-sm font-medium transition-colors"
          >
            View all
          </button>
        </div>
      </div>
      
      <div className="space-y-4">
        {displayAppointments.map((appointment) => (
          <div 
            key={appointment.id} 
            className="border-b border-gray-100 last:border-0 pb-4 cursor-pointer hover:bg-gray-50 p-2 rounded transition-colors"
            onClick={() => handleViewDetails(appointment.id)}
            tabIndex={0}
            role="button"
            aria-label={`View details for ${appointment.fullName}'s appointment`}
            onKeyDown={(e) => {
              if (e.key === 'Enter' || e.key === ' ') {
                e.preventDefault();
                handleViewDetails(appointment.id);
              }
            }}
          >
            <div className="flex justify-between items-start">
              <div>
                <h4 className="font-medium text-gray-900">{appointment.fullName}</h4>
                <p className="text-sm text-gray-600">{appointment.service}</p>
              </div>
              
              <span className={cn("text-xs px-2 py-1 rounded-full", getStatusBadgeColor(appointment.status))}>
                {appointment.status}
              </span>
            </div>
            
            <div className="mt-2 flex items-center text-sm text-gray-500">
              <svg className="h-4 w-4 mr-1" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
              </svg>
              {formatDateDisplay(appointment.appointmentDate, appointment.appointmentTime)}
            </div>
          </div>
        ))}
      </div>
      
      {appointments.length > 5 && (
        <div className="mt-4 text-center">
          <button
            onClick={() => navigate('/appointments')}
            className="text-blue-600 hover:text-blue-800 text-sm font-medium transition-colors"
          >
            See {appointments.length - 5} more appointment{appointments.length - 5 > 1 ? 's' : ''}
          </button>
        </div>
      )}
    </Card>
  );
};

export default UpcomingAppointmentsCard; 