import React, { useState } from 'react';
import { testEmailConfiguration } from '../services/emailService';
import Card from '../components/ui/Card';
import Button from '../components/ui/Button';
import FormInput from '../components/ui/FormInput';

const EmailTest: React.FC = () => {
  const [email, setEmail] = useState('');
  const [isSending, setIsSending] = useState(false);
  const [result, setResult] = useState<{ success?: boolean; error?: string } | null>(null);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    if (!email) {
      setResult({ error: 'Please enter an email address' });
      return;
    }
    
    setIsSending(true);
    setResult(null);
    
    try {
      await testEmailConfiguration(email);
      setResult({ success: true });
    } catch (error) {
      console.error('Email test failed:', error);
      setResult({ 
        error: error instanceof Error ? error.message : 'Unknown error occurred'
      });
    } finally {
      setIsSending(false);
    }
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-6">Email Configuration Test</h1>
      
      <Card className="max-w-xl mx-auto">
        <div className="p-6">
          <h2 className="text-xl font-semibold mb-4">Send Test Email</h2>
          <p className="mb-4 text-gray-600">
            Use this form to test if the email sending functionality is working correctly.
            A test email will be sent to the address you provide.
          </p>
          
          <form onSubmit={handleSubmit}>
            <div className="mb-4">
              <FormInput
                id="email"
                name="email"
                label="Recipient Email"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Enter email address"
              />
            </div>
            
            <Button 
              type="submit" 
              className="w-full" 
              isDisabled={isSending}
            >
              {isSending ? 'Sending...' : 'Send Test Email'}
            </Button>
          </form>
          
          {result && (
            <div className={`mt-4 p-3 rounded ${result.success ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800'}`}>
              {result.success ? (
                <p>Test email sent successfully! Please check the inbox.</p>
              ) : (
                <p>Error sending test email: {result.error}</p>
              )}
            </div>
          )}
          
          <div className="mt-6 border-t pt-4 text-sm text-gray-500">
            <h3 className="font-medium text-gray-700 mb-2">Email Configuration Details</h3>
            <ul className="list-disc pl-5 space-y-1">
              <li>SMTP Server: mail.hudumacenter.com</li>
              <li>Port: 587</li>
              <li>From Address: booking@hudumacenter.com</li>
              <li>Authentication: Required</li>
            </ul>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default EmailTest; 