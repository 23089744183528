import React from 'react';
import { Link } from 'react-router-dom';
import { colors, spacing, borderRadius, transitions, typography } from '../../styles/designTokens';

type ButtonVariant = 'primary' | 'secondary' | 'outline' | 'ghost' | 'danger';
type ButtonSize = 'sm' | 'md' | 'lg';

interface ButtonProps {
  children: React.ReactNode;
  variant?: ButtonVariant;
  size?: ButtonSize;
  isFullWidth?: boolean;
  isDisabled?: boolean;
  isLoading?: boolean;
  href?: string;
  type?: 'button' | 'submit' | 'reset';
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  className?: string;
  icon?: React.ReactNode;
}

const Button: React.FC<ButtonProps> = ({
  children,
  variant = 'primary',
  size = 'md',
  isFullWidth = false,
  isDisabled = false,
  isLoading = false,
  href,
  type = 'button',
  onClick,
  className = '',
  icon,
}) => {
  // Base styles
  const baseStyle = `
    inline-flex items-center justify-center
    font-medium
    rounded-md
    transition-all duration-200
    focus:outline-none focus:ring-2 focus:ring-offset-2
    ${isDisabled ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer'}
    ${isFullWidth ? 'w-full' : ''}
  `;

  // Size styles
  const sizeStyles = {
    sm: 'px-3 py-1.5 text-sm',
    md: 'px-4 py-2 text-base',
    lg: 'px-6 py-3 text-lg',
  };

  // Variant styles with enhanced text contrast
  const variantStyles = {
    primary: `bg-blue-900 hover:bg-blue-800 text-white font-semibold focus:ring-blue-500`,
    secondary: `bg-blue-500 hover:bg-blue-600 text-white font-semibold focus:ring-blue-400`,
    outline: `border border-blue-600 text-blue-700 hover:bg-blue-50 focus:ring-blue-500`,
    ghost: `text-blue-600 hover:bg-blue-50 focus:ring-blue-500`,
    danger: `bg-red-600 hover:bg-red-700 text-white font-semibold focus:ring-red-500`,
  };

  const buttonStyle = `
    ${baseStyle}
    ${sizeStyles[size]}
    ${variantStyles[variant]}
    ${className}
  `;

  // Loading spinner
  const LoadingSpinner = () => (
    <svg 
      className="animate-spin -ml-1 mr-2 h-4 w-4" 
      xmlns="http://www.w3.org/2000/svg" 
      fill="none" 
      viewBox="0 0 24 24"
    >
      <circle 
        className="opacity-25" 
        cx="12" 
        cy="12" 
        r="10" 
        stroke="currentColor" 
        strokeWidth="4"
      ></circle>
      <path 
        className="opacity-75" 
        fill="currentColor" 
        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
      ></path>
    </svg>
  );

  if (href) {
    return (
      <Link
        to={href}
        className={buttonStyle}
        aria-disabled={isDisabled}
        tabIndex={isDisabled ? -1 : 0}
      >
        {icon && <span className="mr-2">{icon}</span>}
        {children}
      </Link>
    );
  }

  return (
    <button
      type={type}
      className={buttonStyle}
      disabled={isDisabled || isLoading}
      onClick={onClick}
    >
      {isLoading && <LoadingSpinner />}
      {icon && !isLoading && <span className="mr-2">{icon}</span>}
      {children}
    </button>
  );
};

export default Button; 