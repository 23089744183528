import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
import { 
  getVisitById, 
  getVisitorHistory, 
  getStaffMembers, 
  addVisitNote, 
  getVisitNotes, 
  assignStaffToVisit, 
  updateVisitStatus,
  Visit, 
  Staff, 
  VisitNote, 
  VisitStatus 
} from '../services/visitorService';
import { Timestamp } from 'firebase/firestore';
import { useAuth } from '../contexts/AuthContext';
import VisitCompletionDialog from '../components/VisitCompletionDialog';
import Card from '../components/ui/Card';
import Button from '../components/ui/Button';
import Breadcrumbs from '../components/ui/Breadcrumbs';

// Define tab types
type TabType = 'details' | 'history' | 'notes';

// Define available visitor statuses
const availableStatuses: VisitStatus[] = ['waiting', 'being-served', 'on-hold', 'completed', 'abandoned'];

// Status configuration for badges
interface StatusConfig {
  color: string;
  bgColor: string;
  label: string;
}

const statusConfig: Record<VisitStatus, StatusConfig> = {
  'waiting': { 
    color: 'text-yellow-800', 
    bgColor: 'bg-yellow-100',
    label: 'Waiting'
  },
  'being-served': { 
    color: 'text-green-800', 
    bgColor: 'bg-green-100',
    label: 'Being Served'
  },
  'on-hold': { 
    color: 'text-orange-800', 
    bgColor: 'bg-orange-100',
    label: 'On Hold'
  },
  'completed': { 
    color: 'text-blue-800', 
    bgColor: 'bg-blue-100',
    label: 'Completed'
  },
  'abandoned': { 
    color: 'text-red-800', 
    bgColor: 'bg-red-100',
    label: 'Abandoned'
  }
};

const VisitorDetailsPage: React.FC = () => {
  const { visitId } = useParams<{ visitId: string }>();
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  
  // Refs for timers
  const waitingTimeRef = useRef<NodeJS.Timeout | null>(null);
  
  // State variables
  const [activeTab, setActiveTab] = useState<TabType>('details');
  const [visit, setVisit] = useState<Visit | null>(null);
  const [visitorHistory, setVisitorHistory] = useState<Visit[]>([]);
  const [staffMembers, setStaffMembers] = useState<Staff[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  
  // Status update state
  const [isUpdatingStatus, setIsUpdatingStatus] = useState(false);
  const [statusDropdownOpen, setStatusDropdownOpen] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState<VisitStatus | null>(null);
  const [statusConfirmationOpen, setStatusConfirmationOpen] = useState(false);
  
  // Staff assignment state
  const [selectedStaff, setSelectedStaff] = useState<string>('');
  const [isAssigning, setIsAssigning] = useState(false);
  const [showStaffDropdown, setShowStaffDropdown] = useState(false);
  
  // Visit completion state
  const [isCompleting, setIsCompleting] = useState(false);
  const [completionDialogOpen, setCompletionDialogOpen] = useState(false);
  
  // Notes state
  const [visitNote, setVisitNote] = useState('');
  const [visitNotes, setVisitNotes] = useState<VisitNote[]>([]);
  const [loadingNotes, setLoadingNotes] = useState(false);
  const [addingNote, setAddingNote] = useState(false);
  
  // Calculated waiting time state
  const [waitingTime, setWaitingTime] = useState<number | null>(null);
  
  // Fetch visit data
  useEffect(() => {
    if (!visitId) return;

    const fetchVisitData = async () => {
      try {
        setLoading(true);
        const visitData = await getVisitById(visitId);
        console.log('Fetched visit data:', visitData);
        setVisit(visitData);
        
        // Fetch visitor history
        if (visitData.phoneNumber) {
          const history = await getVisitorHistory(visitData.phoneNumber);
          setVisitorHistory(history.filter(v => v.id !== visitId));
        }
        
        // Fetch staff members
        const staff = await getStaffMembers();
        setStaffMembers(staff);
        
        // Fetch visit notes
        await fetchVisitNotes(visitId);
        
        setLoading(false);
      } catch (err) {
        console.error('Error fetching visit data:', err);
        setError('Failed to load visit data');
        setLoading(false);
      }
    };

    fetchVisitData();
    
    // Cleanup function
    return () => {
      if (waitingTimeRef.current) {
        clearInterval(waitingTimeRef.current);
      }
    };
  }, [visitId]);

  // Set up waiting time counter
  useEffect(() => {
    if (!visit || visit.status === 'completed' || visit.status === 'abandoned') {
      return;
    }
    
    // Calculate initial waiting time
    if (visit.checkInTime) {
      const checkInDate = visit.checkInTime.toDate();
      const now = new Date();
      const diffInMinutes = Math.floor((now.getTime() - checkInDate.getTime()) / (1000 * 60));
      setWaitingTime(diffInMinutes);
      
      // Set up interval to update waiting time every minute
      waitingTimeRef.current = setInterval(() => {
        setWaitingTime(prev => (prev !== null ? prev + 1 : null));
      }, 60000); // Update every minute
    }
    
    return () => {
      if (waitingTimeRef.current) {
        clearInterval(waitingTimeRef.current);
      }
    };
  }, [visit]);

  // Fetch visit notes
  const fetchVisitNotes = async (visitId: string) => {
    setLoadingNotes(true);
    try {
      const notes = await getVisitNotes(visitId);
      console.log(`Fetched ${notes.length} notes for visit ${visitId}`);
      setVisitNotes(notes);
    } catch (err) {
      console.error('Error fetching visit notes:', err);
    } finally {
      setLoadingNotes(false);
    }
  };

  // Handle adding a note
  const handleAddNote = async () => {
    if (!visitId || !currentUser) {
      setError('Cannot add note: Missing required information');
      return;
    }
    
    if (!visitNote.trim()) {
      return;
    }
    
    try {
      setAddingNote(true);
      
      // Get the user's name
      const userName = currentUser.displayName || currentUser.email?.split('@')[0] || currentUser.uid;
      
      // Add the note
      const noteId = await addVisitNote(
        visitId,
        visitNote.trim(),
        currentUser.uid,
        userName
      );
      
      // Add to local state to avoid refresh
      const newNote: VisitNote = {
        id: noteId,
        visitId,
        note: visitNote.trim(),
        createdAt: Timestamp.now(),
        createdBy: currentUser.uid,
        createdByName: userName,
        deleted: false
      };
      
      setVisitNotes(prev => [newNote, ...prev]);
      
      // Clear the input
      setVisitNote('');
      setSuccessMessage('Note added successfully');
      setTimeout(() => setSuccessMessage(null), 3000);
    } catch (err) {
      console.error('Error adding note:', err);
      setError('Failed to add note');
    } finally {
      setAddingNote(false);
    }
  };

  // Format timestamp to readable date
  const formatTime = (timestamp: any) => {
    if (!timestamp) return '';
    
    if (timestamp?.toDate) {
      return format(timestamp.toDate(), 'PPpp'); // Format: Apr 29, 2023, 2:00 PM
    }
    
    return '';
  };

  // Format phone number with WhatsApp link
  const formatPhoneWithWhatsApp = (phone: string) => {
    if (!phone) return '';
    
    // Clean the phone number (remove non-digits)
    const cleanNumber = phone.replace(/\D/g, '');
    
    // Create WhatsApp link
    const whatsappLink = `https://wa.me/${cleanNumber}`;
    
    return (
      <div className="flex items-center">
        <span className="text-gray-700">{phone}</span>
        <a 
          href={whatsappLink} 
          target="_blank" 
          rel="noopener noreferrer"
          className="ml-2 text-green-600 hover:text-green-800"
          aria-label={`Open WhatsApp chat with ${phone}`}
        >
          <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 24 24">
            <path d="M17.472 14.382c-.297-.149-1.758-.867-2.03-.967-.273-.099-.471-.148-.67.15-.197.297-.767.966-.94 1.164-.173.199-.347.223-.644.075-.297-.15-1.255-.463-2.39-1.475-.883-.788-1.48-1.761-1.653-2.059-.173-.297-.018-.458.13-.606.134-.133.298-.347.446-.52.149-.174.198-.298.298-.497.099-.198.05-.371-.025-.52-.075-.149-.669-1.612-.916-2.207-.242-.579-.487-.5-.669-.51-.173-.008-.371-.01-.57-.01-.198 0-.52.074-.792.372-.272.297-1.04 1.016-1.04 2.479 0 1.462 1.065 2.875 1.213 3.074.149.198 2.095 3.2 5.076 4.487.709.306 1.262.489 1.694.625.712.227 1.36.195 1.871.118.571-.085 1.758-.719 2.006-1.413.248-.694.248-1.289.173-1.413-.074-.124-.272-.198-.57-.347m-5.421 7.403h-.004a9.87 9.87 0 01-5.031-1.378l-.361-.214-3.741.982.998-3.648-.235-.374a9.86 9.86 0 01-1.51-5.26c.001-5.45 4.436-9.884 9.888-9.884 2.64 0 5.122 1.03 6.988 2.898a9.825 9.825 0 012.893 6.994c-.003 5.45-4.437 9.884-9.885 9.884m8.413-18.297A11.815 11.815 0 0012.05 0C5.495 0 .16 5.335.157 11.892c0 2.096.547 4.142 1.588 5.945L.057 24l6.305-1.654a11.882 11.882 0 005.683 1.448h.005c6.554 0 11.89-5.335 11.893-11.893a11.821 11.821 0 00-3.48-8.413Z"/>
          </svg>
        </a>
      </div>
    );
  };

  // Handle status change
  const handleUpdateStatus = async (newStatus: VisitStatus) => {
    if (!visitId || !visit) {
      setError('Cannot update status: Missing required information');
      return;
    }
    
    // Prevent changing status if visit is already completed
    if (visit.status === 'completed' && newStatus !== 'completed') {
      setError('Cannot change the status of a completed visit');
      setTimeout(() => setError(null), 3000);
      return;
    }
    
    try {
      setIsUpdatingStatus(true);
      
      // Update the status
      await updateVisitStatus(visitId, newStatus);
      
      // Update local state
      setVisit({
        ...visit,
        status: newStatus
      });
      
      // Add a note about the status change
      if (currentUser) {
        const userName = currentUser.displayName || currentUser.email?.split('@')[0] || currentUser.uid;
        const statusLabel = statusConfig[newStatus].label;
        
        await addVisitNote(
          visitId,
          `Visit status updated to "${statusLabel}" by ${userName}`,
          currentUser.uid,
          userName
        );
        
        // Refresh notes
        await fetchVisitNotes(visitId);
      }
      
      // Show success message
      setSuccessMessage(`Status updated to ${statusConfig[newStatus].label}`);
      setTimeout(() => setSuccessMessage(null), 3000);
      
      // Close dropdown and reset
      setStatusDropdownOpen(false);
      setSelectedStatus(null);
      
    } catch (err) {
      console.error('Error updating status:', err);
      setError('Failed to update status. Please try again.');
    } finally {
      setIsUpdatingStatus(false);
    }
  };

  // Open status confirmation modal
  const openStatusConfirmation = (status: VisitStatus) => {
    // If visit is already completed, show warning and don't allow changes
    if (visit?.status === 'completed') {
      setError('Cannot change the status of a completed visit');
      setTimeout(() => setError(null), 3000);
      return;
    }
    
    setSelectedStatus(status);
    setStatusConfirmationOpen(true);
  };

  // Confirm status change
  const confirmStatusChange = async () => {
    if (selectedStatus) {
      await handleUpdateStatus(selectedStatus);
    }
    setStatusConfirmationOpen(false);
  };

  // Cancel status change
  const cancelStatusChange = () => {
    setStatusConfirmationOpen(false);
    setSelectedStatus(null);
  };

  // Handle assigning staff
  const handleAssignStaff = async () => {
    if (!visitId || !selectedStaff || !visit) {
      setError('Cannot assign staff: Missing required information');
      return;
    }
    
    // Prevent assigning staff if visit is already completed
    if (visit.status === 'completed') {
      setError('Cannot assign staff to a completed visit');
      setTimeout(() => setError(null), 3000);
      return;
    }
    
    try {
      setIsAssigning(true);
      
      const staff = staffMembers.find(s => s.id === selectedStaff);
      if (!staff) {
        setError('Selected staff member not found');
        setIsAssigning(false);
        return;
      }
      
      await assignStaffToVisit(visitId, selectedStaff, staff.name);
      
      // Update local state
      setVisit({ 
        ...visit, 
        assignedStaff: staff.name,
        status: 'being-served' // Update status to being-served when staff is assigned
      });
      
      // Add a note about the assignment
      if (currentUser) {
        const userName = currentUser.displayName || currentUser.email?.split('@')[0] || currentUser.uid;
        await addVisitNote(
          visitId,
          `Visit assigned to ${staff.name} by ${userName}`,
          currentUser.uid,
          userName
        );
        
        // Refresh notes
        await fetchVisitNotes(visitId);
      }
      
      // Show success message
      setSuccessMessage(`Visit assigned to ${staff.name}`);
      setTimeout(() => setSuccessMessage(null), 3000);
      
      setIsAssigning(false);
      setShowStaffDropdown(false);
      
    } catch (err) {
      console.error('Error assigning staff:', err);
      setError('Failed to assign staff. Please try again.');
      setIsAssigning(false);
    }
  };
  
  // Handle completing visit
  const handleCompleteVisit = async () => {
    if (!visitId || !visit) {
      setError('Cannot complete visit: Missing required information');
      return;
    }
    
    // Open completion dialog
    setCompletionDialogOpen(true);
  };
  
  // Handle completion success
  const handleCompletionSuccess = async () => {
    setCompletionDialogOpen(false);
    
    try {
      // Reload the visit to get updated info
      const updatedVisit = await getVisitById(visitId!);
      setVisit(updatedVisit);
      
      // Show success message
      setSuccessMessage('Visit completed successfully');
      setTimeout(() => setSuccessMessage(null), 3000);
      
    } catch (err) {
      console.error('Error refreshing visit data:', err);
      setError('Failed to refresh visit data after completion');
    }
  };
  
  // Handle dialog close
  const handleCompletionDialogClose = () => {
    setCompletionDialogOpen(false);
  };

  // Get status badge with appropriate styling
  const getStatusBadge = (status: string) => {
    const config = statusConfig[status as VisitStatus] || {
      color: 'text-gray-800',
      bgColor: 'bg-gray-100',
      label: status.charAt(0).toUpperCase() + status.slice(1)
    };
    
    return (
      <span className={`px-3 py-1 inline-flex text-sm leading-5 font-semibold rounded-full ${config.bgColor} ${config.color} capitalize`}>
        {config.label}
      </span>
    );
  };
  
  // Send notification to visitor
  const handleNotifyVisitor = () => {
    // Implementation would require integration with a notification service
    setSuccessMessage('Notification functionality will be implemented in a future update');
    setTimeout(() => setSuccessMessage(null), 3000);
  };
  
  // Print visitor badge
  const handlePrintBadge = () => {
    // Implementation would require integration with a printing service
    setSuccessMessage('Print badge functionality will be implemented in a future update');
    setTimeout(() => setSuccessMessage(null), 3000);
  };
  
  // Navigate to dashboard
  const navigateToDashboard = () => {
    navigate('/dashboard');
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="bg-red-50 border-l-4 border-red-500 p-4 mt-4">
        <div className="flex">
          <div className="flex-shrink-0">
            <svg className="h-5 w-5 text-red-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
              <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clipRule="evenodd" />
            </svg>
          </div>
          <div className="ml-3">
            <p className="text-sm text-red-700">{error}</p>
          </div>
        </div>
      </div>
    );
  }

  if (!visit) {
    return (
      <div className="text-center py-8">
        <p className="text-gray-500">Visit not found</p>
      </div>
    );
  }

  return (
    <>
      <div className="mx-auto px-4 sm:px-6 lg:px-8 py-6 max-w-7xl">
        {/* Header with title, breadcrumbs and back button */}
        <div className="mb-6">
          <div className="flex justify-between items-center mb-3">
            <h1 className="text-2xl font-bold text-gray-900">
              Visitor Details
            </h1>
            <button
              onClick={() => navigate('/visits')}
              className="bg-white hover:bg-gray-50 text-gray-700 px-4 py-2 rounded-md flex items-center shadow-sm border border-gray-300"
              aria-label="Return to visits"
            >
              <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-2" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z" clipRule="evenodd" />
              </svg>
              Back to Visits
            </button>
          </div>
          
          {/* Breadcrumbs */}
          <Breadcrumbs 
            items={[
              { label: 'Dashboard', path: '/dashboard' },
              { label: 'Visits', path: '/visits' },
              { label: 'Visitor Details', path: '' }
            ]} 
          />
        </div>
        
        {/* Success message notification */}
        {successMessage && (
          <div className="mb-6 rounded-md bg-green-50 p-4 transition-opacity duration-500 ease-in-out">
            <div className="flex">
              <div className="flex-shrink-0">
                <svg className="h-5 w-5 text-green-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                  <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
                </svg>
              </div>
              <div className="ml-3">
                <p className="text-sm font-medium text-green-800">{successMessage}</p>
              </div>
            </div>
          </div>
        )}
        
        {/* Visitor Overview Card */}
        <Card className="mb-6 overflow-visible">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
            {/* Visitor basic info */}
            <div>
              <h2 className="text-xl font-semibold mb-4">{visit.visitorName}</h2>
              <div className="flex items-center mb-4">
                <div className="p-2 rounded-full bg-blue-100 mr-3">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-blue-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z" />
                  </svg>
                </div>
                {formatPhoneWithWhatsApp(visit.phoneNumber)}
              </div>
              <div className="flex items-center">
                <div className="p-2 rounded-full bg-blue-100 mr-3">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-blue-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                  </svg>
                </div>
                {getStatusBadge(visit.status)}
              </div>
            </div>
            
            {/* Service details */}
            <div>
              <h3 className="text-sm font-medium text-gray-500 uppercase tracking-wider mb-3">Service Details</h3>
              <div className="space-y-4">
                <div>
                  <h4 className="text-sm font-medium text-gray-500">Service</h4>
                  <p className="mt-1 text-gray-900 font-medium">{visit.service}</p>
                </div>
                <div>
                  <h4 className="text-sm font-medium text-gray-500">Check-in Time</h4>
                  <p className="mt-1 text-gray-900">{formatTime(visit.checkInTime)}</p>
                </div>
              </div>
            </div>
            
            {/* Assignment & timing */}
            <div>
              <h3 className="text-sm font-medium text-gray-500 uppercase tracking-wider mb-3">Assignment</h3>
              <div className="space-y-4">
                <div>
                  <h4 className="text-sm font-medium text-gray-500">Assigned Staff</h4>
                  <p className="mt-1 text-gray-900 font-medium">{visit.assignedStaff || 'Not assigned'}</p>
                </div>
                <div>
                  <h4 className="text-sm font-medium text-gray-500">Waiting Time</h4>
                  <div className="mt-1 flex items-center">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-yellow-500 mr-1" viewBox="0 0 20 20" fill="currentColor">
                      <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-12a1 1 0 10-2 0v4a1 1 0 00.293.707l2.828 2.829a1 1 0 101.415-1.415L11 9.586V6z" clipRule="evenodd" />
                    </svg>
                    <span className="text-gray-900 font-medium">
                      {waitingTime !== null ? `${waitingTime} minutes` : 'N/A'}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          
          {/* Action Buttons */}
          <div className="mt-8 flex flex-wrap gap-3 sm:justify-end">
            {/* Status update dropdown */}
            {visit.status !== 'completed' && (
              <div className="relative w-full sm:w-auto mb-2 sm:mb-0">
                <Button
                  variant="outline"
                  className="w-full sm:w-auto"
                  onClick={() => setStatusDropdownOpen(!statusDropdownOpen)}
                  icon={
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                      <path fillRule="evenodd" d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z" clipRule="evenodd" />
                    </svg>
                  }
                >
                  Update Status
                </Button>
                
                {statusDropdownOpen && (
                  <div className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-10">
                    <div className="py-1">
                      {availableStatuses.map((status) => (
                        <button
                          key={status}
                          onClick={() => {
                            openStatusConfirmation(status);
                            setStatusDropdownOpen(false);
                          }}
                          className={`${
                            visit.status === status 
                              ? 'bg-gray-100 text-gray-900' 
                              : 'text-gray-700 hover:bg-gray-50'
                          } flex w-full px-4 py-2 text-sm`}
                          disabled={visit.status === status}
                        >
                          <span className="mr-2">{getStatusBadge(status)}</span>
                          <span>{status === visit.status ? '(Current)' : ''}</span>
                        </button>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            )}
            
            {/* Staff assignment button */}
            {visit.status !== 'completed' && (
              <div className="relative w-full sm:w-auto mb-2 sm:mb-0">
                {!showStaffDropdown ? (
                  <Button
                    variant="primary"
                    className="w-full sm:w-auto"
                    onClick={() => setShowStaffDropdown(true)}
                    isDisabled={isAssigning || staffMembers.length === 0}
                    icon={
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                        <path d="M9 6a3 3 0 11-6 0 3 3 0 016 0zM17 6a3 3 0 11-6 0 3 3 0 016 0zM12.93 17c.046-.327.07-.66.07-1a6.97 6.97 0 00-1.5-4.33A5 5 0 0119 16v1h-6.07zM6 11a5 5 0 015 5v1H1v-1a5 5 0 015-5z" />
                      </svg>
                    }
                  >
                    Assign Staff
                  </Button>
                ) : (
                  <div className="bg-white shadow-lg rounded-md border border-gray-200 p-3 z-10 w-full sm:w-64">
                    <div className="mb-2">
                      <select
                        value={selectedStaff}
                        onChange={(e) => setSelectedStaff(e.target.value)}
                        className="block w-full border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                      >
                        <option value="">Select staff member</option>
                        {staffMembers
                          .filter(staff => staff.available)
                          .map(staff => (
                            <option key={staff.id} value={staff.id}>
                              {staff.name} ({staff.role})
                            </option>
                          ))}
                      </select>
                    </div>
                    <div className="flex space-x-2">
                      <Button
                        variant="primary"
                        className="w-full"
                        onClick={handleAssignStaff}
                        isDisabled={!selectedStaff || isAssigning}
                        isLoading={isAssigning}
                      >
                        Assign
                      </Button>
                      <Button
                        variant="outline"
                        className="w-full"
                        onClick={() => setShowStaffDropdown(false)}
                      >
                        Cancel
                      </Button>
                    </div>
                  </div>
                )}
              </div>
            )}
            
            {/* Complete visit button */}
            {visit.status !== 'completed' && (visit.status === 'being-served' || visit.status === 'on-hold') && (
              <Button
                variant="secondary"
                className="w-full sm:w-auto mb-2 sm:mb-0"
                onClick={handleCompleteVisit}
                isDisabled={isCompleting}
                isLoading={isCompleting}
                icon={
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                    <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                  </svg>
                }
              >
                Complete Visit
              </Button>
            )}
            
            {/* Notify visitor button */}
            <Button
              variant="outline"
              className="w-full sm:w-auto mb-2 sm:mb-0"
              onClick={handleNotifyVisitor}
              icon={
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                  <path d="M10 2a6 6 0 00-6 6v3.586l-.707.707A1 1 0 004 14h12a1 1 0 00.707-1.707L16 11.586V8a6 6 0 00-6-6zM10 18a3 3 0 01-3-3h6a3 3 0 01-3 3z" />
                </svg>
              }
            >
              Notify Visitor
            </Button>
            
            {/* Print badge button */}
            <Button
              variant="outline"
              className="w-full sm:w-auto"
              onClick={handlePrintBadge}
              icon={
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                  <path fillRule="evenodd" d="M5 4v3H4a2 2 0 00-2 2v3a2 2 0 002 2h1v2a2 2 0 002 2h6a2 2 0 002-2v-2h1a2 2 0 002-2V9a2 2 0 00-2-2h-1V4a2 2 0 00-2-2H7a2 2 0 00-2 2zm8 0v3H7V4h6zm-5 7h4v4H8v-4z" clipRule="evenodd" />
                </svg>
              }
            >
              Print Badge
            </Button>
          </div>
        </Card>

        {/* Status confirmation modal */}
        {statusConfirmationOpen && selectedStatus && (
          <div className="fixed inset-0 bg-gray-600 bg-opacity-75 overflow-y-auto h-full w-full z-50 flex justify-center items-center">
            <div className="relative bg-white rounded-lg shadow-xl mx-4 max-w-md md:max-w-lg w-full">
              <div className="px-6 pt-6 pb-6">
                <h3 className="text-xl font-semibold text-gray-900 mb-3">
                  Update Visit Status
                </h3>
                <p className="text-sm text-gray-600 mb-6">
                  Are you sure you want to update the status to <span className="font-medium">{statusConfig[selectedStatus].label}</span>?
                </p>
                
                <div className="flex space-x-3 justify-end">
                  <Button
                    variant="outline"
                    onClick={cancelStatusChange}
                    isDisabled={isUpdatingStatus}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="primary"
                    onClick={confirmStatusChange}
                    isLoading={isUpdatingStatus}
                    isDisabled={isUpdatingStatus}
                  >
                    Confirm
                  </Button>
                </div>
              </div>
            </div>
          </div>
        )}

        {/* Tabs */}
        <div className="mb-6 border-b border-gray-200 overflow-x-auto">
          <nav className="flex space-x-4 md:space-x-8 min-w-max" aria-label="Visitor details tabs">
            {(['details', 'history', 'notes'] as TabType[]).map(tab => (
              <button
                key={tab}
                className={`py-4 px-3 md:px-4 border-b-2 font-medium text-sm whitespace-nowrap ${
                  activeTab === tab
                    ? 'border-blue-500 text-blue-600'
                    : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
                }`}
                onClick={() => setActiveTab(tab)}
                aria-current={activeTab === tab ? 'page' : undefined}
                aria-label={`View ${tab === 'details' ? 'visitor details' : tab === 'history' ? 'visitor history' : 'visit notes'}`}
                role="tab"
                aria-selected={activeTab === tab}
                tabIndex={0}
                onKeyDown={(e) => e.key === 'Enter' && setActiveTab(tab)}
              >
                {tab === 'details' ? 'Visit Details' : tab === 'history' ? 'Visit History' : 'Notes'}
              </button>
            ))}
          </nav>
        </div>

        {/* Tab Content */}
        <Card className="overflow-hidden">
          <div className="px-4 py-6 md:p-6">
            {activeTab === 'details' && (
              <div>
                <h2 className="text-xl font-semibold mb-6">Visit Details</h2>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6 md:gap-8">
                  <div>
                    <h3 className="text-sm font-medium text-gray-500 uppercase tracking-wider mb-4">Visitor Information</h3>
                    <div className="bg-gray-50 p-4 md:p-6 rounded-md shadow-sm">
                      <div className="space-y-4 md:space-y-6">
                        <div>
                          <h4 className="text-xs font-medium text-gray-500 uppercase">Full Name</h4>
                          <p className="mt-1 text-gray-900 font-medium">{visit.visitorName}</p>
                        </div>
                        <div>
                          <h4 className="text-xs font-medium text-gray-500 uppercase">Phone Number</h4>
                          <div className="mt-1">{formatPhoneWithWhatsApp(visit.phoneNumber)}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <h3 className="text-sm font-medium text-gray-500 uppercase tracking-wider mb-4">Visit Information</h3>
                    <div className="bg-gray-50 p-4 md:p-6 rounded-md shadow-sm">
                      <div className="space-y-4 md:space-y-6">
                        <div>
                          <h4 className="text-xs font-medium text-gray-500 uppercase">Service</h4>
                          <p className="mt-1 text-gray-900">{visit.service}</p>
                        </div>
                        <div>
                          <h4 className="text-xs font-medium text-gray-500 uppercase">Status</h4>
                          <div className="mt-1">{getStatusBadge(visit.status)}</div>
                        </div>
                        <div>
                          <h4 className="text-xs font-medium text-gray-500 uppercase">Check-in Time</h4>
                          <p className="mt-1 text-gray-900">{formatTime(visit.checkInTime)}</p>
                        </div>
                        <div>
                          <h4 className="text-xs font-medium text-gray-500 uppercase">Assigned Staff</h4>
                          <p className="mt-1 text-gray-900 font-medium">{visit.assignedStaff || 'Not assigned'}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {activeTab === 'history' && (
              <div>
                <h2 className="text-xl font-semibold mb-6">Visit History</h2>
                {visitorHistory.length === 0 ? (
                  <div className="text-center py-10 bg-gray-50 rounded-md">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-12 w-12 mx-auto text-gray-400 mb-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>
                    <p className="text-gray-600 font-medium">No previous visits found for this visitor.</p>
                    <p className="text-gray-500 text-sm mt-1">This appears to be their first visit.</p>
                  </div>
                ) : (
                  <div className="overflow-x-auto bg-gray-50 rounded-md shadow-sm -mx-4 sm:mx-0">
                    <table className="min-w-full divide-y divide-gray-200">
                      <thead className="bg-gray-100">
                        <tr>
                          <th className="px-4 sm:px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Date & Time
                          </th>
                          <th className="px-4 sm:px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Service
                          </th>
                          <th className="hidden sm:table-cell px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Status
                          </th>
                          <th className="hidden md:table-cell px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Staff
                          </th>
                          <th className="px-4 sm:px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Actions
                          </th>
                        </tr>
                      </thead>
                      <tbody className="bg-white divide-y divide-gray-200">
                        {visitorHistory.map((historyItem) => (
                          <tr key={historyItem.id} className="hover:bg-gray-50">
                            <td className="px-4 sm:px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                              {formatTime(historyItem.checkInTime)}
                            </td>
                            <td className="px-4 sm:px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                              {historyItem.service}
                            </td>
                            <td className="hidden sm:table-cell px-6 py-4 whitespace-nowrap">
                              {getStatusBadge(historyItem.status)}
                            </td>
                            <td className="hidden md:table-cell px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                              {historyItem.assignedStaff || 'Not assigned'}
                            </td>
                            <td className="px-4 sm:px-6 py-4 whitespace-nowrap text-sm font-medium">
                              <Button
                                variant="outline"
                                size="sm"
                                href={`/visits/${historyItem.id}`}
                                aria-label={`View details for ${historyItem.visitorName}'s visit on ${formatTime(historyItem.checkInTime)}`}
                              >
                                View Details
                              </Button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                )}
              </div>
            )}

            {activeTab === 'notes' && (
              <div>
                <h2 className="text-xl font-semibold mb-6">Visit Notes</h2>
                
                {/* Add a new note */}
                <div className="bg-gray-50 rounded-md p-4 sm:p-5 mb-6 sm:mb-8 shadow-sm">
                  <div className="mb-3">
                    <div className="flex justify-between items-center mb-2">
                      <label htmlFor="visitNote" className="block text-sm font-medium text-gray-700">
                        Add a note
                      </label>
                      <span className="text-sm text-gray-500">
                        {visitNote.length} characters
                      </span>
                    </div>
                    <textarea
                      id="visitNote"
                      className="w-full border border-gray-300 rounded-md p-3 h-24 focus:ring-blue-500 focus:border-blue-500"
                      placeholder="Add a note about this visit..."
                      value={visitNote}
                      onChange={(e) => setVisitNote(e.target.value)}
                      aria-label="Visit note"
                    ></textarea>
                  </div>
                  <div className="flex justify-end">
                    <Button
                      variant="primary"
                      onClick={handleAddNote}
                      isDisabled={addingNote || !visitNote.trim()}
                      isLoading={addingNote}
                      icon={!addingNote ? (
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                          <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v2H7a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V7z" clipRule="evenodd" />
                        </svg>
                      ) : undefined}
                    >
                      Add Note
                    </Button>
                  </div>
                </div>
                
                {/* Notes list */}
                {loadingNotes ? (
                  <div className="flex justify-center items-center py-10">
                    <svg className="animate-spin h-8 w-8 text-blue-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                      <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>
                  </div>
                ) : visitNotes.length > 0 ? (
                  <div className="space-y-4">
                    {visitNotes.map((note) => (
                      <div key={note.id} className="bg-gray-50 rounded-lg p-4 border border-gray-200 shadow-sm">
                        <div className="flex flex-col sm:flex-row sm:justify-between sm:items-center mb-3">
                          <div className="flex items-center mb-2 sm:mb-0">
                            <div className="h-8 w-8 rounded-full bg-blue-100 flex items-center justify-center mr-3">
                              <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 text-blue-600" viewBox="0 0 20 20" fill="currentColor">
                                <path fillRule="evenodd" d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z" clipRule="evenodd" />
                              </svg>
                            </div>
                            <span className="font-medium text-gray-800">{note.createdByName}</span>
                          </div>
                          <span className="text-sm text-gray-500">
                            {note.createdAt && format(note.createdAt.toDate(), 'MMM d, yyyy h:mm a')}
                          </span>
                        </div>
                        <p className="text-gray-800 whitespace-pre-wrap text-sm">{note.note}</p>
                      </div>
                    ))}
                  </div>
                ) : (
                  <div className="text-center py-10 bg-gray-50 rounded-md">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-12 w-12 mx-auto text-gray-400 mb-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
                    </svg>
                    <p className="text-gray-600 font-medium">No notes have been added for this visit yet.</p>
                    <p className="text-gray-500 text-sm mt-1">Add a note to keep track of important information.</p>
                  </div>
                )}
              </div>
            )}
          </div>
        </Card>
      </div>

      {/* Visit Completion Dialog */}
      {visit && (
        <VisitCompletionDialog
          visitId={visit.id}
          visitorName={visit.visitorName}
          isOpen={completionDialogOpen}
          onClose={handleCompletionDialogClose}
          onSuccess={handleCompletionSuccess}
        />
      )}
    </>
  );
};

export default VisitorDetailsPage; 