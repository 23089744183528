import React, { useState, useEffect } from 'react';
import { 
  format, 
  startOfDay, 
  endOfDay, 
  startOfWeek, 
  endOfWeek, 
  startOfMonth, 
  endOfMonth,
  startOfYear,
  endOfYear,
  subDays,
  subWeeks,
  subMonths,
  addDays,
  isValid,
  parseISO
} from 'date-fns';
import { Calendar, ChevronLeft, ChevronRight } from 'lucide-react';

interface DateRangePickerProps {
  startDate: Date;
  endDate: Date;
  onChange: (startDate: Date, endDate: Date) => void;
}

type DatePreset = 
  | 'today' 
  | 'yesterday' 
  | 'thisWeek' 
  | 'lastWeek' 
  | 'thisMonth' 
  | 'lastMonth'
  | 'thisYear'
  | 'last7Days'
  | 'last30Days'
  | 'last90Days'
  | 'custom';

const DateRangePicker: React.FC<DateRangePickerProps> = ({ 
  startDate,
  endDate,
  onChange
}) => {
  const [localStartDate, setLocalStartDate] = useState<Date>(startDate);
  const [localEndDate, setLocalEndDate] = useState<Date>(endDate);
  const [activePreset, setActivePreset] = useState<DatePreset>('thisMonth');
  const [isOpen, setIsOpen] = useState(false);
  
  // Format date for input fields
  const formatDateForInput = (date: Date): string => {
    return format(date, 'yyyy-MM-dd');
  };
  
  // Format date range for display
  const formatDateRange = (): string => {
    return `${format(startDate, 'MMM d, yyyy')} - ${format(endDate, 'MMM d, yyyy')}`;
  };
  
  // Apply selected date range
  const applyDateRange = () => {
    onChange(localStartDate, localEndDate);
    setIsOpen(false);
  };
  
  // Apply a preset date range
  const applyPreset = (preset: DatePreset) => {
    setActivePreset(preset);
    
    const today = new Date();
    let newStartDate: Date;
    let newEndDate: Date;
    
    switch (preset) {
      case 'today':
        newStartDate = startOfDay(today);
        newEndDate = endOfDay(today);
        break;
      case 'yesterday':
        newStartDate = startOfDay(subDays(today, 1));
        newEndDate = endOfDay(subDays(today, 1));
        break;
      case 'thisWeek':
        newStartDate = startOfWeek(today, { weekStartsOn: 1 });
        newEndDate = endOfWeek(today, { weekStartsOn: 1 });
        break;
      case 'lastWeek':
        newStartDate = startOfWeek(subWeeks(today, 1), { weekStartsOn: 1 });
        newEndDate = endOfWeek(subWeeks(today, 1), { weekStartsOn: 1 });
        break;
      case 'thisMonth':
        newStartDate = startOfMonth(today);
        newEndDate = endOfDay(today);
        break;
      case 'lastMonth':
        newStartDate = startOfMonth(subMonths(today, 1));
        newEndDate = endOfMonth(subMonths(today, 1));
        break;
      case 'thisYear':
        newStartDate = startOfYear(today);
        newEndDate = endOfDay(today);
        break;
      case 'last7Days':
        newStartDate = startOfDay(subDays(today, 6));
        newEndDate = endOfDay(today);
        break;
      case 'last30Days':
        newStartDate = startOfDay(subDays(today, 29));
        newEndDate = endOfDay(today);
        break;
      case 'last90Days':
        newStartDate = startOfDay(subDays(today, 89));
        newEndDate = endOfDay(today);
        break;
      default:
        return;
    }
    
    setLocalStartDate(newStartDate);
    setLocalEndDate(newEndDate);
    onChange(newStartDate, newEndDate);
    setIsOpen(false);
  };
  
  // Go to previous period
  const goToPrevious = () => {
    if (activePreset === 'custom') {
      const daysDiff = Math.round((endDate.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24));
      const newStartDate = subDays(startDate, daysDiff + 1);
      const newEndDate = subDays(endDate, daysDiff + 1);
      setLocalStartDate(newStartDate);
      setLocalEndDate(newEndDate);
      onChange(newStartDate, newEndDate);
    } else {
      const today = new Date();
      let newStartDate: Date;
      let newEndDate: Date;
      
      switch (activePreset) {
        case 'today':
          newStartDate = startOfDay(subDays(today, 1));
          newEndDate = endOfDay(subDays(today, 1));
          setActivePreset('yesterday');
          break;
        case 'yesterday':
          newStartDate = startOfDay(subDays(today, 2));
          newEndDate = endOfDay(subDays(today, 2));
          break;
        case 'thisWeek':
          newStartDate = startOfWeek(subWeeks(today, 1), { weekStartsOn: 1 });
          newEndDate = endOfWeek(subWeeks(today, 1), { weekStartsOn: 1 });
          setActivePreset('lastWeek');
          break;
        case 'lastWeek':
          newStartDate = startOfWeek(subWeeks(today, 2), { weekStartsOn: 1 });
          newEndDate = endOfWeek(subWeeks(today, 2), { weekStartsOn: 1 });
          break;
        case 'thisMonth':
          newStartDate = startOfMonth(subMonths(today, 1));
          newEndDate = endOfMonth(subMonths(today, 1));
          setActivePreset('lastMonth');
          break;
        case 'lastMonth':
          newStartDate = startOfMonth(subMonths(today, 2));
          newEndDate = endOfMonth(subMonths(today, 2));
          break;
        case 'thisYear':
          newStartDate = startOfYear(subMonths(today, 12));
          newEndDate = endOfYear(subMonths(today, 12));
          break;
        case 'last7Days':
          newStartDate = startOfDay(subDays(today, 13));
          newEndDate = endOfDay(subDays(today, 7));
          break;
        case 'last30Days':
          newStartDate = startOfDay(subDays(today, 59));
          newEndDate = endOfDay(subDays(today, 30));
          break;
        case 'last90Days':
          newStartDate = startOfDay(subDays(today, 179));
          newEndDate = endOfDay(subDays(today, 90));
          break;
        default:
          return;
      }
      
      setLocalStartDate(newStartDate);
      setLocalEndDate(newEndDate);
      onChange(newStartDate, newEndDate);
    }
  };
  
  // Go to next period
  const goToNext = () => {
    if (activePreset === 'custom') {
      const daysDiff = Math.round((endDate.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24));
      const newStartDate = addDays(startDate, daysDiff + 1);
      const newEndDate = addDays(endDate, daysDiff + 1);
      
      // Don't allow future dates beyond today
      if (newEndDate > new Date()) return;
      
      setLocalStartDate(newStartDate);
      setLocalEndDate(newEndDate);
      onChange(newStartDate, newEndDate);
    } else {
      const today = new Date();
      let newStartDate: Date;
      let newEndDate: Date;
      
      switch (activePreset) {
        case 'yesterday':
          newStartDate = startOfDay(today);
          newEndDate = endOfDay(today);
          setActivePreset('today');
          break;
        case 'lastWeek':
          newStartDate = startOfWeek(today, { weekStartsOn: 1 });
          newEndDate = endOfWeek(today, { weekStartsOn: 1 });
          setActivePreset('thisWeek');
          break;
        case 'lastMonth':
          newStartDate = startOfMonth(today);
          newEndDate = endOfDay(today);
          setActivePreset('thisMonth');
          break;
        default:
          // Check if we would exceed current date
          return;
      }
      
      // Don't allow future dates beyond today
      if (newEndDate > today) {
        newEndDate = today;
      }
      
      setLocalStartDate(newStartDate);
      setLocalEndDate(newEndDate);
      onChange(newStartDate, newEndDate);
    }
  };

  // Handle custom date input changes
  const handleStartDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const date = parseISO(e.target.value);
    if (isValid(date)) {
      setActivePreset('custom');
      setLocalStartDate(date);
    }
  };

  const handleEndDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const date = parseISO(e.target.value);
    if (isValid(date)) {
      setActivePreset('custom');
      setLocalEndDate(date);
    }
  };
  
  return (
    <div className="relative">
      <div className="flex items-center space-x-4">
        <button
          type="button"
          onClick={goToPrevious}
          className="p-2 rounded-full text-gray-600 hover:bg-gray-100"
          aria-label="Previous period"
        >
          <ChevronLeft className="h-5 w-5" />
        </button>
        
        <button
          type="button"
          onClick={() => setIsOpen(!isOpen)}
          className="flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm bg-white text-gray-700 hover:bg-gray-50"
        >
          <Calendar className="mr-2 h-4 w-4 text-gray-500" />
          <span>{formatDateRange()}</span>
        </button>
        
        <button
          type="button"
          onClick={goToNext}
          className="p-2 rounded-full text-gray-600 hover:bg-gray-100"
          aria-label="Next period"
          disabled={endDate >= new Date()}
        >
          <ChevronRight className="h-5 w-5" />
        </button>
      </div>
      
      {isOpen && (
        <div className="absolute z-10 mt-2 bg-white rounded-md shadow-lg p-4 border border-gray-200 w-80">
          <div className="space-y-4">
            <div className="grid grid-cols-2 gap-2">
              <button
                type="button"
                onClick={() => applyPreset('today')}
                className={`px-3 py-1 text-sm rounded-md ${
                  activePreset === 'today'
                    ? 'bg-blue-100 text-blue-700'
                    : 'text-gray-700 hover:bg-gray-100'
                }`}
              >
                Today
              </button>
              <button
                type="button"
                onClick={() => applyPreset('yesterday')}
                className={`px-3 py-1 text-sm rounded-md ${
                  activePreset === 'yesterday'
                    ? 'bg-blue-100 text-blue-700'
                    : 'text-gray-700 hover:bg-gray-100'
                }`}
              >
                Yesterday
              </button>
              <button
                type="button"
                onClick={() => applyPreset('thisWeek')}
                className={`px-3 py-1 text-sm rounded-md ${
                  activePreset === 'thisWeek'
                    ? 'bg-blue-100 text-blue-700'
                    : 'text-gray-700 hover:bg-gray-100'
                }`}
              >
                This Week
              </button>
              <button
                type="button"
                onClick={() => applyPreset('lastWeek')}
                className={`px-3 py-1 text-sm rounded-md ${
                  activePreset === 'lastWeek'
                    ? 'bg-blue-100 text-blue-700'
                    : 'text-gray-700 hover:bg-gray-100'
                }`}
              >
                Last Week
              </button>
              <button
                type="button"
                onClick={() => applyPreset('thisMonth')}
                className={`px-3 py-1 text-sm rounded-md ${
                  activePreset === 'thisMonth'
                    ? 'bg-blue-100 text-blue-700'
                    : 'text-gray-700 hover:bg-gray-100'
                }`}
              >
                This Month
              </button>
              <button
                type="button"
                onClick={() => applyPreset('lastMonth')}
                className={`px-3 py-1 text-sm rounded-md ${
                  activePreset === 'lastMonth'
                    ? 'bg-blue-100 text-blue-700'
                    : 'text-gray-700 hover:bg-gray-100'
                }`}
              >
                Last Month
              </button>
              <button
                type="button"
                onClick={() => applyPreset('last7Days')}
                className={`px-3 py-1 text-sm rounded-md ${
                  activePreset === 'last7Days'
                    ? 'bg-blue-100 text-blue-700'
                    : 'text-gray-700 hover:bg-gray-100'
                }`}
              >
                Last 7 Days
              </button>
              <button
                type="button"
                onClick={() => applyPreset('last30Days')}
                className={`px-3 py-1 text-sm rounded-md ${
                  activePreset === 'last30Days'
                    ? 'bg-blue-100 text-blue-700'
                    : 'text-gray-700 hover:bg-gray-100'
                }`}
              >
                Last 30 Days
              </button>
            </div>
            
            <div className="border-t border-gray-200 pt-4">
              <div className="text-sm font-medium text-gray-700 mb-2">Custom Range</div>
              <div className="grid grid-cols-2 gap-2">
                <div>
                  <label htmlFor="startDate" className="block text-xs text-gray-500 mb-1">
                    Start Date
                  </label>
                  <input
                    type="date"
                    id="startDate"
                    value={formatDateForInput(localStartDate)}
                    onChange={handleStartDateChange}
                    className="w-full px-2 py-1 text-sm border border-gray-300 rounded-md"
                  />
                </div>
                <div>
                  <label htmlFor="endDate" className="block text-xs text-gray-500 mb-1">
                    End Date
                  </label>
                  <input
                    type="date"
                    id="endDate"
                    value={formatDateForInput(localEndDate)}
                    onChange={handleEndDateChange}
                    className="w-full px-2 py-1 text-sm border border-gray-300 rounded-md"
                  />
                </div>
              </div>
            </div>
            
            <div className="flex justify-end space-x-2">
              <button
                type="button"
                onClick={() => setIsOpen(false)}
                className="px-3 py-1 text-sm rounded-md border border-gray-300 text-gray-700 hover:bg-gray-50"
              >
                Cancel
              </button>
              <button
                type="button"
                onClick={applyDateRange}
                className="px-3 py-1 text-sm rounded-md bg-blue-600 text-white hover:bg-blue-700"
              >
                Apply
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default DateRangePicker; 