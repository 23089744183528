import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Button from '../components/ui/Button';
import ServiceCard from '../components/ServiceCard';
import ServiceCategoryNav from '../components/ServiceCategoryNav';
import Breadcrumbs from '../components/ui/Breadcrumbs';

// Define service type
interface Service {
  id: string;
  title: string;
  shortDescription: string;
  fullDescription: string;
  category: string;
  estimatedTime: string;
  fee: string;
  requiresAppointment: boolean;
  documentsRequired: string[];
  applicationProcess: string[];
  eligibility: string;
  relatedServices: string[];
  icon: string;
}

const ServicesCatalogPage: React.FC = () => {
  const [activeCategory, setActiveCategory] = useState<string>('all');
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [filteredServices, setFilteredServices] = useState<Service[]>([]);
  
  // Updated categories based on your services
  const categories = [
    { id: 'all', name: 'All Services' },
    { id: 'immigration', name: 'Immigration Services' },
    { id: 'business', name: 'Business Services' },
    { id: 'identification', name: 'Identification & Records' },
    { id: 'healthcare', name: 'Healthcare Services' },
    { id: 'professional', name: 'Professional Services' },
    { id: 'notary', name: 'Notary & Legal Services' }
  ];
  
  // Updated services data with accurate information
  const services: Service[] = [
    {
      id: 'passport-application',
      title: 'Passport Application Services',
      shortDescription: 'Apply for or renew your Kenyan passport',
      fullDescription: 'Our passport application services include assistance with first-time applications, renewals, and expedited processing for Kenyan passports. Our trained staff will help you navigate the application process, verify your documents, and ensure your application is complete and accurate before submission.',
      category: 'immigration',
      estimatedTime: '1-3 months',
      fee: '$170',
      requiresAppointment: true,
      documentsRequired: [
        'Proof of citizenship (birth certificate or previous passport)',
        'National ID',
        'Passport photos (2x2 inches)',
        'Completed application form'
      ],
      applicationProcess: [
        'Complete the application form',
        'Gather required documents',
        'Schedule an appointment',
        'Pay the application fee',
        'Attend your appointment',
        'Wait for processing'
      ],
      eligibility: 'Kenyan citizens',
      relatedServices: ['visa-application', 'id-verification'],
      icon: 'passport'
    },
    {
      id: 'business-registration',
      title: 'Business Registration',
      shortDescription: 'Register your business, LLC, or corporation',
      fullDescription: 'Complete business registration services including LLC formation, corporation setup, obtaining EINs, and business license applications. Our team can help you choose the right business structure, complete all necessary paperwork, and ensure your business is properly registered with state and federal authorities.',
      category: 'business',
      estimatedTime: '7-10 business days',
      fee: '$250 - $350',
      requiresAppointment: false,
      documentsRequired: [
        'Business name and address',
        'Owner identification',
        'Articles of organization/incorporation',
        'Operating agreement (if applicable)'
      ],
      applicationProcess: [
        'Choose your business structure',
        'Complete the registration forms',
        'Submit your application',
        'Pay registration fees',
        'Receive your business documents'
      ],
      eligibility: 'Anyone looking to start a business entity',
      relatedServices: ['tax-id-application', 'business-license', 'notary-services'],
      icon: 'building'
    },
    {
      id: 'id-verification',
      title: 'Identity Application Assistance',
      shortDescription: 'Assistance with identity verification and documentation',
      fullDescription: 'Comprehensive identity application assistance including document authentication, background checks, and digital ID verification. We can help with various types of identity applications and verifications needed for official purposes.',
      category: 'identification',
      estimatedTime: '1 business day',
      fee: 'Varies by service',
      requiresAppointment: true,
      documentsRequired: [
        'Government-issued photo ID',
        'Proof of address',
        'Social Security card or number',
        'Birth certificate (if applicable)'
      ],
      applicationProcess: [
        'Complete verification request form',
        'Submit required documents',
        'Pay verification fee',
        'Attend in-person verification (if required)',
        'Receive verification certificate'
      ],
      eligibility: 'Anyone requiring official identity verification',
      relatedServices: ['document-authentication', 'background-check'],
      icon: 'id-card'
    },
    {
      id: 'hca-exam-registration',
      title: 'HCA Examination Registration',
      shortDescription: 'Register for Home Care Aide certification exams',
      fullDescription: 'Complete registration service for Home Care Aide certification examinations, including scheduling, documentation verification, and test preparation resources.',
      category: 'healthcare',
      estimatedTime: '1-2 months for processing',
      fee: '$130',
      requiresAppointment: true,
      documentsRequired: [
        'Training completion certificate',
        'Government-issued ID',
        'Application form',
        'Background check clearance'
      ],
      applicationProcess: [
        'Verify eligibility requirements',
        'Complete application form',
        'Submit required documentation',
        'Pay examination fee',
        'Schedule examination date',
        'Receive confirmation'
      ],
      eligibility: 'Individuals who have completed required HCA training',
      relatedServices: ['caregiver-training', 'certification-verification'],
      icon: 'certificate'
    },
    {
      id: 'hca-exam-booking',
      title: 'HCA Exam Booking Assistance',
      shortDescription: 'Assistance with booking and preparing for HCA exams',
      fullDescription: 'Comprehensive assistance with booking your Home Care Aide examination, including selecting test dates, locations, and providing guidance on exam preparation. We handle the entire booking process and provide support throughout your exam preparation journey.',
      category: 'healthcare',
      estimatedTime: '1 day',
      fee: '$170',
      requiresAppointment: true,
      documentsRequired: [
        'Training completion certificate',
        'Government-issued ID',
        'Contact information',
        'Preferred exam dates'
      ],
      applicationProcess: [
        'Consultation to determine needs',
        'Selection of exam date and location',
        'Documentation verification',
        'Exam registration',
        'Confirmation of booking',
        'Exam preparation guidance'
      ],
      eligibility: 'Individuals who have completed HCA training and need to take certification exams',
      relatedServices: ['hca-exam-registration', 'caregiver-training'],
      icon: 'book-open'
    },
    {
      id: 'notary-services',
      title: 'Notary Services',
      shortDescription: 'Professional notarization of documents',
      fullDescription: 'Fast and professional notary services for a wide range of documents. Our licensed notaries can authenticate various legal documents, providing official verification required for many business and personal transactions.',
      category: 'notary',
      estimatedTime: 'Same day (typically 15-30 minutes per document)',
      fee: '$20 per document',
      requiresAppointment: true,
      documentsRequired: [
        'Document requiring notarization',
        'Valid government-issued photo ID',
        'Any supporting documentation required for the specific notarization'
      ],
      applicationProcess: [
        'Bring unsigned document',
        'Present valid identification',
        'Sign document in presence of notary',
        'Notary completes certificate',
        'Pay notarization fee',
        'Receive notarized document'
      ],
      eligibility: 'Anyone needing documents notarized who can provide valid identification',
      relatedServices: ['document-authentication', 'business-registration'],
      icon: 'document-seal'
    },
    {
      id: 'license-renewal',
      title: 'License Renewal & Applications',
      shortDescription: 'Assistance with professional and business license renewals',
      fullDescription: 'Comprehensive support for renewing various professional and business licenses. We help navigate the often complex renewal processes, ensuring all documentation is correct and submitted on time to prevent lapses in licensing.',
      category: 'business',
      estimatedTime: '1-7 days (varies by license type)',
      fee: '$130 - $230 (varies by license type)',
      requiresAppointment: true,
      documentsRequired: [
        'Current license information',
        'Proof of continuing education (if required)',
        'Business information',
        'Identification documents'
      ],
      applicationProcess: [
        'Determine license renewal requirements',
        'Gather necessary documentation',
        'Complete renewal application',
        'Submit application and payment',
        'Track renewal status',
        'Receive renewed license'
      ],
      eligibility: 'Individuals and businesses with existing licenses requiring renewal',
      relatedServices: ['business-registration', 'notary-services'],
      icon: 'badge-check'
    },
    {
      id: 'resume-services',
      title: 'Job Application & Resume Drafting',
      shortDescription: 'Professional resume creation and job application assistance',
      fullDescription: 'Professional resume development and job application support services tailored to your industry and career goals. Our experts will help you create compelling application materials that highlight your strengths and increase your chances of landing interviews.',
      category: 'professional',
      estimatedTime: '1 business day',
      fee: '$50',
      requiresAppointment: true,
      documentsRequired: [
        'Current resume (if available)',
        'Employment history',
        'Educational background',
        'Skills and certifications'
      ],
      applicationProcess: [
        'Initial consultation to understand goals',
        'Information gathering',
        'Draft creation',
        'Review and feedback',
        'Final document preparation',
        'Job application strategy session'
      ],
      eligibility: 'Anyone seeking employment or career advancement',
      relatedServices: ['professional-consultation'],
      icon: 'document-text'
    },
    {
      id: 'professional-consultation',
      title: 'Professional Consultation',
      shortDescription: 'Expert guidance on business, immigration, and career matters',
      fullDescription: 'One-on-one professional consultation services covering business formation, immigration processes, career development, and more. Our experienced consultants provide personalized guidance to help you navigate complex processes and make informed decisions.',
      category: 'professional',
      estimatedTime: '1-2 hour sessions',
      fee: '$75 - $150 per session',
      requiresAppointment: true,
      documentsRequired: [
        'Relevant documentation for your specific consultation topic',
        'List of questions or topics you want to discuss',
        'Any previous correspondence related to your matter'
      ],
      applicationProcess: [
        'Schedule consultation appointment',
        'Complete pre-consultation questionnaire',
        'Attend consultation session',
        'Receive summary and action plan',
        'Follow-up support as needed'
      ],
      eligibility: 'Anyone seeking professional guidance in our areas of expertise',
      relatedServices: ['business-registration', 'passport-application', 'resume-services'],
      icon: 'user-group'
    },
    {
      id: 'cna-written-exam',
      title: 'CNA Written Exam Taking',
      shortDescription: 'Assistance with CNA certification written examination',
      fullDescription: 'Comprehensive support services for Certified Nursing Assistant (CNA) written examinations. We provide registration assistance, scheduling, and preparation resources to help you pass your written exam and advance in your healthcare career.',
      category: 'healthcare',
      estimatedTime: '2 hours',
      fee: '$50',
      requiresAppointment: true,
      documentsRequired: [
        'Completed CNA training certificate',
        'Government-issued photo ID',
        'Social Security Number',
        'Application form'
      ],
      applicationProcess: [
        'Verify eligibility requirements',
        'Complete exam registration form',
        'Submit required documentation',
        'Pay examination fee',
        'Schedule examination date',
        'Receive exam confirmation',
        'Prepare using provided resources'
      ],
      eligibility: 'Individuals who have completed a state-approved CNA training program',
      relatedServices: ['hca-exam-registration', 'hca-exam-booking'],
      icon: 'book-open'
    }
  ];
  
  // Filter services based on active category and search term
  useEffect(() => {
    let filtered = services;
    
    // Filter by category
    if (activeCategory !== 'all') {
      filtered = filtered.filter(service => service.category === activeCategory);
    }
    
    // Filter by search term
    if (searchTerm.trim() !== '') {
      const term = searchTerm.toLowerCase();
      filtered = filtered.filter(service => 
        service.title.toLowerCase().includes(term) || 
        service.shortDescription.toLowerCase().includes(term) ||
        service.fullDescription.toLowerCase().includes(term)
      );
    }
    
    setFilteredServices(filtered);
  }, [activeCategory, searchTerm]);
  
  // Use useEffect to set the document title
  useEffect(() => {
    document.title = "Government Services Catalog - Huduma Center USA";
    return () => {
      // Reset title when component unmounts if needed
    };
  }, []);
  
  return (
    <>
      <div className="bg-gray-50 py-8">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          {/* Breadcrumbs */}
          <Breadcrumbs
            items={[
              { label: 'Home', path: '/' },
              { label: 'Services', path: '/services' }
            ]}
          />
          
          <div className="flex flex-col md:flex-row pt-6">
            {/* Left Sidebar - Categories */}
            <div className="w-full md:w-1/4 pr-0 md:pr-8 mb-6 md:mb-0">
              <ServiceCategoryNav 
                categories={categories} 
                activeCategory={activeCategory} 
                onCategoryChange={setActiveCategory}
              />
              
              {/* Additional Resources Section */}
              <div className="mt-8 bg-white rounded-lg border border-gray-200 p-4">
                <h3 className="text-lg font-semibold text-gray-900 mb-3">Resources</h3>
                <ul className="space-y-2">
                  <li>
                    <a 
                      href="/resources/forms" 
                      className="text-blue-600 hover:text-blue-800 hover:underline text-sm flex items-center"
                    >
                      <svg className="w-4 h-4 mr-2" fill="currentColor" viewBox="0 0 20 20">
                        <path d="M9 2a1 1 0 000 2h2a1 1 0 100-2H9z" />
                        <path fillRule="evenodd" d="M4 5a2 2 0 012-2h8a2 2 0 012 2v10a2 2 0 01-2 2H6a2 2 0 01-2-2V5zm3 1h6v4H7V6zm8 8v2H5v-2h10zM7 8h2v2H7V8zm6 0h-2v2h2V8z" clipRule="evenodd" />
                      </svg>
                      Downloadable Forms
                    </a>
                  </li>
                  <li>
                    <a 
                      href="/resources/faq" 
                      className="text-blue-600 hover:text-blue-800 hover:underline text-sm flex items-center"
                    >
                      <svg className="w-4 h-4 mr-2" fill="currentColor" viewBox="0 0 20 20">
                        <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z" clipRule="evenodd" />
                      </svg>
                      Frequently Asked Questions
                    </a>
                  </li>
                  <li>
                    <a 
                      href="/contact" 
                      className="text-blue-600 hover:text-blue-800 hover:underline text-sm flex items-center"
                    >
                      <svg className="w-4 h-4 mr-2" fill="currentColor" viewBox="0 0 20 20">
                        <path d="M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z" />
                      </svg>
                      Contact Support
                    </a>
                  </li>
                  <li>
                    <a 
                      href="/services/fees" 
                      className="text-blue-600 hover:text-blue-800 hover:underline text-sm flex items-center"
                    >
                      <svg className="w-4 h-4 mr-2" fill="currentColor" viewBox="0 0 20 20">
                        <path fillRule="evenodd" d="M4 4a2 2 0 00-2 2v4a2 2 0 002 2V6h10a2 2 0 00-2-2H4zm2 6a2 2 0 012-2h8a2 2 0 012 2v4a2 2 0 01-2 2H8a2 2 0 01-2-2v-4zm6 4a2 2 0 100-4 2 2 0 000 4z" clipRule="evenodd" />
                      </svg>
                      Fee Schedule
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            
            {/* Main Content */}
            <div className="w-full md:w-3/4">
              <div className="bg-white rounded-lg shadow-sm border border-gray-200 overflow-hidden">
                {/* Page Header */}
                <div className="bg-blue-700 text-white px-6 py-4">
                  <h1 className="text-2xl font-bold">Services Catalog</h1>
                  <p className="text-blue-100 mt-1">
                    Browse our comprehensive list of services and resources
                  </p>
                </div>
                
                {/* Search Bar */}
                <div className="border-b border-gray-200 bg-gray-50 px-6 py-4">
                  <div className="relative">
                    <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                      <svg className="h-5 w-5 text-gray-400" fill="currentColor" viewBox="0 0 20 20">
                        <path fillRule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clipRule="evenodd" />
                      </svg>
                    </div>
                    <input
                      type="text"
                      className="block w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md leading-5 bg-white placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                      placeholder="Search for services..."
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                      aria-label="Search for services"
                    />
                  </div>
                </div>
                
                {/* Services List */}
                <div className="px-6 py-6">
                  <h2 className="text-xl font-semibold text-gray-900 mb-4">
                    {activeCategory === 'all' ? 'All Services' : categories.find(cat => cat.id === activeCategory)?.name}
                    <span className="text-sm font-normal text-gray-500 ml-2">
                      ({filteredServices.length} {filteredServices.length === 1 ? 'service' : 'services'})
                    </span>
                  </h2>
                  
                  {filteredServices.length === 0 ? (
                    <div className="text-center py-12">
                      <svg className="mx-auto h-12 w-12 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9.172 16.172a4 4 0 015.656 0M9 10h.01M15 10h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                      </svg>
                      <h3 className="mt-2 text-lg font-medium text-gray-900">No services found</h3>
                      <p className="mt-1 text-sm text-gray-500">
                        Try adjusting your search or filter to find what you're looking for.
                      </p>
                      <div className="mt-6">
                        <button 
                          onClick={() => {setActiveCategory('all'); setSearchTerm('');}}
                          className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                        >
                          Reset filters
                        </button>
                      </div>
                    </div>
                  ) : (
                    <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
                      {filteredServices.map((service) => (
                        <ServiceCard 
                          key={service.id}
                          service={service}
                        />
                      ))}
                    </div>
                  )}
                </div>
              </div>
              
              {/* Contact and Support Banner */}
              <div className="mt-8 bg-blue-50 border border-blue-200 rounded-lg p-6">
                <div className="flex flex-col md:flex-row items-center justify-between">
                  <div className="mb-4 md:mb-0">
                    <h3 className="text-lg font-medium text-blue-900">Need assistance with a service?</h3>
                    <p className="text-blue-700">Our support team is ready to help you with any questions.</p>
                  </div>
                  <div className="flex space-x-4">
                    <Button
                      href="/contact"
                      variant="outline"
                    >
                      Contact Us
                    </Button>
                    <Button
                      href="/appointments/book"
                    >
                      Schedule Appointment
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ServicesCatalogPage; 