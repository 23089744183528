import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { 
  getAllUsers, 
  updateUserRole, 
  updateUserStatus,
  updateUserDepartment,
  updateUserPermissions,
  updateUserNotes,
  UserRole, 
  User,
  UserStatus,
  ROLE_PERMISSIONS,
  DEPARTMENTS,
  hasPermission,
  ALL_PERMISSIONS
} from '../services/userService';
import { useAuth } from '../contexts/AuthContext';
import Card from '../components/ui/Card';
import Button from '../components/ui/Button';
import toast from 'react-hot-toast';

const UsersManagementPage: React.FC = () => {
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const [users, setUsers] = useState<User[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [editingUser, setEditingUser] = useState<User | null>(null);
  const [selectedRole, setSelectedRole] = useState<UserRole>('agent');
  const [selectedStatus, setSelectedStatus] = useState<UserStatus>('active');
  const [selectedDepartment, setSelectedDepartment] = useState<string>('');
  const [selectedPermissions, setSelectedPermissions] = useState<string[]>([]);
  const [userNotes, setUserNotes] = useState<string>('');
  const [isUpdating, setIsUpdating] = useState(false);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [activeTab, setActiveTab] = useState<'general' | 'permissions' | 'notes'>('general');
  const [selectedUser, setSelectedUser] = useState<User | null>(null);
  const [selectedPages, setSelectedPages] = useState<string[]>([]);
  const [saving, setSaving] = useState(false);

  // List of all available pages and their required permissions
  const availablePages = [
    { name: 'Dashboard', permissions: ['view_dashboard'] },
    { name: 'Visitor Registration', permissions: ['view_visitor_registration', 'manage_visitor_registration'] },
    { name: 'Visit Management', permissions: ['view_visit_management', 'manage_visits'] },
    { name: 'HCA Applications', permissions: ['view_applications', 'manage_applications'] },
    { name: 'Appointments', permissions: ['view_appointments', 'manage_appointments'] },
    { name: 'Jobs', permissions: ['view_jobs', 'manage_jobs'] },
    { name: 'Messages', permissions: ['view_messages', 'send_messages'] },
    { name: 'Reports', permissions: ['view_reports', 'manage_reports'] },
    { name: 'Users', permissions: ['view_users', 'manage_users'] },
    { name: 'Bank Services', permissions: ['view_bank_services', 'manage_bank_services'] }
  ];

  // Check if user has access to this page
  useEffect(() => {
    if (!currentUser?.uid || currentUser.role !== 'admin') {
      navigate('/dashboard');
      return;
    }
  }, [currentUser, navigate]);

  // Fetch all users
  const loadUsers = async () => {
    setLoading(true);
    try {
      const fetchedUsers = await getAllUsers();
      setUsers(fetchedUsers.filter(user => user.uid !== currentUser?.uid));
    } catch (error) {
      console.error('Error loading users:', error);
      toast.error('Failed to load users');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadUsers();
  }, [currentUser]);

  // Handle role update
  const handleRoleUpdate = async () => {
    if (!editingUser || !currentUser) return;
    
    setIsUpdating(true);
    try {
      await updateUserRole(editingUser.uid, selectedRole);
      
      // Update local state
      setUsers(prevUsers => 
        prevUsers.map(user => 
          user.uid === editingUser.uid 
            ? { ...user, role: selectedRole } 
            : user
        )
      );
      
      setSuccessMessage(`${editingUser.displayName}'s role updated to ${selectedRole}`);
      setTimeout(() => setSuccessMessage(null), 3000);
    } catch (err) {
      console.error('Error updating user role:', err);
      setError('Failed to update user role');
    } finally {
      setIsUpdating(false);
    }
  };

  // Handle status update
  const handleStatusUpdate = async () => {
    if (!editingUser || !currentUser) return;
    
    setIsUpdating(true);
    try {
      await updateUserStatus(editingUser.uid, selectedStatus, currentUser.uid);
      
      // Update local state
      setUsers(prevUsers => 
        prevUsers.map(user => 
          user.uid === editingUser.uid 
            ? { ...user, status: selectedStatus } 
            : user
        )
      );
      
      setSuccessMessage(`${editingUser.displayName}'s status updated to ${selectedStatus}`);
      setTimeout(() => setSuccessMessage(null), 3000);
    } catch (err) {
      console.error('Error updating user status:', err);
      setError('Failed to update user status');
    } finally {
      setIsUpdating(false);
    }
  };

  // Handle department update
  const handleDepartmentUpdate = async () => {
    if (!editingUser || !currentUser) return;
    
    setIsUpdating(true);
    try {
      await updateUserDepartment(editingUser.uid, selectedDepartment, currentUser.uid);
      
      // Update local state
      setUsers(prevUsers => 
        prevUsers.map(user => 
          user.uid === editingUser.uid 
            ? { ...user, department: selectedDepartment } 
            : user
        )
      );
      
      setSuccessMessage(`${editingUser.displayName}'s department updated to ${selectedDepartment}`);
      setTimeout(() => setSuccessMessage(null), 3000);
    } catch (err) {
      console.error('Error updating user department:', err);
      setError('Failed to update user department');
    } finally {
      setIsUpdating(false);
    }
  };

  // Handle permissions update
  const handlePermissionsUpdate = async () => {
    if (!editingUser || !currentUser) return;
    
    setIsUpdating(true);
    try {
      await updateUserPermissions(editingUser.uid, selectedPermissions, currentUser.uid);
      
      // Update local state
      setUsers(prevUsers => 
        prevUsers.map(user => 
          user.uid === editingUser.uid 
            ? { ...user, permissions: selectedPermissions } 
            : user
        )
      );
      
      setSuccessMessage(`${editingUser.displayName}'s permissions have been updated`);
      setTimeout(() => setSuccessMessage(null), 3000);
    } catch (err) {
      console.error('Error updating user permissions:', err);
      setError('Failed to update user permissions');
    } finally {
      setIsUpdating(false);
    }
  };

  // Handle notes update
  const handleNotesUpdate = async () => {
    if (!editingUser || !currentUser) return;
    
    setIsUpdating(true);
    try {
      await updateUserNotes(editingUser.uid, userNotes, currentUser.uid);
      
      // Update local state
      setUsers(prevUsers => 
        prevUsers.map(user => 
          user.uid === editingUser.uid 
            ? { ...user, notes: userNotes } 
            : user
        )
      );
      
      setSuccessMessage(`Notes updated for ${editingUser.displayName}`);
      setTimeout(() => setSuccessMessage(null), 3000);
    } catch (err) {
      console.error('Error updating user notes:', err);
      setError('Failed to update user notes');
    } finally {
      setIsUpdating(false);
    }
  };

  // Get role badge color
  const getRoleBadgeColor = (role: UserRole | undefined) => {
    switch (role) {
      case 'admin':
        return 'bg-purple-100 text-purple-800 border border-purple-300';
      case 'manager':
        return 'bg-blue-100 text-blue-800 border border-blue-300';
      case 'agent':
        return 'bg-green-100 text-green-800 border border-green-300';
      case 'receptionist':
        return 'bg-yellow-100 text-yellow-800 border border-yellow-300';
      case 'bank_agent':
        return 'bg-indigo-100 text-indigo-800 border border-indigo-300';
      default:
        return 'bg-gray-100 text-gray-800 border border-gray-300';
    }
  };

  // Get status badge color
  const getStatusBadgeColor = (status: UserStatus | undefined) => {
    switch (status) {
      case 'active':
        return 'bg-green-100 text-green-800 border border-green-300';
      case 'inactive':
        return 'bg-gray-100 text-gray-800 border border-gray-300';
      case 'suspended':
        return 'bg-red-100 text-red-800 border border-red-300';
      case 'pending':
        return 'bg-yellow-100 text-yellow-800 border border-yellow-300';
      default:
        return 'bg-gray-100 text-gray-800 border border-gray-300';
    }
  };

  // Format date for display
  const formatDate = (date: Date | undefined) => {
    if (!date) return 'N/A';
    return new Date(date).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    });
  };

  const handleEditUser = (user: User) => {
    if (!user) return;
    
    setEditingUser(user);
    setSelectedRole(user.role || 'agent');
    setSelectedStatus(user.status || 'active');
    setSelectedDepartment(user.department || '');
    setSelectedPermissions(user.permissions || []);
    setUserNotes(user.notes || '');
    setActiveTab('general');
  };

  const handleUserSelect = (user: User) => {
    setSelectedUser(user);
    // Set selected pages based on user's custom permissions
    const userPermissions = user.permissions || [];
    const selectedPageNames = availablePages
      .filter(page => page.permissions.some(perm => userPermissions.includes(perm)))
      .map(page => page.name);
    setSelectedPages(selectedPageNames);
  };

  const handlePageToggle = (pageName: string) => {
    setSelectedPages(prev =>
      prev.includes(pageName)
        ? prev.filter(p => p !== pageName)
        : [...prev, pageName]
    );
  };

  const handleSavePermissions = async () => {
    if (!selectedUser) return;
    
    setSaving(true);
    try {
      // Get all permissions from selected pages
      const newPermissions = selectedPages.flatMap(pageName => {
        const page = availablePages.find(p => p.name === pageName);
        return page ? page.permissions : [];
      });
      
      console.log('Saving permissions:', {
        userId: selectedUser.uid,
        newPermissions
      });
      
      await updateUserPermissions(
        selectedUser.uid,
        newPermissions,
        currentUser?.uid || 'system'
      );

      // Refresh the users list
      await loadUsers();
      toast.success('Permissions updated successfully');
    } catch (error) {
      console.error('Error saving permissions:', error);
      toast.error('Failed to update permissions');
    } finally {
      setSaving(false);
    }
  };

  // If user doesn't have access, return null
  if (!currentUser?.uid || currentUser.role !== 'admin') {
    return null;
  }

  return (
    <div className="max-w-7xl mx-auto px-4 py-8">
      <div className="flex flex-col md:flex-row md:items-center justify-between mb-6">
        <div>
          <h1 className="text-2xl font-bold text-gray-900">User Management</h1>
          <p className="mt-1 text-sm text-gray-500">
            View and manage user accounts, roles, and permissions
          </p>
        </div>
      </div>
      
      {error && (
        <div className="mb-6 bg-red-50 border border-red-200 rounded-md p-4">
          <div className="flex">
            <div className="flex-shrink-0">
              <svg className="h-5 w-5 text-red-400" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clipRule="evenodd" />
              </svg>
            </div>
            <div className="ml-3">
              <p className="text-sm text-red-700">{error}</p>
            </div>
          </div>
        </div>
      )}
      
      {successMessage && (
        <div className="mb-6 bg-green-50 border border-green-200 rounded-md p-4">
          <div className="flex">
            <div className="flex-shrink-0">
              <svg className="h-5 w-5 text-green-400" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
              </svg>
            </div>
            <div className="ml-3">
              <p className="text-sm text-green-700">{successMessage}</p>
            </div>
          </div>
        </div>
      )}
      
      <Card>
        {loading ? (
          <div className="p-6 flex justify-center">
            <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-900"></div>
          </div>
        ) : users.length === 0 ? (
          <div className="p-6 text-center">
            <svg xmlns="http://www.w3.org/2000/svg" className="mx-auto h-12 w-12 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z" />
            </svg>
            <h3 className="mt-2 text-lg font-medium text-gray-900">No users found</h3>
            <p className="mt-1 text-sm text-gray-500">
              There are no users in the system yet.
            </p>
          </div>
        ) : (
          <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    User
                  </th>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Role
                  </th>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Status
                  </th>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Department
                  </th>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Last Updated
                  </th>
                  <th scope="col" className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {users.map((user) => (
                  <tr key={user.uid} className="hover:bg-gray-50">
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="flex items-center">
                        <div className="flex-shrink-0 h-10 w-10">
                          {user.photoURL ? (
                            <img className="h-10 w-10 rounded-full" src={user.photoURL} alt={user.displayName || ''} />
                          ) : (
                            <div className="h-10 w-10 rounded-full bg-gray-200 flex items-center justify-center">
                              <span className="text-gray-500 font-medium">
                                {(user.displayName || '').charAt(0).toUpperCase()}
                              </span>
                            </div>
                          )}
                        </div>
                        <div className="ml-4">
                          <div className="text-sm font-medium text-gray-900">{user.displayName || 'Unnamed User'}</div>
                          <div className="text-sm text-gray-500">{user.email}</div>
                        </div>
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${getRoleBadgeColor(user.role)}`}>
                        {(user.role || 'unknown').split('_').map(word => 
                          word.charAt(0).toUpperCase() + word.slice(1)
                        ).join(' ')}
                      </span>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${getStatusBadgeColor(user.status)}`}>
                        {(user.status || 'unknown').charAt(0).toUpperCase() + (user.status || 'unknown').slice(1)}
                      </span>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm text-gray-900">{user.department || 'N/A'}</div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm text-gray-900">{formatDate(user.lastUpdated)}</div>
                      {user.lastUpdatedBy && (
                        <div className="text-xs text-gray-500">
                          by {users.find(u => u.uid === user.lastUpdatedBy)?.displayName || 'Unknown'}
                        </div>
                      )}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                      <button
                        onClick={() => handleEditUser(user)}
                        className="text-blue-600 hover:text-blue-900"
                      >
                        Edit User
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </Card>
      
      {/* Edit User Modal */}
      {editingUser && (
        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div className="fixed inset-0 transition-opacity" aria-hidden="true">
              <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
            </div>
            
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
            
            <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
              <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div className="sm:flex sm:items-start">
                  <div className="mt-3 text-center sm:mt-0 sm:text-left w-full">
                    <h3 className="text-lg leading-6 font-medium text-gray-900">
                      Edit User: {editingUser.displayName}
                    </h3>
                    
                    {/* Tabs */}
                    <div className="mt-4 border-b border-gray-200">
                      <nav className="-mb-px flex space-x-8" aria-label="Tabs">
                        <button
                          onClick={() => setActiveTab('general')}
                          className={`${
                            activeTab === 'general'
                              ? 'border-blue-500 text-blue-600'
                              : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
                          } whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm`}
                        >
                          General
                        </button>
                        <button
                          onClick={() => setActiveTab('permissions')}
                          className={`${
                            activeTab === 'permissions'
                              ? 'border-blue-500 text-blue-600'
                              : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
                          } whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm`}
                        >
                          Permissions
                        </button>
                        <button
                          onClick={() => setActiveTab('notes')}
                          className={`${
                            activeTab === 'notes'
                              ? 'border-blue-500 text-blue-600'
                              : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
                          } whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm`}
                        >
                          Notes
                        </button>
                      </nav>
                    </div>
                    
                    {/* General Tab */}
                    {activeTab === 'general' && (
                      <div className="mt-4 space-y-4">
                        <div>
                          <label htmlFor="role" className="block text-sm font-medium text-gray-700">
                            Role
                          </label>
                          <select
                            id="role"
                            name="role"
                            value={selectedRole}
                            onChange={(e) => setSelectedRole(e.target.value as UserRole)}
                            className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md"
                          >
                            {Object.keys(ROLE_PERMISSIONS).map((role) => (
                              <option key={role} value={role}>
                                {role.charAt(0).toUpperCase() + role.slice(1)}
                              </option>
                            ))}
                          </select>
                        </div>
                        
                        <div>
                          <label htmlFor="status" className="block text-sm font-medium text-gray-700">
                            Status
                          </label>
                          <select
                            id="status"
                            name="status"
                            value={selectedStatus}
                            onChange={(e) => setSelectedStatus(e.target.value as UserStatus)}
                            className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md"
                          >
                            <option value="active">Active</option>
                            <option value="inactive">Inactive</option>
                            <option value="suspended">Suspended</option>
                            <option value="pending">Pending</option>
                          </select>
                        </div>
                        
                        <div>
                          <label htmlFor="department" className="block text-sm font-medium text-gray-700">
                            Department
                          </label>
                          <select
                            id="department"
                            name="department"
                            value={selectedDepartment}
                            onChange={(e) => setSelectedDepartment(e.target.value)}
                            className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md"
                          >
                            <option value="">Select Department</option>
                            {DEPARTMENTS.map((dept) => (
                              <option key={dept} value={dept}>
                                {dept}
                              </option>
                            ))}
                          </select>
                        </div>
                        
                        <div className="flex justify-end space-x-3">
                          <Button
                            onClick={() => {
                              handleRoleUpdate();
                              handleStatusUpdate();
                              handleDepartmentUpdate();
                            }}
                            isDisabled={isUpdating}
                            className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                          >
                            {isUpdating ? 'Updating...' : 'Update'}
                          </Button>
                        </div>
                      </div>
                    )}
                    
                    {/* Permissions Tab */}
                    {activeTab === 'permissions' && (
                      <div className="mt-4">
                        <div className="space-y-4">
                          <div>
                            <label className="block text-sm font-medium text-gray-700 mb-2">
                              Role Permissions
                            </label>
                            <div className="bg-gray-50 p-3 rounded-md">
                              <ul className="list-disc pl-5 space-y-1 text-sm text-gray-600">
                                {ROLE_PERMISSIONS[selectedRole].map((permission) => (
                                  <li key={permission}>
                                    {permission.split('_').join(' ').charAt(0).toUpperCase() + 
                                     permission.split('_').join(' ').slice(1)}
                                  </li>
                                ))}
                              </ul>
                            </div>
                          </div>
                          
                          <div>
                            <label className="block text-sm font-medium text-gray-700 mb-2">
                              Additional Permissions
                            </label>
                            <div className="space-y-2">
                              {Object.values(ROLE_PERMISSIONS).flat().filter((value, index, self) => self.indexOf(value) === index)
                                .map((permission) => (
                                  <div key={permission} className="flex items-center">
                                    <input
                                      type="checkbox"
                                      id={permission}
                                      checked={selectedPermissions.includes(permission)}
                                      onChange={(e) => {
                                        if (e.target.checked) {
                                          setSelectedPermissions([...selectedPermissions, permission]);
                                        } else {
                                          setSelectedPermissions(selectedPermissions.filter(p => p !== permission));
                                        }
                                      }}
                                      className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                                    />
                                    <label htmlFor={permission} className="ml-2 text-sm text-gray-700">
                                      {permission.split('_').join(' ').charAt(0).toUpperCase() + 
                                       permission.split('_').join(' ').slice(1)}
                                    </label>
                                  </div>
                                ))}
                            </div>
                          </div>
                        </div>
                        
                        <div className="mt-4 flex justify-end">
                          <Button
                            onClick={handlePermissionsUpdate}
                            isDisabled={isUpdating}
                            className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                          >
                            {isUpdating ? 'Updating...' : 'Update Permissions'}
                          </Button>
                        </div>
                      </div>
                    )}
                    
                    {/* Notes Tab */}
                    {activeTab === 'notes' && (
                      <div className="mt-4">
                        <label htmlFor="notes" className="block text-sm font-medium text-gray-700">
                          User Notes
                        </label>
                        <textarea
                          id="notes"
                          name="notes"
                          rows={4}
                          value={userNotes}
                          onChange={(e) => setUserNotes(e.target.value)}
                          className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                          placeholder="Add notes about this user..."
                        />
                        
                        <div className="mt-4 flex justify-end">
                          <Button
                            onClick={handleNotesUpdate}
                            isDisabled={isUpdating}
                            className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                          >
                            {isUpdating ? 'Updating...' : 'Update Notes'}
                          </Button>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              
              <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                <button
                  type="button"
                  onClick={() => setEditingUser(null)}
                  className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:mt-0 sm:w-auto sm:text-sm"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mt-6">
        {/* Users List */}
        <div className="bg-white rounded-lg shadow p-4">
          <h2 className="text-lg font-semibold mb-4">Users</h2>
          <div className="space-y-2">
            {users.map(user => (
              <button
                key={user.uid}
                onClick={() => handleUserSelect(user)}
                className={`w-full text-left p-3 rounded ${
                  selectedUser?.uid === user.uid
                    ? 'bg-blue-50 border border-blue-200'
                    : 'hover:bg-gray-50 border border-gray-200'
                }`}
              >
                <div className="font-medium">{user.displayName}</div>
                <div className="text-sm text-gray-500">{user.email}</div>
                <div className="text-xs text-gray-400">Role: {user.role}</div>
              </button>
            ))}
          </div>
        </div>

        {/* Permissions Management */}
        <div className="bg-white rounded-lg shadow p-4">
          <h2 className="text-lg font-semibold mb-4">Page Access</h2>
          {selectedUser ? (
            <>
              <div className="space-y-3 mb-6">
                {availablePages.map(page => (
                  <label
                    key={page.name}
                    className="flex items-center space-x-3 p-2 hover:bg-gray-50 rounded"
                  >
                    <input
                      type="checkbox"
                      checked={selectedPages.includes(page.name)}
                      onChange={() => handlePageToggle(page.name)}
                      className="rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                    />
                    <span>{page.name}</span>
                  </label>
                ))}
              </div>
              <button
                onClick={handleSavePermissions}
                disabled={saving}
                className="w-full bg-blue-600 text-white py-2 px-4 rounded hover:bg-blue-700 disabled:bg-blue-300"
              >
                {saving ? 'Saving...' : 'Save Permissions'}
              </button>
            </>
          ) : (
            <div className="text-gray-500 text-center py-8">
              Select a user to manage their page access
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default UsersManagementPage; 