import { getFunctions, httpsCallable } from 'firebase/functions';
import { getEnvironment } from '../utils/environment';

export interface EmailData {
  to: string;
  subject: string;
  html: string;
  from?: string;
  text?: string;
}

/**
 * Sends an email using Firebase Cloud Functions
 * @param emailData - The email data to send
 * @returns A promise that resolves when the email is sent
 */
export async function sendEmail(emailData: EmailData): Promise<boolean> {
  // Validate required fields
  if (!emailData.to || !emailData.subject) {
    console.error('Email missing required fields:', { to: emailData.to, subject: emailData.subject });
    return false;
  }

  // For development environment, just log the email
  if (process.env.NODE_ENV === 'development') {
    return sendEmailSimulated(emailData);
  }

  try {
    // Set a timeout for the email sending operation (30 seconds)
    const timeoutPromise = new Promise<boolean>((_, reject) => {
      setTimeout(() => reject(new Error('Email sending timed out')), 30000);
    });

    // Actual email sending logic
    const sendPromise = new Promise<boolean>(async (resolve, reject) => {
      try {
        // Get the callable cloud function
        const functions = getFunctions();
        const sendEmailFunction = httpsCallable(functions, 'sendEmail');
        
        // Call the function with the email data
        const result = await sendEmailFunction(emailData);
        
        // Log the result
        console.log('Email sent successfully:', result.data);
        resolve(true);
      } catch (error) {
        // Check for specific Firebase permission error
        if (error instanceof Error && 
            error.toString().includes('Firebase') && 
            error.toString().includes('permission')) {
          console.warn('Firebase permission error when sending email, falling back to simulation mode');
          // Fall back to simulation mode
          const simulated = await sendEmailSimulated(emailData);
          resolve(simulated);
        } else {
          reject(error);
        }
      }
    });

    // Race the promises
    return await Promise.race([sendPromise, timeoutPromise]);
  } catch (error) {
    console.error('Error sending email:', error);
    
    // Fallback to simulation in case of error
    console.warn('Email sending failed, falling back to simulation mode');
    return sendEmailSimulated(emailData);
  }
}

/**
 * Simulates sending an email (for development or fallback purposes)
 * @param emailData - The email data to simulate sending
 * @returns A promise that resolves after a short delay
 */
export const sendEmailSimulated = async (emailData: EmailData): Promise<any> => {
  return new Promise((resolve) => {
    // Log the email details
    console.log('EMAIL SIMULATION --------------------------------');
    console.log('To:', emailData.to);
    console.log('Subject:', emailData.subject);
    console.log('HTML Content Preview:', emailData.html.substring(0, 200) + '...');
    console.log('------------------------------------------------');
    
    // Simulate a delay
    setTimeout(() => {
      resolve({ success: true, simulated: true });
    }, 500);
  });
};

// Add retry mechanism with exponential backoff
const wait = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

export async function sendEmailWithRetry(
  emailData: EmailData, 
  maxRetries: number = 3
): Promise<boolean> {
  let retryCount = 0;
  let lastError: any;

  while (retryCount < maxRetries) {
    try {
      // Add exponential backoff
      if (retryCount > 0) {
        await wait(Math.pow(2, retryCount) * 1000);
      }

      const result = await sendEmail(emailData);
      if (result) {
        return true;
      }
    } catch (error) {
      lastError = error;
      console.error(`Email send attempt ${retryCount + 1} failed:`, error);
      retryCount++;
    }
  }

  console.error('All email send attempts failed:', lastError);
  return false;
}

// Add debounce for status update notifications
let statusUpdateTimeout: NodeJS.Timeout;
export const sendStatusUpdateEmail = async (emailData: EmailData): Promise<boolean> => {
  clearTimeout(statusUpdateTimeout);
  
  return new Promise((resolve) => {
    statusUpdateTimeout = setTimeout(async () => {
      try {
        const result = await sendEmailWithRetry(emailData);
        resolve(result);
      } catch (error) {
        console.error('Status update email failed:', error);
        resolve(false);
      }
    }, 1000); // Debounce for 1 second
  });
};

/**
 * Tests the email configuration by sending a test email
 * @param recipientEmail The email address to send the test to
 * @returns A promise that resolves when the test email is sent
 */
export const testEmailConfiguration = async (recipientEmail: string): Promise<any> => {
  console.log(`Testing email configuration by sending to ${recipientEmail}`);
  
  const testHtml = `
    <div style="font-family: Arial, sans-serif; padding: 20px; border: 1px solid #e0e0e0; border-radius: 5px;">
      <h1 style="color: #1e3a8a;">Email Configuration Test</h1>
      <p>This is a test email to verify that your SMTP configuration is working correctly.</p>
      <p>If you're seeing this, your email system is properly configured!</p>
      <p>Details:</p>
      <ul>
        <li>Server: mail.hudumacenter.com</li>
        <li>From: booking@hudumacenter.com</li>
        <li>Time sent: ${new Date().toLocaleString()}</li>
      </ul>
      <p style="color: #666; font-size: 14px;">This is an automated test email.</p>
    </div>
  `;
  
  return await sendEmailWithRetry({
    to: recipientEmail,
    from: '"Huduma Center USA" <booking@hudumacenter.com>',
    subject: "Huduma Center Email Configuration Test",
    html: testHtml,
  }, 3);
}; 