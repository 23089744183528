import React, { useState, useEffect } from 'react';
import { format } from 'date-fns';
import { getStaffPerformanceReport, StaffPerformanceMetrics } from '../../services/reportService';
import { Users, Star, Clock, DollarSign, Trophy } from 'lucide-react';

interface StaffPerformanceReportProps {
  startDate: Date;
  endDate: Date;
}

const StaffPerformanceReport: React.FC<StaffPerformanceReportProps> = ({ startDate, endDate }) => {
  const [reportData, setReportData] = useState<StaffPerformanceMetrics[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [sortField, setSortField] = useState<keyof StaffPerformanceMetrics>('totalIncome');
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('desc');
  
  // Fetch report data
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      setError(null);
      
      try {
        const data = await getStaffPerformanceReport(startDate, endDate);
        setReportData(data);
      } catch (err) {
        console.error('Error fetching staff performance report:', err);
        setError('Failed to load staff performance data.');
      } finally {
        setIsLoading(false);
      }
    };
    
    fetchData();
  }, [startDate, endDate]);
  
  // Format currency
  const formatCurrency = (amount: number) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }).format(amount);
  };
  
  // Handle sorting
  const handleSort = (field: keyof StaffPerformanceMetrics | 'staffName') => {
    if (field === 'staffName') {
      // Handle the special case for staffName by mapping to the name field
      if (sortField === 'name') {
        setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
      } else {
        setSortField('name');
        setSortDirection('desc');
      }
    } else if (field as keyof StaffPerformanceMetrics) {
      if (field === sortField) {
        setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
      } else {
        setSortField(field as keyof StaffPerformanceMetrics);
        setSortDirection('desc');
      }
    }
  };
  
  // Get sorted data
  const getSortedData = () => {
    if (!reportData.length) return [];
    
    return [...reportData].sort((a, b) => {
      let comparison = 0;
      
      // Handle string fields specially
      if (sortField === 'name') {
        comparison = a.name.localeCompare(b.name);
      } else {
        // For numeric fields
        if (a[sortField] < b[sortField]) comparison = -1;
        if (a[sortField] > b[sortField]) comparison = 1;
      }
      
      return sortDirection === 'asc' ? comparison : -comparison;
    });
  };
  
  // Calculate totals for all staff
  const calculateTotals = () => {
    if (!reportData.length) return null;
    
    const totals = reportData.reduce(
      (acc, staff) => {
        return {
          totalStaff: acc.totalStaff + 1,
          totalTasks: acc.totalTasks + staff.tasksCompleted,
          totalIncome: acc.totalIncome + staff.totalIncome,
        };
      },
      { totalStaff: 0, totalTasks: 0, totalIncome: 0 }
    );
    
    // Find top performer by income
    const topPerformer = reportData.reduce((prev, current) => 
      prev.totalIncome > current.totalIncome ? prev : current
    );
    
    return {
      ...totals,
      topPerformer: topPerformer?.name || 'N/A',
      topPerformerIncome: topPerformer?.totalIncome || 0
    };
  };
  
  const sortedData = getSortedData();
  const totals = calculateTotals();
  
  // Render loading state
  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-64">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-700"></div>
      </div>
    );
  }
  
  // Render error state
  if (error) {
    return (
      <div className="bg-red-50 p-4 rounded-md text-red-800">
        <p className="font-medium">Error loading report</p>
        <p className="text-sm mt-1">{error}</p>
      </div>
    );
  }
  
  // Render empty state
  if (!reportData.length) {
    return (
      <div className="text-center py-12">
        <Users className="h-12 w-12 text-gray-400 mx-auto mb-4" />
        <h3 className="text-lg font-medium text-gray-900">No staff performance data available</h3>
        <p className="text-gray-500 mt-2">
          There is no staff performance data available for the selected date range.
          <br />
          Try selecting a different date range or check back later.
        </p>
      </div>
    );
  }
  
  return (
    <div>
      <div className="mb-6">
        <h2 className="text-xl font-bold text-gray-900">Staff Performance Report</h2>
        <p className="text-gray-500 text-sm">
          {format(startDate, 'MMMM d, yyyy')} - {format(endDate, 'MMMM d, yyyy')}
        </p>
      </div>
      
      {totals && (
        <div className="grid grid-cols-1 md:grid-cols-4 gap-4 mb-8">
          <div className="bg-white p-4 rounded-lg border border-gray-200 shadow-sm">
            <div className="flex justify-between items-start">
              <div>
                <p className="text-gray-500 text-sm">Total Staff</p>
                <p className="text-2xl font-bold text-gray-900">{totals.totalStaff}</p>
              </div>
              <div className="p-2 bg-blue-100 rounded-full">
                <Users className="h-5 w-5 text-blue-600" />
              </div>
            </div>
          </div>
          
          <div className="bg-white p-4 rounded-lg border border-gray-200 shadow-sm">
            <div className="flex justify-between items-start">
              <div>
                <p className="text-gray-500 text-sm">Total Tasks</p>
                <p className="text-2xl font-bold text-gray-900">{totals.totalTasks}</p>
              </div>
              <div className="p-2 bg-green-100 rounded-full">
                <Clock className="h-5 w-5 text-green-600" />
              </div>
            </div>
            <div className="mt-1 text-sm text-gray-500">
              {(totals.totalTasks / totals.totalStaff).toFixed(1)} tasks per staff
            </div>
          </div>
          
          <div className="bg-white p-4 rounded-lg border border-gray-200 shadow-sm">
            <div className="flex justify-between items-start">
              <div>
                <p className="text-gray-500 text-sm">Total Income</p>
                <p className="text-2xl font-bold text-gray-900">{formatCurrency(totals.totalIncome)}</p>
              </div>
              <div className="p-2 bg-purple-100 rounded-full">
                <DollarSign className="h-5 w-5 text-purple-600" />
              </div>
            </div>
            <div className="mt-1 text-sm text-gray-500">
              {formatCurrency(totals.totalIncome / totals.totalStaff)} avg. per staff
            </div>
          </div>
          
          <div className="bg-white p-4 rounded-lg border border-gray-200 shadow-sm">
            <div className="flex justify-between items-start">
              <div>
                <p className="text-gray-500 text-sm">Top Performer</p>
                <p className="text-2xl font-bold text-gray-900">{totals.topPerformer}</p>
              </div>
              <div className="p-2 bg-yellow-100 rounded-full">
                <Trophy className="h-5 w-5 text-yellow-600" />
              </div>
            </div>
            <div className="mt-1 text-sm text-gray-500">
              {formatCurrency(totals.topPerformerIncome)} in revenue
            </div>
          </div>
        </div>
      )}
      
      {/* Performance Table */}
      <div className="overflow-x-auto">
        <table className="min-w-full divide-y divide-gray-200 border">
          <thead className="bg-gray-50">
            <tr>
              <th 
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer hover:bg-gray-100"
                onClick={() => handleSort('staffName')}
              >
                <div className="flex items-center">
                  Staff Name
                  {sortField === 'name' && (
                    <span className="ml-1">
                      {sortDirection === 'asc' ? '▲' : '▼'}
                    </span>
                  )}
                </div>
              </th>
              <th 
                scope="col" 
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer hover:bg-gray-100"
                onClick={() => handleSort('tasksCompleted')}
              >
                <div className="flex items-center">
                  Tasks
                  {sortField === 'tasksCompleted' && (
                    <span className="ml-1">
                      {sortDirection === 'asc' ? '▲' : '▼'}
                    </span>
                  )}
                </div>
              </th>
              <th 
                scope="col" 
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer hover:bg-gray-100"
                onClick={() => handleSort('visitsCompleted')}
              >
                <div className="flex items-center">
                  Visits
                  {sortField === 'visitsCompleted' && (
                    <span className="ml-1">
                      {sortDirection === 'asc' ? '▲' : '▼'}
                    </span>
                  )}
                </div>
              </th>
              <th 
                scope="col" 
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer hover:bg-gray-100"
                onClick={() => handleSort('appointmentsCompleted')}
              >
                <div className="flex items-center">
                  Appointments
                  {sortField === 'appointmentsCompleted' && (
                    <span className="ml-1">
                      {sortDirection === 'asc' ? '▲' : '▼'}
                    </span>
                  )}
                </div>
              </th>
              <th 
                scope="col" 
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer hover:bg-gray-100"
                onClick={() => handleSort('averageRating')}
              >
                <div className="flex items-center">
                  Rating
                  {sortField === 'averageRating' && (
                    <span className="ml-1">
                      {sortDirection === 'asc' ? '▲' : '▼'}
                    </span>
                  )}
                </div>
              </th>
              <th 
                scope="col" 
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer hover:bg-gray-100"
                onClick={() => handleSort('averageServiceTime')}
              >
                <div className="flex items-center">
                  Service Time
                  {sortField === 'averageServiceTime' && (
                    <span className="ml-1">
                      {sortDirection === 'asc' ? '▲' : '▼'}
                    </span>
                  )}
                </div>
              </th>
              <th 
                scope="col" 
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer hover:bg-gray-100"
                onClick={() => handleSort('totalIncome')}
              >
                <div className="flex items-center">
                  Income
                  {sortField === 'totalIncome' && (
                    <span className="ml-1">
                      {sortDirection === 'asc' ? '▲' : '▼'}
                    </span>
                  )}
                </div>
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {sortedData.map((staff) => (
              <tr key={staff.staffId} className="hover:bg-gray-50">
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="flex items-center">
                    <div className="flex-shrink-0 h-10 w-10 rounded-full bg-gray-200 flex items-center justify-center">
                      <span className="text-gray-600 font-medium text-sm">
                        {staff.name.substring(0, 2).toUpperCase()}
                      </span>
                    </div>
                    <div className="ml-4">
                      <div className="text-sm font-medium text-gray-900">{staff.name}</div>
                    </div>
                  </div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                  {staff.tasksCompleted}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                  {staff.visitsCompleted}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                  {staff.appointmentsCompleted}
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="flex items-center">
                    <Star className="h-4 w-4 text-yellow-400 mr-1" />
                    <span className="text-sm text-gray-900">
                      {staff.averageRating > 0 ? staff.averageRating.toFixed(1) : 'N/A'}
                    </span>
                  </div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                  {staff.averageServiceTime > 0 ? `${Math.round(staff.averageServiceTime)} min` : 'N/A'}
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="text-sm font-medium text-gray-900">
                    {formatCurrency(staff.totalIncome)}
                  </div>
                  <div className="text-xs text-gray-500">
                    {formatCurrency(staff.totalIncome / staff.tasksCompleted)} per task
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      
      {/* Income Distribution Chart */}
      <div className="mt-10">
        <h3 className="text-lg font-medium text-gray-900 mb-4">Income by Staff Member</h3>
        <div className="space-y-4">
          {sortedData.slice(0, 10).map((staff) => {
            // Calculate percentage of total income
            const percentage = totals 
              ? (staff.totalIncome / totals.totalIncome) * 100 
              : 0;
            
            return (
              <div key={staff.staffId}>
                <div className="flex justify-between text-sm mb-1">
                  <span className="font-medium text-gray-700">{staff.name}</span>
                  <span className="text-gray-600">{formatCurrency(staff.totalIncome)}</span>
                </div>
                <div className="w-full h-2 bg-gray-100 rounded-full overflow-hidden">
                  <div 
                    className="h-full bg-blue-500 rounded-full"
                    style={{ width: `${percentage}%` }}
                  ></div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default StaffPerformanceReport; 