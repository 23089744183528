import React from 'react';
import { cn } from '@/lib/utils';
import { format } from 'date-fns';
import { Search, UserCheck, UserMinus } from 'lucide-react';

interface VisitorDetailsProps {
  visitor: any;
  visitorHistory: any[];
  onCheckIn: () => Promise<void>;
  onCheckOut: () => Promise<void>;
  onNewSearch: () => void;
  isProcessing: boolean;
}

const VisitorDetails: React.FC<VisitorDetailsProps> = ({
  visitor,
  visitorHistory,
  onCheckIn,
  onCheckOut,
  onNewSearch,
  isProcessing
}) => {
  
  // Format date for display
  const formatDate = (date: Date | null | undefined): string => {
    if (!date) return 'N/A';
    try {
      return format(date, 'MM/dd/yyyy');
    } catch (error) {
      return 'Invalid Date';
    }
  };
  
  return (
    <div className="bg-green-50 border border-green-200 rounded-lg p-6 m-6">
      <div className="flex items-start justify-between mb-4">
        <div>
          <h2 className="text-xl font-semibold text-gray-900 flex items-center">
            <UserCheck className="w-5 h-5 mr-2 text-green-600" />
            Visitor Found
          </h2>
          <p className="text-sm text-gray-600">
            Visitor record found in the system
          </p>
        </div>
        {visitor.photoUrl && (
          <div className="w-16 h-16 rounded-full overflow-hidden">
            <img 
              src={visitor.photoUrl} 
              alt={`${visitor.fullName}'s photo`} 
              className="w-full h-full object-cover"
            />
          </div>
        )}
      </div>
      
      <div className="grid grid-cols-1 md:grid-cols-2 gap-x-6 gap-y-4">
        <div>
          <p className="text-sm font-medium text-gray-500">Name</p>
          <p className="text-base font-semibold">{visitor.fullName}</p>
        </div>
        
        <div>
          <p className="text-sm font-medium text-gray-500">Phone</p>
          <p className="text-base">{visitor.phoneNumber}</p>
        </div>
        
        <div>
          <p className="text-sm font-medium text-gray-500">Email</p>
          <p className="text-base">{visitor.email || 'Not provided'}</p>
        </div>
        
        <div>
          <p className="text-sm font-medium text-gray-500">Last Visit</p>
          <p className="text-base">{formatDate(visitor.lastVisit)}</p>
        </div>
        
        {visitorHistory.length > 0 && (
          <div className="col-span-2">
            <p className="text-sm font-medium text-gray-500 mb-2">Visit History</p>
            <div className="bg-white rounded-md p-3 max-h-40 overflow-y-auto">
              <ul className="space-y-2">
                {visitorHistory.map((visit, index) => (
                  <li key={index} className="text-sm flex justify-between">
                    <span>{formatDate(visit.date)}</span>
                    <span className="text-gray-600">{visit.purpose}</span>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        )}
      </div>
      
      <div className="flex flex-wrap space-x-3 mt-6">
        {(!visitor.status || visitor.status === 'checked-out') ? (
          <button
            type="button"
            className={cn(
              "flex items-center justify-center px-5 py-2.5 text-sm font-medium text-white bg-blue-700 rounded-lg focus:ring-4 focus:ring-blue-200 hover:bg-blue-800",
              isProcessing ? "opacity-70 cursor-not-allowed" : ""
            )}
            onClick={onCheckIn}
            disabled={isProcessing}
          >
            {isProcessing ? (
              <>
                <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
                Processing...
              </>
            ) : (
              <>
                <UserCheck className="w-5 h-5 mr-2" />
                Check In Visitor
              </>
            )}
          </button>
        ) : visitor.status === 'checked-in' && (
          <button
            type="button"
            className={cn(
              "flex items-center justify-center px-5 py-2.5 text-sm font-medium text-white bg-amber-600 rounded-lg focus:ring-4 focus:ring-amber-200 hover:bg-amber-700",
              isProcessing ? "opacity-70 cursor-not-allowed" : ""
            )}
            onClick={onCheckOut}
            disabled={isProcessing}
          >
            {isProcessing ? (
              <>
                <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
                Processing...
              </>
            ) : (
              <>
                <UserMinus className="w-5 h-5 mr-2" />
                Check Out Visitor
              </>
            )}
          </button>
        )}
        
        <button
          type="button"
          className="flex items-center justify-center px-5 py-2.5 text-sm font-medium text-blue-700 bg-white border border-blue-700 rounded-lg focus:ring-4 focus:ring-blue-200 hover:bg-blue-50"
          onClick={onNewSearch}
        >
          <Search className="w-5 h-5 mr-2" />
          New Search
        </button>
      </div>
    </div>
  );
};

export default VisitorDetails; 