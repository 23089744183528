import { 
  collection, 
  getDocs, 
  getDoc, 
  doc, 
  addDoc, 
  updateDoc, 
  serverTimestamp, 
  query, 
  where, 
  orderBy, 
  onSnapshot,
  Timestamp,
  DocumentData,
  setDoc
} from 'firebase/firestore';
import { db } from '../firebase/config';
import { getAllStaffMembers, User } from './userService';

// Define Visit status type
export type VisitStatus = 'waiting' | 'being-served' | 'on-hold' | 'completed' | 'abandoned';

// Define Visit interface
export interface Visit {
  id: string;
  visitorName: string;
  phoneNumber: string;
  service: string;
  status: VisitStatus;
  assignedStaff: string | null;
  checkInTime: Timestamp;
  waitingTime?: number; // in minutes
  notes?: string;
}

// Define Registration interface to match the registration collection
export interface Registration {
  id: string;
  visitorId: string;
  fullName: string;
  phoneNumber: string;
  email: string;
  serviceId: string;
  serviceName: string;
  otherService?: string;
  queuePriority: 'standard' | 'high';
  status: 'waiting' | 'serving' | 'completed' | 'cancelled';
  queueNumber: string;
  estimatedWaitTime: number;
  checkInTime: Timestamp;
  createdBy?: string;
  createdAt?: Timestamp;
  assignedStaff?: string;
  staffId?: string;
}

// Define Staff interface (adapted to match User interface properties we need)
export interface Staff {
  id: string;
  name: string;
  role: string;
  available: boolean;
  email?: string;
}

// Define visit statistics interface
export interface VisitStats {
  totalCheckedIn: number;
  inQueue: number;
  beingServed: number;
  completed: number;
  avgWaitTime: number;
}

// Define VisitNote interface
export interface VisitNote {
  id: string;
  visitId: string;
  note: string;
  createdAt: Timestamp;
  createdBy: string;
  createdByName: string;
  deleted: boolean;
}

// Define VisitCompletion interface
export interface VisitCompletion {
  id: string;
  visitId: string;
  feedback: string;
  serviceRendered: boolean;
  wasCharged: boolean;
  amountCharged?: number;
  reasonNotRendered?: string;
  completedAt: Timestamp;
  completedBy: string;
  completedByName: string;
}

// Set up Firebase collection references
const visitsCollectionRef = collection(db, 'visits');
const registrationsCollectionRef = collection(db, 'registrations');
const visitCompletionsCollectionRef = collection(db, 'visitCompletions');

// Helper function to convert Registration to Visit
const convertRegistrationToVisit = (registration: Registration): Visit => {
  return {
    id: registration.id,
    visitorName: registration.fullName,
    phoneNumber: registration.phoneNumber,
    service: registration.serviceName || registration.otherService || 'Unknown',
    status: convertStatus(registration.status),
    assignedStaff: registration.assignedStaff || null,
    checkInTime: registration.checkInTime,
    waitingTime: registration.estimatedWaitTime
  };
};

// Helper function to convert registration status to visit status
const convertStatus = (registrationStatus: string): VisitStatus => {
  switch (registrationStatus) {
    case 'waiting':
      return 'waiting';
    case 'serving':
      return 'being-served';
    case 'completed':
      return 'completed';
    case 'cancelled':
      return 'abandoned';
    default:
      return 'waiting';
  }
};

/**
 * Get all active visits (not completed or abandoned)
 */
export const getActiveVisits = async (): Promise<Visit[]> => {
  const activeVisitsQuery = query(
    registrationsCollectionRef,
    where('status', 'in', ['waiting', 'serving']),
    orderBy('checkInTime', 'asc')
  );
  
  const querySnapshot = await getDocs(activeVisitsQuery);
  
  return querySnapshot.docs.map(doc => {
    const registration = {
      id: doc.id,
      ...doc.data()
    } as Registration;
    
    return convertRegistrationToVisit(registration);
  });
};

/**
 * Subscribe to active visits (real-time updates)
 */
export const subscribeToActiveVisits = (callback: (visits: Visit[]) => void) => {
  // Get start of today
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  const startOfToday = Timestamp.fromDate(today);
  
  const activeVisitsQuery = query(
    registrationsCollectionRef,
    where('status', 'in', ['waiting', 'serving']),
    where('checkInTime', '>=', startOfToday),
    orderBy('checkInTime', 'asc')
  );
  
  return onSnapshot(activeVisitsQuery, (snapshot) => {
    const visits = snapshot.docs.map(doc => {
      const registration = {
        id: doc.id,
        ...doc.data()
      } as Registration;
      
      return convertRegistrationToVisit(registration);
    });
    
    callback(visits);
  });
};

/**
 * Get completed visits for today
 */
export const getCompletedVisitsToday = async (): Promise<Visit[]> => {
  // Get start of today
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  const startOfToday = Timestamp.fromDate(today);
  
  const completedVisitsQuery = query(
    registrationsCollectionRef,
    where('status', '==', 'completed'),
    where('checkInTime', '>=', startOfToday),
    orderBy('checkInTime', 'desc')
  );
  
  const querySnapshot = await getDocs(completedVisitsQuery);
  
  return querySnapshot.docs.map(doc => {
    const registration = {
      id: doc.id,
      ...doc.data()
    } as Registration;
    
    return convertRegistrationToVisit(registration);
  });
};

/**
 * Subscribe to completed visits for today (real-time updates)
 */
export const subscribeToCompletedVisitsToday = (callback: (visits: Visit[]) => void) => {
  // Get start of today
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  const startOfToday = Timestamp.fromDate(today);
  
  const completedVisitsQuery = query(
    registrationsCollectionRef,
    where('status', '==', 'completed'),
    where('checkInTime', '>=', startOfToday),
    orderBy('checkInTime', 'desc')
  );
  
  return onSnapshot(completedVisitsQuery, (snapshot) => {
    const visits = snapshot.docs.map(doc => {
      const registration = {
        id: doc.id,
        ...doc.data()
      } as Registration;
      
      return convertRegistrationToVisit(registration);
    });
    
    callback(visits);
  });
};

/**
 * Get all staff members (from users collection instead of staff collection)
 */
export const getStaffMembers = async (): Promise<Staff[]> => {
  try {
    console.log('Getting staff members from users collection');
    // Get staff members from userService instead
    const users = await getAllStaffMembers();
    
    // Convert User objects to Staff objects
    return users.map(user => ({
      id: user.uid,
      name: user.displayName,
      role: user.role,
      available: user.isActive,
      email: user.email
    }));
  } catch (error) {
    console.error('Error fetching staff members from users collection:', error);
    return [];
  }
};

/**
 * Check if a visitor is already checked in (to prevent double check-ins)
 */
export const isVisitorAlreadyCheckedIn = async (phoneNumber: string): Promise<boolean> => {
  try {
    // Check if there's an active registration with this phone number
    const activeVisitQuery = query(
      registrationsCollectionRef,
      where('phoneNumber', '==', phoneNumber),
      where('status', 'in', ['waiting', 'serving'])
    );
    
    const querySnapshot = await getDocs(activeVisitQuery);
    return !querySnapshot.empty;
  } catch (error) {
    console.error('Error checking if visitor is already checked in:', error);
    // Return false to allow check-in if there's an error (better to allow than block by default)
    return false;
  }
};

/**
 * Add a new visit with validation to prevent double check-ins
 */
export const addVisit = async (visitData: Omit<Visit, 'id' | 'checkInTime'>): Promise<string> => {
  // First check if visitor is already checked in
  const isAlreadyCheckedIn = await isVisitorAlreadyCheckedIn(visitData.phoneNumber);
  
  if (isAlreadyCheckedIn) {
    throw new Error('This visitor is already checked in. Cannot check in twice simultaneously.');
  }
  
  // Convert Visit to Registration format
  const registrationData = {
    visitorId: 'manual-entry',
    fullName: visitData.visitorName,
    phoneNumber: visitData.phoneNumber,
    email: '',
    serviceId: 'manual',
    serviceName: visitData.service,
    queuePriority: 'standard',
    status: convertVisitStatusToRegistration(visitData.status),
    queueNumber: generateQueueNumber(),
    estimatedWaitTime: visitData.waitingTime || 20,
    checkInTime: serverTimestamp(),
    createdAt: serverTimestamp(),
    notes: visitData.notes
  };
  
  const docRef = await addDoc(registrationsCollectionRef, registrationData);
  
  return docRef.id;
};

/**
 * Update a visit
 */
export const updateVisit = async (visitId: string, updates: Partial<Visit>): Promise<void> => {
  const visitRef = doc(db, 'registrations', visitId);
  
  // Convert Visit updates to Registration updates
  const registrationUpdates: any = {};
  
  if (updates.visitorName) registrationUpdates.fullName = updates.visitorName;
  if (updates.phoneNumber) registrationUpdates.phoneNumber = updates.phoneNumber;
  if (updates.service) registrationUpdates.serviceName = updates.service;
  if (updates.status) registrationUpdates.status = convertVisitStatusToRegistration(updates.status);
  if (updates.assignedStaff) registrationUpdates.assignedStaff = updates.assignedStaff;
  if (updates.notes) registrationUpdates.notes = updates.notes;
  
  await updateDoc(visitRef, registrationUpdates);
};

/**
 * Calculate visit statistics
 */
export const calculateVisitStats = async (
  activeVisits: Visit[], 
  completedVisits: Visit[]
): Promise<VisitStats> => {
  // Get today's date at midnight for comparison
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  
  // Filter visits for today only
  const todayActiveVisits = activeVisits.filter(visit => {
    const checkInDate = visit.checkInTime instanceof Date 
      ? visit.checkInTime 
      : new Date((visit.checkInTime as any).seconds * 1000);
    return checkInDate >= today;
  });
  
  const todayCompletedVisits = completedVisits.filter(visit => {
    const checkInDate = visit.checkInTime instanceof Date 
      ? visit.checkInTime 
      : new Date((visit.checkInTime as any).seconds * 1000);
    return checkInDate >= today;
  });
  
  // Calculate basic stats from visits only
  const totalCheckedIn = todayActiveVisits.length + todayCompletedVisits.length;
  const inQueue = todayActiveVisits.filter(v => v.status === 'waiting').length;
  const completed = todayCompletedVisits.length;
  
  return {
    totalCheckedIn,
    inQueue,
    beingServed: 0, // This is no longer displayed but kept for interface compatibility
    completed,
    avgWaitTime: 0 // This is no longer displayed but kept for interface compatibility
  };
};

/**
 * Complete a visit
 */
export const completeVisit = async (visitId: string): Promise<void> => {
  try {
    console.log(`Attempting to complete visit with ID: ${visitId}`);
    
    const registrationRef = doc(db, 'registrations', visitId);
    const registrationSnap = await getDoc(registrationRef);
    
    if (!registrationSnap.exists()) {
      console.error(`Registration document ${visitId} does not exist`);
      throw new Error('Registration not found');
    }
    
    const registrationData = registrationSnap.data();
    const checkInTime = registrationData.checkInTime.toDate();
    const waitingTime = Math.floor((Date.now() - checkInTime.getTime()) / (1000 * 60)); // minutes
    
    console.log(`Updating registration ${visitId} to completed status`);
    await updateDoc(registrationRef, {
      status: 'completed',
      estimatedWaitTime: waitingTime,
      lastUpdated: serverTimestamp()
    });
    
    console.log(`Successfully completed visit ${visitId}`);
  } catch (error) {
    console.error('Error in completeVisit:', error);
    throw error; // Re-throw to handle in calling function
  }
};

/**
 * Assign staff to a visit
 */
export const assignStaffToVisit = async (visitId: string, staffId: string, staffName: string): Promise<void> => {
  try {
    console.log(`Assigning staff ${staffName} (${staffId}) to visit ${visitId}`);
    const registrationRef = doc(db, 'registrations', visitId);
    
    // Update the registration with assigned staff details
    await updateDoc(registrationRef, {
      assignedStaff: staffName,
      staffId: staffId,
      status: 'serving'
    });
    
    console.log('Staff assignment successful');
  } catch (error) {
    console.error('Error assigning staff to visit:', error);
    throw error;
  }
};

/**
 * Update visit status
 */
export const updateVisitStatus = async (visitId: string, status: VisitStatus): Promise<void> => {
  const visitRef = doc(db, 'registrations', visitId);
  
  await updateDoc(visitRef, {
    status: convertVisitStatusToRegistration(status)
  });
};

/**
 * Helper function to convert visit status to registration status
 */
const convertVisitStatusToRegistration = (status: VisitStatus): string => {
  switch (status) {
    case 'waiting':
      return 'waiting';
    case 'being-served':
      return 'serving';
    case 'completed':
      return 'completed';
    case 'abandoned':
      return 'cancelled';
    case 'on-hold':
      return 'waiting'; // Registrations don't have an on-hold state
    default:
      return 'waiting';
  }
};

/**
 * Generate a queue number
 */
const generateQueueNumber = (): string => {
  const prefix = 'Q';
  const date = new Date();
  const datePart = `${date.getFullYear()}${String(date.getMonth() + 1).padStart(2, '0')}${String(date.getDate()).padStart(2, '0')}`;
  const randomPart = Math.floor(Math.random() * 1000).toString().padStart(3, '0');
  
  return `${prefix}${datePart}-${randomPart}`;
};

/**
 * Get a visit by ID
 */
export const getVisitById = async (visitId: string): Promise<Visit> => {
  try {
    const registrationDocRef = doc(db, 'registrations', visitId);
    const registrationDoc = await getDoc(registrationDocRef);
    
    if (!registrationDoc.exists()) {
      throw new Error('Visit not found');
    }
    
    const registrationData = registrationDoc.data() as Registration;
    return convertRegistrationToVisit({ 
      ...registrationData, 
      id: registrationDoc.id 
    });
  } catch (error) {
    console.error('Error getting visit by ID:', error);
    throw error;
  }
};

/**
 * Get visitor history (past visits)
 */
export const getVisitorHistory = async (visitorIdOrPhone: string): Promise<any[]> => {
  try {
    let visitorId = visitorIdOrPhone;
    
    // Check if input might be a phone number
    if (visitorIdOrPhone.length <= 15 && /^\+?\d+$/.test(visitorIdOrPhone)) {
      // This looks like a phone number, so search for the visitor first
      const visitor = await searchVisitorByPhone(visitorIdOrPhone);
      if (visitor) {
        // Handle both single result and array result cases
        if (Array.isArray(visitor) && visitor.length > 0) {
          visitorId = visitor[0].id;
        } else if (!Array.isArray(visitor)) {
          visitorId = visitor.id;
        } else {
          return []; // Visitor not found or multiple visitors found
        }
      } else {
        return []; // Visitor not found
      }
    }
    
    // Query registrations for this visitor
    const registrationsQuery = query(
      registrationsCollectionRef,
      where('visitorId', '==', visitorId),
      where('status', '==', 'completed'),
      orderBy('checkInTime', 'desc')
    );
    
    const querySnapshot = await getDocs(registrationsQuery);
    
    return querySnapshot.docs.map(doc => {
      const data = doc.data();
      return {
        id: doc.id,
        date: data.checkInTime ? data.checkInTime.toDate() : null,
        purpose: data.serviceName || 'General Visit',
        status: data.status
      };
    });
  } catch (error) {
    console.error('Error fetching visitor history:', error);
    return [];
  }
};

/**
 * Update visit notes
 */
export const updateVisitNotes = async (visitId: string, notes: string): Promise<void> => {
  console.log(`Updating notes for visit ${visitId}`);
  console.log('Notes content:', notes);
  
  try {
    const registrationDocRef = doc(db, 'registrations', visitId);
    
    // Verify the document exists first
    const docSnap = await getDoc(registrationDocRef);
    if (!docSnap.exists()) {
      console.error(`Visit document ${visitId} does not exist`);
      throw new Error(`Visit document ${visitId} does not exist`);
    }

    console.log('Document exists, updating notes...');

    // Use a more direct approach to update the document
    await updateDoc(registrationDocRef, {
      notes: notes,
      updatedAt: serverTimestamp()
    });

    console.log('Notes updated successfully');
  } catch (error) {
    console.error('Error updating visit notes:', error);
    throw error;
  }
};

/**
 * Get completed visits for a specific date range
 */
export const getCompletedVisitsByDateRange = async (startDate: Date, endDate: Date): Promise<Visit[]> => {
  // Create Date objects that represent the start of the day and end of the day
  const startOfDay = new Date(startDate);
  startOfDay.setHours(0, 0, 0, 0);
  
  const endOfDay = new Date(endDate);
  endOfDay.setHours(23, 59, 59, 999);
  
  console.log('Fetching completed visits by date range:', { 
    startOfDay: startOfDay.toISOString(), 
    endOfDay: endOfDay.toISOString()
  });
  
  try {
    // Query all completed visits and do client-side filtering
    const completedVisitsQuery = query(
      registrationsCollectionRef,
      where('status', '==', 'completed')
    );
    
    const querySnapshot = await getDocs(completedVisitsQuery);
    console.log(`Found ${querySnapshot.size} total completed visits`);
    
    // In-memory filtering for date range
    const visits = querySnapshot.docs.map(doc => {
      const data = doc.data() as Omit<Registration, 'id'>;
      return convertRegistrationToVisit({
        id: doc.id,
        ...data
      });
    });
    
    const filteredVisits = visits.filter(visit => {
      if (!visit.checkInTime) return false;
      
      // Convert Firestore timestamp to JavaScript Date
      const visitDate = visit.checkInTime.toDate();
      
      // Simple time comparison
      return visitDate >= startOfDay && visitDate <= endOfDay;
    });
    
    console.log(`Filtered to ${filteredVisits.length} visits in date range`);
    return filteredVisits;
  } catch (error) {
    console.error('Error fetching completed visits by date range:', error);
    return [];
  }
};

/**
 * Subscribe to completed visits for a specific date range (real-time updates)
 */
export const subscribeToCompletedVisitsByDateRange = (
  startDate: Date, 
  endDate: Date, 
  callback: (visits: Visit[]) => void
) => {
  // Create Date objects that represent the start of the day and end of the day
  const startOfDay = new Date(startDate);
  startOfDay.setHours(0, 0, 0, 0);
  
  const endOfDay = new Date(endDate);
  endOfDay.setHours(23, 59, 59, 999);
  
  console.log('Subscribing to completed visits by date range:', { 
    startOfDay: startOfDay.toISOString(), 
    endOfDay: endOfDay.toISOString()
  });
  
  // Initialize with empty array to prevent UI from getting stuck
  callback([]);
  
  try {
    // Query all completed visits
    const completedVisitsQuery = query(
      registrationsCollectionRef,
      where('status', '==', 'completed')
    );
    
    return onSnapshot(completedVisitsQuery, (snapshot) => {
      console.log(`Received ${snapshot.size} total completed visits`);
      
      // In-memory filtering for date range
      const visits = snapshot.docs.map(doc => {
        const data = doc.data() as Omit<Registration, 'id'>;
        return convertRegistrationToVisit({
          id: doc.id,
          ...data
        });
      });
      
      const filteredVisits = visits.filter(visit => {
        if (!visit.checkInTime) return false;
        
        // Convert Firestore timestamp to JavaScript Date
        const visitDate = visit.checkInTime.toDate();
        
        // Simple time comparison with inclusive boundaries
        const isInRange = visitDate >= startOfDay && visitDate <= endOfDay;
        
        if (isInRange) {
          console.log(`Visit ${visit.id} is in range: ${visitDate.toISOString()}`);
        }
        
        return isInRange;
      });
      
      console.log(`Filtered to ${filteredVisits.length} visits in date range`);
      callback(filteredVisits);
    }, (error) => {
      console.error('Error in completed visits subscription:', error);
      callback([]);
    });
  } catch (error) {
    console.error('Setup error in completed visits subscription:', error);
    callback([]);
    // Return a no-op function as unsubscribe
    return () => {};
  }
};

/**
 * Add a note to a visit
 */
export const addVisitNote = async (
  visitId: string,
  note: string,
  userId: string,
  userName: string
): Promise<string> => {
  try {
    console.log(`Adding note to visit ${visitId}`);
    console.log('Note content:', note);
    console.log('User creating note:', { userId, userName });

    // Use the provided userName directly - already has fallbacks in the component
    const noteData = {
      visitId,
      note,
      createdAt: serverTimestamp(),
      createdBy: userId,
      createdByName: userName, // No fallback needed here as component already handles it
      deleted: false
    };
    
    const visitNotesCollection = collection(db, 'visitNotes');
    const docRef = await addDoc(visitNotesCollection, noteData);
    
    console.log('Visit note added successfully:', docRef.id);
    return docRef.id;
  } catch (error) {
    console.error('Error adding visit note:', error);
    throw error;
  }
};

/**
 * Fetch all notes for a visit
 */
export const getVisitNotes = async (visitId: string): Promise<VisitNote[]> => {
  try {
    console.log(`Fetching notes for visit ${visitId}`);
    
    const notesQuery = query(
      collection(db, 'visitNotes'),
      where('visitId', '==', visitId),
      where('deleted', '==', false)
    );
    
    const notesSnapshot = await getDocs(notesQuery);
    
    // Convert to array of note objects
    const notes = notesSnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    })) as VisitNote[];
    
    // Sort by creation time, newest first
    notes.sort((a, b) => {
      if (!a.createdAt || !b.createdAt) return 0;
      return b.createdAt.seconds - a.createdAt.seconds;
    });
    
    console.log(`Found ${notes.length} notes for visit ${visitId}`);
    return notes;
  } catch (error) {
    console.error('Error fetching visit notes:', error);
    return [];
  }
};

/**
 * Records completion details for a visit and adds a note
 */
export const recordVisitCompletion = async (
  registrationId: string,
  completionData: {
    feedback: string;
    serviceRendered: boolean;
    wasCharged: boolean;
    amountCharged?: number;
    reasonNotRendered?: string;
  },
  userId: string,
  userName: string
): Promise<void> => {
  try {
    console.log(`Recording completion for registration ${registrationId}`);
    
    // Reference to the registration document
    const registrationRef = doc(db, 'registrations', registrationId);
    
    // Get the registration document first
    const registrationSnap = await getDoc(registrationRef);
    if (!registrationSnap.exists()) {
      throw new Error('Registration not found');
    }

    // Create the completion data
    const completionDetails = {
      status: 'completed',
      completedAt: serverTimestamp(),
      completedBy: userId,
      completedByName: userName,
      feedback: completionData.feedback,
      serviceRendered: completionData.serviceRendered,
      wasCharged: completionData.wasCharged,
      amountCharged: completionData.amountCharged || 0,
      reasonNotRendered: completionData.reasonNotRendered || '',
      lastUpdated: serverTimestamp(),
      updatedBy: userId
    };

    // Update the registration document with completion details
    await updateDoc(registrationRef, completionDetails);
    
    // Add a note about the completion
    const noteContent = completionData.serviceRendered
      ? `Visit completed by ${userName}.\nFeedback: ${completionData.feedback}\nService was rendered.${
          completionData.wasCharged 
            ? `\nAmount charged: $${completionData.amountCharged || 0}`
            : '\nNo charges were applied.'
        }`
      : `Visit completed by ${userName}.\nFeedback: ${completionData.feedback}\nService was NOT rendered.\nReason: ${
          completionData.reasonNotRendered || 'Not specified'
        }`;

    // Add the completion note
    await addDoc(collection(db, 'visitNotes'), {
      registrationId,
      note: noteContent,
      createdAt: serverTimestamp(),
      createdBy: userId,
      createdByName: userName,
      type: 'completion'
    });

    console.log(`Successfully completed registration ${registrationId}`);
  } catch (error) {
    console.error('Error recording completion:', error);
    throw error;
  }
};

/**
 * Get completion details for a visit
 */
export const getVisitCompletionDetails = async (visitId: string): Promise<VisitCompletion | null> => {
  try {
    console.log(`Getting completion details for visit ${visitId}`);
    
    // First check in the registrations collection (which is where we currently store completion data)
    const registrationRef = doc(db, 'registrations', visitId);
    const registrationSnap = await getDoc(registrationRef);
    
    if (registrationSnap.exists()) {
      const regData = registrationSnap.data() as Record<string, any>;
      
      // Check if this registration has been completed
      if (regData.status === 'completed' && regData.completedAt) {
        return {
          id: registrationSnap.id,
          visitId: registrationSnap.id,
          feedback: regData.feedback || '',
          serviceRendered: regData.serviceRendered || false,
          wasCharged: regData.wasCharged || false,
          amountCharged: regData.amountCharged || 0,
          reasonNotRendered: regData.reasonNotRendered || '',
          completedAt: regData.completedAt,
          completedBy: regData.completedBy || '',
          completedByName: regData.completedByName || ''
        };
      }
    }
    
    // As a fallback, check in the visitCompletions collection
    const completionQuery = query(
      visitCompletionsCollectionRef,
      where('visitId', '==', visitId)
    );
    
    const querySnapshot = await getDocs(completionQuery);
    
    if (querySnapshot.empty) {
      console.log(`No completion details found for visit ${visitId}`);
      return null;
    }
    
    // Get the first matching document
    const docData = querySnapshot.docs[0];
    
    return {
      id: docData.id,
      ...docData.data()
    } as VisitCompletion;
  } catch (error) {
    console.error('Error getting visit completion details:', error);
    return null;
  }
};

/**
 * Search for a visitor by phone
 */
export const searchVisitorByPhone = async (phoneNumber: string) => {
  try {
    // Clean the phone number by removing non-digit characters
    const cleanedNumber = phoneNumber.replace(/\D/g, '');
    
    // If the phone number is too short, don't search to avoid too many results
    if (cleanedNumber.length < 3) {
      console.log('Phone number search term too short:', cleanedNumber);
      return null;
    }

    console.log('Searching for visitors with phone number containing:', cleanedNumber);
    const visitorsRef = collection(db, 'visitors');
    // We need to fetch all records since Firestore doesn't support native partial text search
    const querySnapshot = await getDocs(visitorsRef);
    console.log(`Found ${querySnapshot.size} total visitors to filter`);
    
    // Filter client-side for partial matches
    const matches = querySnapshot.docs.filter(doc => {
      const visitorData = doc.data();
      if (!visitorData.phoneNumber) return false;
      
      // Clean the visitor's phone number as well
      const visitorPhoneClean = String(visitorData.phoneNumber).replace(/\D/g, '');
      
      // Check if the visitor's phone number contains the search query digits
      const isMatch = visitorPhoneClean.includes(cleanedNumber);
      
      if (isMatch) {
        console.log(`Match found: ${visitorData.phoneNumber} (${visitorPhoneClean}) contains ${cleanedNumber}`);
      }
      
      return isMatch;
    });
    
    console.log(`Found ${matches.length} visitors matching phone search`);
    
    if (matches.length === 1) {
      // If exactly one match, return it directly for backward compatibility
      const visitorDoc = matches[0];
      const visitorData = visitorDoc.data();
      return {
        id: visitorDoc.id,
        ...visitorData,
        fullName: visitorData.fullName || '',
        phoneNumber: visitorData.phoneNumber || '',
        email: visitorData.email || '',
        lastVisit: visitorData.lastVisit ? visitorData.lastVisit.toDate() : null,
        checkInTime: visitorData.checkInTime ? visitorData.checkInTime.toDate() : null,
        checkOutTime: visitorData.checkOutTime ? visitorData.checkOutTime.toDate() : null,
      };
    } else if (matches.length > 1) {
      // If multiple matches, return them all in an array
      return matches.map(visitorDoc => {
        const visitorData = visitorDoc.data();
        return {
          id: visitorDoc.id,
          ...visitorData,
          fullName: visitorData.fullName || '',
          phoneNumber: visitorData.phoneNumber || '',
          email: visitorData.email || '',
          lastVisit: visitorData.lastVisit ? visitorData.lastVisit.toDate() : null,
          checkInTime: visitorData.checkInTime ? visitorData.checkInTime.toDate() : null,
          checkOutTime: visitorData.checkOutTime ? visitorData.checkOutTime.toDate() : null,
        };
      });
    }
    
    console.log('No visitors found matching phone search');
    return null;
  } catch (error) {
    console.error('Error searching visitor by phone:', error);
    throw error;
  }
};

/**
 * Search for a visitor by email
 */
export const searchVisitorByEmail = async (email: string) => {
  try {
    // Convert search term to lowercase
    const emailLower = email.toLowerCase().trim();
    
    // If the email is too short, don't search to avoid too many results
    if (emailLower.length < 2) {
      console.log('Email search term too short:', emailLower);
      return null;
    }

    console.log('Searching for visitors with email containing:', emailLower);
    const visitorsRef = collection(db, 'visitors');
    // We need to fetch all records since Firestore doesn't support native partial text search
    const querySnapshot = await getDocs(visitorsRef);
    console.log(`Found ${querySnapshot.size} total visitors to filter`);
    
    // Filter client-side for partial matches
    const matches = querySnapshot.docs.filter(doc => {
      const visitorData = doc.data();
      if (!visitorData.email) return false;
      
      const visitorEmail = String(visitorData.email).toLowerCase();
      const isMatch = visitorEmail.includes(emailLower);
      
      if (isMatch) {
        console.log(`Match found: ${visitorEmail} contains ${emailLower}`);
      }
      
      return isMatch;
    });
    
    console.log(`Found ${matches.length} visitors matching email search`);
    
    if (matches.length > 0) {
      // Return all matches
      return matches.map(visitorDoc => {
        const visitorData = visitorDoc.data();
        return {
          id: visitorDoc.id,
          ...visitorData,
          fullName: visitorData.fullName || '',
          phoneNumber: visitorData.phoneNumber || '',
          email: visitorData.email || '',
          lastVisit: visitorData.lastVisit ? visitorData.lastVisit.toDate() : null,
          checkInTime: visitorData.checkInTime ? visitorData.checkInTime.toDate() : null,
          checkOutTime: visitorData.checkOutTime ? visitorData.checkOutTime.toDate() : null,
        };
      });
    }
    
    console.log('No visitors found matching email search');
    return null;
  } catch (error) {
    console.error('Error searching visitor by email:', error);
    throw error;
  }
};

/**
 * Search for a visitor by name
 */
export const searchVisitorByName = async (name: string) => {
  try {
    // Convert search term to lowercase and trim whitespace
    const nameLower = name.toLowerCase().trim();
    
    // If the name is too short, don't search to avoid too many results
    if (nameLower.length < 2) {
      console.log('Name search term too short:', nameLower);
      return null;
    }

    console.log('Searching for visitors with name containing:', nameLower);
    const visitorsRef = collection(db, 'visitors');
    // We need to fetch all records since Firestore doesn't support native partial text search
    const querySnapshot = await getDocs(visitorsRef);
    console.log(`Found ${querySnapshot.size} total visitors to filter`);
    
    // Filter client-side for partial matches
    const matches = querySnapshot.docs.filter(doc => {
      const visitorData = doc.data();
      
      // Check if fullName exists and is a string
      const fullName = visitorData.fullName;
      if (!fullName || typeof fullName !== 'string') {
        return false;
      }
      
      const visitorName = fullName.toLowerCase();
      
      // Simple contains check - most straightforward approach
      const isContainMatch = visitorName.includes(nameLower);
      
      // Check individual names (first name, last name)
      const searchWords = nameLower.split(/\s+/).filter(w => w.length > 0);
      const nameWords = visitorName.split(/\s+/).filter(w => w.length > 0);
      
      // Check if any name part starts with search terms
      const isWordMatch = searchWords.some(searchWord => 
        nameWords.some(nameWord => 
          nameWord.startsWith(searchWord) || searchWord.startsWith(nameWord)
        )
      );
      
      const isMatch = isContainMatch || isWordMatch;
      
      if (isMatch) {
        console.log(`Match found: "${fullName}" matches search term "${name}"`);
      }
      
      return isMatch;
    });
    
    console.log(`Found ${matches.length} visitors matching name search`);
    
    if (matches.length > 0) {
      // Return all matches
      return matches.map(visitorDoc => {
        const visitorData = visitorDoc.data();
        return {
          id: visitorDoc.id,
          ...visitorData,
          fullName: visitorData.fullName || '',
          phoneNumber: visitorData.phoneNumber || '',
          email: visitorData.email || '',
          lastVisit: visitorData.lastVisit ? visitorData.lastVisit.toDate() : null,
          checkInTime: visitorData.checkInTime ? visitorData.checkInTime.toDate() : null,
          checkOutTime: visitorData.checkOutTime ? visitorData.checkOutTime.toDate() : null,
        };
      });
    }
    
    console.log('No visitors found matching name search');
    return null;
  } catch (error) {
    console.error('Error searching visitor by name:', error);
    throw error;
  }
};

/**
 * Register a new visitor
 */
export const registerNewVisitor = async (visitorData: any) => {
  try {
    // Add visitor to 'visitors' collection
    const visitorRef = await addDoc(collection(db, 'visitors'), {
      ...visitorData,
      createdAt: serverTimestamp(),
      lastVisit: serverTimestamp()
    });
    
    // Generate queue number if needed
    const queueNumber = visitorData.queueNumber || generateQueueNumber();
    
    // Calculate estimated wait time if not provided
    let estimatedWaitTime = visitorData.estimatedWaitTime;
    if (!estimatedWaitTime) {
      estimatedWaitTime = await calculateEstimatedWaitTime(visitorData.priority || 'standard');
    }
    
    // Create a visit/registration record
    const registrationData = {
      visitorId: visitorRef.id,
      fullName: visitorData.fullName,
      phoneNumber: visitorData.phoneNumber,
      email: visitorData.email || '',
      serviceId: visitorData.serviceId || '',
      serviceName: visitorData.purpose || 'General Visit',
      queuePriority: visitorData.priority || 'standard',
      status: 'waiting',
      queueNumber,
      estimatedWaitTime: estimatedWaitTime,
      checkInTime: serverTimestamp(),
      createdAt: serverTimestamp(),
      createdBy: visitorData.createdBy || '',
      notes: visitorData.notes || ''
    };
    
    await addDoc(registrationsCollectionRef, registrationData);
    
    return visitorRef.id;
  } catch (error) {
    console.error('Error registering new visitor:', error);
    throw error;
  }
};

/**
 * Check in a visitor
 */
export const checkInVisitor = async (visitorId: string) => {
  try {
    // Get visitor data
    const visitorRef = doc(db, 'visitors', visitorId);
    const visitorDoc = await getDoc(visitorRef);
    
    if (!visitorDoc.exists()) {
      throw new Error('Visitor not found');
    }
    
    const visitorData = visitorDoc.data();
    
    // Update visitor status
    await updateDoc(visitorRef, {
      lastVisit: serverTimestamp(),
      status: 'checked-in'
    });
    
    // Generate queue number
    const queueNumber = generateQueueNumber();
    
    // Calculate the estimated wait time (await it to resolve the promise)
    const estimatedWaitTime = await calculateEstimatedWaitTime(visitorData.priority || 'standard');
    
    // Create registration record
    const registrationData = {
      visitorId,
      fullName: visitorData.fullName || '',
      phoneNumber: visitorData.phoneNumber || '',
      email: visitorData.email || '',
      serviceId: '',
      serviceName: visitorData.purpose || 'General Visit',
      queuePriority: visitorData.priority || 'standard',
      status: 'waiting',
      queueNumber,
      estimatedWaitTime: estimatedWaitTime,
      checkInTime: serverTimestamp(),
      createdAt: serverTimestamp()
    };
    
    await addDoc(registrationsCollectionRef, registrationData);
    
    // Return the queue number for display
    return queueNumber;
  } catch (error) {
    console.error('Error checking in visitor:', error);
    throw error;
  }
};

/**
 * Check out a visitor
 */
export const checkOutVisitor = async (visitorId: string) => {
  try {
    // Get visitor data
    const visitorRef = doc(db, 'visitors', visitorId);
    
    // Update visitor status
    await updateDoc(visitorRef, {
      checkOutTime: serverTimestamp(),
      status: 'checked-out'
    });
    
    // Find active registration/visit record for this visitor
    const registrationsQuery = query(
      registrationsCollectionRef,
      where('visitorId', '==', visitorId),
      where('status', 'in', ['waiting', 'serving'])
    );
    
    const registrationsSnap = await getDocs(registrationsQuery);
    
    // Update all active registrations to completed
    for (const regDoc of registrationsSnap.docs) {
      await updateDoc(doc(registrationsCollectionRef, regDoc.id), {
        status: 'completed'
      });
    }
    
    return true;
  } catch (error) {
    console.error('Error checking out visitor:', error);
    throw error;
  }
};

/**
 * Calculate estimated wait time based on priority and current queue
 */
const calculateEstimatedWaitTime = async (priority: string) => {
  try {
    // Get number of people waiting in queue
    const waitingQuery = query(
      registrationsCollectionRef,
      where('status', '==', 'waiting')
    );
    
    const waitingSnap = await getDocs(waitingQuery);
    const waitingCount = waitingSnap.docs.length;
    
    // Base wait time is 5 minutes per person in queue
    let estimatedTime = waitingCount * 5;
    
    // Adjust based on priority
    if (priority === 'urgent') {
      estimatedTime = Math.max(estimatedTime - 10, 5); // Min 5 minutes
    } else if (priority === 'vip') {
      estimatedTime = Math.max(estimatedTime - 15, 3); // Min 3 minutes
    }
    
    return estimatedTime;
  } catch (error) {
    console.error('Error calculating wait time:', error);
    // Default to 15 minutes if calculation fails
    return 15;
  }
};

/**
 * Send notification to staff about visitor check-in
 * This is a placeholder - would need integration with notification service
 */
export const notifyStaffOfVisitorCheckIn = async (visitorName: string, purpose: string) => {
  // This would be implemented with a notification service like Firebase Cloud Messaging
  console.log(`Notification: ${visitorName} has checked in for ${purpose}`);
  return true;
};

/**
 * Subscribe to completed appointments for today (real-time updates)
 */
export const subscribeToCompletedAppointments = (callback: (count: number) => void) => {
  // Get today's date in YYYY-MM-DD format for appointmentDate field
  const today = new Date();
  const todayFormatted = `${today.getFullYear()}-${String(today.getMonth() + 1).padStart(2, '0')}-${String(today.getDate()).padStart(2, '0')}`;
  
  try {
    // Query appointments collection for completed appointments today
    const completedAppointmentsQuery = query(
      collection(db, 'appointments'),
      where('status', '==', 'completed'),
      where('appointmentDate', '==', todayFormatted)
    );
    
    return onSnapshot(completedAppointmentsQuery, (snapshot) => {
      const completedAppointmentsCount = snapshot.size;
      console.log(`Real-time update: Found ${completedAppointmentsCount} completed appointments for today`);
      callback(completedAppointmentsCount);
    }, (error) => {
      console.error('Error in completed appointments subscription:', error);
      callback(0);
    });
  } catch (error) {
    console.error('Error setting up completed appointments subscription:', error);
    callback(0);
    // Return a no-op function as unsubscribe
    return () => {};
  }
}; 