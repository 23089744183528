import { Timestamp } from 'firebase/firestore';
import { BankClient, AccountStatus } from '../types';

export const formatTimestamp = (timestamp: Timestamp | undefined): string => {
  if (!timestamp) return '';
  
  try {
    if (timestamp && typeof timestamp.toDate === 'function') {
      const date = timestamp.toDate();
      return new Intl.DateTimeFormat('en-US', {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit'
      }).format(date);
    }
    
    return 'Invalid date';
  } catch (error) {
    console.error('Error formatting date:', error);
    return 'Invalid date';
  }
};

export const calculateTimePeriod = (client: BankClient): { daysOpen: number, daysSinceLastUpdate: number } => {
  if (!client) {
    return { daysOpen: 0, daysSinceLastUpdate: 0 };
  }
  
  try {
    const now = new Date();
    let daysOpen = 0;
    let daysSinceLastUpdate = 0;
    
    if (client.createdAt && typeof client.createdAt.toDate === 'function') {
      const createdDate = client.createdAt.toDate();
      daysOpen = Math.floor((now.getTime() - createdDate.getTime()) / (1000 * 60 * 60 * 24));
    }
    
    if (client.updatedAt && typeof client.updatedAt.toDate === 'function') {
      const lastUpdated = client.updatedAt.toDate();
      daysSinceLastUpdate = Math.floor((now.getTime() - lastUpdated.getTime()) / (1000 * 60 * 60 * 24));
    }
    
    return { daysOpen, daysSinceLastUpdate };
  } catch (error) {
    console.error('Error calculating time period:', error);
    return { daysOpen: 0, daysSinceLastUpdate: 0 };
  }
};

export const formatStatus = (status: AccountStatus | undefined | null): string => {
  if (!status) return 'Unknown';
  
  return status
    .split('-')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

export const isManager = (): boolean => {
  // This is a placeholder implementation. Replace with actual logic.
  return true;
}; 