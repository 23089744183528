import React from 'react';
import { useLocation } from 'react-router-dom';

interface UnderConstructionProps {
  pageName?: string;
}

const UnderConstruction: React.FC<UnderConstructionProps> = ({ pageName }) => {
  const location = useLocation();
  const path = location.pathname.split('/').pop() || '';
  const formattedPath = path ? path.charAt(0).toUpperCase() + path.slice(1) : '';
  const pageTitle = pageName || formattedPath || 'Page';

  return (
    <div className="px-6 py-8 max-w-7xl mx-auto">
      <div className="flex flex-col items-center justify-center text-center py-12">
        <div className="mb-6">
          <svg className="w-20 h-20 text-yellow-500 mx-auto" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
          </svg>
        </div>
        <h1 className="text-3xl font-bold text-blue-900 mb-2">{pageTitle}</h1>
        <p className="text-xl text-gray-600 mb-8">This page is under construction</p>
        <div className="bg-yellow-50 border-l-4 border-yellow-400 p-4 max-w-lg">
          <div className="flex">
            <div className="flex-shrink-0">
              <svg className="h-5 w-5 text-yellow-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fillRule="evenodd" d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z" clipRule="evenodd" />
              </svg>
            </div>
            <div className="ml-3">
              <p className="text-sm text-yellow-700">
                We're working hard to bring you this feature soon. Please check back later.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UnderConstruction; 