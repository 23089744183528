import { getApp } from 'firebase/app';
import { getFirestore, enableNetwork, disableNetwork, collection, getDocs, limit, query } from 'firebase/firestore';
import { getAuth, signInAnonymously, signInWithEmailAndPassword } from 'firebase/auth';

/**
 * Firebase Connection Checker Utility
 * 
 * This utility helps diagnose connection issues with Firebase services.
 * It can be used to check if the Firebase project is properly connected,
 * if Firestore is accessible, and if the app has the proper permissions.
 */

// Debug mode for verbose logging
const DEBUG = true;

/**
 * Check if Firebase app is initialized
 */
export const checkFirebaseInitialization = (): boolean => {
  try {
    const app = getApp();
    console.log('✅ Firebase app is initialized:', app.name);
    return true;
  } catch (error) {
    console.error('❌ Firebase app is not initialized:', error);
    return false;
  }
};

/**
 * Test basic Firestore connectivity
 */
export const testFirestoreConnection = async (): Promise<boolean> => {
  try {
    const db = getFirestore();
    
    // First try to read a public collection
    console.log('🔍 Testing Firestore connection...');
    
    // Try to get a small number of documents from a public collection
    const servicesRef = collection(db, 'services');
    const q = query(servicesRef, limit(1));
    
    const querySnapshot = await getDocs(q);
    
    if (querySnapshot.empty) {
      console.log('⚠️ Connection successful but no documents found in services collection');
    } else {
      console.log('✅ Firestore connection successful, documents retrieved');
    }
    
    return true;
  } catch (error) {
    console.error('❌ Firestore connection failed:', error);
    return false;
  }
};

/**
 * Try to reset Firestore connection by disabling and re-enabling the network
 */
export const resetFirestoreConnection = async (): Promise<boolean> => {
  try {
    const db = getFirestore();
    
    console.log('🔄 Resetting Firestore connection...');
    
    // Disable network and then re-enable
    await disableNetwork(db);
    console.log('Network disabled for Firestore');
    
    // Short delay
    await new Promise(resolve => setTimeout(resolve, 1000));
    
    await enableNetwork(db);
    console.log('Network re-enabled for Firestore');
    
    return true;
  } catch (error) {
    console.error('❌ Failed to reset Firestore connection:', error);
    return false;
  }
};

/**
 * Try anonymous authentication to see if auth is working
 */
export const testAnonymousAuth = async (): Promise<boolean> => {
  try {
    // Skip auth test if already authenticated
    const currentUser = getAuth().currentUser;
    if (currentUser) {
      console.log('✅ Already authenticated as:', currentUser.email);
      return true;
    }

    // Don't try anonymous auth in production
    if (process.env.NODE_ENV === 'production') {
      console.log('ℹ️ Skipping anonymous auth test in production');
      return true;
    }

    console.log('ℹ️ Anonymous auth is disabled, this is expected');
    return true;

  } catch (error) {
    console.warn('⚠️ Auth test failed, but this may be expected:', error);
    // Return true since we expect anonymous auth to be disabled
    return true;
  }
};

/**
 * Perform a comprehensive check of Firebase services
 */
export const runFirebaseHealthCheck = async (): Promise<{
  initialized: boolean;
  firestoreConnection: boolean;
  authWorking: boolean;
  resetAttempted: boolean;
  overallStatus: 'healthy' | 'degraded' | 'offline';
}> => {
  const result = {
    initialized: false,
    firestoreConnection: false,
    authWorking: false,
    resetAttempted: false,
    overallStatus: 'offline' as 'healthy' | 'degraded' | 'offline'
  };
  
  // Check initialization
  result.initialized = checkFirebaseInitialization();
  
  if (!result.initialized) {
    return { ...result, overallStatus: 'offline' };
  }
  
  // Check Firestore connection
  result.firestoreConnection = await testFirestoreConnection();
  
  // If Firestore connection failed, try resetting
  if (!result.firestoreConnection) {
    result.resetAttempted = await resetFirestoreConnection();
    
    // Try connection again after reset
    if (result.resetAttempted) {
      result.firestoreConnection = await testFirestoreConnection();
    }
  }
  
  // Check auth regardless of Firestore status
  result.authWorking = await testAnonymousAuth();
  
  // Determine overall status
  if (result.firestoreConnection && result.authWorking) {
    result.overallStatus = 'healthy';
  } else if (result.initialized && (result.firestoreConnection || result.authWorking)) {
    result.overallStatus = 'degraded';
  } else {
    result.overallStatus = 'offline';
  }
  
  console.log('📊 Firebase Health Check Results:', result);
  
  return result;
};

/**
 * Call this at app startup to verify Firebase is working
 */
export const verifyFirebaseOnStartup = async (): Promise<void> => {
  console.log('🔎 Verifying Firebase connection on startup...');
  
  const healthStatus = await runFirebaseHealthCheck();
  
  if (healthStatus.overallStatus !== 'healthy') {
    console.warn(`⚠️ Firebase connection is ${healthStatus.overallStatus}. Some features may not work correctly.`);
    
    // If completely offline, show a warning in the console that's very visible
    if (healthStatus.overallStatus === 'offline') {
      console.error(`
      %c ⚠️ FIREBASE CONNECTION FAILED ⚠️ 
      %c The application cannot connect to Firebase services.
      Check your internet connection, Firebase project status,
      and API keys configuration.
      `, 
      'background: #FF5555; color: white; font-size: 14px; font-weight: bold; padding: 5px;', 
      'background: #FFEEEE; color: black; font-size: 12px; padding: 5px;');
    }
  } else {
    console.log('✅ Firebase connection verified successfully');
  }
}; 