import { useState, useCallback } from 'react';
import { ref, uploadBytesResumable, getDownloadURL, getStorage, StorageReference, UploadMetadata, getBlob } from 'firebase/storage';
import { storage } from '../firebase/config';

interface UploadState {
  isUploading: boolean;
  progress: number;
  error: Error | null;
  url: string | null;
  fileName: string | null;
  debugInfo: string | null;
}

interface UploadResult {
  success: boolean;
  url?: string;
  fileName?: string;
  error?: Error;
  fileType?: string;
  debugInfo?: string;
}

/**
 * Custom hook for handling file uploads to Firebase Storage with improved error handling
 * and debugging information to help diagnose upload issues.
 */
export const useFirebaseStorageUpload = () => {
  const [uploadState, setUploadState] = useState<UploadState>({
    isUploading: false,
    progress: 0,
    error: null,
    url: null,
    fileName: null,
    debugInfo: null
  });

  const uploadFile = useCallback(async (
    file: File, 
    path: string
  ): Promise<UploadResult> => {
    if (!file) {
      const error = new Error('No file provided');
      setUploadState(prev => ({
        ...prev,
        error,
        debugInfo: 'Upload failed: No file provided'
      }));
      return { 
        success: false, 
        error,
        debugInfo: 'No file provided'
      };
    }

    // Generate a unique filename preserving the original extension
    const timestamp = Date.now();
    const randomStr = Math.random().toString(36).substring(2, 8);
    const fileExtension = file.name.split('.').pop() || '';
    const originalNameWithoutExt = file.name.substring(0, file.name.lastIndexOf('.'));
    const sanitizedName = originalNameWithoutExt.replace(/[^a-zA-Z0-9]/g, '_').substring(0, 20);
    
    // Use a more predictable filename format: timestamp-randomString-sanitizedOriginalName.extension
    const fileName = `${timestamp}-${randomStr}-${sanitizedName}.${fileExtension}`;
    const fullPath = `${path}/${fileName}`;
    
    // Log detailed information about the upload
    console.log('Preparing upload with details:', {
      originalFileName: file.name,
      generatedFileName: fileName,
      fileType: file.type,
      fileSize: file.size,
      uploadPath: fullPath,
      timestamp: new Date().toISOString()
    });
    
    // Set initial upload state
    setUploadState({
      isUploading: true,
      progress: 0,
      error: null,
      url: null,
      fileName,
      debugInfo: `Starting upload of ${file.name} (${file.size} bytes) to path: ${fullPath}`
    });

    try {
      // Create storage reference with the full path
      const storageRef = ref(storage, fullPath);
      
      // Define metadata with CORS-friendly headers
      const metadata: UploadMetadata = {
        contentType: file.type,
        customMetadata: {
          'originalName': file.name,
          'uploadedAt': new Date().toISOString(),
          'source': 'hudumacenter-app',
          'fileSize': file.size.toString()
        },
        cacheControl: 'public, max-age=86400',
        contentDisposition: `attachment; filename="${encodeURIComponent(file.name)}"`,
      };

      console.log('Upload starting with metadata:', metadata);

      // Use uploadBytesResumable for better control and progress tracking
      const uploadTask = uploadBytesResumable(storageRef, file, metadata);
      
      // Return a promise that resolves when upload is complete
      return new Promise((resolve, reject) => {
        uploadTask.on('state_changed',
          // Progress handler
          (snapshot) => {
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            const state = snapshot.state;
            
            console.log(`Upload progress: ${progress.toFixed(2)}%, State: ${state}`);
            
            setUploadState(prev => ({
              ...prev,
              progress,
              debugInfo: `Upload in progress: ${progress.toFixed(2)}% complete. State: ${state}`
            }));
          },
          // Error handler
          (error: any) => {
            console.error('Upload error:', error);
            let errorMessage = 'Unknown upload error';
            let errorCode = 'unknown';
            
            // Check for specific Firebase Storage errors
            if (error && typeof error === 'object' && 'code' in error) {
              errorCode = error.code;
              
              // Map error codes to user-friendly messages
              switch(errorCode) {
                case 'storage/unauthorized':
                  errorMessage = 'You do not have permission to upload to this location.';
                  break;
                case 'storage/canceled':
                  errorMessage = 'Upload was canceled.';
                  break;
                case 'storage/unknown':
                  errorMessage = 'An unknown error occurred during upload.';
                  break;
                case 'storage/quota-exceeded':
                  errorMessage = 'Storage quota exceeded. Please contact support.';
                  break;
                case 'storage/invalid-checksum':
                  errorMessage = 'File integrity check failed. Please try again.';
                  break;
                case 'storage/retry-limit-exceeded':
                  errorMessage = 'Maximum retry limit exceeded. Please try again later.';
                  break;
                case 'storage/server-file-wrong-size':
                  errorMessage = 'File size mismatch. Please try again.';
                  break;
                case 'storage/cannot-slice-blob':
                  errorMessage = 'Error processing the file. Please try again with a different file.';
                  break;
                default:
                  errorMessage = `Upload failed: ${errorCode}`;
              }
            }
            
            const detailedError = new Error(errorMessage);
            const debugInfo = `Upload failed with error code: ${errorCode}. Path: ${fullPath}. Details: ${JSON.stringify(error)}`;
            
            setUploadState({
              isUploading: false,
              progress: 0,
              error: detailedError,
              url: null,
              fileName: null,
              debugInfo
            });
            
            reject({
              success: false,
              error: detailedError,
              debugInfo
            });
          },
          // Completion handler
          async () => {
            try {
              console.log('Upload completed, retrieving download URL...');
              
              // Get the download URL with a fresh token when upload completes
              const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
              
              console.log('Download URL retrieved successfully:', downloadURL);
              
              setUploadState({
                isUploading: false,
                progress: 100,
                error: null,
                url: downloadURL,
                fileName,
                debugInfo: `Upload completed successfully. File: ${fileName}, Size: ${file.size} bytes`
              });
              
              resolve({ 
                success: true, 
                url: downloadURL, 
                fileName,
                fileType: file.type,
                debugInfo: `Upload completed successfully. URL: ${downloadURL}`
              });
            } catch (error: any) {
              console.error('Error getting download URL:', error);
              
              let errorMessage = 'Failed to get download URL';
              if (error && typeof error === 'object') {
                if ('message' in error) {
                  errorMessage = error.message;
                } else if ('code' in error) {
                  errorMessage = `Error code: ${error.code}`;
                }
              }
              
              setUploadState({
                isUploading: false,
                progress: 0,
                error: new Error(errorMessage),
                url: null,
                fileName: null,
                debugInfo: `Upload succeeded but failed to get download URL: ${errorMessage}`
              });
              
              reject({
                success: false,
                error: new Error(errorMessage),
                debugInfo: `Upload succeeded but failed to get download URL: ${errorMessage}`
              });
            }
          }
        );
      });
    } catch (error: any) {
      console.error('Upload initialization error:', error);
      
      let errorMessage = 'Failed to initialize upload';
      if (error && typeof error === 'object' && 'message' in error) {
        errorMessage = error.message;
      }
      
      setUploadState({
        isUploading: false,
        progress: 0,
        error: new Error(errorMessage),
        url: null,
        fileName: null,
        debugInfo: `Failed to initialize upload: ${errorMessage}`
      });
      
      return { 
        success: false, 
        error: new Error(errorMessage),
        debugInfo: `Failed to initialize upload: ${errorMessage}`
      };
    }
  }, []);

  const resetUploadState = useCallback(() => {
    setUploadState({
      isUploading: false,
      progress: 0,
      error: null,
      url: null,
      fileName: null,
      debugInfo: null
    });
  }, []);

  return {
    ...uploadState,
    uploadFile,
    resetUploadState
  };
};

export default useFirebaseStorageUpload; 