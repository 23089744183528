import { db } from '../firebase/config';
import { collection, addDoc, serverTimestamp, updateDoc, doc, getDoc, getDocs, query, orderBy, arrayUnion } from 'firebase/firestore';
import { Timestamp } from 'firebase/firestore';

export interface HCAExamFormData {
  // Personal Information
  firstName: string;
  lastName: string;
  dateOfBirth: string;
  email: string;
  phoneNumber: string;
  
  // Address Information
  streetAddress: string;
  apartmentNumber?: string; // Optional apartment number
  city: string;
  state: string;
  zipCode: string;
  
  // Identification
  ssn: string;
  hasSSN: string;
  identgoOCANumber: string;
  
  // Terms Agreement
  agreeToTerms: boolean;
}

// Interface for application notes
export interface ApplicationNote {
  content: string;
  createdAt: any; // Firestore timestamp
  createdBy: string;
  createdByEmail?: string;
}

// Interface for application documents
export interface ApplicationDocument {
  fileName: string;
  fileURL: string;
  uploadedAt: any; // Firestore timestamp
  uploadedBy: string;
  uploadedByEmail?: string;
  fileType: string;
  description?: string;
}

export interface SavedHCAApplication extends Omit<HCAExamFormData, 'agreeToTerms'> {
  createdAt: any; // Firestore timestamp
  status: ApplicationStatus;
  userId: string;
  applicationId?: string;
  notes?: ApplicationNote[];
  documents?: ApplicationDocument[];
  completedBy?: string;
  completedByEmail?: string;
  completedAt?: any;
  rejectedBy?: string;
  rejectedByEmail?: string;
  rejectedAt?: any;
}

// Updated status types to include all required statuses
export type ApplicationStatus = 
  | 'pending' 
  | 'waiting-for-certificate' 
  | 'waiting-for-payment' 
  | 'certificate-expired' 
  | 'completed' 
  | 'rejected';

/**
 * Gets an HCA application from Firestore
 * @param applicationId The id of the application
 * @returns The application data or null if not found
 */
export const getHCAApplication = async (applicationId: string): Promise<SavedHCAApplication | null> => {
  try {
    const docRef = doc(db, 'hcaApplications', applicationId);
    const docSnap = await getDoc(docRef);
    
    if (docSnap.exists()) {
      return docSnap.data() as SavedHCAApplication;
    } else {
      console.log('No such document!');
      return null;
    }
  } catch (error) {
    console.error('Error getting HCA application:', error);
    throw error;
  }
};

/**
 * Saves an HCA application to Firestore
 * @param formData The form data to save
 * @param userId The user ID of the applicant (optional)
 * @returns The ID of the created document
 */
export const saveHCAApplication = async (
  formData: HCAExamFormData, 
  userId?: string
): Promise<string> => {
  let applicationId = '';
  
  try {
    console.log('Creating application document...');
    
    // Create a new document in the hcaApplications collection
    const docRef = await addDoc(collection(db, 'hcaApplications'), {
      firstName: formData.firstName,
      lastName: formData.lastName,
      dateOfBirth: formData.dateOfBirth,
      email: formData.email,
      phoneNumber: formData.phoneNumber,
      streetAddress: formData.streetAddress,
      apartmentNumber: formData.apartmentNumber || '',
      city: formData.city,
      state: formData.state,
      zipCode: formData.zipCode,
      ssn: formData.ssn,
      hasSSN: formData.hasSSN,
      identgoOCANumber: formData.identgoOCANumber,
      status: 'pending',
      createdAt: serverTimestamp(),
      userId: userId || 'anonymous',
      notes: [],
      documents: []
    });
    
    applicationId = docRef.id;
    console.log('Application saved with ID:', applicationId);
    
    // Update the document with its ID for easier reference
    await updateDoc(docRef, {
      applicationId: applicationId
    });
    
    return applicationId;
  } catch (error) {
    console.error('Error saving application:', error);
    
    throw error;
  }
};

/**
 * Fetches all HCA applications from Firestore
 * @returns Array of HCA applications
 */
export const getAllHCAApplications = async (): Promise<SavedHCAApplication[]> => {
  try {
    const applicationsRef = collection(db, 'hcaApplications');
    const q = query(applicationsRef, orderBy('createdAt', 'desc'));
    const querySnapshot = await getDocs(q);
    
    const applications: SavedHCAApplication[] = [];
    
    querySnapshot.forEach((doc) => {
      const data = doc.data() as SavedHCAApplication;
      applications.push({
        ...data,
        applicationId: doc.id
      });
    });
    
    return applications;
  } catch (error) {
    console.error('Error fetching HCA applications:', error);
    throw error;
  }
};

/**
 * Updates the status of an HCA application
 * @param applicationId The ID of the application to update
 * @param status The new status
 * @param staffName The name of the staff member making the change
 * @param staffEmail The email of the staff member making the change
 */
export const updateHCAApplicationStatus = async (
  applicationId: string, 
  status: ApplicationStatus,
  staffName?: string,
  staffEmail?: string
): Promise<void> => {
  try {
    const docRef = doc(db, 'hcaApplications', applicationId);
    const updateData: Record<string, any> = { status };
    
    // Add staff tracking information based on status
    if (status === 'completed') {
      updateData.completedBy = staffName || 'Unknown';
      updateData.completedAt = serverTimestamp();
      // Only add email if provided
      if (staffEmail) {
        updateData.completedByEmail = staffEmail;
      }
    } else if (status === 'rejected') {
      updateData.rejectedBy = staffName || 'Unknown';
      updateData.rejectedAt = serverTimestamp();
      // Only add email if provided
      if (staffEmail) {
        updateData.rejectedByEmail = staffEmail;
      }
    }
    
    await updateDoc(docRef, updateData);
    console.log(`Application ${applicationId} status updated to ${status}`);
  } catch (error) {
    console.error('Error updating HCA application status:', error);
    throw error;
  }
};

/**
 * Adds a note to an HCA application
 * @param applicationId The ID of the application to add the note to
 * @param content The note content
 * @param staffName The name of the staff member adding the note
 * @param staffEmail The email of the staff member adding the note
 */
export const addNoteToApplication = async (
  applicationId: string,
  content: string,
  staffName: string,
  staffEmail?: string
): Promise<void> => {
  try {
    const docRef = doc(db, 'hcaApplications', applicationId);
    
    const note: ApplicationNote = {
      content,
      createdAt: Timestamp.now(),
      createdBy: staffName,
      createdByEmail: staffEmail
    };
    
    await updateDoc(docRef, {
      notes: arrayUnion(note)
    });
    
    console.log(`Note added to application ${applicationId}`);
  } catch (error) {
    console.error('Error adding note to application:', error);
    throw error;
  }
};

/**
 * Adds a document reference to an HCA application
 * @param applicationId The ID of the application to add the document to
 * @param document The document information
 */
export const addDocumentToApplication = async (
  applicationId: string,
  document: ApplicationDocument
): Promise<void> => {
  try {
    const docRef = doc(db, 'hcaApplications', applicationId);
    
    await updateDoc(docRef, {
      documents: arrayUnion(document)
    });
    
    console.log(`Document added to application ${applicationId}`);
  } catch (error) {
    console.error('Error adding document to application:', error);
    throw error;
  }
}; 