import React from 'react';
import { Link } from 'react-router-dom';

interface ServiceCardProps {
  title: string;
  description: string;
  icon: React.ReactNode;
  linkTo: string;
  className?: string;
}

const ServiceCard: React.FC<ServiceCardProps> = ({
  title,
  description,
  icon,
  linkTo,
  className = '',
}) => {
  return (
    <div className={`bg-white rounded-lg shadow-sm border border-gray-100 p-6 transition-all hover:shadow-md ${className}`}>
      <div className="flex items-center mb-3">
        <div className="mr-3 flex-shrink-0">
          {icon}
        </div>
        <h3 className="text-primary font-bold text-lg uppercase tracking-wide">
          {title}
        </h3>
      </div>
      
      <p className="text-gray-600 mb-4">
        {description}
      </p>
      
      <Link 
        to={linkTo} 
        className="text-blue-600 font-medium hover:text-blue-800 inline-flex items-center transition-colors"
        aria-label={`Learn more about ${title}`}
      >
        LEARN MORE
        <svg 
          xmlns="http://www.w3.org/2000/svg" 
          className="h-4 w-4 ml-1" 
          fill="none" 
          viewBox="0 0 24 24" 
          stroke="currentColor"
        >
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
        </svg>
      </Link>
    </div>
  );
};

export default ServiceCard; 