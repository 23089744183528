import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { hasPermission, User } from '../services/userService';
import { db } from '../firebase/config';
import { collection, query, where, onSnapshot, Timestamp } from 'firebase/firestore';

interface NavigationItem {
  name: string;
  path: string;
  icon: JSX.Element;
  permission: string;
  badge?: number;
}

interface SidebarProps {
  className?: string;
  isCollapsed?: boolean;
  onToggle?: () => void;
}

const Sidebar: React.FC<SidebarProps> = ({ 
  className = '', 
  isCollapsed: externalIsCollapsed, 
  onToggle: externalOnToggle
}) => {
  const location = useLocation();
  const [internalIsCollapsed, setInternalIsCollapsed] = useState(false);
  const { currentUser } = useAuth();
  const [unreadMessages, setUnreadMessages] = useState(0);
  
  // Determine if we're using internal or external state for collapsed status
  const isControlled = externalIsCollapsed !== undefined;
  const isCollapsed = isControlled ? externalIsCollapsed : internalIsCollapsed;
  
  // Cast currentUser to User type if it exists
  const userWithRole = currentUser as (User | null);

  // Listen for unread messages
  useEffect(() => {
    if (!userWithRole?.uid) return;

    const messagesRef = collection(db, 'messages');
    const q = query(
      messagesRef,
      where('recipientId', '==', userWithRole.uid),
      where('read', '==', false)
    );

    const unsubscribe = onSnapshot(q, (snapshot) => {
      setUnreadMessages(snapshot.size);
    });

    return () => unsubscribe();
  }, [userWithRole?.uid]);

  // Debug log for user data
  useEffect(() => {
    if (userWithRole) {
      console.log('Current User:', {
        uid: userWithRole.uid,
        role: userWithRole.role,
        permissions: userWithRole.permissions,
        email: userWithRole.email
      });
    }
  }, [userWithRole]);

  const navigationItems: NavigationItem[] = [
    {
      name: 'Dashboard',
      path: '/dashboard',
      permission: 'view_dashboard',
      icon: (
        <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6" />
        </svg>
      )
    },
    {
      name: 'Visitor Registration',
      path: '/visitors/register',
      permission: 'view_visitor_registration',
      icon: (
        <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M18 9v3m0 0v3m0-3h3m-3 0h-3m-2-5a4 4 0 11-8 0 4 4 0 018 0zM3 20a6 6 0 0112 0v1H3v-1z" />
        </svg>
      )
    },
    {
      name: 'Visit Management',
      path: '/visits',
      permission: 'view_visit_management',
      icon: (
        <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2" />
        </svg>
      )
    },
    {
      name: 'HCA Applications',
      path: '/applications',
      permission: 'view_applications',
      icon: (
        <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
        </svg>
      )
    },
    {
      name: 'Appointments',
      path: '/appointments',
      permission: 'view_appointments',
      icon: (
        <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
        </svg>
      )
    },
    {
      name: 'Jobs',
      path: '/jobs',
      permission: 'view_jobs',
      icon: (
        <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M21 13.255A23.931 23.931 0 0112 15c-3.183 0-6.22-.62-9-1.745M16 6V4a2 2 0 00-2-2h-4a2 2 0 00-2 2v2m4 6h.01M5 20h14a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
        </svg>
      )
    },
    {
      name: 'Messages',
      path: '/messages',
      permission: 'view_messages',
      icon: (
        <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 10h.01M12 10h.01M16 10h.01M9 16H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-5l-5 5v-5z" />
        </svg>
      ),
      badge: unreadMessages
    },
    {
      name: 'Reports',
      path: '/reports',
      permission: 'view_reports',
      icon: (
        <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z" />
        </svg>
      )
    },
    {
      name: 'Users',
      path: '/users',
      permission: 'view_users',
      icon: (
        <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z" />
        </svg>
      )
    },
    {
      name: 'Bank Services',
      path: '/bank-services',
      permission: 'view_bank_services',
      icon: (
        <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 6l3 1m0 0l-3 9a5.002 5.002 0 006.001 0M6 7l3 9M6 7l6-2m6 2l3-1m-3 1l-3 9a5.002 5.002 0 006.001 0M18 7l3 9m-3-9l-6-2m0-2v2m0 16V5m0 16H9m3 0h3" />
        </svg>
      )
    }
  ];

  const toggleSidebar = () => {
    if (isControlled && externalOnToggle) {
      externalOnToggle();
    } else {
      setInternalIsCollapsed(!internalIsCollapsed);
    }
  };

  // Filter navigation items based on user permissions
  const filteredNavigationItems = navigationItems.filter(item => {
    if (!userWithRole) {
      console.log('No user data available');
      return false;
    }

    // Special case for 'equity' role - only show bank services
    if (userWithRole.role && userWithRole.role.toLowerCase() === 'equity') {
      return item.path === '/bank-services';
    }

    // Debug log for permission check
    const hasAccess = hasPermission(userWithRole, item.permission);
    console.log(`Checking permission for ${item.name}:`, {
      permission: item.permission,
      hasAccess,
      userRole: userWithRole.role,
      userPermissions: userWithRole.permissions,
      path: item.path
    });

    return hasAccess;
  });

  // Debug log for filtered items
  useEffect(() => {
    console.log('Filtered Navigation Items:', 
      filteredNavigationItems.map(item => ({
        name: item.name,
        path: item.path,
        permission: item.permission
      }))
    );
  }, [filteredNavigationItems]);

  return (
    <div 
      className={`
        bg-white shadow-sm transition-all duration-300
        ${isCollapsed ? 'w-14' : 'w-64'} 
        h-screen flex flex-col border-r border-gray-200
        ${className}
      `}
    >
      <div className="border-b border-gray-200 flex justify-center">
        <button 
          onClick={toggleSidebar}
          className="p-4 w-full text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-blue-500 flex justify-center"
          aria-label={isCollapsed ? "Expand sidebar" : "Collapse sidebar"}
        >
          <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            {isCollapsed ? (
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 5l7 7-7 7M5 5l7 7-7 7" />
            ) : (
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M11 19l-7-7 7-7m8 14l-7-7 7-7" />
            )}
          </svg>
          {!isCollapsed && <span className="ml-2 text-xs font-semibold text-gray-500">NAVIGATION</span>}
        </button>
      </div>
      
      <nav className="flex-1 overflow-y-auto py-4">
        <ul className={`space-y-1 ${isCollapsed ? 'px-1' : 'px-2'}`}>
          {filteredNavigationItems.map((item) => {
            const isActive = location.pathname === item.path;
            
            return (
              <li key={item.path}>
                <Link
                  to={item.path}
                  className={`
                    ${isCollapsed ? 'justify-center' : 'px-3'} 
                    flex items-center py-2 rounded-md transition-colors duration-150 
                    focus:outline-none focus:ring-2 focus:ring-blue-500
                    ${isActive ? 'bg-blue-50 text-blue-900' : 'text-gray-700 hover:bg-gray-100'}
                  `}
                  aria-current={isActive ? 'page' : undefined}
                  title={isCollapsed ? item.name : undefined}
                >
                  <div className="flex-shrink-0 relative">
                    {item.icon}
                    {item.badge && item.badge > 0 && (
                      <span className="absolute -top-1 -right-1 bg-red-500 text-white text-xs font-bold rounded-full w-5 h-5 flex items-center justify-center">
                        {item.badge > 99 ? '99+' : item.badge}
                      </span>
                    )}
                  </div>
                  {!isCollapsed && (
                    <span className="ml-3 truncate">{item.name}</span>
                  )}
                </Link>
              </li>
            );
          })}
        </ul>
      </nav>
    </div>
  );
};

export default Sidebar; 