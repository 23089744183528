import React from 'react';

interface Category {
  id: string;
  name: string;
}

interface ServiceCategoryNavProps {
  categories: Category[];
  activeCategory: string;
  onCategoryChange: (category: string) => void;
}

const ServiceCategoryNav: React.FC<ServiceCategoryNavProps> = ({
  categories,
  activeCategory,
  onCategoryChange
}) => {
  return (
    <div className="bg-white rounded-lg border border-gray-200 overflow-hidden">
      <div className="bg-blue-50 px-4 py-3 border-b border-gray-200">
        <h2 className="text-lg font-medium text-blue-900">Categories</h2>
      </div>
      <nav className="divide-y divide-gray-200">
        {categories.map((category) => (
          <button
            key={category.id}
            className={`w-full text-left px-4 py-3 hover:bg-gray-50 focus:outline-none focus:bg-gray-50 transition-colors ${
              activeCategory === category.id ? 'bg-blue-50 font-medium text-blue-700' : 'text-gray-700'
            }`}
            onClick={() => onCategoryChange(category.id)}
          >
            <div className="flex items-center justify-between">
              <span>{category.name}</span>
              {activeCategory === category.id && (
                <svg className="h-5 w-5 text-blue-500" fill="currentColor" viewBox="0 0 20 20">
                  <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" />
                </svg>
              )}
            </div>
          </button>
        ))}
      </nav>
    </div>
  );
};

export default ServiceCategoryNav; 