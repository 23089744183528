import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { doc, getDoc, updateDoc, Timestamp, collection, query, where, getDocs, addDoc, serverTimestamp } from 'firebase/firestore';
import { db } from '../firebase/config';
import { useAuth } from '../contexts/AuthContext';
import Card from '../components/ui/Card';
import Button from '../components/ui/Button';
import { getAllStaffMembers } from '../services/userService';
import AppointmentCompletionDialog from '../components/AppointmentCompletionDialog';

// Lucide icons for enhanced visual experience
import { 
  Calendar, 
  Mail, 
  Phone, 
  Briefcase, 
  Clock, 
  CheckCircle, 
  XCircle, 
  FileText, 
  Users, 
  ChevronLeft, 
  AlertCircle,
  RotateCcw,
  UserCheck,
  MessageCircle,
  Edit,
  Trash,
  Loader2,
  User
} from 'lucide-react';

// WhatsApp Icon Component
function WhatsAppIcon() {
  return (
    <svg 
      xmlns="http://www.w3.org/2000/svg" 
      width="20" 
      height="20" 
      viewBox="0 0 24 24" 
      fill="currentColor" 
      className="text-green-600"
    >
      <path d="M17.472 14.382c-.297-.149-1.758-.867-2.03-.967-.273-.099-.471-.148-.67.15-.197.297-.767.966-.94 1.164-.173.199-.347.223-.644.075-.297-.15-1.255-.463-2.39-1.475-.883-.788-1.48-1.761-1.653-2.059-.173-.297-.018-.458.13-.606.134-.133.298-.347.446-.52.149-.174.198-.298.298-.497.099-.198.05-.371-.025-.52-.075-.149-.669-1.612-.916-2.207-.242-.579-.487-.5-.669-.51-.173-.008-.371-.01-.57-.01-.198 0-.52.074-.792.372-.272.297-1.04 1.016-1.04 2.479 0 1.462 1.065 2.875 1.213 3.074.149.198 2.096 3.2 5.077 4.487.709.306 1.262.489 1.694.625.712.227 1.36.195 1.871.118.571-.085 1.758-.719 2.006-1.413.248-.694.248-1.289.173-1.413-.074-.124-.272-.198-.57-.347m-5.421 7.403h-.004a9.87 9.87 0 01-5.031-1.378l-.361-.214-3.741.982.998-3.648-.235-.374a9.86 9.86 0 01-1.51-5.26c.001-5.45 4.436-9.884 9.888-9.884 2.64 0 5.122 1.03 6.988 2.898a9.825 9.825 0 012.893 6.994c-.003 5.45-4.437 9.884-9.885 9.884m8.413-18.297A11.815 11.815 0 0012.05 0C5.495 0 .16 5.335.157 11.892c0 2.096.547 4.142 1.588 5.945L.057 24l6.305-1.654a11.882 11.882 0 005.683 1.448h.005c6.554 0 11.89-5.335 11.893-11.893a11.821 11.821 0 00-3.48-8.413z" />
    </svg>
  );
}

// Reusable StatusBadge component
interface StatusBadgeProps {
  status: AppointmentStatus;
}

const StatusBadge: React.FC<StatusBadgeProps> = ({ status }) => {
  // Define status config type
  type StatusConfigType = {
    [key in AppointmentStatus]: {
      color: string;
      icon: React.ReactNode;
    }
  };
  
  // Define color mapping and icons for each status
  const statusConfig: StatusConfigType = {
    'scheduled': {
      color: 'bg-blue-100 text-blue-800',
      icon: <Clock className="w-3 h-3" />
    },
    'assigned': {
      color: 'bg-purple-100 text-purple-800',
      icon: <UserCheck className="w-3 h-3" />
    },
    'in-progress': {
      color: 'bg-yellow-100 text-yellow-800',
      icon: <Loader2 className="w-3 h-3 animate-spin" />
    },
    'completed': {
      color: 'bg-green-100 text-green-800',
      icon: <CheckCircle className="w-3 h-3" />
    },
    'cancelled': {
      color: 'bg-red-100 text-red-800',
      icon: <XCircle className="w-3 h-3" />
    },
    'no-show': {
      color: 'bg-gray-100 text-gray-800',
      icon: <AlertCircle className="w-3 h-3" />
    }
  };

  return (
    <span className={`inline-flex items-center gap-1 px-2.5 py-1 rounded-full text-xs font-medium ${statusConfig[status].color}`}>
      {statusConfig[status].icon}
      {status === 'no-show' ? 'No Show' : status.charAt(0).toUpperCase() + status.slice(1)}
    </span>
  );
};

// Breadcrumb component
const Breadcrumb: React.FC = () => {
  return (
    <div className="mt-2">
      <nav className="flex text-sm text-gray-500" aria-label="Breadcrumb">
        <ol className="flex items-center space-x-1">
          <li>
            <Link to="/dashboard" className="hover:text-gray-700">Dashboard</Link>
          </li>
          <li className="flex items-center">
            <span className="mx-1">/</span>
            <Link to="/appointments" className="hover:text-gray-700">Appointments</Link>
          </li>
          <li className="flex items-center">
            <span className="mx-1">/</span>
            <span className="text-gray-900 font-medium">Details</span>
          </li>
        </ol>
      </nav>
    </div>
  );
};

// Type for the tabs
type TabType = 'details' | 'notes' | 'history';

// Define the appointment status type
type AppointmentStatus = 'scheduled' | 'assigned' | 'in-progress' | 'completed' | 'cancelled' | 'no-show';

// Define the Appointment interface
interface Appointment {
  id: string;
  clientName: string;
  clientEmail: string;
  clientPhone: string;
  service: string;
  appointmentDate: Timestamp | string; // Can be either Timestamp or string
  appointmentTime: string;
  status: AppointmentStatus;
  notes?: string;
  createdAt: Timestamp;
  assignedTo?: string;
  assignedToName?: string;
}

// Define interfaces for better type checking
interface Staff {
  id: string;
  name: string;
  available: boolean;
  email?: string;
  role?: string;
  department?: string;
}

const AppointmentDetailsPage: React.FC = () => {
  const { appointmentId } = useParams<{ appointmentId: string }>();
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  
  const [appointment, setAppointment] = useState<Appointment | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [activeTab, setActiveTab] = useState<TabType>('details');
  const [isUpdating, setIsUpdating] = useState(false);
  const [staffMembers, setStaffMembers] = useState<Staff[]>([]);
  const [selectedStaff, setSelectedStaff] = useState<string>('');
  const [assignStaffDialogOpen, setAssignStaffDialogOpen] = useState(false);
  const [appointmentNote, setAppointmentNote] = useState<string>('');
  const [appointmentNotes, setAppointmentNotes] = useState<{id: string; note: string; createdAt: Timestamp; createdBy: string; createdByName: string}[]>([]);
  const [appointmentHistory, setAppointmentHistory] = useState<{id: string; action: string; timestamp: Timestamp; performedBy: string; performedByName: string}[]>([]);
  const [loadingNotes, setLoadingNotes] = useState(false);
  const [loadingHistory, setLoadingHistory] = useState(false);
  const [completionDialogOpen, setCompletionDialogOpen] = useState(false);
  const [isCompleting, setIsCompleting] = useState(false);
  const [statusChangeConfirmOpen, setStatusChangeConfirmOpen] = useState(false);
  const [pendingStatus, setPendingStatus] = useState<'scheduled' | 'completed' | 'cancelled' | 'no-show' | 'in-progress' | null>(null);
  const [rescheduleDialogOpen, setRescheduleDialogOpen] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState<string | null>(null);
  const [isLoadingStaff, setIsLoadingStaff] = useState(false);
  const [newAppointmentDate, setNewAppointmentDate] = useState<string>('');
  const [newAppointmentTime, setNewAppointmentTime] = useState<string>('');
  const [isRescheduling, setIsRescheduling] = useState(false);
  
  // Fetch appointment data
  useEffect(() => {
    const fetchAppointment = async () => {
      if (!appointmentId) return;
      
      setLoading(true);
      try {
        const appointmentRef = doc(db, 'appointments', appointmentId);
        const appointmentSnap = await getDoc(appointmentRef);
        
        if (appointmentSnap.exists()) {
          const data = appointmentSnap.data();
          
          setAppointment({
            id: appointmentSnap.id,
            clientName: data.fullName,
            clientEmail: data.email,
            clientPhone: data.phoneNumber,
            service: data.service,
            appointmentDate: data.appointmentDate,
            appointmentTime: data.appointmentTime,
            status: data.status,
            createdAt: data.createdAt,
            notes: data.additionalNotes,
            assignedTo: data.assignedTo || data.assignedStaffId,
            assignedToName: data.assignedToName || data.assignedStaff
          });
          
          console.log('Appointment data loaded:', data);

          // Fetch notes when appointment data is loaded
          fetchAppointmentNotes(appointmentSnap.id);
          
          // Fetch history when appointment data is loaded
          fetchAppointmentHistory(appointmentSnap.id);
        } else {
          setError('Appointment not found');
        }
      } catch (error) {
        console.error('Error fetching appointment:', error);
        setError('Failed to load appointment');
      } finally {
        setLoading(false);
      }
    };
    
    fetchAppointment();
  }, [appointmentId]);
  
  // Fetch staff members
  useEffect(() => {
    const fetchStaffMembers = async () => {
      if (staffMembers.length === 0) {
        try {
          setIsLoadingStaff(true);
          const users = await getAllStaffMembers();
          console.log(`Retrieved ${users.length} staff members from database`);
          
          // Convert User objects to Staff interface
          const staffList: Staff[] = users.map(user => ({
            id: user.uid,
            name: user.displayName || user.email || 'Unknown',
            available: user.isActive || true,
            email: user.email,
            role: user.role,
            department: user.department
          }));
          
          setStaffMembers(staffList);
        } catch (error) {
          console.error('Error fetching staff members:', error);
          setError('Failed to fetch staff members. Please try again.');
        } finally {
          setIsLoadingStaff(false);
        }
      }
    };
    
    fetchStaffMembers();
  }, []);
  
  // Handle status update
  const handleUpdateStatus = async (newStatus: AppointmentStatus) => {
    if (!appointment) return;
    
    // Prevent status updates for completed appointments
    if (appointment.status === 'completed') {
      setError('Completed appointments cannot be modified.');
      return;
    }
    
    try {
      setIsUpdating(true);
      
      console.log(`Attempting to update appointment ${appointment.id} status to ${newStatus}`);
      console.log('Current user:', currentUser?.uid);
      
      const appointmentRef = doc(db, 'appointments', appointment.id);
      
      // Update appointment status
      await updateDoc(appointmentRef, {
        status: newStatus,
        lastUpdated: serverTimestamp()
      });
      
      try {
        // Add history entry - in a separate try/catch to ensure status is updated even if history fails
        await addDoc(collection(db, 'appointments', appointment.id, 'history'), {
          action: 'status-update',
          timestamp: serverTimestamp(),
          userId: currentUser?.uid || 'unknown',
          userName: currentUser?.displayName || currentUser?.email || 'Unknown User',
          details: `Status updated to ${newStatus}`
        });
      } catch (historyError) {
        console.error('Error updating appointment history (status was updated):', historyError);
      }
      
      // Update local state
      setAppointment({
        ...appointment,
        status: newStatus
      });
      
      setShowSuccessMessage(`Status updated to ${newStatus}`);
      
      // Clear success message after 3 seconds
      setTimeout(() => {
        setShowSuccessMessage(null);
      }, 3000);
      
    } catch (error) {
      console.error('Error updating status:', error);
      if (error instanceof Error && error.message.includes('permission')) {
        setError('You do not have permission to update this appointment. Please contact an administrator.');
      } else {
        setError('Failed to update status. Please try again.');
      }
    } finally {
      setIsUpdating(false);
    }
  };
  
  // Handle assign staff button click
  const handleAssignStaff = () => {
    if (staffMembers.length === 0) {
      // Call the existing function to fetch staff members
      const fetchStaff = async () => {
        try {
          const users = await getAllStaffMembers();
          // Map User objects to Staff objects
          const staffList: Staff[] = users.map(user => ({
            id: user.uid,
            name: user.displayName || user.email || 'Unknown',
            available: user.isActive || true,
            email: user.email,
            role: user.role,
            department: user.department
          }));
          setStaffMembers(staffList);
        } catch (error) {
          console.error('Error fetching staff members:', error);
          setError('Failed to fetch staff members. Please try again.');
        }
      };
      
      fetchStaff();
    }
    setAssignStaffDialogOpen(true);
  };
  
  // Handle staff assignment submission
  const handleSubmitAssignment = async (e: React.FormEvent) => {
    e.preventDefault();
    
    if (!selectedStaff || !appointment) return;
    
    // Prevent reassigning completed appointments
    if (appointment.status === 'completed') {
      setError('Completed appointments cannot be reassigned.');
      setAssignStaffDialogOpen(false);
      return;
    }
    
    try {
      setIsUpdating(true);
      
      // Find the staff member's name
      const staffMember = staffMembers.find(staff => staff.id === selectedStaff);
      if (!staffMember) {
        throw new Error('Selected staff member not found');
      }
      
      const staffName = staffMember.name;
      const appointmentRef = doc(db, 'appointments', appointment.id);
      
      // Update appointment with assigned staff
      await updateDoc(appointmentRef, {
        assignedTo: selectedStaff,
        assignedToName: staffName,
        status: 'assigned', // Update status to assigned
        lastUpdated: serverTimestamp()
      });
      
      // Add history entry
      await addDoc(collection(db, 'appointments', appointment.id, 'history'), {
        action: 'assign',
        timestamp: serverTimestamp(),
        userId: currentUser?.uid || 'unknown',
        userName: currentUser?.displayName || currentUser?.email || 'Unknown User',
        details: `Assigned to ${staffName}`
      });
      
      // Update local state
      setAppointment({
        ...appointment,
        assignedTo: selectedStaff,
        assignedToName: staffName,
        status: 'assigned'
      });
      
      setShowSuccessMessage(`Successfully assigned to ${staffName}`);
      setAssignStaffDialogOpen(false);
      
      // Clear success message after 3 seconds
      setTimeout(() => {
        setShowSuccessMessage(null);
      }, 3000);
      
    } catch (error) {
      console.error('Error assigning staff:', error);
      setError('Failed to assign staff. Please try again.');
    } finally {
      setIsUpdating(false);
      setSelectedStaff('');
    }
  };
  
  // Function to format date from string to display format
  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', { 
      year: 'numeric', 
      month: 'long', 
      day: 'numeric' 
    });
  };
  
  // Format timestamp function that can handle string or Timestamp
  const formatTimestamp = (timestamp: Timestamp | string | undefined) => {
    if (!timestamp) return 'N/A';
    
    try {
      let date: Date;
      
      if (typeof timestamp === 'string') {
        // Try to create a Date from string
        date = new Date(timestamp);
      } else {
        // Convert Firestore Timestamp to Date
        date = timestamp.toDate();
      }
      
      return date.toLocaleDateString('en-US', { 
        year: 'numeric', 
        month: 'long', 
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit'
      });
    } catch (error) {
      console.error('Error formatting timestamp:', error);
      return 'Invalid Date';
    }
  };
  
  // Fetch appointment notes
  const fetchAppointmentNotes = async (appointmentId: string) => {
    setLoadingNotes(true);
    try {
      const notesQuery = query(
        collection(db, 'appointmentNotes'),
        where('appointmentId', '==', appointmentId),
        where('deleted', '==', false)
      );
      
      const notesSnapshot = await getDocs(notesQuery);
      const notes = notesSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
      })) as {id: string; note: string; createdAt: Timestamp; createdBy: string; createdByName: string}[];
      
      // Sort notes by creation time (newest first)
      notes.sort((a, b) => b.createdAt.seconds - a.createdAt.seconds);
      
      setAppointmentNotes(notes);
    } catch (err) {
      console.error('Error fetching appointment notes:', err);
    } finally {
      setLoadingNotes(false);
    }
  };

  // Add a new note
  const handleAddNote = async () => {
    if (!currentUser || !appointment || !appointmentNote.trim()) {
      return;
    }
    
    try {
      const noteData = {
        appointmentId: appointment.id,
        note: appointmentNote.trim(),
        createdAt: Timestamp.now(),
        createdBy: currentUser.uid,
        createdByName: currentUser.displayName || 'Staff Member',
        deleted: false
      };
      
      const notesCollection = collection(db, 'appointmentNotes');
      const docRef = await addDoc(notesCollection, noteData);
      
      // Add to local state
      setAppointmentNotes(prev => [
        {
          id: docRef.id,
          note: noteData.note,
          createdAt: noteData.createdAt,
          createdBy: noteData.createdBy,
          createdByName: noteData.createdByName
        },
        ...prev
      ]);
      
      // Clear the input
      setAppointmentNote('');
      
      // Add history entry for note addition
      await addHistoryEntry('Added a note');
    } catch (err) {
      console.error('Error adding note:', err);
      setError('Failed to add note');
    }
  };

  // Fetch appointment history
  const fetchAppointmentHistory = async (appointmentId: string) => {
    setLoadingHistory(true);
    try {
      const historyQuery = query(
        collection(db, 'appointmentHistory'),
        where('appointmentId', '==', appointmentId)
      );
      
      const historySnapshot = await getDocs(historyQuery);
      const history = historySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
      })) as {id: string; action: string; timestamp: Timestamp; performedBy: string; performedByName: string}[];
      
      // Sort history entries by timestamp (newest first)
      history.sort((a, b) => b.timestamp.seconds - a.timestamp.seconds);
      
      setAppointmentHistory(history);
    } catch (err) {
      console.error('Error fetching appointment history:', err);
    } finally {
      setLoadingHistory(false);
    }
  };

  // Add history entry
  const addHistoryEntry = async (action: string) => {
    if (!currentUser || !appointment) {
      return;
    }
    
    try {
      const historyData = {
        appointmentId: appointment.id,
        action,
        timestamp: Timestamp.now(),
        performedBy: currentUser.uid,
        performedByName: currentUser.displayName || 'Staff Member'
      };
      
      const historyCollection = collection(db, 'appointmentHistory');
      const docRef = await addDoc(historyCollection, historyData);
      
      // Add to local state
      setAppointmentHistory(prev => [
        {
          id: docRef.id,
          action: historyData.action,
          timestamp: historyData.timestamp,
          performedBy: historyData.performedBy,
          performedByName: historyData.performedByName
        },
        ...prev
      ]);
    } catch (err) {
      console.error('Error adding history entry:', err);
    }
  };

  // View previous appointment
  const handleViewPreviousAppointment = (appointmentId: string) => {
    navigate(`/appointments/${appointmentId}`);
  };
  
  // Render tab content based on active tab
  const renderTabContent = () => {
    if (activeTab === 'details') {
      return (
        <div className="space-y-6">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div>
              <h3 className="text-base font-medium text-gray-900 mb-3">Appointment Information</h3>
              <table className="min-w-full divide-y divide-gray-200">
                <tbody className="divide-y divide-gray-200">
                  <tr>
                    <td className="py-3 text-sm font-medium text-gray-500">ID</td>
                    <td className="py-3 text-sm text-gray-900">{appointment?.id}</td>
                  </tr>
                  <tr>
                    <td className="py-3 text-sm font-medium text-gray-500">Created At</td>
                    <td className="py-3 text-sm text-gray-900">{appointment?.createdAt ? formatTimestamp(appointment.createdAt) : 'N/A'}</td>
                  </tr>
                  <tr>
                    <td className="py-3 text-sm font-medium text-gray-500">Service</td>
                    <td className="py-3 text-sm text-gray-900">{appointment?.service}</td>
                  </tr>
                  {appointment?.notes && (
                    <tr>
                      <td className="py-3 text-sm font-medium text-gray-500">Additional Notes</td>
                      <td className="py-3 text-sm text-gray-900">{appointment.notes}</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            
            <div>
              <h3 className="text-base font-medium text-gray-900 mb-3">Client Information</h3>
              <table className="min-w-full divide-y divide-gray-200">
                <tbody className="divide-y divide-gray-200">
                  <tr>
                    <td className="py-3 text-sm font-medium text-gray-500">Full Name</td>
                    <td className="py-3 text-sm text-gray-900">{appointment?.clientName}</td>
                  </tr>
                  <tr>
                    <td className="py-3 text-sm font-medium text-gray-500">Email</td>
                    <td className="py-3 text-sm text-gray-900">
                      <a href={`mailto:${appointment?.clientEmail}`} className="text-blue-600 hover:text-blue-800 hover:underline">
                        {appointment?.clientEmail}
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td className="py-3 text-sm font-medium text-gray-500">Phone</td>
                    <td className="py-3 text-sm text-gray-900 flex items-center space-x-2">
                      <Phone className="h-4 w-4 text-gray-500" />
                      {appointment && (
                        <div className="text-base text-gray-900 flex items-center space-x-2">
                          <span>{appointment.clientPhone}</span>
                          
                          {/* WhatsApp Link */}
                          <a 
                            href={`https://wa.me/${appointment.clientPhone.replace(/\D/g, '')}?text=${encodeURIComponent(`Hello! This is a reminder about your appointment at Huduma Center USA.`)}`}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="text-green-600 hover:text-green-800"
                            aria-label="Contact via WhatsApp"
                          >
                            <WhatsAppIcon />
                          </a>
                        </div>
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      );
    }
    
    if (activeTab === 'notes') {
      return (
        <div>
          {/* Notes form */}
          <div className="mb-6 bg-gray-50 p-5 rounded-lg border border-gray-200">
            <h3 className="text-base font-medium text-gray-900 mb-3">Add Note</h3>
            <textarea
              value={appointmentNote}
              onChange={(e) => setAppointmentNote(e.target.value)}
              placeholder="Type your note here..."
              className="w-full p-3 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
              rows={3}
            />
            <div className="mt-3 flex justify-end">
              <button
                onClick={handleAddNote}
                disabled={!appointmentNote.trim()}
                className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 disabled:opacity-50 disabled:cursor-not-allowed"
              >
                <MessageCircle className="h-4 w-4 mr-2" />
                Add Note
              </button>
            </div>
          </div>
    
          {/* Notes list */}
          <div>
            <h3 className="text-base font-medium text-gray-900 mb-3">Notes History</h3>
            {loadingNotes ? (
              <div className="flex justify-center p-6">
                <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-blue-900"></div>
              </div>
            ) : appointmentNotes.length === 0 ? (
              <div className="text-center py-8 bg-gray-50 rounded-lg border border-gray-200">
                <MessageCircle className="mx-auto h-10 w-10 text-gray-400" />
                <p className="mt-2 text-sm text-gray-500">No notes have been added to this appointment yet.</p>
              </div>
            ) : (
              <div className="space-y-4">
                {appointmentNotes.map((note) => (
                  <div key={note.id} className="bg-gray-50 p-4 rounded-lg border border-gray-200">
                    <div className="flex justify-between items-start mb-2">
                      <span className="text-sm font-medium text-gray-900">{note.createdByName || 'Staff Member'}</span>
                      <span className="text-xs text-gray-500">{formatTimestamp(note.createdAt)}</span>
                    </div>
                    <p className="text-sm text-gray-700 whitespace-pre-wrap">{note.note}</p>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      );
    }
    
    if (activeTab === 'history') {
      return (
        <div>
          <h3 className="text-base font-medium text-gray-900 mb-3">Appointment History</h3>
          {loadingHistory ? (
            <div className="flex justify-center p-6">
              <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-blue-900"></div>
            </div>
          ) : appointmentHistory.length === 0 ? (
            <div className="text-center py-8 bg-gray-50 rounded-lg border border-gray-200">
              <Clock className="mx-auto h-10 w-10 text-gray-400" />
              <p className="mt-2 text-sm text-gray-500">No history available for this appointment.</p>
            </div>
          ) : (
            <div className="relative border-l-2 border-gray-200 ml-3 pl-8 space-y-6">
              {appointmentHistory.map((history, index) => (
                <div key={history.id} className="relative">
                  {/* Timeline dot */}
                  <div className="absolute -left-11 mt-1.5 w-5 h-5 rounded-full bg-blue-100 border-2 border-blue-500 flex items-center justify-center">
                    <div className="w-2 h-2 rounded-full bg-blue-500"></div>
                  </div>
                  
                  <div className="bg-white p-4 rounded-lg border border-gray-200 shadow-sm">
                    <div className="flex justify-between items-start mb-2">
                      <span className="text-sm font-medium text-gray-900">{history.performedByName || 'System'}</span>
                      <span className="text-xs text-gray-500">{formatTimestamp(history.timestamp)}</span>
                    </div>
                    <p className="text-sm text-gray-700">{history.action}</p>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      );
    }
    
    return null;
  };
  
  // Handle completing appointment
  const handleCompleteAppointment = async () => {
    if (!appointmentId || !appointment) {
      setError('Cannot complete appointment: Missing required information');
      return;
    }
    
    try {
      // First verify if the user has permission to complete appointments
      // This will help catch permission issues before attempting to update in the completion dialog
      const testRef = doc(db, 'appointments', appointmentId);
      
      // We're not actually updating here, just logging to help debug
      console.log('Opening appointment completion dialog for:', appointment.clientName);
      console.log('Current user:', currentUser?.uid);
      
      // Open the completion dialog
      setCompletionDialogOpen(true);
    } catch (error) {
      console.error('Error preparing to complete appointment:', error);
      setError('You do not have permission to complete this appointment. Please contact an administrator.');
    }
  };
  
  // Handle completion success
  const handleCompletionSuccess = async () => {
    setCompletionDialogOpen(false);
    setShowSuccessMessage('Appointment completed successfully');
    
    try {
      // Reload the appointment to get updated info
      if (appointmentId) {
        const appointmentRef = doc(db, 'appointments', appointmentId);
        const appointmentSnap = await getDoc(appointmentRef);
        
        if (appointmentSnap.exists()) {
          const data = appointmentSnap.data();
          
          setAppointment({
            id: appointmentSnap.id,
            clientName: data.fullName,
            clientEmail: data.email,
            clientPhone: data.phoneNumber,
            service: data.service,
            appointmentDate: data.appointmentDate,
            appointmentTime: data.appointmentTime,
            status: data.status,
            createdAt: data.createdAt,
            notes: data.additionalNotes,
            assignedTo: data.assignedTo || data.assignedStaffId,
            assignedToName: data.assignedToName || data.assignedStaff
          });
          
          // Fetch notes when appointment data is loaded
          fetchAppointmentNotes(appointmentSnap.id);
          
          // Fetch history when appointment data is loaded
          fetchAppointmentHistory(appointmentSnap.id);
        }
      }
    } catch (err) {
      console.error('Error refreshing appointment data:', err);
      setError('Appointment was completed, but failed to refresh data. Please reload the page.');
    }
    
    // Clear success message after 3 seconds
    setTimeout(() => {
      setShowSuccessMessage(null);
    }, 3000);
  };
  
  // Handle dialog close
  const handleCompletionDialogClose = () => {
    setCompletionDialogOpen(false);
  };
  
  // Function to generate WhatsApp link
  const getWhatsAppLink = (phone: string, date: string, time: string) => {
    // Format phone number (remove any non-digit characters)
    const formattedPhone = phone.replace(/\D/g, '');
    // Construct message with appointment details
    const message = `Hello! This is a reminder about your appointment at Huduma Center USA on ${date} at ${time}.`;
    // Return WhatsApp link
    return `https://wa.me/${formattedPhone}?text=${encodeURIComponent(message)}`;
  };
  
  // Show success message with auto-dismiss
  const showSuccess = (message: string) => {
    setShowSuccessMessage(message);
    
    // Auto-dismiss after 3 seconds
    setTimeout(() => {
      setShowSuccessMessage(null);
    }, 3000);
  };

  // Handle status confirmation dialog
  const openStatusConfirmation = (status: 'scheduled' | 'completed' | 'cancelled' | 'no-show' | 'in-progress') => {
    setPendingStatus(status);
    setStatusChangeConfirmOpen(true);
  };
  
  // Confirm status change
  const confirmStatusChange = async () => {
    if (!pendingStatus) return;
    
    await handleUpdateStatus(pendingStatus);
    setStatusChangeConfirmOpen(false);
    setPendingStatus(null);
    
    // Show success message
    showSuccess(`Appointment status updated to ${pendingStatus}`);
  };
  
  // Cancel status change
  const cancelStatusChange = () => {
    setStatusChangeConfirmOpen(false);
    setPendingStatus(null);
  };
  
  // Handle reschedule dialog
  const openRescheduleDialog = () => {
    if (appointment) {
      // Initialize the new date and time with current values
      const currentDate = typeof appointment.appointmentDate === 'string' 
        ? appointment.appointmentDate 
        : appointment.appointmentDate instanceof Timestamp
          ? appointment.appointmentDate.toDate().toISOString().split('T')[0]
          : new Date().toISOString().split('T')[0];
      
      setNewAppointmentDate(currentDate);
      setNewAppointmentTime(appointment.appointmentTime);
    }
    setRescheduleDialogOpen(true);
  };
  
  // Close reschedule dialog
  const closeRescheduleDialog = () => {
    setRescheduleDialogOpen(false);
  };
  
  // Add the handleReschedule function
  const handleReschedule = async () => {
    if (!appointment) return;
    
    // Prevent rescheduling completed appointments
    if (appointment.status === 'completed') {
      setError('Completed appointments cannot be rescheduled.');
      closeRescheduleDialog();
      return;
    }
    
    if (!newAppointmentDate || !newAppointmentTime) {
      return;
    }
    
    try {
      setIsRescheduling(true);
      
      const appointmentRef = doc(db, 'appointments', appointment.id);
      
      // Update appointment with new date and time
      await updateDoc(appointmentRef, {
        appointmentDate: newAppointmentDate,
        appointmentTime: newAppointmentTime,
        lastUpdated: serverTimestamp()
      });
      
      // Add history entry
      await addDoc(collection(db, 'appointments', appointment.id, 'history'), {
        action: 'reschedule',
        timestamp: serverTimestamp(),
        userId: currentUser?.uid || 'unknown',
        userName: currentUser?.displayName || currentUser?.email || 'Unknown User',
        details: `Rescheduled to ${formatDate(newAppointmentDate)} at ${newAppointmentTime}`
      });
      
      // Update local state
      setAppointment({
        ...appointment,
        appointmentDate: newAppointmentDate,
        appointmentTime: newAppointmentTime
      });
      
      setShowSuccessMessage(`Successfully rescheduled to ${formatDate(newAppointmentDate)} at ${newAppointmentTime}`);
      closeRescheduleDialog();
      
      // Clear success message after 3 seconds
      setTimeout(() => {
        setShowSuccessMessage(null);
      }, 3000);
      
    } catch (error) {
      console.error('Error rescheduling appointment:', error);
      setError('Failed to reschedule appointment. Please try again.');
    } finally {
      setIsRescheduling(false);
    }
  };
  
  // Add this new function for the staff assignment dialog
  const renderStaffAssignmentDialog = () => {
    if (!assignStaffDialogOpen) return null;
    
    return (
      <div className="fixed inset-0 bg-black/50 z-50 flex items-center justify-center p-4">
        <div className="bg-white rounded-lg shadow-xl max-w-md w-full p-6">
          <h3 className="text-lg font-semibold text-gray-900 mb-4">Assign Staff Member</h3>
          
          {staffMembers.length === 0 ? (
            <p className="text-gray-600 mb-6">No staff members available. Please add staff members first.</p>
          ) : (
            <>
              <div className="mb-6">
                <label htmlFor="staff-select" className="block text-sm font-medium text-gray-700 mb-2">
                  Select Staff Member
                </label>
                <select
                  id="staff-select"
                  value={selectedStaff}
                  onChange={(e) => setSelectedStaff(e.target.value)}
                  className="block w-full border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                >
                  <option value="">Select staff member</option>
                  {staffMembers
                    .filter(staff => staff.available)
                    .map(staff => (
                      <option key={staff.id} value={staff.id}>
                        {staff.name} ({staff.role})
                      </option>
                    ))}
                </select>
              </div>
              
              <p className="text-sm text-gray-500 mb-6">
                The selected staff member will be assigned to this appointment and will be notified.
              </p>
            </>
          )}
          
          <div className="flex justify-end space-x-3">
            <button 
              onClick={() => setAssignStaffDialogOpen(false)}
              className="px-4 py-2 bg-gray-100 text-gray-700 rounded-md hover:bg-gray-200 transition-colors"
            >
              Cancel
            </button>
            <button 
              onClick={handleSubmitAssignment}
              disabled={!selectedStaff || isUpdating}
              className={`px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition-colors ${
                !selectedStaff || isUpdating ? "opacity-50 cursor-not-allowed" : ""
              }`}
            >
              {isUpdating ? (
                <>
                  <span className="inline-block mr-2 w-4 h-4 border-2 border-white border-t-transparent rounded-full animate-spin"></span>
                  Assigning...
                </>
              ) : (
                "Assign"
              )}
            </button>
          </div>
        </div>
      </div>
    );
  };
  
  if (loading) {
    return (
      <div className="max-w-7xl mx-auto px-4 py-8">
        <div className="flex flex-col items-center justify-center min-h-[400px]">
          <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-blue-900"></div>
          <p className="mt-4 text-gray-600">Loading appointment details...</p>
        </div>
      </div>
    );
  }
  
  if (error || !appointment) {
    return (
      <div className="max-w-7xl mx-auto px-4 py-8">
        <Card>
          <div className="p-6 text-center">
            <AlertCircle className="mx-auto h-12 w-12 text-red-500" />
            <h3 className="mt-4 text-lg font-medium text-gray-900">Error</h3>
            <p className="mt-2 text-sm text-gray-500">{error || 'Appointment not found'}</p>
            <div className="mt-6">
              <Button
                href="/appointments"
                variant="primary"
                className="bg-blue-600 hover:bg-blue-700"
              >
                Back to Appointments
              </Button>
            </div>
          </div>
        </Card>
      </div>
    );
  }
  
  return (
    <div className="container mx-auto px-4 py-6 max-w-7xl">
      {/* Success Message Toast */}
      {showSuccessMessage && (
        <div className="fixed top-5 right-5 bg-green-100 border border-green-300 text-green-800 px-4 py-3 rounded-md shadow-md z-50 flex items-center">
          <CheckCircle className="w-5 h-5 mr-2" />
          <span>{showSuccessMessage}</span>
        </div>
      )}
    
      {/* Header and Breadcrumb */}
      <div className="mb-6">
        <div className="flex justify-between items-center">
          <div className="flex items-center">
            <Link to="/appointments" className="mr-3 p-2 rounded-full bg-gray-100 hover:bg-gray-200 text-gray-600 transition-colors">
              <ChevronLeft className="h-5 w-5" />
            </Link>
            <h1 className="text-2xl font-bold text-gray-900">Appointment Details</h1>
          </div>
          
          <a
            href={`mailto:${appointment.clientEmail}`}
            className="inline-flex items-center px-4 py-2 rounded-md bg-blue-50 text-blue-700 hover:bg-blue-100 transition-colors"
            aria-label="Send email to client"
          >
            <Mail className="h-4 w-4 mr-2" />
            Email Client
          </a>
        </div>
        
        <Breadcrumb />
      </div>
      
      {/* Main Content */}
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-6 mb-6">
        {/* Left Column - Client Information */}
        <div className="lg:col-span-1">
          <Card className="h-full overflow-visible">
            <div className="border-b border-gray-200 bg-gray-50 px-6 py-4 rounded-t-lg">
              <h2 className="text-lg font-semibold text-gray-900 flex items-center">
                <User className="h-5 w-5 mr-2 text-blue-600" />
                Client Information
              </h2>
              <p className="text-sm text-gray-500">Personal details of the client</p>
            </div>
            
            <div className="p-6 space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-500 mb-1">Full Name</label>
                <div className="text-base font-medium text-gray-900">{appointment.clientName}</div>
              </div>
              
              <div>
                <label className="block text-sm font-medium text-gray-500 mb-1">Email Address</label>
                <div className="text-base text-gray-900 flex items-center">
                  <Mail className="h-4 w-4 mr-2 text-gray-500" />
                  <a href={`mailto:${appointment.clientEmail}`} className="text-blue-600 hover:text-blue-800 hover:underline">
                    {appointment.clientEmail}
                  </a>
                </div>
              </div>
              
              <div>
                <label className="block text-sm font-medium text-gray-500 mb-1">Phone number</label>
                <div className="text-base text-gray-900 flex items-center space-x-2">
                  <Phone className="h-4 w-4 text-gray-500" />
                  {appointment && (
                    <div className="text-base text-gray-900 flex items-center space-x-2">
                      <span>{appointment.clientPhone}</span>
                      
                      {/* WhatsApp Link */}
                      <a 
                        href={`https://wa.me/${appointment.clientPhone.replace(/\D/g, '')}?text=${encodeURIComponent(`Hello! This is a reminder about your appointment at Huduma Center USA.`)}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-green-600 hover:text-green-800"
                        aria-label="Contact via WhatsApp"
                      >
                        <WhatsAppIcon />
                      </a>
                    </div>
                  )}
                </div>
              </div>
              
              <div>
                <label className="block text-sm font-medium text-gray-500 mb-1">Service</label>
                <div className="text-base text-gray-900 flex items-center">
                  <Briefcase className="h-4 w-4 mr-2 text-gray-500" />
                  {appointment.service}
                </div>
              </div>
              
              {appointment.notes && (
                <div>
                  <label className="block text-sm font-medium text-gray-500 mb-1">Additional Notes</label>
                  <div className="text-sm text-gray-900 bg-gray-50 p-3 rounded-md border border-gray-200">
                    {appointment.notes}
                  </div>
                </div>
              )}
            </div>
          </Card>
        </div>
        
        {/* Right Column - Appointment Details and Actions */}
        <div className="lg:col-span-2">
          <Card className="h-full overflow-visible">
            <div className="border-b border-gray-200 bg-gray-50 px-6 py-4 rounded-t-lg">
              <h2 className="text-lg font-semibold text-gray-900 flex items-center">
                <Calendar className="h-5 w-5 mr-2 text-blue-600" />
                Appointment Details
              </h2>
              <p className="text-sm text-gray-500">Date, time, and status information</p>
            </div>
            
            <div className="p-6 space-y-6">
              {/* Date & Time */}
              <div className="bg-blue-50 rounded-lg p-4 flex items-start">
                <div className="rounded-full bg-blue-100 p-3 mr-4">
                  <Calendar className="h-6 w-6 text-blue-600" />
                </div>
                <div>
                  <h3 className="text-sm font-medium text-gray-500">Date & Time</h3>
                  <p className="text-lg font-semibold text-gray-900">
                    {formatDate(typeof appointment.appointmentDate === 'string' 
                      ? appointment.appointmentDate 
                      : appointment.appointmentDate instanceof Timestamp
                        ? appointment.appointmentDate.toDate().toISOString().split('T')[0]
                        : new Date().toISOString().split('T')[0]
                    )} at {appointment.appointmentTime}
                  </p>
                  <button 
                    onClick={openRescheduleDialog}
                    className="mt-2 text-sm text-blue-600 hover:text-blue-800 inline-flex items-center"
                  >
                    <Edit className="h-3 w-3 mr-1" />
                    Reschedule
                  </button>
                </div>
              </div>
              
              {/* Status */}
              <div>
                <label className="block text-sm font-medium text-gray-500 mb-3">Current Status</label>
                <div className="mb-4">
                  <StatusBadge status={appointment.status} />
                </div>
                
                {/* Update Status */}
                <div>
                  <label className="block text-sm font-medium text-gray-500 mb-3">Update Status</label>
                  <div className="p-4 rounded-lg border border-gray-200">
                    <div className="grid grid-cols-5 gap-3">
                      <button
                        onClick={() => openStatusConfirmation('scheduled')}
                        className={`flex flex-col items-center justify-center p-3 border rounded-md ${
                          appointment.status === 'scheduled'
                            ? 'bg-gray-100 border-gray-300 text-gray-700'
                            : 'bg-white border-gray-200 hover:bg-gray-50 text-gray-700'
                        }`}
                        aria-label="Mark as Scheduled"
                        disabled={appointment.status === 'completed'}
                      >
                        <Calendar className={`h-5 w-5 mb-1 ${appointment.status === 'scheduled' ? 'text-gray-600' : 'text-gray-500'}`} />
                        <span className="text-xs font-medium">Scheduled</span>
                      </button>
                      
                      <button
                        onClick={() => openStatusConfirmation('in-progress')}
                        className={`flex flex-col items-center justify-center p-3 border rounded-md ${
                          appointment.status === 'in-progress'
                            ? 'bg-blue-100 border-blue-300 text-blue-700'
                            : 'bg-white border-gray-200 hover:bg-gray-50 text-gray-700'
                        }`}
                        aria-label="Mark as In Progress"
                        disabled={appointment.status === 'completed'}
                      >
                        <Clock className={`h-5 w-5 mb-1 ${appointment.status === 'in-progress' ? 'text-blue-600' : 'text-gray-500'}`} />
                        <span className="text-xs font-medium">In Progress</span>
                      </button>
                      
                      <button
                        onClick={() => openStatusConfirmation('completed')}
                        className={`flex flex-col items-center justify-center p-3 border rounded-md ${
                          appointment.status === 'completed'
                            ? 'bg-green-100 border-green-300 text-green-700'
                            : 'bg-white border-gray-200 hover:bg-gray-50 text-gray-700'
                        }`}
                        aria-label="Mark as Completed"
                      >
                        <CheckCircle className={`h-5 w-5 mb-1 ${appointment.status === 'completed' ? 'text-green-600' : 'text-gray-500'}`} />
                        <span className="text-xs font-medium">Completed</span>
                      </button>
                      
                      <button
                        onClick={() => openStatusConfirmation('cancelled')}
                        className={`flex flex-col items-center justify-center p-3 border rounded-md ${
                          appointment.status === 'cancelled'
                            ? 'bg-gray-100 border-gray-300 text-gray-700'
                            : 'bg-white border-gray-200 hover:bg-gray-50 text-gray-700'
                        }`}
                        aria-label="Mark as Cancelled"
                        disabled={appointment.status === 'completed'}
                      >
                        <XCircle className={`h-5 w-5 mb-1 ${appointment.status === 'cancelled' ? 'text-gray-600' : 'text-gray-500'}`} />
                        <span className="text-xs font-medium">Cancelled</span>
                      </button>
                      
                      <button
                        onClick={() => openStatusConfirmation('no-show')}
                        className={`flex flex-col items-center justify-center p-3 border rounded-md ${
                          appointment.status === 'no-show'
                            ? 'bg-red-100 border-red-300 text-red-700'
                            : 'bg-white border-gray-200 hover:bg-gray-50 text-gray-700'
                        }`}
                        aria-label="Mark as No Show"
                        disabled={appointment.status === 'completed'}
                      >
                        <AlertCircle className={`h-5 w-5 mb-1 ${appointment.status === 'no-show' ? 'text-red-600' : 'text-gray-500'}`} />
                        <span className="text-xs font-medium">No Show</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              
              {/* Staff Assignment */}
              <div>
                <label className="block text-sm font-medium text-gray-500 mb-3">Assigned Staff</label>
                <div className="flex items-center bg-gray-50 p-4 rounded-lg border border-gray-200">
                  <div className="rounded-full bg-gray-200 p-2 mr-3">
                    <UserCheck className="h-5 w-5 text-gray-600" />
                  </div>
                  
                  <div className="flex-1">
                    {appointment.assignedTo ? (
                      <div>
                        <div className="font-medium text-gray-900">{appointment.assignedToName}</div>
                        <div className="text-xs text-gray-500">
                          {appointment.assignedTo ? 
                            `Assigned on ${formatTimestamp(new Date().toISOString())}` : 
                            'Not yet assigned'
                          }
                        </div>
                      </div>
                    ) : (
                      <div className="text-gray-500">No staff member assigned</div>
                    )}
                  </div>
                  
                  {appointment.status !== 'completed' && (
                    <button
                      onClick={handleAssignStaff}
                      className="ml-2 px-3 py-1.5 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition-colors text-sm flex items-center"
                    >
                      {appointment.assignedTo ? 'Reassign' : 'Assign'}
                    </button>
                  )}
                </div>
              </div>
              
              <div className="flex flex-wrap justify-center sm:justify-end pt-4 gap-4">
                {appointment.status !== 'completed' && (
                  <button
                    onClick={openRescheduleDialog}
                    className="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md bg-white text-gray-700 hover:bg-gray-50"
                  >
                    <RotateCcw className="h-4 w-4 mr-2" />
                    Reschedule
                  </button>
                )}
                
                {appointment.status !== 'completed' && (
                  <button
                    onClick={handleCompleteAppointment}
                    className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-green-600 hover:bg-green-700"
                  >
                    <CheckCircle className="h-4 w-4 mr-2" />
                    Complete Appointment
                  </button>
                )}
              </div>
            </div>
          </Card>
        </div>
      </div>

      {/* Tabs content - Keep existing tabs functionality */}
      <div className="mb-6">
        <div className="border-b border-gray-200">
          <nav className="-mb-px flex space-x-8">
            <button
              onClick={() => setActiveTab('details')}
              className={`${
                activeTab === 'details'
                  ? 'border-blue-600 text-blue-600'
                  : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
              } whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm flex items-center`}
              aria-label="View appointment details"
            >
              <FileText className="h-4 w-4 mr-2" />
              Details
            </button>
            
            <button
              onClick={() => setActiveTab('notes')}
              className={`${
                activeTab === 'notes'
                  ? 'border-blue-600 text-blue-600'
                  : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
              } whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm flex items-center`}
              aria-label="View appointment notes"
            >
              <MessageCircle className="h-4 w-4 mr-2" />
              Notes
            </button>
            
            <button
              onClick={() => setActiveTab('history')}
              className={`${
                activeTab === 'history'
                  ? 'border-blue-600 text-blue-600'
                  : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
              } whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm flex items-center`}
              aria-label="View appointment history"
            >
              <Clock className="h-4 w-4 mr-2" />
              History
            </button>
          </nav>
        </div>
      </div>

      {/* Tab Content */}
      <Card className="mb-6">
        <div className="p-6">
          {renderTabContent()}
        </div>
      </Card>

      {/* Staff Assignment Dialog */}
      {renderStaffAssignmentDialog()}

      {/* Status Change Confirmation Dialog */}
      {statusChangeConfirmOpen && pendingStatus && (
        <div className="fixed inset-0 bg-black/50 z-50 flex items-center justify-center p-4">
          <div className="bg-white rounded-lg shadow-xl max-w-md w-full p-6">
            <h3 className="text-lg font-semibold text-gray-900 mb-4">Confirm Status Change</h3>
            <p className="text-gray-600 mb-6">
              Are you sure you want to mark this appointment as <strong>{pendingStatus}</strong>?
            </p>
            <div className="flex justify-end space-x-3">
              <button 
                onClick={cancelStatusChange}
                className="px-4 py-2 bg-gray-100 text-gray-700 rounded-md hover:bg-gray-200 transition-colors"
              >
                Cancel
              </button>
              <button 
                onClick={confirmStatusChange}
                className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition-colors"
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Appointment Completion Dialog */}
      {appointment && (
        <AppointmentCompletionDialog
          appointmentId={appointment.id}
          clientName={appointment.clientName}
          isOpen={completionDialogOpen}
          onClose={handleCompletionDialogClose}
          onSuccess={handleCompletionSuccess}
        />
      )}
      
      {/* Reschedule Dialog */}
      {rescheduleDialogOpen && (
        <div className="fixed inset-0 bg-black/50 z-50 flex items-center justify-center p-4">
          <div className="bg-white rounded-lg shadow-xl max-w-md w-full p-6">
            <h3 className="text-lg font-semibold text-gray-900 mb-4">Reschedule Appointment</h3>
            
            <div className="space-y-4">
              <div>
                <label htmlFor="appointment-date" className="block text-sm font-medium text-gray-700 mb-1">
                  New Date
                </label>
                <input
                  type="date"
                  id="appointment-date"
                  value={newAppointmentDate}
                  onChange={(e) => setNewAppointmentDate(e.target.value)}
                  min={new Date().toISOString().split('T')[0]}
                  className="block w-full border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                  required
                />
              </div>
              
              <div>
                <label htmlFor="appointment-time" className="block text-sm font-medium text-gray-700 mb-1">
                  New Time
                </label>
                <input
                  type="time"
                  id="appointment-time"
                  value={newAppointmentTime}
                  onChange={(e) => setNewAppointmentTime(e.target.value)}
                  className="block w-full border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                  required
                />
              </div>
              
              <div className="bg-gray-50 p-4 rounded-md border border-gray-200 text-sm text-gray-600">
                <p>Current appointment: {formatDate(typeof appointment.appointmentDate === 'string' 
                  ? appointment.appointmentDate 
                  : appointment.appointmentDate instanceof Timestamp
                    ? appointment.appointmentDate.toDate().toISOString().split('T')[0]
                    : new Date().toISOString().split('T')[0]
                )} at {appointment.appointmentTime}</p>
              </div>
            </div>
            
            <div className="flex justify-end space-x-3 mt-6">
              <button 
                onClick={closeRescheduleDialog}
                className="px-4 py-2 bg-gray-100 text-gray-700 rounded-md hover:bg-gray-200 transition-colors"
              >
                Cancel
              </button>
              <button 
                onClick={handleReschedule}
                disabled={!newAppointmentDate || !newAppointmentTime || isRescheduling}
                className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
              >
                {isRescheduling ? (
                  <>
                    <span className="inline-block mr-2 w-4 h-4 border-2 border-white border-t-transparent rounded-full animate-spin"></span>
                    Rescheduling...
                  </>
                ) : (
                  "Reschedule"
                )}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AppointmentDetailsPage; 