import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
  apiKey: "AIzaSyABEjoi5cmOWvBMh137TxpVsLqXVIycZkM",
  authDomain: "huduma-portal-5425b.firebaseapp.com",
  projectId: "huduma-portal-5425b",
  storageBucket: "huduma-portal-5425b.appspot.com",
  messagingSenderId: "256786349949",
  appId: "1:256786349949:web:201a2b39bdd0e113c61614",
  measurementId: "G-7EE7E8NVSZ"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);

// Initialize Firebase services
export const auth = getAuth(app);
export const db = getFirestore(app);
export const storage = getStorage(app);

// Only initialize analytics in browser environment
let analytics = null;
if (typeof window !== 'undefined') {
  analytics = getAnalytics(app);
}

// Configure storage CORS settings
const storageSettings = {
  cors: {
    origin: ['*', 'https://hudumacenter.com', 'https://www.hudumacenter.com', 'http://localhost:3000'],
    methods: ['GET', 'PUT', 'POST', 'DELETE', 'HEAD', 'OPTIONS'],
    allowedHeaders: ['Content-Type', 'Authorization', 'X-Requested-With', 'Accept', 'Origin'],
    exposedHeaders: ['ETag', 'Cache-Control', 'Content-Disposition', 'Content-Length', 'Content-Range'],
    maxAge: 3600
  }
};

// Default storage metadata for uploads
const defaultStorageMetadata = {
  cacheControl: 'public, max-age=86400',
  contentDisposition: 'inline',
  customMetadata: {
    'origin': 'hudumacenter.com'
  }
};

export { analytics, storageSettings, defaultStorageMetadata };