import React, { useState, useEffect } from 'react';
import { useAuth } from '../contexts/AuthContext';
import DashboardMetrics from '../components/DashboardMetrics';
import { 
  AppointmentSummary, 
  subscribeToUpcomingAppointments
} from '../services/dashboardService';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../firebase/config';
import UpcomingAppointmentsCard from '../components/Dashboard/UpcomingAppointmentsCard';

// Dashboard component with real-time data
const Dashboard = () => {
  const { currentUser } = useAuth();
  const [isLoading, setIsLoading] = useState(true);
  const [loadingError, setLoadingError] = useState<string | null>(null);
  const [upcomingAppointments, setUpcomingAppointments] = useState<AppointmentSummary[]>([]);
  const [userInfo, setUserInfo] = useState<any>(null);
  
  // Current date formatted
  const today = new Date().toLocaleDateString('en-US', {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  });

  // Fetch user info
  useEffect(() => {
    const fetchUserInfo = async () => {
      if (!currentUser) return;
      
      try {
        const userDoc = await getDoc(doc(db, 'users', currentUser.uid));
        if (userDoc.exists()) {
          setUserInfo(userDoc.data());
        }
      } catch (error) {
        console.error('Error fetching user info:', error);
      }
    };
    
    fetchUserInfo();
  }, [currentUser]);

  // Subscribe to real-time updates for appointments
  useEffect(() => {
    setIsLoading(true);
    setLoadingError(null);
    
    try {
      // Set up real-time listeners
      const unsubscribeAppointments = subscribeToUpcomingAppointments((appointments) => {
        console.log(`Dashboard received ${appointments.length} appointments from subscription`);
        setUpcomingAppointments(appointments);
        setIsLoading(false);
      });
      
      // Clean up listeners on unmount
      return () => {
        unsubscribeAppointments();
      };
    } catch (error) {
      console.error('Error setting up appointment subscription:', error);
      setLoadingError('Failed to connect to the appointments database. Please try again later.');
      setIsLoading(false);
      
      // Return empty cleanup function
      return () => {};
    }
  }, []);

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-6">
      <div className="mb-6">
        <h1 className="text-2xl font-bold text-gray-900">Dashboard</h1>
        <p className="text-gray-500">{today}</p>
      </div>
      
      {/* Dashboard Metrics */}
      <DashboardMetrics />
      
      {/* Dashboard Content */}
      <div className="mt-6">
        {loadingError ? (
          <div className="bg-red-50 border border-red-200 rounded-lg p-4 mb-6">
            <div className="flex">
              <div className="flex-shrink-0">
                <svg className="h-5 w-5 text-red-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
              </div>
              <div className="ml-3">
                <h3 className="text-sm font-medium text-red-800">
                  Error Loading Appointments
                </h3>
                <div className="mt-2 text-sm text-red-700">
                  <p>{loadingError}</p>
                </div>
              </div>
            </div>
          </div>
        ) : (
          /* Upcoming Appointments Card */
          <UpcomingAppointmentsCard 
            appointments={upcomingAppointments}
            className="h-full"
            isLoading={isLoading}
          />
        )}
      </div>
    </div>
  );
};

export default Dashboard; 