import React from 'react';

// This interface represents a Google review
interface GoogleReview {
  author: string;
  rating: number;
  date: string;
  text: string;
  profilePhoto?: string;
}

const GoogleReviews: React.FC = () => {
  // Representative reviews with East African and Southern African names
  const reviews: GoogleReview[] = [
    {
      author: "Wambui Kamau",
      rating: 5,
      date: "2 months ago",
      text: "My experience at Huduma Center was exceptional. The staff were not only professional but also very helpful in guiding me through the HCA certification process. As someone who recently moved from Kenya, I appreciated their cultural sensitivity and patience. Highly recommend for anyone pursuing caregiver training!",
      profilePhoto: "https://ui-avatars.com/api/?name=Wambui+Kamau&background=0D8ABC&color=fff"
    },
    {
      author: "Themba Dlamini",
      rating: 5,
      date: "3 weeks ago",
      text: "Excellent training facility with knowledgeable instructors. I completed my caregiver certification here and found the experience very professional and thorough. The practical sessions were particularly helpful in preparing me for real-world scenarios. The staff were also very supportive throughout my job placement process.",
      profilePhoto: "https://ui-avatars.com/api/?name=Themba+Dlamini&background=27AE60&color=fff"
    },
    {
      author: "Njeri Mwangi",
      rating: 5,
      date: "1 month ago",
      text: "Huduma Center staff were incredibly helpful with my caregiver application. The process was smooth and they guided me through every step. What impressed me the most was how they followed up after I completed my training to ensure I was succeeding in my new role. Their dedication to student success is outstanding!",
      profilePhoto: "https://ui-avatars.com/api/?name=Njeri+Mwangi&background=8E44AD&color=fff"
    },
    {
      author: "Sibusiso Nkosi",
      rating: 5,
      date: "4 months ago",
      text: "I can't praise Huduma Center enough for their excellent training program. The instructors are passionate and truly care about preparing students for success in the healthcare field. The facility is clean and well-equipped, and they offer flexible scheduling which helped me balance work and studies.",
      profilePhoto: "https://ui-avatars.com/api/?name=Sibusiso+Nkosi&background=D35400&color=fff"
    },
    {
      author: "Akinyi Odhiambo",
      rating: 5,
      date: "2 months ago",
      text: "After relocating from Nairobi, I was looking for a training center that would understand my background and help me transition into the American healthcare system. Huduma Center exceeded my expectations. Not only did they provide excellent training, but they also offered valuable networking opportunities and job placement assistance.",
      profilePhoto: "https://ui-avatars.com/api/?name=Akinyi+Odhiambo&background=2C3E50&color=fff"
    },
    {
      author: "Tandzile Zwane",
      rating: 5,
      date: "3 months ago",
      text: "The caregiver program at Huduma Center is comprehensive and practical. I appreciated how the instructors incorporated real-world scenarios into our training. The administrative staff were also very helpful with paperwork and certification requirements. I'm now successfully employed and feel well-prepared for my role.",
      profilePhoto: "https://ui-avatars.com/api/?name=Tandzile+Zwane&background=16A085&color=fff"
    }
  ];

  // Function to render stars based on rating
  const renderStars = (rating: number) => {
    const stars = [];
    for (let i = 1; i <= 5; i++) {
      stars.push(
        <svg 
          key={i}
          className={`h-5 w-5 ${i <= rating ? 'text-yellow-400' : 'text-gray-300'}`}
          xmlns="http://www.w3.org/2000/svg" 
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
        </svg>
      );
    }
    return stars;
  };

  return (
    <div className="bg-gray-50 py-16 px-4 sm:px-6 lg:px-8 rounded-lg my-12">
      <div className="max-w-7xl mx-auto">
        <div className="text-center">
          <h2 className="text-3xl font-extrabold text-gray-900 sm:text-4xl">
            What Our Clients Say
          </h2>
          <p className="mt-3 max-w-2xl mx-auto text-xl text-gray-500 sm:mt-4">
            Read reviews from our clients on Google
          </p>
          
          <div className="mt-2 flex items-center justify-center">
            <div className="flex items-center">
              {renderStars(5)}
              <p className="ml-2 text-sm text-gray-600">5.0 out of 5 stars (21 reviews)</p>
            </div>
          </div>
        </div>

        <div className="mt-12 grid gap-6 lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1">
          {reviews.map((review, index) => (
            <div 
              key={index}
              className="bg-white rounded-lg shadow-md p-6 hover:shadow-lg transition-shadow border border-gray-100"
            >
              <div className="flex items-center mb-4">
                {review.profilePhoto ? (
                  <img 
                    src={review.profilePhoto} 
                    alt={review.author}
                    className="h-12 w-12 rounded-full mr-4"
                  />
                ) : (
                  <div className="h-12 w-12 rounded-full bg-blue-100 flex items-center justify-center mr-4">
                    <span className="text-blue-800 font-medium text-lg">
                      {review.author.charAt(0)}
                    </span>
                  </div>
                )}
                <div>
                  <h3 className="text-lg font-medium text-gray-900">{review.author}</h3>
                  <div className="flex items-center">
                    <div className="flex">
                      {renderStars(review.rating)}
                    </div>
                    <span className="ml-2 text-sm text-gray-500">{review.date}</span>
                  </div>
                </div>
              </div>
              <p className="text-gray-600 text-sm">{review.text}</p>
            </div>
          ))}
        </div>

        <div className="mt-12 text-center">
          <a 
            href="https://g.page/r/CeRbpBBUkl9aEBM/review" 
            target="_blank" 
            rel="noopener noreferrer" 
            className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-blue-700 hover:bg-blue-800 shadow-md transition-all duration-200"
          >
            <svg className="w-5 h-5 mr-2" fill="currentColor" viewBox="0 0 24 24">
              <path d="M12.48 10.92v3.28h7.84c-.24 1.84-.853 3.187-1.787 4.133-1.147 1.147-2.933 2.4-6.053 2.4-4.827 0-8.6-3.893-8.6-8.72s3.773-8.72 8.6-8.72c2.6 0 4.507 1.027 5.907 2.347l2.307-2.307C18.747 1.44 16.133 0 12.48 0 5.867 0 .307 5.387.307 12s5.56 12 12.173 12c3.573 0 6.267-1.173 8.373-3.36 2.16-2.16 2.84-5.213 2.84-7.667 0-.76-.053-1.467-.173-2.053H12.48z"/>
            </svg>
            View All Google Reviews
          </a>
        </div>
      </div>
    </div>
  );
};

export default GoogleReviews; 