import React, { useState } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { 
  searchVisitorByPhone, 
  searchVisitorByEmail, 
  searchVisitorByName,
  registerNewVisitor,
  checkInVisitor,
  checkOutVisitor,
  getVisitorHistory,
  notifyStaffOfVisitorCheckIn
} from '../services/visitorService';

// Import subcomponents
import StepIndicator from './VisitorRegistration/StepIndicator';
import VisitorSearch from './VisitorRegistration/VisitorSearch';
import VisitorDetails from './VisitorRegistration/VisitorDetails';
import VisitorRegistrationFormComponent from './VisitorRegistration/VisitorRegistrationForm';
import VisitorConfirmation from './VisitorRegistration/VisitorConfirmation';

// Type definitions
type RegistrationStep = 'search' | 'results' | 'register' | 'confirmation';
type SearchType = 'phone' | 'email' | 'name';
type StepStatus = 'active' | 'completed' | 'upcoming';

interface Step {
  id: string;
  label: string;
  status: StepStatus;
}

const VisitorRegistrationForm: React.FC = () => {
  // Auth context
  const { currentUser } = useAuth();
  
  // State management
  const [currentStep, setCurrentStep] = useState<RegistrationStep>('search');
  const [foundVisitor, setFoundVisitor] = useState<any>(null);
  const [searchResults, setSearchResults] = useState<any[]>([]);
  const [visitorHistory, setVisitorHistory] = useState<any[]>([]);
  const [isSearching, setIsSearching] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [searchError, setSearchError] = useState<string | null>(null);
  const [initialFormData, setInitialFormData] = useState<any>({});
  const [checkInOutAction, setCheckInOutAction] = useState<'check-in' | 'check-out'>('check-in');

  // Generate steps for the step indicator
  const getSteps = (): Step[] => [
    { 
      id: 'search', 
      label: 'Search', 
      status: currentStep === 'search' ? 'active' : 'completed' 
    },
    { 
      id: 'results', 
      label: foundVisitor ? 'Visitor Found' : 'Register New', 
      status: (currentStep === 'results' || currentStep === 'register') ? 'active' : 
              (currentStep === 'confirmation' ? 'completed' : 'upcoming')
    },
    { 
      id: 'confirmation', 
      label: 'Confirmation', 
      status: currentStep === 'confirmation' ? 'active' : 'upcoming'
    }
  ];
  
  // Search for visitor
  const handleSearch = async (query: string, type: SearchType): Promise<void> => {
    if (!query.trim()) {
      setSearchError('Please enter a search term');
      return;
    }
    
    setIsSearching(true);
    setSearchError(null);
    setSearchResults([]);
    
    try {
      let result;
      
      switch (type) {
        case 'phone':
          result = await searchVisitorByPhone(query);
          // If result is an array, it means multiple matches were found
          if (Array.isArray(result)) {
            handleMultipleResults(result, query, type);
          } else if (result) {
            // Single result found
            setFoundVisitor(result);
            setSearchResults([]);
            // Also fetch visitor history
            const history = await getVisitorHistory(result.id);
            setVisitorHistory(history);
            setCurrentStep('results');
          } else {
            handleNoResults(query, type);
          }
          break;
          
        case 'email':
          result = await searchVisitorByEmail(query);
          handleMultipleResults(result, query, type);
          break;
          
        case 'name':
          result = await searchVisitorByName(query);
          handleMultipleResults(result, query, type);
          break;
          
        default:
          result = await searchVisitorByPhone(query);
          if (Array.isArray(result)) {
            handleMultipleResults(result, query, type);
          } else if (result) {
            // Single result found
            setFoundVisitor(result);
            setSearchResults([]);
            // Also fetch visitor history
            const history = await getVisitorHistory(result.id);
            setVisitorHistory(history);
            setCurrentStep('results');
          } else {
            handleNoResults(query, type);
          }
      }
    } catch (error) {
      console.error('Error searching for visitor:', error);
      setSearchError('An error occurred while searching. Please try again.');
    } finally {
      setIsSearching(false);
    }
  };
  
  // Handle multiple search results
  const handleMultipleResults = (results: any[] | null, query: string, type: SearchType) => {
    if (results && results.length > 0) {
      if (results.length === 1) {
        // If only one result, proceed directly
        setFoundVisitor(results[0]);
        setSearchResults([]);
        // Also fetch visitor history
        getVisitorHistory(results[0].id).then(history => {
          setVisitorHistory(history);
        });
        setCurrentStep('results');
      } else {
        // If multiple results, store them for user selection
        setSearchResults(results);
        setFoundVisitor(null);
      }
    } else {
      handleNoResults(query, type);
    }
  };
  
  // Handle no search results
  const handleNoResults = (query: string, type: SearchType) => {
    setFoundVisitor(null);
    setSearchResults([]);
    setSearchError('No visitor found with the provided information');
    // Pre-fill phone or email in the new visitor form
    if (type === 'phone') {
      setInitialFormData({...initialFormData, phoneNumber: query});
    } else if (type === 'email') {
      setInitialFormData({...initialFormData, email: query});
    } else if (type === 'name') {
      setInitialFormData({...initialFormData, fullName: query});
    }
  };
  
  // Select a visitor from search results
  const handleSelectVisitor = async (visitor: any) => {
    setFoundVisitor(visitor);
    setSearchResults([]);
    // Also fetch visitor history
    const history = await getVisitorHistory(visitor.id);
    setVisitorHistory(history);
    setCurrentStep('results');
  };
  
  // Check in a visitor
  const handleCheckIn = async (): Promise<void> => {
    if (!foundVisitor) return;
    
    setIsProcessing(true);
    
    try {
      await checkInVisitor(foundVisitor.id);
      
      // Send notification to staff
      if (foundVisitor.purpose) {
        await notifyStaffOfVisitorCheckIn(foundVisitor.fullName, foundVisitor.purpose);
      }
      
      setCheckInOutAction('check-in');
      setCurrentStep('confirmation');
    } catch (error) {
      console.error('Error checking in visitor:', error);
      setSearchError('Failed to check in visitor. Please try again.');
    } finally {
      setIsProcessing(false);
    }
  };
  
  // Check out a visitor
  const handleCheckOut = async (): Promise<void> => {
    if (!foundVisitor) return;
    
    setIsProcessing(true);
    
    try {
      await checkOutVisitor(foundVisitor.id);
      setCheckInOutAction('check-out');
      setCurrentStep('confirmation');
    } catch (error) {
      console.error('Error checking out visitor:', error);
      setSearchError('Failed to check out visitor. Please try again.');
    } finally {
      setIsProcessing(false);
    }
  };
  
  // Register a new visitor
  const handleRegisterVisitor = async (visitorData: any): Promise<void> => {
    setIsProcessing(true);
    
    try {
      // Add created by info
      const dataToSubmit = {
        ...visitorData,
        createdBy: currentUser?.uid || '',
      };
      
      // Register new visitor
      const visitorId = await registerNewVisitor(dataToSubmit);
      
      // Update found visitor
      setFoundVisitor({
        id: visitorId,
        ...dataToSubmit,
        checkInTime: new Date(),
        status: 'checked-in'
      });
      
      // Send notification to staff
      if (visitorData.purpose) {
        await notifyStaffOfVisitorCheckIn(visitorData.fullName, visitorData.purpose);
      }
      
      setCheckInOutAction('check-in');
      setCurrentStep('confirmation');
    } catch (error) {
      console.error('Error registering visitor:', error);
      setSearchError('Failed to register visitor. Please try again.');
    } finally {
      setIsProcessing(false);
    }
  };
  
  // Reset form
  const handleReset = () => {
    setFoundVisitor(null);
    setSearchResults([]);
    setVisitorHistory([]);
    setSearchError(null);
    setInitialFormData({});
    setCurrentStep('search');
  };
  
  // Render search results list
  const renderSearchResults = () => {
    if (searchResults.length === 0) return null;
    
    return (
      <div className="mt-4 border rounded-lg p-4">
        <h3 className="text-lg font-medium text-gray-900 mb-2">Multiple visitors found</h3>
        <p className="text-sm text-gray-600 mb-4">
          Multiple visitors match your search. Please select one:
        </p>
        <div className="space-y-2 max-h-80 overflow-y-auto">
          {searchResults.map((visitor, index) => (
            <div 
              key={visitor.id || index}
              className="border rounded-lg p-3 cursor-pointer hover:bg-blue-50 transition-colors"
              onClick={() => handleSelectVisitor(visitor)}
            >
              <p className="font-medium">{visitor.fullName}</p>
              <div className="flex flex-wrap gap-4 text-sm text-gray-600">
                {visitor.phoneNumber && <p>Phone: {visitor.phoneNumber}</p>}
                {visitor.email && <p>Email: {visitor.email}</p>}
                {visitor.lastVisit && <p>Last Visit: {visitor.lastVisit.toLocaleDateString()}</p>}
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  };
  
  // Render the current step
  const renderStep = () => {
    switch (currentStep) {
      case 'search':
        return (
          <>
            <VisitorSearch 
              onSearch={handleSearch}
              onRegisterNew={() => setCurrentStep('register')}
              isSearching={isSearching}
              searchError={searchError}
            />
            {renderSearchResults()}
          </>
        );
      case 'results':
        return (
          <VisitorDetails 
            visitor={foundVisitor}
            visitorHistory={visitorHistory}
            onCheckIn={handleCheckIn}
            onCheckOut={handleCheckOut}
            onNewSearch={handleReset}
            isProcessing={isProcessing}
          />
        );
      case 'register':
        return (
          <VisitorRegistrationFormComponent 
            initialData={initialFormData}
            onRegister={handleRegisterVisitor}
            onCancel={handleReset}
            isProcessing={isProcessing}
          />
        );
      case 'confirmation':
        return (
          <VisitorConfirmation 
            visitor={foundVisitor}
            action={checkInOutAction}
            onNewSearch={handleReset}
            onVisitorDetails={() => setCurrentStep('results')}
          />
        );
      default:
        return <div>Invalid step</div>;
    }
  };
  
  // Render the component
  return (
    <div className="bg-white shadow-sm rounded-lg">
      <StepIndicator 
        steps={getSteps()} 
        currentStep={currentStep}
        className="pt-6 px-6"
      />
      {renderStep()}
    </div>
  );
};

export default VisitorRegistrationForm; 