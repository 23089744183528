import React from 'react';
import { Link } from 'react-router-dom';
import Card from '../components/ui/Card';

const AboutUsPage: React.FC = () => {
  return (
    <div className="bg-white">
      {/* Hero Section */}
      <div className="bg-gradient-to-r from-blue-900 to-blue-700 text-white">
        <div className="max-w-7xl mx-auto px-4 py-16 sm:px-6 lg:px-8 lg:py-24">
          <div className="text-center">
            <h1 className="text-4xl font-extrabold tracking-tight sm:text-5xl lg:text-6xl">
              About Huduma Center USA
            </h1>
            <p className="mt-6 text-xl max-w-3xl mx-auto">
              Simplifying access to essential services through innovative and efficient solutions
            </p>
          </div>
        </div>
      </div>
      
      {/* Mission & Vision */}
      <div className="max-w-7xl mx-auto px-4 py-16 sm:px-6 lg:px-8">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-12">
          <Card className="overflow-hidden shadow-lg">
            <div className="px-6 py-8">
              <div className="flex items-center mb-4">
                <div className="bg-blue-600 p-2 rounded-full mr-3">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 10V3L4 14h7v7l9-11h-7z" />
                  </svg>
                </div>
                <h2 className="text-2xl font-bold text-gray-900">Our Mission</h2>
              </div>
              <p className="text-gray-600 leading-relaxed">
                To transform the delivery of essential services by creating accessible, efficient, and user-friendly platforms that connect people with the services they need, when they need them. We strive to reduce administrative burdens, simplify processes, and ensure equitable access to services for all community members.
              </p>
            </div>
          </Card>
          
          <Card className="overflow-hidden shadow-lg">
            <div className="px-6 py-8">
              <div className="flex items-center mb-4">
                <div className="bg-blue-600 p-2 rounded-full mr-3">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
                  </svg>
                </div>
                <h2 className="text-2xl font-bold text-gray-900">Our Vision</h2>
              </div>
              <p className="text-gray-600 leading-relaxed">
                To be the premier one-stop center for public and essential services, recognized for excellence in service delivery, innovation, and customer satisfaction. We envision a future where administrative barriers are eliminated, and all individuals can access services with dignity, efficiency, and transparency.
              </p>
            </div>
          </Card>
        </div>
      </div>
      
      {/* Core Values */}
      <div className="bg-gray-50 py-16">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center mb-12">
            <h2 className="text-3xl font-extrabold text-gray-900">Our Core Values</h2>
            <p className="mt-4 text-lg text-gray-600 max-w-3xl mx-auto">
              The principles that guide our approach to service delivery and innovation
            </p>
          </div>
          
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
            <div className="bg-white p-6 rounded-lg shadow">
              <div className="w-12 h-12 bg-blue-100 text-blue-600 rounded-lg flex items-center justify-center mb-4">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z" />
                </svg>
              </div>
              <h3 className="text-xl font-bold text-gray-900 mb-2">Customer First</h3>
              <p className="text-gray-600">
                We prioritize customer needs and experiences in everything we do, ensuring services are designed around the people we serve.
              </p>
            </div>
            
            <div className="bg-white p-6 rounded-lg shadow">
              <div className="w-12 h-12 bg-blue-100 text-blue-600 rounded-lg flex items-center justify-center mb-4">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z" />
                </svg>
              </div>
              <h3 className="text-xl font-bold text-gray-900 mb-2">Integrity</h3>
              <p className="text-gray-600">
                We act with honesty, transparency, and ethical standards in all interactions, building trust within our community.
              </p>
            </div>
            
            <div className="bg-white p-6 rounded-lg shadow">
              <div className="w-12 h-12 bg-blue-100 text-blue-600 rounded-lg flex items-center justify-center mb-4">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 10V3L4 14h7v7l9-11h-7z" />
                </svg>
              </div>
              <h3 className="text-xl font-bold text-gray-900 mb-2">Innovation</h3>
              <p className="text-gray-600">
                We constantly seek better ways to deliver services, embracing technology and creative solutions to improve efficiency.
              </p>
            </div>
            
            <div className="bg-white p-6 rounded-lg shadow">
              <div className="w-12 h-12 bg-blue-100 text-blue-600 rounded-lg flex items-center justify-center mb-4">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M21 12a9 9 0 01-9 9m9-9a9 9 0 00-9-9m9 9H3m9 9a9 9 0 01-9-9m9 9c1.657 0 3-4.03 3-9s-1.343-9-3-9m0 18c-1.657 0-3-4.03-3-9s1.343-9 3-9m-9 9a9 9 0 019-9" />
                </svg>
              </div>
              <h3 className="text-xl font-bold text-gray-900 mb-2">Accessibility</h3>
              <p className="text-gray-600">
                We ensure our services are available to all community members, removing barriers to access through inclusive design.
              </p>
            </div>
          </div>
        </div>
      </div>
      
      {/* Our Story */}
      <div className="max-w-7xl mx-auto px-4 py-16 sm:px-6 lg:px-8">
        <div className="lg:grid lg:grid-cols-2 lg:gap-8 lg:items-center">
          <div>
            <h2 className="text-3xl font-extrabold text-gray-900 sm:text-4xl">
              Our Story
            </h2>
            <p className="mt-3 max-w-3xl text-lg text-gray-600">
              Huduma Center USA was established in 2024 with a mission to transform how essential services are delivered to the community. Inspired by successful service integration models globally, we saw an opportunity to create a centralized platform where people could access multiple services efficiently.
            </p>
            <p className="mt-3 max-w-3xl text-lg text-gray-600">
              What began as a small initiative to streamline appointment booking for community services has evolved into a comprehensive platform that now serves thousands of clients annually. Our journey reflects our commitment to continuous improvement and responsive service delivery.
            </p>
            <div className="mt-8">
              <Link to="/services" className="text-blue-600 hover:text-blue-500 font-medium">
                Learn more about our services <span aria-hidden="true">→</span>
              </Link>
            </div>
          </div>
          <div className="mt-10 lg:mt-0">
            <div className="bg-blue-100 rounded-lg p-6">
              <blockquote className="italic text-gray-700">
                "Huduma Center USA represents our commitment to putting people at the center of service delivery. By simplifying processes and creating intuitive systems, we're not just providing services—we're empowering communities."
                <div className="mt-4 font-medium text-gray-900">
                  — Kennedy Gichobi, Founder & CEO
                </div>
              </blockquote>
            </div>
          </div>
        </div>
      </div>
      
      {/* Our Team */}
      <div className="bg-gray-50 py-16">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center mb-12">
            <h2 className="text-3xl font-extrabold text-gray-900">Our Leadership Team</h2>
            <p className="mt-4 text-lg text-gray-600 max-w-3xl mx-auto">
              Dedicated professionals committed to excellence in service delivery
            </p>
          </div>
          
          <div className="flex justify-center">
            {/* Kennedy Gichobi */}
            <div className="bg-white rounded-lg overflow-hidden shadow-lg max-w-md">
              <div className="h-48 bg-blue-200 w-full flex items-center justify-center">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-24 w-24 text-blue-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1} d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
              </div>
              <div className="p-6">
                <h3 className="text-xl font-bold text-gray-900">Kennedy Gichobi</h3>
                <p className="text-blue-600 mb-4">Founder & Chief Executive Officer</p>
                <p className="text-gray-600">
                  With extensive experience in community service and administration, Kennedy founded Huduma Center USA to create an accessible platform that bridges the gap between essential services and the community.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      {/* Contact Section */}
      <div className="max-w-7xl mx-auto px-4 py-16 sm:px-6 lg:px-8">
        <div className="bg-blue-700 rounded-2xl shadow-xl overflow-hidden">
          <div className="pt-10 pb-12 px-6 sm:pt-16 sm:px-16 lg:py-16 lg:pr-0 xl:py-20 xl:px-20">
            <div className="lg:self-center">
              <h2 className="text-3xl font-extrabold text-white sm:text-4xl">
                <span className="block">Ready to get started?</span>
                <span className="block">Book an appointment today.</span>
              </h2>
              <p className="mt-4 text-lg leading-6 text-blue-200">
                Our team is ready to assist you with all your service needs. Schedule an appointment or reach out to learn more about how we can help.
              </p>
              <div className="mt-8 flex space-x-4">
                <Link to="/book-appointment" className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-blue-700 bg-white hover:bg-blue-50">
                  Book Appointment
                </Link>
                <Link to="/contact" className="inline-flex items-center justify-center px-5 py-3 border border-white text-base font-medium rounded-md text-white hover:bg-blue-800">
                  Contact Us
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUsPage; 