import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { 
  getAllHCAApplications, 
  SavedHCAApplication, 
  updateHCAApplicationStatus,
  ApplicationStatus
} from '../services/applicationService';
import Button from '../components/ui/Button';
import { Timestamp } from 'firebase/firestore';

// Application filter type
type ApplicationFilter = 'all' | ApplicationStatus;

const HCAApplications: React.FC = () => {
  const navigate = useNavigate();
  const [applications, setApplications] = useState<SavedHCAApplication[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [refreshing, setRefreshing] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [currentFilter, setCurrentFilter] = useState<ApplicationFilter>('all');
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [selectedApplication, setSelectedApplication] = useState<SavedHCAApplication | null>(null);
  const [isUpdating, setIsUpdating] = useState(false);

  // Get URL parameters
  const [searchParams] = useSearchParams();
  
  // Set filter based on URL parameter
  useEffect(() => {
    const statusParam = searchParams.get('status');
    if (statusParam && (statusParam === 'pending' || statusParam === 'completed' || 
        statusParam === 'waiting-for-certificate' || statusParam === 'waiting-for-payment')) {
      setCurrentFilter(statusParam as ApplicationFilter);
      console.log(`Setting filter to ${statusParam} from URL parameter`);
    }
  }, [searchParams]);

  // Fetch applications on component mount
  useEffect(() => {
    const fetchApplications = async () => {
      try {
        setError(null);
        setRefreshing(true);
        const fetchedApplications = await getAllHCAApplications();
        setApplications(fetchedApplications);
      } catch (err) {
        console.error('Error fetching applications:', err);
        setError('Failed to load applications. Please try again.');
      } finally {
        setLoading(false);
        setRefreshing(false);
      }
    };

    fetchApplications();
  }, []);

  // Format the date from Firestore timestamp
  const formatDate = (timestamp: any) => {
    if (!timestamp) return 'N/A';
    
    if (timestamp instanceof Timestamp) {
      const date = timestamp.toDate();
      return new Intl.DateTimeFormat('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      }).format(date);
    }
    
    return 'N/A';
  };

  // Get status badge color based on status
  const getStatusBadgeColor = (status: ApplicationStatus) => {
    switch (status) {
      case 'pending':
        return 'bg-yellow-100 text-yellow-800 border border-yellow-300';
      case 'waiting-for-certificate':
        return 'bg-blue-100 text-blue-800 border border-blue-300';
      case 'waiting-for-payment':
        return 'bg-purple-100 text-purple-800 border border-purple-300';
      case 'certificate-expired':
        return 'bg-red-100 text-red-800 border border-red-300';
      case 'completed':
        return 'bg-green-100 text-green-800 border border-green-300';
      case 'rejected':
        return 'bg-red-100 text-red-800 border border-red-300';
      default:
        return 'bg-gray-100 text-gray-800 border border-gray-300';
    }
  };

  // Format status for display
  const formatStatus = (status: ApplicationStatus) => {
    switch (status) {
      case 'pending':
        return 'Pending';
      case 'waiting-for-certificate':
        return 'Wait for 75hrs Certificate';
      case 'waiting-for-payment':
        return 'Waiting for Payment';
      case 'certificate-expired':
        return '75hrs Certificate Expired';
      case 'completed':
        return 'Completed';
      case 'rejected':
        return 'Rejected';
      default:
        return status;
    }
  };

  // Handle status change
  const handleStatusChange = async (applicationId: string, newStatus: ApplicationStatus) => {
    try {
      setIsUpdating(true);
      await updateHCAApplicationStatus(applicationId, newStatus);
      
      // Update local state
      setApplications(prevApplications => 
        prevApplications.map(app => 
          app.applicationId === applicationId 
            ? { ...app, status: newStatus } 
            : app
        )
      );

      // If the selected application is the one being updated, also update it
      if (selectedApplication?.applicationId === applicationId) {
        setSelectedApplication(prev => prev ? { ...prev, status: newStatus } : null);
      }
    } catch (error) {
      console.error('Error updating application status:', error);
      setError('Failed to update status. Please try again.');
    } finally {
      setIsUpdating(false);
    }
  };

  // Close detail view
  const handleCloseDetail = () => {
    setSelectedApplication(null);
  };

  // Handle row click to navigate to details page
  const handleRowClick = (applicationId: string) => {
    navigate(`/applications/${applicationId}`);
  };

  // Filter applications based on current filter and search term
  const filteredApplications = applications.filter(app => {
    // First filter by status
    const statusMatch = currentFilter === 'all' || app.status === currentFilter;
    
    // Then filter by search term (if any)
    if (!searchTerm) return statusMatch;
    
    const searchLower = searchTerm.toLowerCase();
    return statusMatch && (
      app.firstName.toLowerCase().includes(searchLower) ||
      app.lastName.toLowerCase().includes(searchLower) ||
      app.email.toLowerCase().includes(searchLower) ||
      app.applicationId?.toLowerCase().includes(searchLower)
    );
  });
  
  // Get status counts for the filter badges
  const getStatusCount = (status: ApplicationFilter) => {
    if (status === 'all') return applications.length;
    return applications.filter(app => app.status === status).length;
  };

  return (
    <div className="max-w-7xl mx-auto px-4 py-8">
      <div className="flex flex-col md:flex-row md:items-center justify-between mb-6">
        <div>
          <h1 className="text-2xl lg:text-3xl font-bold text-gray-900">HCA Applications</h1>
          <p className="mt-1 text-sm text-gray-500">
            Manage and review HCA exam registration applications
          </p>
        </div>
        
        <div className="mt-4 md:mt-0 flex items-center">
          <button
            onClick={() => {
              setLoading(true);
              setError(null);
              getAllHCAApplications()
                .then(data => {
                  setApplications(data);
                  setLoading(false);
                })
                .catch(err => {
                  console.error('Error refreshing applications:', err);
                  setError('Failed to refresh applications. Please try again.');
                  setLoading(false);
                });
            }}
            disabled={refreshing}
            className="ml-3 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50"
            aria-label="Refresh applications"
          >
            {refreshing ? (
              <>
                <svg className="animate-spin -ml-1 mr-2 h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
                Refreshing...
              </>
            ) : (
              <>
                <svg className="-ml-1 mr-2 h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
                </svg>
                Refresh
              </>
            )}
          </button>
        </div>
      </div>
      
      {/* Error message */}
      {error && (
        <div className="mb-6 bg-red-50 border border-red-200 rounded-md p-4">
          <div className="flex">
            <div className="flex-shrink-0">
              <svg className="h-5 w-5 text-red-400" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clipRule="evenodd" />
              </svg>
            </div>
            <div className="ml-3">
              <p className="text-sm text-red-700">{error}</p>
            </div>
          </div>
        </div>
      )}
      
      {/* Filters and search */}
      <div className="mb-6 bg-white shadow-sm rounded-lg border border-gray-200 p-4">
        <div className="flex flex-col md:flex-row md:items-center space-y-3 md:space-y-0 md:space-x-4">
          <div className="flex flex-wrap gap-2">
            <button
              onClick={() => setCurrentFilter('all')}
              className={`px-3 py-1 text-sm font-medium rounded-full ${
                currentFilter === 'all'
                  ? 'bg-blue-100 text-blue-800 border border-blue-300'
                  : 'bg-gray-100 text-gray-800 border border-gray-300 hover:bg-gray-200'
              }`}
            >
              All ({getStatusCount('all')})
            </button>
            <button
              onClick={() => setCurrentFilter('pending')}
              className={`px-3 py-1 text-sm font-medium rounded-full ${
                currentFilter === 'pending'
                  ? 'bg-yellow-100 text-yellow-800 border border-yellow-300'
                  : 'bg-gray-100 text-gray-800 border border-gray-300 hover:bg-gray-200'
              }`}
            >
              Pending ({getStatusCount('pending')})
            </button>
            <button
              onClick={() => setCurrentFilter('waiting-for-certificate')}
              className={`px-3 py-1 text-sm font-medium rounded-full ${
                currentFilter === 'waiting-for-certificate'
                  ? 'bg-blue-100 text-blue-800 border border-blue-300'
                  : 'bg-gray-100 text-gray-800 border border-gray-300 hover:bg-gray-200'
              }`}
            >
              Wait for Certificate ({getStatusCount('waiting-for-certificate')})
            </button>
            <button
              onClick={() => setCurrentFilter('waiting-for-payment')}
              className={`px-3 py-1 text-sm font-medium rounded-full ${
                currentFilter === 'waiting-for-payment'
                  ? 'bg-purple-100 text-purple-800 border border-purple-300'
                  : 'bg-gray-100 text-gray-800 border border-gray-300 hover:bg-gray-200'
              }`}
            >
              Waiting for Payment ({getStatusCount('waiting-for-payment')})
            </button>
            <button
              onClick={() => setCurrentFilter('completed')}
              className={`px-3 py-1 text-sm font-medium rounded-full ${
                currentFilter === 'completed'
                  ? 'bg-green-100 text-green-800 border border-green-300'
                  : 'bg-gray-100 text-gray-800 border border-gray-300 hover:bg-gray-200'
              }`}
            >
              Completed ({getStatusCount('completed')})
            </button>
          </div>
          
          <div className="flex-grow">
            <div className="relative">
              <input
                type="text"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                placeholder="Search applications..."
                className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500"
              />
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <svg className="h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor">
                  <path fillRule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clipRule="evenodd" />
                </svg>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      {/* Applications table */}
      <div className="bg-white shadow-sm rounded-lg border border-gray-200 overflow-hidden">
        {loading ? (
          <div className="p-6 flex justify-center">
            <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-900"></div>
          </div>
        ) : applications.length === 0 ? (
          <div className="p-6 text-center">
            <svg xmlns="http://www.w3.org/2000/svg" className="mx-auto h-12 w-12 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
            </svg>
            <h3 className="mt-2 text-sm font-medium text-gray-900">No applications found</h3>
            <p className="mt-1 text-sm text-gray-500">
              No applications have been submitted yet.
            </p>
            <div className="mt-6">
              <button
                onClick={() => {
                  setLoading(true);
                  setError(null);
                  getAllHCAApplications()
                    .then(data => {
                      setApplications(data);
                      setLoading(false);
                    })
                    .catch(err => {
                      console.error('Error refreshing applications:', err);
                      setError('Failed to refresh applications. Please try again.');
                      setLoading(false);
                    });
                }}
                className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                Refresh
              </button>
            </div>
          </div>
        ) : filteredApplications.length === 0 ? (
          <div className="p-6 text-center">
            <svg xmlns="http://www.w3.org/2000/svg" className="mx-auto h-12 w-12 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
            </svg>
            <h3 className="mt-2 text-sm font-medium text-gray-900">No results found</h3>
            <p className="mt-1 text-sm text-gray-500">
              Try adjusting your search or filter to find what you're looking for.
            </p>
            <div className="mt-6">
              <button
                onClick={() => {
                  setCurrentFilter('all');
                  setSearchTerm('');
                }}
                className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                Clear filters
              </button>
            </div>
          </div>
        ) : (
          <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Applicant
                  </th>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Submission Date
                  </th>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Status
                  </th>
                  <th scope="col" className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {filteredApplications.map((application) => (
                  <tr 
                    key={application.applicationId} 
                    onClick={() => handleRowClick(application.applicationId || '')}
                    className="hover:bg-gray-50 cursor-pointer transition-colors"
                  >
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="flex items-center">
                        <div>
                          <div className="text-sm font-medium text-gray-900">
                            {application.firstName} {application.lastName}
                          </div>
                          <div className="text-sm text-gray-500">{application.email}</div>
                        </div>
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm text-gray-900">{formatDate(application.createdAt)}</div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${getStatusBadgeColor(application.status)}`}>
                        {formatStatus(application.status)}
                      </span>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                      <button
                        onClick={(e) => {
                          e.stopPropagation();
                          handleRowClick(application.applicationId || '');
                        }}
                        className="text-blue-600 hover:text-blue-900 focus:outline-none focus:underline"
                        aria-label={`View details for ${application.firstName} ${application.lastName}`}
                      >
                        View Details
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  );
};

export default HCAApplications;